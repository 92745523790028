import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';
import { iconTypes } from '@ace/core-visuals';

import Block from '../structure/Block';
import Amount from '../Amount';

import s from './Balance.module.scss';

import i18n from './messages';

const Balance = ({ amount, id, intl: { formatMessage }, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <Block pv="small" id={thisId}>
      <div className={s.this} title={amount}>
        <span className={s.label}>{formatMessage(i18n.balanceLabel)}</span>
        <Amount currency={iconTypes.mBTC} parentId={thisId} value={amount} view="small" />
      </div>
    </Block>
  );
};

Balance.propTypes = {
  amount: PropTypes.string.isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string
};

Balance.defaultProps = {
  id: 'balance',
  parentId: ''
};

export { Balance as BalanceWithoutIntl };

export default injectIntl(Balance);
