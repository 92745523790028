import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import { Button, Popup } from '@ace/core-components';
import messages from './messages';

const PassphraseCopiedPopup = ({ intl: { formatMessage }, isActive, onButtonClick, onClose }) => (
  <Popup
    intro={<FormattedHTMLMessage {...messages.passphraseCopiedPopup} />}
    isActive={isActive}
    onClose={onClose}
    theme="success"
    title={formatMessage(messages.successStatus)}
  >
    <Button
      onClick={onButtonClick}
      theme="primary"
      title={formatMessage(messages.okayButton)}
      value={formatMessage(messages.okayButton)}
      view="cta"
    />
  </Popup>
);

PassphraseCopiedPopup.propTypes = {
  intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

PassphraseCopiedPopup.defaultProps = {
  isActive: false
};

export default injectIntl(PassphraseCopiedPopup);
