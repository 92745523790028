import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { Icon, iconTypes } from '@ace/core-visuals';
import s from './FieldFile.module.scss';

const FieldFileDisplay = ({
  fieldName,
  file,
  fileList,
  form,
  id,
  inputRef,
  parentId,
  title,
  canRemove
}) => {
  const thisId = components.generateId(id, parentId);

  const fileInput = inputRef;
  let Tag = 'a';
  let src = file;
  let download = true;
  let target = '_blank';
  let view = 'link';
  if (typeof file !== 'string') {
    Tag = 'div';
    src = null;
    download = false;
    target = undefined;
    view = 'block';
  }
  const handleRemove = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    // Set the value of the input to blank so the file can be selected again
    if (fileInput) {
      fileInput.value = '';
    }
    // Remove from the Redux form
    form.change(fieldName, fileList.filter(f => f.name !== file.name));
  };
  return (
    <Tag href={src} download={download} id={thisId} target={target} className={s[view]}>
      {canRemove && (
        <button type="button" className={s.remove} onClick={handleRemove}>
          <Icon parentId={thisId} type={iconTypes.navigationClose} />
        </button>
      )}
      <span className={s.content}>
        <span className={s.title}>{title}</span>
      </span>
    </Tag>
  );
};

FieldFileDisplay.propTypes = {
  canRemove: PropTypes.bool,
  fieldName: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
  fileList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  form: PropTypes.shape({}),
  id: PropTypes.string,
  inputRef: PropTypes.instanceOf(Element),
  parentId: PropTypes.string,
  title: PropTypes.string.isRequired
};

FieldFileDisplay.defaultProps = {
  file: null,
  fileList: [],
  fieldName: null,
  form: null,
  id: 'field-file-display',
  inputRef: null,
  canRemove: true,
  parentId: ''
};

export default FieldFileDisplay;
