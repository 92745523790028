import { defineMessages } from 'react-intl';

export default defineMessages({
  authApp: 'Authenticator App',
  installed: 'Installed',
  nextButton: 'Next',
  offlineCodes: 'Offline Codes',
  para1: 'For backup method, you may select Authenticator App, Offline Codes or Text Message.',
  passphrase: 'Passphrase',
  phone: 'Text Message',
  skipButton: 'Skip for now',
  title: 'Backup Method.',
  viewCodes: 'View Codes',
});
