import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import s from './Block.module.scss';

const Block = ({ children, fullbleed, id, parentId, ph, pv, tag, title, view, ...rest }) => {
  const BlockTag = tag;

  const thisId = components.generateId(id, parentId);
  const thisClass = classNames({
    [s[view]]: true,
    [s[`_ph_${ph}`]]: ph && !fullbleed,
    [s[`_pv_${pv}`]]: pv && !fullbleed,
    [s._fullbleed]: fullbleed
  });
  return (
    <BlockTag className={thisClass} id={thisId} {...rest}>
      {title && <header className={s.title}>{title}</header>}
      {children}
    </BlockTag>
  );
};

Block.propTypes = {
  children: PropTypes.node,
  fullbleed: PropTypes.bool,
  id: PropTypes.string,
  parentId: PropTypes.string,
  ph: PropTypes.oneOf(['default', 'large', 'none', 'small']),
  pv: PropTypes.oneOf(['default', 'large', 'none', 'small']),
  tag: PropTypes.oneOf(['article', 'aside', 'footer', 'header', 'section', 'ul']),
  title: PropTypes.string,
  view: PropTypes.oneOf(['default', 'image', 'primary'])
};

Block.defaultProps = {
  children: null,
  fullbleed: false,
  id: 'block',
  parentId: '',
  ph: 'default',
  pv: 'default',
  tag: 'section',
  title: null,
  view: 'default'
};

export default Block;
