import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

import messagesEnUs from './translations/en-US.json';
import messagesZhCn from './translations/zh-CN.json';
import { switchLocale } from './store/actions/locale';

addLocaleData({ locale: 'en-US', parentLocale: 'en' });
addLocaleData({ locale: 'zh-CN', parentLocale: 'en' }); // TODO: switch to parentLocale zh (needs pluralisation formatters)

const messages = {
  'en-US': messagesEnUs,
  'zh-CN': messagesZhCn
};

export const locales = Object.keys(messages);

class LocaleProvider extends React.PureComponent {
  componentDidMount() {
    this.props.switchLocale();
  }

  render() {
    const { children, locale } = this.props;
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    );
  }
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  switchLocale: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  locale: state.locale
});

const mapDispatchToProps = {
  switchLocale: switchLocale.request
};

export default connect(mapStateToProps, mapDispatchToProps)(LocaleProvider);
