import queryString from 'query-string';
import { decodeToken, getQueryParams, history } from '@ace/core-utils';
import { API_USER_PROFILE } from '@ace/core-modules/src/user/actions';
import { APP_AUTH_VALIDATE } from '../actions';

const { idToken, accessToken, ...queryParams } = getQueryParams();
const decodedIdToken = decodeToken(idToken);
const decodedAccessToken = decodeToken(accessToken);
const validTokens = !!decodedIdToken.exp && !!decodedAccessToken.exp;

const initialState = {
  ...queryParams,
  accessToken,
  decodedIdToken,
  decodedAccessToken,
  idToken,
  tokensPendingValidation: validTokens,
  preventAclRedirects: validTokens,
  identifiedUser: false
};

const { pathname } = history.location;
history.replace({ pathname, search: queryString.stringify(queryParams) });

export const getParamsAceRole = state => state.params.decodedIdToken.aceRole;
export const getAuthInfo = state => state.params;
// If we "maybe" have valid tokens, we should prevent doing any routing until 'checking' with the API
export const getPreventAclRedirects = state => state.params.preventAclRedirects;
export const getTokensPendingValidation = state => state.params.tokensPendingValidation;

const params = (state = initialState, action) => {
  switch (action.type) {
    case APP_AUTH_VALIDATE.SUCCESS:
      return {
        ...state,
        identifiedUser: action.payload.identifiedUser,
        // Because now we know if they're valid or not.
        tokensPendingValidation: false,
        preventAclRedirects: false
      };

    case API_USER_PROFILE.SUCCESS:
      return {
        ...state,
        identifiedUser: true
      };

    default:
      return state;
  }
};

export default params;
