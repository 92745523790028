import React from 'react';
import { reduxForm, propTypes } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { parse } from 'query-string';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import asyncFormSubmit from '../../utils/asyncFormSubmit';
import { forgotPassword } from '../../store/actions/user';
import { getUserProfileFromJwtToken } from '../../store/reducers/profile';
import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import messages from './messages';
import { useFlag } from '../../flags';

function PasswordChange(props) {
  const {
    handleSubmit,
    initialValues,
    intl: { formatMessage },
    pristine,
    submitting,
    onSubmit,
    form,
    profile
  } = props;
  const onConfirmSubmit = React.useCallback(
    () => asyncFormSubmit(form, onSubmit).catch(() => {}),
    []
  );
  const usernameFlag = useFlag('username');
  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
      <PageMain>
        <Block>
          <form id={CONFIG.formNames.reset} onSubmit={handleSubmit(onConfirmSubmit)}>
            {usernameFlag && profile.username ? (
              <FieldInputs.UsernameOrEmail required disabled />
            ) : (
              <FieldInputs.Email required disabled />
            )}
          </form>
        </Block>
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.requestButton}>
          {label => (
            <Button
              disabled={pristine && !initialValues.username}
              form={CONFIG.formNames.reset}
              submitting={submitting}
              theme="primary"
              title={label}
              type="submit"
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
}

PasswordChange.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

const PasswordResetWithIntl = injectIntl(PasswordChange);

const PasswordResetWithIntlForm = reduxForm({
  form: CONFIG.formNames.reset
})(PasswordResetWithIntl);

const mapDispatchToProps = {
  onSubmit: forgotPassword.request
};

const mapStateToProps = state => {
  const profile = getUserProfileFromJwtToken(state);
  return {
    profile,
    initialValues: {
      username: profile ? profile.username || profile.email : '',
      email: profile ? profile.email : ''
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const location = (ownProps[0] && ownProps[0].location) || ownProps.location;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ...ownProps[0],
    params: parse(location.search)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PasswordResetWithIntlForm);
