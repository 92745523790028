import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { Page, PageMain, Menu, Button, PageFooter } from '@ace/core-components';
import * as clipboard from 'clipboard-polyfill';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { iconTypes } from '@ace/core-visuals';

import {
  setupPassphrase,
  apiUserData,
  selection,
  validatePassphrase
} from '../../store/actions/user';
import { getUserData, getPassphrase } from '../../store/reducers/mfa';
import { getUserProfile } from '../../store/reducers/profile';
import { getSelections } from '../../store/reducers/selection';
import PageHeader from '../../components/PageHeader';
import messages from './messages';
import PassphraseCopiedPopup from './PassphraseCopiedPopup';

import ValidateMfa from '../../components/ValidateMfa';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

class SetupPassphrase extends PureComponent {
  state = {
    passphraseCopiedPopup: false,
    showPassphrasePromptPage: false
  };

  componentDidMount() {
    const { form, requestUserData, passphrase, requestApiPassphrase, user, match } = this.props;

    if (!user) {
      requestUserData();
    } else if (!passphrase && match.isExact) {
      asyncFormSubmit(form, requestApiPassphrase, {}).catch(() => {});
    }
  }

  componentDidUpdate({ user }) {
    if (!user && this.props.user) {
      if (!this.props.passphrase && this.props.match.isExact) {
        asyncFormSubmit(this.props.form, this.props.requestApiPassphrase, {}).catch(() => {});
      }
    }
  }

  copyPassphrase = () => {
    const dt = new clipboard.DT();
    dt.setData('text/plain', this.props.passphrase);
    clipboard
      .write(dt)
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Failed to copy', e);
        return clipboard.writeText(this.props.passphrase);
      })
      .then(() => {
        this.props.saveSelection({ passphraseCopied: true });
        this.togglePassphraseCopiedPopup();
      });
  };

  togglePassphraseCopiedPopup = () => {
    this.setState(
      prevState => ({ passphraseCopiedPopup: !prevState.passphraseCopiedPopup }),
      () => {
        if (this.state.passphraseCopiedPopup === false) {
          this.onNextClick();
        }
      }
    );
  };

  onNextClick = () => {
    const {
      selections: { passphraseCopied },
      history,
      location,
      match: { url }
    } = this.props;
    if (passphraseCopied) {
      this.setState(
        {
          showPassphrasePromptPage: true
        },
        () => {
          history.push({
            ...location,
            pathname: `${url}/validate`
          });
        }
      );
    }
  };

  setPassphraseCode = formData => {
    const { form, requestPassphraseValidate, type, onSuccess } = this.props;

    return asyncFormSubmit(form, requestPassphraseValidate, {
      ...formData.params,
      auth_method: type
    })
      .then(() => {
        this.setState({ showPassphrasePromptPage: false }, onSuccess);
      })
      .catch(e => {
        if (formData.submitPromise) {
          formData.submitPromise.reject(e);
        }
      });
  };

  _renderPassphrase = () => {
    const {
      intl: { formatMessage },
      passphrase,
      selections: { passphraseCopied }
    } = this.props;

    const passphraseOptions = [
      {
        icon: iconTypes.menuTransactions,
        isSelected: passphraseCopied,
        label: formatMessage(messages.copyPassphrase),
        onClick: passphrase ? this.copyPassphrase : undefined
      }
    ];

    return <Menu items={passphraseOptions} />;
  };

  render() {
    const { showPassphrasePromptPage } = this.state;
    const {
      intl: { formatMessage },
      selections: { passphraseCopied },
      user,
      match: { path, url },
      location
    } = this.props;
    const { passphraseCopiedPopup } = this.state;
    const submitEnabled = passphraseCopied && user && !user.passphrase_auth_method;
    const renderPage = () => (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          {user ? this._renderPassphrase() : null}
          <PassphraseCopiedPopup
            isActive={passphraseCopiedPopup}
            onButtonClick={this.togglePassphraseCopiedPopup}
            onClose={this.togglePassphraseCopiedPopup}
          />
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.finishButton}>
            {label => (
              <Button
                disabled={!submitEnabled}
                onClick={this.onNextClick}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
    return (
      <Switch>
        <Route
          path={`${path}/validate`}
          render={() => {
            if (!showPassphrasePromptPage) {
              return <Redirect to={{ ...location, pathname: url }} />;
            }
            return (
              <ValidateMfa onSubmit={this.setPassphraseCode} method="passphrase" mode="validate" />
            );
          }}
        />
        <Route path={`${path}/resume`} render={renderPage} />
        <Route render={renderPage} />
      </Switch>
    );
  }
}

SetupPassphrase.propTypes = {
  intl: intlShape.isRequired,
  passphrase: PropTypes.string,
  requestApiPassphrase: PropTypes.func.isRequired,
  requestPassphraseValidate: PropTypes.func.isRequired,
  saveSelection: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

SetupPassphrase.defaultProps = {
  passphrase: null,
  onSuccess: () => {}
};

const mapStateToProps = state => ({
  profile: getUserProfile(state),
  user: getUserData(state),
  passphrase: getPassphrase(state),
  selections: getSelections(state)
});

const mapDispatchToProps = {
  requestApiPassphrase: setupPassphrase.request,
  saveSelection: selection.save,
  requestUserData: apiUserData.request,
  requestPassphraseValidate: validatePassphrase.request
};

const SavePassphraseConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupPassphrase);

export default injectIntl(SavePassphraseConnected);
