import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { iconTypes, Illustration } from '@ace/core-visuals';
import Amount from '../Amount';
import HeroBg from '../HeroBg';

import s from './TransferInProgress.module.scss';

import i18n from './messages';

const TransferInProgress = ({
  amount,
  children,
  id,
  intl: { formatMessage },
  parentId,
  view,
  user
}) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div id={thisId} className={s.this}>
      <HeroBg>
        <div className={s.summary}>
          <div className={s.user}>
            {user && user.avatar && (
              <div
                className={s.avatar}
                style={{
                  backgroundImage: `url(${user.avatar})`
                }}
              />
            )}
            <div className={s.sparkles}>
              {user && user.avatar ? (
                <Illustration
                  parentId={thisId}
                  title={formatMessage(i18n[`${view}IllustrationTitle`])}
                  type="transferBitcoin"
                />
              ) : (
                <Illustration
                  parentId={thisId}
                  title={formatMessage(i18n[`${view}NoAvatarIllustrationTitle`])}
                  type="transferBitcoinPlaceholder"
                />
              )}
            </div>
          </div>
          <div className={s.amount}>
            <div className={s.status}>{formatMessage(i18n[`${view}Status`])}</div>
            <Amount currency={iconTypes.mBTC} parentId={thisId} value={amount} view="large" />
            {view === 'deposit' && (
              <div className={s.comment}>{formatMessage(i18n.depositComment)}</div>
            )}
          </div>
        </div>
      </HeroBg>
      <div className={s.content} id={thisId}>
        <h1 className={s.title}>{formatMessage(i18n.title)}</h1>
        <div className={s.text}>{children}</div>
      </div>
    </div>
  );
};

TransferInProgress.propTypes = {
  amount: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string,
  user: PropTypes.shape({
    avatar: PropTypes.string
  }),
  view: PropTypes.oneOf(['deposit', 'distribution']).isRequired
};

TransferInProgress.defaultProps = {
  id: 'transfer-in-progress',
  parentId: '',
  user: null
};

export { TransferInProgress as TransferInProgressWithoutIntl };

export default injectIntl(TransferInProgress);
