import 'react-app-polyfill/ie9';
import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import history from './utils/history';
import configureStore from './store/store';
import App from './App';
import LocaleProvider from './i18n';
import 'typeface-montserrat/index.css';
import './styles/index.scss';
import './errors/messages';
import { PersistedFlagsPrivider } from './flags';

const rootElement = document.getElementById(CONFIG.applicationNodeName);
const store = configureStore();

console.log(`ACE v${CONFIG.appVersion}`); // eslint-disable-line no-console
console.log(`Commit SHA: ${CONFIG.appCommitSHA}`); // eslint-disable-line no-console

const renderApp = () => {
  render(
    <PersistedFlagsPrivider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <LocaleProvider>
            <Router history={history}>
              <App />
            </Router>
          </LocaleProvider>
        </PersistGate>
      </Provider>
    </PersistedFlagsPrivider>,
    rootElement
  );
};

if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
  });
}

renderApp();
