import React, { PureComponent } from 'react';
import { reduxForm, propTypes } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageFooter, PageMain, Button } from '@ace/core-components';

import asyncFormSubmit from '../../../../utils/asyncFormSubmit';
import Block from '../../../../components/Block';
import PageHeader from '../../../../components/PageHeader';
import Textarea from '../../../../components/Textarea';
import messages from './messages';

class PassphrasePage extends PureComponent {
  onConfirmSubmit = () => asyncFormSubmit(this.props.form, this.props.onSubmit).catch(() => {});

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
      pristine,
      submitting
    } = this.props;
    return (
      <Page>
        <FormattedMessage {...messages.title}>{title => <Helmet title={title} />}</FormattedMessage>
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          <form id={CONFIG.formNames.recover} onSubmit={handleSubmit(this.onConfirmSubmit)}>
            <Block>
              <FormattedMessage {...messages.passphrasePlaceholder}>
                {placeholder => <Textarea placeholder={placeholder} name="passphrase" />}
              </FormattedMessage>
            </Block>
          </form>
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.nextButton}>
            {label => (
              <Button
                disabled={pristine}
                form={CONFIG.formNames.recover}
                submitting={submitting}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

PassphrasePage.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

const PassphrasePageConnected = reduxForm({
  form: CONFIG.formNames.recover,
  destroyOnUnmount: false
})(PassphrasePage);

export default injectIntl(PassphrasePageConnected);
