import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import Notifications from '../../Notifications';
import s from './PageFooter.module.scss';

class PageFooter extends PureComponent {
  constructor() {
    super();
    this.footerRef = React.createRef();
    this.state = {
      footerHeight: undefined
    };
  }

  componentDidMount() {
    this.updateFooterHeight();
  }

  componentDidUpdate() {
    this.updateFooterHeight();
  }

  updateFooterHeight = () => {
    const { footerHeight } = this.state;
    const container = this.footerRef.current;
    // Need to set the state here to be sure of the component re-rendering with the correct placeholder height

    if (container && container.clientHeight !== footerHeight) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        footerHeight: container.clientHeight
      });
    }
  };

  render() {
    const { children, id, parentId, absolute, transparent } = this.props;
    const { footerHeight } = this.state;

    const thisId = components.generateId(id, parentId);
    const thisClass = classNames(s.this, {
      [s._transparent]: transparent,
      [s._absolute]: absolute
    });

    return (
      <React.Fragment>
        <footer className={thisClass} id={thisId}>
          <div className={s.container}>
            <div className={s.mainContainer} ref={this.footerRef}>
              <Notifications parentId={thisId} />
              {children}
            </div>
          </div>
        </footer>

        <div aria-hidden="true" className={s.placeholder} style={{ height: footerHeight }} />
      </React.Fragment>
    );
  }
}

PageFooter.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  parentId: PropTypes.string,
  transparent: PropTypes.bool
};

PageFooter.defaultProps = {
  children: null,
  id: 'page-footer',
  parentId: '',
  absolute: false,
  transparent: false
};

export default PageFooter;
