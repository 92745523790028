import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from 'query-string';

import messages from './messages';
import IconPage from '../../components/IconPage';
import AppRoutes from '../../routes';

function ChangeUsernameSuccess({ history, location }) {
  return (
    <IconPage
      title={messages.title}
      intro={messages.intro}
      icon="success"
      button={{
        label: messages.finishButton,
        onClick: () => {
          const params = parse(location.search);
          history.push({
            pathname: AppRoutes.signin.index,
            search: stringify({
              client_id: params.client_id,
              redirect_uri: params.redirect_uri
            })
          });
        }
      }}
    />
  );
}

ChangeUsernameSuccess.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(ChangeUsernameSuccess);
