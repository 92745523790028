import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import { Link } from 'react-router-dom';
import { Icon, iconTypeArray } from '@ace/core-visuals';
import s from '../MenuItem/MenuItem.module.scss';

class MenuLink extends PureComponent {
  render() {
    const {
      download,
      icon,
      id,
      isHighlighted,
      isSelected,
      href,
      label,
      linkTo,
      parentId,
      target,
      children,
      ...rest
    } = this.props;

    const thisId = components.generateId(id, parentId);
    const thisClass = classNames({
      [s.this]: true,
      [s._highlighted]: isHighlighted,
      [s._selected]: isSelected
    });
    let Tag = 'span';
    let contentView = 'block';
    let targetValue;
    if (linkTo) {
      Tag = Link;
      contentView = 'link';
    }
    if (href) {
      Tag = 'a';
      contentView = 'link';
      targetValue = target;
    }
    return (
      <li className={thisClass} id={thisId} {...rest}>
        <Tag
          className={s[contentView]}
          download={download}
          href={href}
          to={linkTo}
          target={targetValue}
        >
          {icon && (
            <span className={s.icon}>
              <Icon id="item-icon" parentId={thisId} type={icon} />
            </span>
          )}
          {label && <span className={s.label}>{label}</span>}
          <span className={s.statusIcon}>{children}</span>
        </Tag>
      </li>
    );
  }
}

MenuLink.propTypes = {
  children: PropTypes.node,
  download: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  parentId: PropTypes.string,
  target: PropTypes.string
};

MenuLink.defaultProps = {
  download: undefined,
  href: undefined,
  icon: undefined,
  id: 'menu-item',
  isHighlighted: false,
  isSelected: false,
  label: undefined,
  linkTo: undefined,
  parentId: '',
  target: undefined,
  children: null
};

export default MenuLink;
