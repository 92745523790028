import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmButton: 'Confirm',
  confirmMessage:
    '<strong>Warning!</strong> Your new user name will be: <b>{username}</b>. Please check the spelling and re-enter your username if needed. If everything is correct, tap Confirm to continue.',
  confirmPopupTitle: 'Confirm',
  intro: 'Please enter your new username, then tap Next to verify it.',
  invalidUsernameWith86: 'You can not use a Chinese phone number as a username',
  nextButton: 'Next',
  title: 'Change Username.',
  username: 'Username',
});
