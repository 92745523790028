import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import Block from '../structure/Block';
import s from './ListItem.module.scss';

const ListItem = ({ children, id, marker, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <Block id={thisId}>
      <div className={s.this}>
        {marker && <span className={s.marker}>{marker}</span>}
        <div className={s.content}>{children}</div>
      </div>
    </Block>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  marker: PropTypes.number,
  parentId: PropTypes.string
};

ListItem.defaultProps = {
  id: 'list-item',
  marker: null,
  parentId: ''
};

export default ListItem;
