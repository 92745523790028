import React from 'react';
import PropTypes from 'prop-types';
import s from './View.module.scss';

const View = ({ children }) => (
  <div className={s.this} id="view">
    <div className={s.content}>{children}</div>
  </div>
);

View.propTypes = {
  children: PropTypes.node.isRequired
};

export default View;
