import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, iconTypes } from '@ace/core-visuals';
import s from './MenuItem.module.scss';

class MenuItem extends PureComponent {
  render() {
    const { icon, active, label, ...rest } = this.props;

    const thisClass = classNames({
      [s.this]: true,
      [s._selected]: active,
      [s._used]: !active
    });
    return (
      <li className={thisClass} {...rest}>
        <span className={s.block}>
          {label && <span className={s.label}>{label}</span>}
          <span className={s.statusIcon}>
            <Icon id="status-icon" type={active ? iconTypes.tick : iconTypes.cross} />
          </span>
        </span>
      </li>
    );
  }
}

MenuItem.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.element,
  target: PropTypes.string
};

MenuItem.defaultProps = {
  active: false,
  label: undefined,
  target: undefined
};

export default MenuItem;
