import _ from 'lodash';
import error from './error.svg';
import failure from './failure.svg';
import freeBitcoin from './freeBitcoin.svg';
import heroBg from './heroBg.svg';
import insuranceBalance from './insuranceBalance.svg';
import login from './login.svg';
import maintenance from './maintenance.svg';
import noLocation from './noLocation.svg';
import noMessages from './noMessages.svg';
import noResults from './noResults.svg';
import noTransactions from './noTransactions.svg';
import password from './password.svg';
import rewardJems from './rewardJems.svg';
import success from './success.svg';
import logout from './logout.svg';
import transferBitcoin from './transferBitcoin.svg';
import transferBitcoinPlaceholder from './transferBitcoinPlaceholder.svg';
import verify from './verify.svg';
import welcome from './welcome.svg';

export const allIllustrations = {
  error,
  failure,
  freeBitcoin,
  heroBg,
  insuranceBalance,
  login,
  maintenance,
  noLocation,
  noMessages,
  noResults,
  noTransactions,
  password,
  rewardJems,
  success,
  logout,
  transferBitcoin,
  transferBitcoinPlaceholder,
  verify,
  welcome
};

export const illustrationTypes = _.mapValues(allIllustrations, (value, key) => {
  const val = key;
  return val;
});

export const illustrationTypeArray = Object.keys(illustrationTypes);
