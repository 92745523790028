import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageFooter, PageMain, Button } from '@ace/core-components';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import history from '../../utils/history';
import Block from '../../components/Block';
import Icon from '../../components/Icon';
import PageHeader from '../../components/PageHeader';

import messages from './messages';

class CheckEmail extends Component {
  onClickRequest = () => {
    switch (this.props.newRequest) {
      case 'updateEmailNewRequest':
        return this.props.history.push({
          pathname: '/email-reset',
          search: history.location.search.replace(/(security-code=[a-z0-9]*&)/, '')
        });
      default:
        return asyncFormSubmit(this.props.form, this.props.newRequest).catch(() => {});
    }
  };

  render() {
    const {
      intl: { formatMessage },
      type,
      newRequest = false,
      title = 'title',
      icon = 'email'
    } = this.props;
    return (
      <Page>
        <FormattedMessage {...messages[title]}>{t => <Helmet title={t} />}</FormattedMessage>
        <PageHeader
          back={false}
          intro={formatMessage(messages[type])}
          title={formatMessage(messages[title])}
        />
        <PageMain>
          <Block spacing="xl">
            <Icon icon={icon} />
          </Block>
        </PageMain>
        {newRequest && (
          <PageFooter>
            <FormattedMessage {...messages.request}>
              {label => (
                <Button
                  onClick={this.onClickRequest}
                  theme="primary"
                  title={label}
                  type="submit"
                  value={label}
                  view="cta"
                />
              )}
            </FormattedMessage>
          </PageFooter>
        )}
      </Page>
    );
  }
}

CheckEmail.propTypes = {
  intl: intlShape.isRequired,
  signup: PropTypes.bool
};

CheckEmail.defaultProps = {
  signup: false
};

export default injectIntl(CheckEmail);
