import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';

import s from './Divider.module.scss';

const Divider = ({ id, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return <span id={thisId} className={s.this} />;
};

Divider.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string
};

Divider.defaultProps = {
  id: 'divider',
  parentId: ''
};

export default Divider;
