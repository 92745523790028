import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import Button from '../Button';

import s from './ButtonList.module.scss';

const ButtonList = ({ buttons, id, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <ul className={s.this} id={thisId}>
      {buttons &&
        buttons.map(button => (
          <li className={s.button} key={button.title}>
            <Button id={button.title} parentId={thisId} {...button} />
          </li>
        ))}
    </ul>
  );
};

ButtonList.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string
};

ButtonList.defaultProps = {
  id: 'button-list',
  parentId: ''
};

export default ButtonList;
