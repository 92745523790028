import React from 'react';
import PropTypes from 'prop-types';
import { CustomLink } from '@ace/core-components';
import Timer from '../Timer';
import Styles from './CustomLinkWithTimer.module.scss';

function CustomLinkWithTimer(props) {
  const { showTimer, timer, children, ...rest } = props;
  return (
    <CustomLink className={Styles.customLinkWithTimer} {...rest}>
      {showTimer && <Timer timer={timer} />}
      {children}
    </CustomLink>
  );
}
CustomLinkWithTimer.propTypes = {
  children: PropTypes.element.isRequired,
  showTimer: PropTypes.bool.isRequired,
  timer: PropTypes.string.isRequired
};

export default CustomLinkWithTimer;
