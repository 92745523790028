import React from 'react';
import PropTypes from 'prop-types';
import styles from './SublinkMenuItem.module.scss';

function SublinkMenuItem({ label, sublabel }) {
  return (
    <div className={styles.container}>
      {label}
      {sublabel && <div className={styles.sublabel}>{sublabel}</div>}
    </div>
  );
}
SublinkMenuItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool])
};
SublinkMenuItem.defaultProps = {
  sublabel: null
};

export default SublinkMenuItem;
