import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmButton: 'Confirm',
  confirmPopup:
    '<strong>WARNING!</strong> Your new email address will be: <strong>{newEmail}</strong>. If you see an error please re-enter your email address now. Or tap confirm to continue.',
  confirmPopupTitle: 'Confirm new email',
  intro: 'Please enter your new email address.',
  title: 'New Email.',
  nextButton: 'Next'
});
