import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Block.module.scss';

const Block = ({ children, tag: Tag, spacing, align, view, ...props }) => {
  const thisClass = classNames({
    [styles[view]]: true,
    [styles[align]]: align,
    [styles[spacing]]: spacing
  });
  return (
    <Tag className={thisClass} {...props}>
      {children}
    </Tag>
  );
};

Block.propTypes = {
  align: PropTypes.oneOf(['', 'left', 'right', 'center']),
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf(['', 'sm', 'md', 'lg', 'xl']),
  tag: PropTypes.string, // TODO: whitelist tags
  view: PropTypes.oneOf(['block', 'html'])
};

Block.defaultProps = {
  align: '',
  spacing: '',
  tag: 'section',
  view: 'block'
};

export default Block;
