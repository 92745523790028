import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import { iconTypes } from '@ace/core-visuals';
import Button from '../Button';
import s from './Notification.module.scss';
import i18n from './messages';

const Notification = ({
  id,
  index,
  intl: { formatMessage },
  notification,
  parentId,
  onDismiss
}) => {
  const thisId = components.generateId(id, parentId);
  const contentId = components.generateId('content', thisId);
  const { code, message, theme, reportingEventId, values } = notification;

  const thisClass = classNames({
    [s.this]: true,
    [s[`_${theme}`]]: theme
  });

  // Only show the error message, if we recognise it, otherwise display defaults
  const genericCode = theme === 'error' ? 'DEFAULT_ERROR' : 'DEFAULT_SUCCESS';
  const lookupCode = code || message;
  const content = i18n[lookupCode] || i18n[genericCode];
  return (
    <div className={thisClass} id={thisId}>
      <div className={s.notification}>
        <span className={s.title}>
          <FormattedMessage {...i18n[theme]} />
        </span>
        <span className={s.content} id={contentId}>
          <FormattedMessage {...content} values={values} />
        </span>
        {reportingEventId && (
          <span className={s.reportMessage}>
            <FormattedMessage {...i18n.reportMessage} values={{ reportingEventId }} />
          </span>
        )}
        {lookupCode.match(/MS_ERROR_/) && (
          <span className={s.reportMessage}>{lookupCode.replace('errors.', '')}</span>
        )}
      </div>
      {onDismiss && (
        <div className={s.aside}>
          <Button
            icon={iconTypes.navigationClose}
            onClick={() => onDismiss(index)}
            parentId={thisId}
            theme="transparent"
            title={formatMessage(i18n.closeButtonTitle)}
            view="icon"
          />
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  notification: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
    theme: PropTypes.oneOf(['error', 'success']).isRequired,
    values: PropTypes.shape({})
  }),
  parentId: PropTypes.string,
  onDismiss: PropTypes.func
};

Notification.defaultProps = {
  id: 'notification',
  onDismiss: () => {},
  notification: {
    code: undefined,
    message: undefined,
    values: {}
  },
  parentId: ''
};

export { Notification as NotificationWithoutIntl };

export default injectIntl(Notification);
