import { parse, stringify } from 'query-string';
import getParams from './getParams';

export function buildUrlWithClientAndRedirect(url, internalRedirect = null, searchParams = null) {
  const params = { ...parse(searchParams), ...getParams() };

  if (internalRedirect) {
    params.internal_redirect = internalRedirect;
  }

  return `${url}?${stringify(params)}`;
}
