import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';
import { Icon, iconTypes } from '@ace/core-visuals';

import messages from '../messages';
import styles from '../Input.module.scss';

const getStatus = (message, type) => {
  switch (type) {
    case 'error':
      return {
        message,
        icon: iconTypes.statusError,
      };
    default:
      return {
        icon: iconTypes.statusSuccess,
        message: message || <FormattedMessage {...messages.valid} />,
      };
  }
};

const Status = ({ id, message, parentId, type }) => {
  const messageClassName = classNames(styles.message, styles[`message_${type}`]);
  const status = getStatus(message, type);
  const thisId = components.generateId(id, parentId);

  return (
    <div className={styles.status}>
      <Icon parentId={thisId} type={status.icon} viewBox={null} />
      <span className={messageClassName}>{status.message}</span>
    </div>
  );
};

Status.defaultProps = {
  id: 'status',
  message: '',
  parentId: '',
  type: undefined,
};

Status.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  parentId: PropTypes.string,
  type: PropTypes.string,
};

export default Status;
