import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components, numbers } from '@ace/core-utils';

import RewardBadge from '../RewardBadge';

import s from './BusinessReward.module.scss';

import i18n from './messages';

const BusinessReward = ({ id, intl: { formatMessage }, parentId, rewardPct }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.this} id={thisId}>
      <div className={s.reward}>
        <RewardBadge parentId={thisId} rewardPct={rewardPct} />
      </div>
      <aside className={s.aside}>
        <FormattedMessage
          {...i18n.info}
          values={{
            crypto: <b>{formatMessage(i18n.crypto)}</b>,
            rewardPctRange: (
              <span className={s.rewardRange}>{numbers.formatPctRange(rewardPct)}%</span>
            )
          }}
        />
      </aside>
    </div>
  );
};

BusinessReward.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string,
  rewardPct: PropTypes.string.isRequired
};

BusinessReward.defaultProps = {
  id: 'business-reward',
  parentId: ''
};

export { BusinessReward as BusinessRewardWithoutIntl };

export default injectIntl(BusinessReward);
