import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import RadioButtonsInput from './RadioButtons';
import s from './RadioButtons.module.scss';

const RadioButtons = props => (
  <React.Fragment>
    <div className={s.legend}>
      <FormattedMessage {...props.legend} />
    </div>
    <Field component={RadioButtonsInput} {...props} id={props.id} />
  </React.Fragment>
);

RadioButtons.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  parentId: PropTypes.string
};

RadioButtons.defaultProps = {
  id: 'radio-buttons',
  parentId: ''
};

export default RadioButtons;
