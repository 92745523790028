import { detect } from 'detect-browser';
import { APP_INSTALLER, APP_INSTALLER_COUNT } from '../actions';

const browser = detect();

const appStandalone =
  window.navigator.standalone ||
  window.matchMedia('(display-mode: fullscreen)').matches ||
  window.matchMedia('(display-mode: standalone)').matches;

const initialState = () => {
  switch (browser && browser.name) {
    case 'ios':
      // For iOS, we need to control when to display the prompt
      return {
        // browser.name returns 'ios' for native iOS browser
        appBrowser: !appStandalone && browser.name,
        appStandalone,
        installerReady: false,
        noDisplayBefore: null
      };

    default:
      // For non iOS, we can just let the browser handle when the prompt is available to display
      // We handled the capture and display of the event in the component
      return {
        appBrowser: !appStandalone && browser.name,
        appStandalone,
        installerReady: true
      };
  }
};

export const getIsAppStandalone = state => state.install.appStandalone;
export const getEnableIOSInstallableCount = state => {
  // Enables the counting of our interested urls for displaying in iOS
  const ageOk = Date.now() > state.install.noDisplayBefore;
  // installerReady will always be true for non-iOS, hence this only enables for iOS
  return !state.install.appStandalone && !state.install.installerReady && ageOk;
};
export const getDisplayIOSInstaller = state =>
  // noDisplayBefore will always be null in non IOS
  state.install.installerReady && !!state.install.noDisplayBefore;

const install = (state = initialState(), action) => {
  // These actions are only dispatched on iOS devices
  switch (action.type) {
    case APP_INSTALLER_COUNT.SUCCESS: {
      const oneWeek = 6.048e8;
      return {
        ...state,
        installerReady: true,
        noDisplayBefore: Date.now() + oneWeek
      };
    }

    case APP_INSTALLER.CLOSE:
      return {
        ...state,
        installerReady: false
      };

    default:
      return state;
  }
};

export default install;
