import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { Illustration } from '@ace/core-visuals';
import TextBlock from '../structure/TextBlock';

import s from './NoResults.module.scss';

import i18n from './messages';

const NoResults = ({ id, intl: { formatMessage }, message, parentId, title, view }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s[view]} id={thisId}>
      <div className={s.illustration}>
        <Illustration
          parentId={thisId}
          title={formatMessage(i18n.illustrationTitle)}
          type="noResults"
        />
      </div>
      <TextBlock parentId={thisId} ph="none" title={title || formatMessage(i18n.defaultTitle)}>
        {message || formatMessage(i18n.defaultMessage)}
      </TextBlock>
    </div>
  );
};

NoResults.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  message: PropTypes.string,
  parentId: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.oneOf(['search', 'select']).isRequired
};

NoResults.defaultProps = {
  id: 'no-results',
  message: '',
  parentId: '',
  title: ''
};

export { NoResults as NoResultsWithoutIntl };

export default injectIntl(NoResults);
