import { defineMessages } from 'react-intl';

export default defineMessages({
  authApp: 'Authenticator App',
  backupMethod: 'Backup Method',
  installed: 'Installed',
  offlineCodes: 'Offline Codes',
  para1: 'Here, you can review and change your selected authentication methods.',
  passphrase: 'Passphrase',
  phone: 'Text Message',
  primaryMethod: 'Primary Method',
  reinstall: 'Re-install',
  remove: 'Remove',
  submitButton: 'Change',
  swapButton: 'Swap',
  title: 'Authentication methods.',
  viewCodes: 'View Codes',
});
