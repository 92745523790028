import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { propTypes, formValueSelector, reduxForm } from 'redux-form';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getCountryCallingCode } from 'libphonenumber-js/mobile';
import metadata from 'libphonenumber-js/metadata.mobile.json';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {
  Block,
  Button,
  FieldInputs,
  FieldSelect,
  Page,
  PageFooter,
  PageMain,
} from '@ace/core-components';
import FillSmsCode from '../../components/FillSmsCode';
import PageHeader from '../../components/PageHeader';

import {
  apiSetupPhoneNumber,
  apiUserData,
  apiValidatePhoneNumber,
  selection,
} from '../../store/actions/user';
import { getSmsTransaction } from '../../store/reducers/transactions';
import { getUserData } from '../../store/reducers/mfa';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import CountriesDialingCodes from '../../utils/CountriesDialingCodes.json';
import CountriesEnUs from '../../translations/countries/en-US.json';
import CountriesZhCn from '../../translations/countries/zh-CN.json';
import messages from './messages';

const tryCatch = (fn, fail = () => {}) => (...args) => {
  try {
    return fn(...args);
  } catch (error) {
    return fail(error);
  }
};

const CountriesPhoneDictEn = Object.keys(CountriesDialingCodes).map(iso2 => ({
  label: `(${iso2}) ${CountriesEnUs[iso2]}${tryCatch(
    i => ` (+${getCountryCallingCode(i)})`,
    () => '',
  )(iso2)}`,
  value: iso2,
}));

const CountriesPhoneDictZh = Object.keys(CountriesDialingCodes).map(iso2 => ({
  label: `(${iso2}) ${CountriesZhCn[iso2]}${tryCatch(
    i => ` (+${getCountryCallingCode(i)})`,
    () => '',
  )(iso2)}`,
  value: iso2,
}));

class SetupSms extends PureComponent {
  browser = detect();

  state = {};

  componentDidMount() {
    const { requestUserData, user } = this.props;

    if (!user) {
      requestUserData();
    }
  }

  onNextClick = () => {
    const {
      history,
      location,
      match: { url },
    } = this.props;

    history.push({
      ...location,
      pathname: `${url}/validate`,
    });
  };

  onSubmit = formData => {
    const { form, requestSetupPhoneNumber, type } = this.props;

    return asyncFormSubmit(form.form, requestSetupPhoneNumber, {
      ...formData,
      auth_method: type,
    })
      .then(() => {
        this.onNextClick();
      })
      .catch(() => {});
  };

  onSubmitValidation = formData => {
    const { onSuccess, requestValidatePhoneNumber, type } = this.props;

    return asyncFormSubmit(formData.form, requestValidatePhoneNumber, {
      ...formData.params,
      auth_method: type,
    })
      .then(() => {
        if (formData.submitPromise) {
          formData.submitPromise.resolve();
        }

        onSuccess();
      })
      .catch(() => {
        if (formData.submitPromise) {
          formData.submitPromise.reject();
        }
      });
  };

  onResendCode = () => {
    const { form, phone, phoneCountryCode, requestSetupPhoneNumber, type } = this.props;

    return asyncFormSubmit(form.form, requestSetupPhoneNumber, {
      phone,
      phoneCountryCode,
      auth_method: type,
    })
      .then(() => {
        this.onNextClick();
      })
      .catch(() => {});
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.country && this.props.country !== nextProps.country) {
      try {
        const phoneCountryCode = getCountryCallingCode(nextProps.country);

        if (phoneCountryCode) {
          nextProps.form.change('phoneCountryCode', `+${phoneCountryCode}`);
        }
      } catch (error) {
        // silence
      }
    } else if (
      nextProps.phoneCountryCode &&
      this.props.phoneCountryCode !== nextProps.phoneCountryCode
    ) {
      try {
        const phoneCountryCode = nextProps.phoneCountryCode.replace(/^\+/, '');
        const countries = metadata.country_calling_codes[phoneCountryCode];

        if (countries && countries.length > 0) {
          const selected = countries[0];
          nextProps.form.change('country', selected);
        }
      } catch (error) {
        // silence
      }
    }
  }

  render() {
    const {
      form,
      fullPhone,
      intl: { formatMessage, locale },
      location,
      match: { path, url },
      phone,
      phoneCountryCode,
      submitEnabled,
      transaction,
      user,
    } = this.props;

    return (
      <Switch>
        <Route
          path={`${path}/validate`}
          render={() => {
            if (!phone || !phoneCountryCode) {
              return (
                <Redirect
                  to={{
                    ...location,
                    pathname: `${url}`,
                  }}
                />
              );
            }

            return (
              <FillSmsCode
                method="phone"
                mode="validate"
                phone={fullPhone}
                onResendCode={this.onResendCode}
                onSubmit={this.onSubmitValidation}
                transaction={transaction}
              />
            );
          }}
        />
        <Route
          render={() => (
            <Page>
              <Helmet
                title={
                  user && user.phone
                    ? formatMessage(messages.titleChange)
                    : formatMessage(messages.title)
                }
              />
              <PageHeader
                title={
                  user && user.phone
                    ? formatMessage(messages.titleChange)
                    : formatMessage(messages.title)
                }
                intro={<FormattedMessage {...messages.para1} />}
              />
              <PageMain>
                <form id={form.form} onSubmit={form.handleSubmit(this.onSubmit)}>
                  <Block>
                    <FieldSelect
                      autocomplete
                      form={form}
                      label={formatMessage(messages.countryInputLable)}
                      modal="country"
                      modalTitle={formatMessage(messages.countryInputLable)}
                      name="country"
                      options={locale === 'zh-CN' ? CountriesPhoneDictZh : CountriesPhoneDictEn}
                      placeholder={formatMessage(messages.countryInputLable)}
                      title={formatMessage(messages.countryInputLable)}
                    />
                    <br />
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div
                        style={{
                          marginRight: 10,
                          width: 60,
                        }}
                      >
                        <FieldInputs.PhoneCountryCode
                          required
                          label={<FormattedMessage {...messages.phoneCodeInputLable} />}
                        />
                      </div>
                      <FieldInputs.Phone
                        required
                        label={<FormattedMessage {...messages.phoneNumberInputLable} />}
                      />
                    </div>
                  </Block>
                </form>
              </PageMain>
              <PageFooter>
                <FormattedMessage {...messages.nextButton}>
                  {label => (
                    <Button
                      disabled={!submitEnabled}
                      form={form.form}
                      submitting={form.submitting}
                      theme="primary"
                      title={label}
                      type="submit"
                      value={label}
                      view="cta"
                    />
                  )}
                </FormattedMessage>
              </PageFooter>
            </Page>
          )}
        />
      </Switch>
    );
  }
}

SetupSms.propTypes = {
  form: PropTypes.shape(propTypes).isRequired,
  fullPhone: PropTypes.string,
  intl: intlShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
  requestSetupPhoneNumber: PropTypes.func.isRequired,
  requestUserData: PropTypes.func.isRequired,
  requestValidatePhoneNumber: PropTypes.func.isRequired,
};

SetupSms.defaultProps = {
  fullPhone: '',
};

const SetupSmsForm = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: CONFIG.formNames.setupSms,
  keepDirtyOnReinitialize: true,
  propNamespace: 'form',
  updateUnregisteredFields: true,
})(SetupSms);

const valueSelector = formValueSelector(CONFIG.formNames.setupSms);

const mapStateToProps = state => {
  const country = valueSelector(state, 'country');
  const phoneCountryCode = valueSelector(state, 'phoneCountryCode') || '';
  const phone = valueSelector(state, 'phone') || '';

  return {
    country,
    phone,
    phoneCountryCode,
    fullPhone: `${phoneCountryCode}${phone}`,
    submitEnabled: country && phone,
    transaction: getSmsTransaction(state),
    user: getUserData(state),
  };
};

const mapDispatchToProps = {
  requestSetupPhoneNumber: apiSetupPhoneNumber.request,
  requestUserData: apiUserData.request,
  requestValidatePhoneNumber: apiValidatePhoneNumber.request,
  saveSelection: selection.save,
};

const SetupSmsFormConnected = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SetupSmsForm),
);

export default SetupSmsFormConnected;
