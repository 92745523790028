const CANCEL = 'CANCEL';
const PAUSE = 'PAUSE';
const RESUME = 'RESUME';
const FAILURE = 'FAILURE';
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const SAVE = 'SAVE';
const REMOVE = 'REMOVE';
const OPEN = 'OPEN';
const CLOSE = 'CLOSE';
const UI_ANALYTICS = 'UI_ANALYTICS';

export const generateActionTypes = base =>
  [
    CANCEL,
    REQUEST,
    SUCCESS,
    FAILURE,
    PAUSE,
    RESUME,
    SAVE,
    REMOVE,
    OPEN,
    CLOSE,
    UI_ANALYTICS
  ].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    acc.id = base;
    return acc;
  }, {});

export const generateActions = (base, loader = false) => {
  const defaultMeta = {
    id: base.id,
    loader: false,
    mixpanel: null,
    notification: false,
    reporting: false
  };
  return {
    request: (payload, meta) => ({
      type: base.REQUEST,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'add' : false,
        ...meta
      }
    }),
    cancel: (payload, meta) => ({
      type: base.CANCEL,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'remove' : false,
        ...meta
      }
    }),
    pause: (payload, meta) => ({
      type: base.PAUSE,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'remove' : false,
        ...meta
      }
    }),
    resume: (payload, meta) => ({
      type: base.RESUME,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'add' : false,
        ...meta
      }
    }),
    success: (payload, meta) => ({
      type: base.SUCCESS,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'remove' : false,
        ...meta
      }
    }),
    failure: (payload, meta) => ({
      type: base.FAILURE,
      payload,
      meta: {
        ...defaultMeta,
        loader: loader ? 'remove' : false,
        ...meta
      }
    }),
    save: (payload, meta) => ({
      type: base.SAVE,
      payload,
      meta: {
        ...defaultMeta,
        ...meta
      }
    }),
    remove: (payload, meta) => ({
      type: base.REMOVE,
      payload,
      meta: {
        ...defaultMeta,
        ...meta
      }
    }),
    open: (payload, meta) => ({
      type: base.OPEN,
      payload,
      meta: {
        ...defaultMeta,
        ...meta
      }
    }),
    close: (payload, meta) => ({
      type: base.CLOSE,
      payload,
      meta: {
        ...defaultMeta,
        ...meta
      }
    }),
    uiAnalytics: (payload, meta) => ({
      type: base.UI_ANALYTICS,
      payload,
      meta: {
        ...defaultMeta,
        ...meta
      }
    })
  };
};
