import { defineMessages } from 'react-intl';

export default defineMessages({
  absence: 'must be blank',
  acceptance: 'must be accepted',
  confirmation: "{fieldLabel} don't match",
  dateFormat: 'expected format: {format}',
  dateInvalid: 'is not a valid date',
  dateRange: 'shoud be {op} {date}',
  email: 'is not a valid email',
  emojiNotAllowed: 'Emoji characters are not allowed',
  equalTo: 'must be equal to {count, number}',
  even: 'must be even',
  exclusion: 'is reserved',
  file: 'is not a file',
  fileAccept: 'invalid file {count, plural, one {type} other {types ({count})}}',
  fileTooBig:
    '{count, plural, one {is} other {{count} files are}} too big (maximum is {count, plural, one {{size}} other {{size} each}})',
  fileTooFew: 'invalid number of files (minimum is {count})',
  fileTooMany: 'invalid number of files (maximum is {count})',
  fileTooSmall:
    '{count, plural, one {is} other {{count} files are}} too small (minimum is {count, plural, one {{size}} other {{size} each}})',
  greaterThan: 'must be greater than {count, number}',
  greaterThanOrEqualTo: 'must be greater than or equal to {count, number}',
  inclusion: 'is not included in the list',
  invalid: 'is invalid',
  lessThan: 'must be less than {count, number}',
  lessThanOrEqualTo: 'must be less than or equal to {count, number}',
  notANumber: 'is not a number',
  notAnInteger: 'is not an integer',
  odd: 'must be odd',
  otherThan: 'must be other than {count, number}',
  owasp_0: 'must be at least {minLength} characters long.',
  owasp_1: 'must be fewer than {maxLength} characters.',
  owasp_2: 'may not contain sequences of three or more repeated characters.',
  owasp_3: 'must contain at least one lowercase letter.',
  owasp_4: 'must contain at least one uppercase letter.',
  owasp_5: 'must contain at least one number.',
  owasp_6: 'must contain at least one special character..',
  presence: 'is required',
  rewardAmountBalance: 'you need more Bitcoin for this',
  rewardAmountHigh: 'max. reward is {maxAmount}',
  tooLong:
    'is too long (maximum is {count, number} {count, plural, one {character} other {characters}})',
  tooShort:
    'is too short (minimum is {count, number} {count, plural, one {character} other {characters}})',
  url: 'is not a valid URL',
  wrongLength: 'wrong length, should be {count, number}'
});
