import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { Page, PageFooter, PageMain, Button } from '@ace/core-components';

import Block from '../Block';
import Icon from '../Icon';
import PageHeader from '../PageHeader';

const IconPage = ({ button, intl: { formatMessage }, icon, intro, title }) => (
  <Page>
    <FormattedMessage {...title}>{label => <Helmet title={label} />}</FormattedMessage>
    <PageHeader back={false} intro={formatMessage(intro)} title={formatMessage(title)} />
    <PageMain>
      <Block spacing="xl">
        <Icon icon={icon} />
      </Block>
      {button && (
        <PageFooter>
          <FormattedMessage {...button.label}>
            {label => (
              <Button
                onClick={button.onClick}
                theme={button.theme || 'primary'}
                title={label}
                type="button"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      )}
    </PageMain>
  </Page>
);

const messageShape = PropTypes.shape({
  defaultMessage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
});

IconPage.propTypes = {
  button: PropTypes.shape({
    label: messageShape.isRequired,
    theme: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }),
  icon: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  intro: messageShape.isRequired,
  title: messageShape.isRequired
};

IconPage.defaultProps = {
  button: null
};

export default injectIntl(IconPage);
