import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScreenNotFound from '../../screens/ScreenNotFound';

const CustomSwitch = ownProps => {
  const renderRoute = obj => {
    if (!obj || !obj.props || !obj.props.route) {
      return obj;
    }
    return (
      <Route
        key={`${obj.props.route.path}1`}
        path={obj.props.route.path}
        exact={obj.props.exact || false}
        render={routerProps => React.cloneElement(obj, { ...routerProps })}
      />
    );
  };
  return (
    <Switch>
      {React.Children.map(ownProps.children, obj => renderRoute(obj))}
      <ScreenNotFound />
    </Switch>
  );
};

CustomSwitch.propTypes = {};

CustomSwitch.defaultProps = {};

export default CustomSwitch;
