import queryString from 'query-string';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { history } from '../history';

export const tokenAboutToExpire = exp => {
  const fiveMins = 5 * 60;
  const unixExpiryTime = Math.floor(Date.now() / 1000) + fiveMins;
  return exp < unixExpiryTime;
};

export const getQueryParams = () => {
  const { id_token: idToken, access_token: accessToken, ...rest } = queryString.parse(
    history.location.search
  );
  return {
    accessToken,
    idToken,
    ...rest
  };
};

export const goBack = () => {
  if (document.referrer === '') {
    history.push('/');
    return;
  }
  history.goBack();
};

export const decodeToken = token => {
  try {
    const { sub: _id, ace_role: aceRole, email, exp } = jwtDecode(token);
    // Check if we have non-empty values for required
    const contents = _.pickBy({ _id, email, aceRole, exp });
    if (tokenAboutToExpire(exp)) {
      throw new Error('Token Expired');
    }
    return contents;
  } catch (error) {
    // Needs to be in try / catch because of jwtDecode
    return {};
  }
};
