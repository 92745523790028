import React from 'react';
import PropTypes from 'prop-types';
import generateId from '../../utils/generateId';
import styles from './Textarea.module.scss';

const Textarea = ({ input, meta, id, parentId, placeholder }) => {
  const thisId = generateId(id, parentId);
  return (
    <div>
      <div className={styles.this}>
        <textarea
          className={styles.textarea}
          id={thisId}
          {...input}
          rows="4"
          placeholder={placeholder}
        />
      </div>

      <p className={styles.warning}>{meta.warning}</p>
    </div>
  );
};

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }),
  parentId: PropTypes.string,
  placeholder: PropTypes.string
};

Textarea.defaultProps = {
  parentId: '',
  placeholder: '',
  meta: {}
};

export default Textarea;
