const validatorFileType = (file, formats) => {
  if (!file || !file.name) {
    return false;
  }

  const match = formats.join('|');
  if (file.name.match(new RegExp(match, 'i'))) {
    return true;
  }

  const error = new Error('validatorFileTypeError');
  error.values = { formats: match };
  throw error;
};

export default validatorFileType;
