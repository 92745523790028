import React from 'react';

export const toolbarFind = (
  <g id="Icons/Toolbar/Find" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <circle id="Oval-2" cx="19.5" cy="19.5" r="16.722" stroke="currentColor" strokeWidth="5.555" />
    <rect
      id="Rectangle"
      width="13"
      height="5"
      x="29"
      y="34"
      fill="currentColor"
      rx="2.5"
      transform="rotate(45 35.5 36.5)"
    />
  </g>
);

export const toolbarInbox = (
  <g id="Icons/Toolbar/Inbox" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Combined-Shape"
      stroke="currentColor"
      strokeWidth="4"
      d="M35.379 38.403l-2.273-6.566 1.043-.885C37.871 27.79 40 23.448 40 18.817 40 9.58 31.54 2 21 2S2 9.581 2 18.817c0 9.235 8.46 16.816 19 16.816a21.36 21.36 0 0 0 4.8-.541l.596-.137 8.983 3.448z"
    />
  </g>
);

export const toolbarReward = (
  <g id="Icons/Toolbar/Reward" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      d="M29.363 4.554h8.17v8.17a2.277 2.277 0 1 0 4.553 0V2.276A2.277 2.277 0 0 0 39.809 0H29.363a2.277 2.277 0 1 0 0 4.554z"
    />
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      d="M2.277 31.554h8.17v8.17a2.277 2.277 0 1 0 4.553 0V29.276A2.277 2.277 0 0 0 12.723 27H2.277a2.277 2.277 0 1 0 0 4.554z"
      transform="rotate(180 7.5 34.5)"
    />
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      d="M2.277 4.554h8.17v8.17a2.277 2.277 0 1 0 4.553 0V2.276A2.277 2.277 0 0 0 12.723 0H2.277a2.277 2.277 0 1 0 0 4.554z"
      transform="matrix(-1 0 0 1 15 0)"
    />
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      d="M29.277 31.554h8.17v8.17a2.277 2.277 0 1 0 4.553 0V29.276A2.277 2.277 0 0 0 39.723 27H29.277a2.277 2.277 0 1 0 0 4.554z"
      transform="matrix(1 0 0 -1 0 69)"
    />
  </g>
);

export const toolbarWallet = (
  <g id="Icons/Toolbar/Wallet" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <rect
      id="Combined-Shape"
      width="33.832"
      height="33.832"
      x="2.084"
      y="4.084"
      stroke="currentColor"
      strokeWidth="4.168"
      rx="1.65"
    />
    <rect
      id="Rectangle"
      width="8.832"
      height="9.832"
      x="31.084"
      y="17.084"
      fill="#FFF"
      stroke="currentColor"
      strokeWidth="4.168"
      rx="3.299"
    />
  </g>
);
