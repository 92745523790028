import _ from 'lodash';

export const addItem = (state, item) => {
  if (!item) {
    return state;
  }
  // Helper function so we don't show the same message twice
  if (
    _.findIndex(state, ['code', item.code]) >= 0 ||
    _.findIndex(state, ['message', item.message]) >= 0
  ) {
    return state;
  }
  return state.concat(item);
};

export const removeItem = (state, index) => state.filter((item, i) => i !== index);
