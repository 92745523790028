import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { getFormValues } from 'redux-form';
import { stringify } from 'query-string';
import _ from 'lodash';
import rfActions from 'redux-form/es/actions';

import AppRoutes from '../../routes';
import * as accountRecoveryActions from '../../containers/AccountRecovery/actions';
import * as actions from '../actions/user';
import { getAuthChallenge, getAuthChallengeResponse } from '../reducers/authChallenge';
import { getLocale } from '../reducers/locale';
import { getSmsTransaction } from '../reducers/transactions';
import { getUserData } from '../reducers/mfa';
import { getUserProfile, getUserElevatedTokens } from '../reducers/profile';

import { api as flagsApi } from '../../flags';
import getParams, { hasExpired } from '../../utils/getParams';
import history from '../../utils/history';
import { get, post } from '../../utils/request';
import { getFingerprint } from '../../utils/fingerprint';
import { log } from '../../utils/helpers';
import { setSSOTokens } from '../../utils/sso';

const { apiBase } = CONFIG;
const { destroy, setSubmitFailed, startSubmit } = rfActions;
const { getFlags } = flagsApi;

function* makeMfaAuthChallengeResponse({ offline_code, passphrase, totp_code, sms_code }) {
  switch (true) {
    case !!totp_code:
      return {
        type: 'totp',
        value: totp_code,
      };
    case !!offline_code:
      return {
        type: 'offline_code',
        value: offline_code,
      };
    case !!passphrase:
      return {
        type: 'passphrase',
        value: { passphrase },
      };

    case !!sms_code: {
      const smsTransaction = yield select(getSmsTransaction);

      if (!smsTransaction) {
        throw new Error('No phone request record found');
      }

      return {
        type: 'phone',
        value: { sms_code, request_id: smsTransaction.request_id },
      };
    }

    default:
      return {};
  }
}

function* apiSignUp({ payload: { submitPromise } }) {
  const flags = getFlags();
  const queryParams = getParams();
  const { client_id, redirect_uri, nosso, response_type = 'token' } = queryParams;
  const { username, email, password, passwordConfirm, role, referrer, captcha } = yield select(
    getFormValues(CONFIG.formNames.signup),
  );

  const locale = queryParams.locale || (yield select(getLocale));

  try {
    const finalUsername = username || email;
    const isEmail = /@/.test(finalUsername);
    const response = yield call(
      post,
      `${apiBase}/signup`,
      {
        captcha,
        client_id,
        flags,
        locale,
        password,
        response_type,
        attributes: {
          redirect_uri,
          role,
          referral: referrer,
        },
        nosso: nosso === true || nosso === 'true' || nosso === 1 || nosso === '1',
        password_confirm: passwordConfirm,
        ...(email && { email }),
        ...(username && { username }),
      },
      {
        credentials: 'include',
      },
    );

    submitPromise.resolve();
    yield put(actions.signUp.success());
    // Ensure to destroy the forms to prevent 'back' and re-submit
    yield put(destroy(CONFIG.formNames.signup));

    if (isEmail) {
      history.push('/signup-success');
    } else if (response_type === 'code' && response.code) {
      yield put(actions.signIn.success(response));
      yield put(actions.apiSsoRedirect.request());
    } else if (response && response.token) {
      yield put(actions.signIn.success(response));
      history.push({
        ...window.location,
        pathname: AppRoutes.signup.extraSecurity.index,
      });
    } else {
      history.push({
        ...window.location,
        pathname: AppRoutes.signin.index,
      });
    }
  } catch (e) {
    submitPromise.reject(e);
    yield call(delay);
    yield put(setSubmitFailed(CONFIG.formNames.signup));
    yield put(actions.signUp.failure(null, { notification: e, reporting: e }));
  }
}

/**
 * @deprecated
 */
function* apiSignUpNewRequest({ payload: { submitPromise } = {} }) {
  const { client_id, redirect_uri, email: emailParam } = getParams();
  // email not in params when called from login
  const email = emailParam || (yield select(getFormValues(CONFIG.formNames.login))).email;

  try {
    yield call(post, `${apiBase}/signup-new-request`, { client_id, email, redirect_uri });

    if (submitPromise) {
      submitPromise.resolve();
    }

    yield put(actions.apiSignUpNewRequest.success());
    history.push('/signup-success');
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.apiSignUpNewRequest.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSignUpConfirm() {
  const { security_code, client_id, email, redirect_uri } = getParams();
  const params = _.pickBy({
    client_id,
    email,
    redirect_uri,
    security_code,
  });

  if (hasExpired()) {
    const pathname = getParams().new_passphrase_code
      ? AppRoutes.passphrase.reset.link.expired
      : AppRoutes.signup.link.expired;

    history.replace({
      pathname,
      search: history.location.search,
    });

    return;
  }

  try {
    const response = yield call(post, `${apiBase}/signup-confirm`, params, {
      credentials: 'include',
    });

    const profile = yield select(getUserProfile);

    yield put(actions.signUpConfirm.success({ ...{ confirmed: true }, ...profile }));

    if (response && response.token) {
      yield put(actions.signIn.success(response));
      yield put(actions.apiSsoRedirect.request());
    }
  } catch (e) {
    yield put(actions.signUpConfirm.failure(null, { notification: e, reporting: e }));
    history.replace({ pathname: AppRoutes.signin.index, search: history.location.search });
  }
}

function* checkTokenExpiration(e, submitPromise) {
  if (e.code === 'errors.BAD_TOKEN' || e.code === 'errors.TOKEN_TOO_OLD') {
    yield put(actions.apiUserData.remove({}));

    const { client_id, redirect_uri, internal_redirect, ...rest } = getParams();

    history.push(
      `${AppRoutes.signin.index}?${stringify({
        ...rest,
        client_id,
        redirect_uri,
        internal_redirect: `${window.location.pathname}`,
        ...(internal_redirect && { internal_redirect2: internal_redirect }),
      })}`,
    );
  } else if (e.code === 'errors.ELEVATE_TOKEN_EXPIRED') {
    yield put(actions.apiUserData.remove({}));

    if (submitPromise) {
      submitPromise.reject(e);
    }

    const { client_id, redirect_uri, internal_redirect, ...rest } = getParams();

    history.push(
      `${AppRoutes.elevate.index}?${stringify({
        ...rest,
        client_id,
        redirect_uri,
        internal_redirect: `${window.location.pathname}`,
        ...(internal_redirect && { internal_redirect2: internal_redirect }),
      })}`,
    );
  }
}

function* apiUserData(options = {}) {
  try {
    const profile = yield select(getUserProfile);

    if (!profile) {
      history.push(`${AppRoutes.signin.index}?${stringify(getParams())}`);

      return null;
    }

    let user;

    if (options.fetchPolicy === 'cache-first') {
      user = yield select(getUserData);
    }

    if (!user) {
      user = yield call(get, `${apiBase}/user?access_token=${profile.token}`);
    }

    yield put(actions.apiUserData.success(user));

    return user;
  } catch (e) {
    yield put(actions.signIn.failure(null, { notification: e, reporting: e }));
    throw e;
  }
}

function* getElevatedTokens(action, payload, successRedirect = window.location.pathname) {
  const elevatedTokens = yield select(getUserElevatedTokens);

  if (elevatedTokens) {
    return elevatedTokens;
  }

  yield put(action.pause(payload));
  history.replace(
    `${AppRoutes.elevate.index}?${stringify({
      ...getParams(),
      internal_redirect: successRedirect,
    })}`,
  );
  yield take(actions.ELEVATE.SUCCESS);
  yield put(action.resume(payload));

  return yield select(getUserElevatedTokens);
}

function* apiAuthChallenge({
  payload: {
    params: { totpCode: totp_code, passphrase, offlineCode: offline_code, smsCode: sms_code },
    submitPromise,
  },
}) {
  const requestParams = getParams();
  const { client_id, redirect_uri } = requestParams;
  const challenge = yield select(getAuthChallenge);

  try {
    const challengeResponse = yield* makeMfaAuthChallengeResponse({
      offline_code,
      passphrase,
      sms_code,
      totp_code,
    });
    const response = yield call(
      post,
      `${apiBase}/respond-to-auth-challenge`,
      {
        client_id,
        redirect_uri,
        challange_name: challenge.challengeName,
        challenge_response: challengeResponse,
        session: challenge.session,
      },
      {
        credentials: 'include',
      },
    );

    yield put(actions.apiAuthChallenge.success(response));

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.apiAuthChallenge.failure(null, { notification: e, reporting: e }));
  }
}

function* getMfaChallenge(action, payload, response, options = {}) {
  yield put(action.pause({ ...payload, challenge: response }));

  const newLocation = `${AppRoutes.mfaAuthChallenge.index}?${stringify({
    ...getParams(),
    ...response.challengeParameters,
    mode: options.mode,
  })}`;

  history.push(newLocation);
  yield take(actions.AUTH_CHALLENGE.SUCCESS);

  const challengeResponse = yield select(getAuthChallengeResponse);

  yield put(action.resume(payload));

  return challengeResponse;
}

function* fetchSignIn(action) {
  const flags = getFlags();
  const requestParams = getParams();
  const { client_id, redirect_uri, nosso, response_type = 'token' } = requestParams;
  const locale = requestParams.locale || (yield select(getLocale));
  const { email, username, password } = yield select(getFormValues(CONFIG.formNames.login));

  try {
    const isFingerprintAllowed = client_id && (email || username) && password && redirect_uri;
    const response = yield call(
      post,
      `${apiBase}/signin`,
      {
        client_id,
        flags,
        locale,
        password,
        redirect_uri,
        response_type,
        nosso: nosso === true || nosso === 'true' || nosso === 1 || nosso === '1',
        ...(email && { email }),
        ...(username && { username }),
        ...(isFingerprintAllowed && {
          fingerprint: getFingerprint(),
        }),
      },
      {
        credentials: 'include',
      },
    );

    if (response.challengeName) {
      if (response.challengeName === 'MFA') {
        const redirectTo = `${window.location.pathname}${window.location.search}`;
        const challengeResponse = yield* getMfaChallenge(actions.signIn, action.payload, response);

        history.push(redirectTo);
        yield put(actions.signIn.success(challengeResponse));

        return challengeResponse;
      }

      throw new Error(`Unknown challengeName "${response.challengeName}"`);
    }

    yield put(actions.signIn.success(response));

    return response;
  } catch (e) {
    // need to check error code here as well as the component to suppress the notification
    if (e.code === 'errors.UserNotConfirmedException') {
      yield put(actions.signIn.failure(email || username, { reporting: e }));
    } else {
      yield put(
        actions.signIn.failure(null, {
          notification: e,
          reporting: e,
        }),
      );
    }

    throw e;
  }
}

function* apiSsoRedirect() {
  const { redirect_uri } = getParams();
  const profile = yield select(getUserProfile);

  if (profile.ssoUrls.length === 0) {
    if (profile.code && !profile.token) {
      const queryParams = {
        code: profile.code,
      };
      const final_redirect = `${redirect_uri}?${stringify(queryParams)}`;

      window.location.assign(final_redirect);
    } else {
      const queryParams = {
        access_token: profile.token,
        id_token: profile.idToken,
      };
      const final_redirect = `${redirect_uri}?${stringify(queryParams)}`;

      window.location.assign(final_redirect);
    }
  } else {
    yield setSSOTokens([redirect_uri, ...profile.ssoUrls], true);
  }
}

function* apiSignin(action) {
  const {
    payload: { submitPromise },
  } = action;
  const requestParams = getParams();
  const { internal_redirect, internal_redirect2 } = requestParams;
  let showSetBackupAuthMethodWarning = false;

  try {
    yield* fetchSignIn(action);

    const profile = yield select(getUserProfile);

    if (profile && profile.showSetBackupAuthMethodWarning) {
      showSetBackupAuthMethodWarning = true;
    }

    if (profile.code) {
      yield put(startSubmit(CONFIG.formNames.login));
      yield put(actions.apiSsoRedirect.request());

      return;
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    return;
  }

  let user = {};

  try {
    user = yield* apiUserData();
  } catch (e) {
    if (submitPromise) {
      submitPromise.resolve();
    }

    return;
  }

  // new already logged user.
  if (internal_redirect) {
    // old user.
    if (submitPromise) {
      submitPromise.resolve();
    }

    history.replace(
      `${internal_redirect}?${stringify({
        ...requestParams,
        internal_redirect: internal_redirect2,
      })}`,
    );
  } else if (!user.primary_auth_method) {
    if (submitPromise) {
      submitPromise.resolve();
    }

    history.push(`${AppRoutes.signup.extraSecurity.index}?${stringify(requestParams)}`);
  } else if (showSetBackupAuthMethodWarning) {
    if (submitPromise) {
      submitPromise.resolve();
    }

    history.push(`${AppRoutes.signin.backupWarning}?${stringify(requestParams)}`);
  } else {
    yield put(startSubmit(CONFIG.formNames.login));
    yield put(actions.apiSsoRedirect.request());
  }
}

function* apiPassphraseValidate({ payload: { params, submitPromise } }) {
  const { passphrase, auth_method } = params;

  try {
    const profile = yield select(getUserProfile);
    yield call(post, `${apiBase}/validate-passphrase`, {
      auth_method,
      passphrase,
      access_token: profile.token,
    });

    if (submitPromise) {
      submitPromise.resolve();
    }

    yield put(actions.validatePassphrase.success());
  } catch (e) {
    yield put(actions.validatePassphrase.failure(null, { notification: e, reporting: e }));

    if (submitPromise) {
      submitPromise.reject(e);
    }
  }
}

function* apiCheckUser(payload) {
  let { email } = getParams();

  email = (email && encodeURIComponent(email)) || '';

  try {
    const response = yield call(get, `${apiBase}/check-user?email=${email}`); // Will be secured once the ticket to change the params from the email is done

    if (!response[payload.payload.type]) {
      history.push(`/error?code=${payload.payload.type.toUpperCase()}&icon=success`);
    }

    yield put(actions.checkUser.success(response));
  } catch (e) {
    yield put(actions.checkUser.failure(null, { notification: e }));
  }
}

function* apiForgotPasswordRequest(action) {
  const {
    payload: { submitPromise },
  } = action;
  const flags = getFlags();
  const urlParams = getParams();
  const { client_id, redirect_uri, device } = urlParams;
  const form = yield select(getFormValues(CONFIG.formNames.reset));
  const email = urlParams.email || form.email;
  const username = urlParams.username || form.username;
  const locale = urlParams.locale || (yield select(getLocale));

  try {
    const response = yield call(post, `${apiBase}/reset-password`, {
      client_id,
      email,
      flags,
      locale,
      redirect_uri,
      username,
      remove_device_id: device,
    });

    if (response && response.challengeName) {
      if (response.challengeName === 'MFA') {
        const { hash } = window.location;
        const challengeResponse = yield* getMfaChallenge(
          actions.forgotPassword,
          action.payload,
          response,
          { mode: 'confirm' },
        );

        const { codesLeft } = challengeResponse;
        const search = {
          ...urlParams,
          security_code: challengeResponse.securityCode,
          ...(email && { email }),
          ...(username && { username }),
        };

        if (codesLeft === 0) {
          search.omit_elevate_token = true;
          history.push({
            pathname: AppRoutes.offlineCodes.regenerate,
            search: stringify(search),
            state: { redirect: AppRoutes.password.reset.confirm },
          });
        } else {
          history.push({
            hash,
            pathname: AppRoutes.password.reset.confirm,
            search: stringify(search),
          });
        }

        yield put(actions.forgotPassword.success());
      } else {
        throw new Error(`Unknown challengeName "${response.challengeName}"`);
      }
    } else {
      yield put(actions.forgotPassword.success());

      if (submitPromise) {
        submitPromise.resolve();
      }

      history.push(AppRoutes.password.change.checkEmail);
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.forgotPassword.failure(null, { notification: e, reporting: e }));
  }
}

function* apiForgotPasswordConfirm({ payload: { submitPromise } }) {
  const flags = getFlags();
  const { client_id, security_code, email, username, redirect_uri, expires, nosso } = getParams();
  const { password, passwordConfirm } = yield select(getFormValues(CONFIG.formNames.newpassword));

  try {
    yield call(
      post,
      `${apiBase}/reset-password-confirm`,
      {
        client_id,
        email,
        expires,
        flags,
        password,
        redirect_uri,
        security_code,
        username,
        password_confirm: passwordConfirm,
      },
      {
        credentials: 'include',
      },
    );
    const redirectLocation = `${AppRoutes.signin.index}?${stringify({
      client_id,
      redirect_uri,
      ...(nosso && {
        nosso,
      }),
    })}`;

    window.location.assign(redirectLocation);
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.forgotPasswordConfirm.failure(null, { notification: e, reporting: e }));
  }
}

function* apiChangeUsername(action) {
  const {
    payload: { submitPromise },
  } = action;
  const flags = getFlags();
  const urlParams = getParams();
  const { client_id, redirect_uri } = urlParams;
  const profile = yield select(getUserProfile);
  const form = yield select(getFormValues(CONFIG.formNames.changeUsername));

  try {
    const response = yield call(post, `${apiBase}/change-username`, {
      client_id,
      flags,
      redirect_uri,
      access_token: profile.token,
      username: form.username,
    });

    if (response && response.challengeName) {
      if (response.challengeName === 'MFA') {
        const { hash } = window.location;

        yield* getMfaChallenge(actions.changeUsername, action.payload, response, {
          mode: 'confirm',
        });
        yield put(actions.changeUsername.success());
        history.push({
          hash,
          pathname: AppRoutes.username.change.success,
          search: stringify({
            client_id,
            redirect_uri,
          }),
        });
      } else {
        throw new Error(`Unknown challengeName "${response.challengeName}"`);
      }
    } else {
      yield put(actions.changeUsername.success());

      if (submitPromise) {
        submitPromise.resolve();
      }

      history.push(AppRoutes.password.change.checkEmail);
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.changeUsername.failure(null, { notification: e, reporting: e }));
  }
}

function* apiRecoverUsername(action) {
  const {
    payload: { submitPromise },
  } = action;
  const requestParams = getParams();
  const { client_id, redirect_uri } = requestParams;
  const locale = requestParams.locale || (yield select(getLocale));
  const { idOrPassportNumber } = yield select(getFormValues(CONFIG.formNames.recoverUsername));
  const isUserPassportNumber = /^[a-z0-9$]{8}$/i.test(idOrPassportNumber);

  try {
    const response = yield call(post, `${apiBase}/recover-username`, {
      client_id,
      locale,
      redirect_uri,
      ...(isUserPassportNumber
        ? { passportNumber: idOrPassportNumber }
        : { id: idOrPassportNumber }),
    });

    yield put(actions.recoverUsername.success(response));

    if (submitPromise) {
      submitPromise.resolve();
    }

    history.replace(
      `${AppRoutes.username.recover.success}?${stringify({
        ...getParams(),
      })}`,
    );
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.recoverUsername.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSetupPassphrase(action) {
  const {
    payload: { submitPromise },
  } = action;
  const { new_passphrase_code, 'internal-redirect': internalRedirect } = getParams();

  try {
    const profile = yield select(getUserProfile);

    if (!profile) {
      yield put(actions.setupPassphrase.cancel());
      history.replace(
        `${AppRoutes.signin.index}?${stringify({
          ...getParams(),
          internal_redirect: window.location.pathname,
        })}`,
      );

      return;
    }

    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });
    const queryParams = {
      access_token: profile.token,
    };

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(
        actions.setupPassphrase,
        action.payload,
        `${window.location.pathname}/resume`,
      );

      queryParams.elevated_token = elevatedTokens.idToken;
    }

    if (new_passphrase_code) {
      queryParams.new_passphrase_code = new_passphrase_code;
    }

    const passphraseResponse = yield call(get, `${apiBase}/passphrase?${stringify(queryParams)}`);

    if (!passphraseResponse.passphrase) {
      throw Error('No passphrase in response');
    }

    yield put(actions.setupPassphrase.success(passphraseResponse.passphrase));

    if (internalRedirect) {
      history.push(internalRedirect);
    }

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    if (e.code === 'errors.USER_CONFIRMED') {
      yield put(actions.setupPassphrase.failure(null, { reporting: e }));
      history.push('/error?code=USER_CONFIRMED');
    } else if (e.code === 'errors.INVALID_NEW_PASSPHRASE_CODE') {
      yield put(actions.setupPassphrase.failure(null, { reporting: e }));
      history.push('/passphrase-reset-link-expired');
    }

    yield* checkTokenExpiration(e);
    yield put(actions.setupPassphrase.failure(null, { notification: e, reporting: e }));
  }
}

function* apiElevate({ payload: { params, submitPromise } }) {
  const { internal_redirect, internal_redirect2, ...requestParams } = getParams();
  const { client_id, redirect_uri } = requestParams;
  const profile = yield select(getUserProfile);
  const { totpCode: totp_code, passphrase, offlineCode, smsCode } = params;

  const data = {
    client_id,
    passphrase,
    redirect_uri,
    totp_code,
    offline_code: offlineCode,
    sms_code: smsCode,
    token: profile.idToken,
  };

  if (smsCode) {
    const smsTransaction = yield select(getSmsTransaction);

    if (!smsTransaction) {
      const e = new Error('No phone request record found');

      yield put(actions.elevate.failure(null, { notification: e, reporting: e }));

      if (submitPromise) {
        submitPromise.reject();
      }

      return;
    }

    data.sms_request_id = smsTransaction.request_id;
  }

  try {
    const response = yield call(post, `${apiBase}/elevate`, data);

    yield put(actions.elevate.success(response));

    if (submitPromise) {
      submitPromise.resolve();
    }

    if (internal_redirect) {
      // old user.
      history.replace(
        `${internal_redirect}?${stringify({
          ...requestParams,
          internal_redirect: internal_redirect2,
        })}`,
      );
    } else {
      window.location.assign(
        `${redirect_uri}?access_token=${response.token}&id_token=${response.idToken}`,
      );
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.elevate.failure(null, { notification: e, reporting: e }));
  }
}

function* apiEmailChangeRequest({ payload: { submitPromise } }) {
  let { email } = getParams();

  if (!email) {
    ({ email } = yield select(getFormValues(CONFIG.formNames.changeemail)));
  }

  const locale = getParams().locale || (yield select(getLocale));
  const { client_id, redirect_uri } = getParams();

  try {
    const profile = yield select(getUserProfile);

    yield call(post, `${apiBase}/change-email`, {
      client_id,
      locale,
      redirect_uri,
      idToken: profile.idToken,
      new_email: email,
      token: profile.token,
    });
    yield put(actions.emailChange.success());

    if (submitPromise) {
      submitPromise.resolve();
    }

    history.push('/email-change-check-email');
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.emailChange.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSignOut() {
  const params = getParams();
  const { nosso } = params;
  const { ssoUrls = [] } = yield call(
    post,
    `${apiBase}/signout`,
    {
      ...params,
      nosso: nosso === true || nosso === 'true' || nosso === 1 || nosso === '1',
    },
    {
      credentials: 'include',
    },
  );
  const { redirect_uri } = params;

  if (ssoUrls.length === 0) {
    const final_redirect = redirect_uri;

    window.location.assign(final_redirect);
  } else {
    yield setSSOTokens([redirect_uri, ...ssoUrls], true);
  }
}

function* apiSetupTotp(action) {
  const {
    payload: { submitPromise },
  } = action;

  const profile = yield select(getUserProfile);

  try {
    const bodyParams = {
      access_token: profile.token,
    };
    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(
        actions.apiSetupTotp,
        action.payload,
        `${window.location.pathname}/resume`,
      );

      bodyParams.elevated_token = elevatedTokens.idToken;
    }

    const response = yield call(post, `${apiBase}/setup-totp`, bodyParams);

    yield put(actions.apiSetupTotp.success(response));

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSetupTotp.failure(null, { notification: e, reporting: e }));
  }
}

function* apiValidateTotp(action) {
  const {
    payload: { params, submitPromise },
  } = action;
  const profile = yield select(getUserProfile);
  const { totpCode, auth_method } = params;
  const bodyParams = {
    auth_method,
    access_token: profile.token,
    totp_code: totpCode,
  };

  try {
    const isValid = yield call(post, `${apiBase}/validate-totp`, bodyParams);

    if (isValid === null) {
      yield put(actions.apiValidateTotp.success());

      if (submitPromise) {
        submitPromise.resolve();
      }
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.apiValidateTotp.failure(null, { notification: e, reporting: e }));
  }
}

function* apiValidatePhoneNumber(action) {
  const {
    payload: { params, submitPromise },
  } = action;
  const profile = yield select(getUserProfile);
  const smsTransaction = yield select(getSmsTransaction);
  const { smsCode, auth_method } = params;
  const bodyParams = {
    auth_method,
    access_token: profile.token,
    code: smsCode,
    ...(smsTransaction && { request_id: smsTransaction.request_id }),
  };

  try {
    const isValid = yield call(post, `${apiBase}/validate-phone-number`, bodyParams);

    if (isValid === null) {
      yield put(actions.apiValidatePhoneNumber.success());

      if (submitPromise) {
        submitPromise.resolve();
      }
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield put(actions.apiValidatePhoneNumber.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSetAuthMethods(action) {
  const {
    payload: { params, submitPromise },
  } = action;
  const { primary_auth_method, backup_auth_method } = params;

  try {
    const profile = yield select(getUserProfile);
    const bodyParams = {
      backup_auth_method,
      primary_auth_method,
      access_token: profile.token,
    };
    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(actions.apiSetAuthMethods, action.payload);

      bodyParams.elevated_token = elevatedTokens.idToken;
    }

    yield call(post, `${apiBase}/set-auth-methods`, bodyParams);
    yield put(actions.apiSetAuthMethods.success());

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    yield* checkTokenExpiration(e);
    yield put(actions.apiSetAuthMethods.failure(null, { notification: e, reporting: e }));

    if (submitPromise) {
      // submitPromise.reject(e);
    }
  }
}

function* apiSetPrimaryAuthMethod(action) {
  const {
    payload: { params, submitPromise },
  } = action;
  const { method } = params;

  try {
    const profile = yield select(getUserProfile);

    if (!profile) {
      yield put(actions.apiSetPrimaryAuthMethod.cancel());
      history.replace(
        `${AppRoutes.signin.index}?${stringify({
          ...getParams(),
          internal_redirect: window.location.pathname,
        })}`,
      );

      return;
    }

    const queryParams = {
      method,
      access_token: profile.token,
    };
    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(
        actions.apiSetPrimaryAuthMethod,
        action.payload,
      );

      queryParams.elevated_token = elevatedTokens.idToken;
    }

    yield call(post, `${apiBase}/set-primary-auth-method`, queryParams);
    yield put(actions.apiSetPrimaryAuthMethod.success());
    yield put(destroy(CONFIG.formNames.setupPrimaryMethod));

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSetPrimaryAuthMethod.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSetBackupAuthMethod(action) {
  const {
    payload: { params, submitPromise },
  } = action;
  const { method } = params;

  try {
    const profile = yield select(getUserProfile);

    if (!profile) {
      yield put(actions.apiSetBackupAuthMethod.cancel());
      history.replace(
        `${AppRoutes.signin.index}?${stringify({
          ...getParams(),
          internal_redirect: window.location.pathname,
        })}`,
      );

      return;
    }

    const queryParams = {
      method,
      access_token: profile.token,
    };

    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(
        actions.apiSetBackupAuthMethod,
        action.payload,
      );

      queryParams.elevated_token = elevatedTokens.idToken;
    }

    yield call(post, `${apiBase}/set-backup-auth-method`, queryParams);
    yield put(actions.apiSetBackupAuthMethod.success());
    yield put(destroy(CONFIG.formNames.setupBackupMethod));

    if (submitPromise) {
      submitPromise.resolve();
    }
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSetBackupAuthMethod.failure(null, { notification: e, reporting: e }));
  }
}

function* apiOfflineCodes(action) {
  const {
    payload: { params, submitPromise },
  } = action;

  try {
    const queryParams = getParams();
    const { regenerate, auth_method } = params;
    const profile = yield select(getUserProfile);
    const omit_elevate_token = Boolean(queryParams.omit_elevate_token);

    if (!profile) {
      yield put(actions.apiOfflineCodes.cancel());
      history.replace(
        `${AppRoutes.signin.index}?${stringify({
          ...getParams(),
          internal_redirect: '/offline-codes',
        })}`,
      );

      return null;
    }

    const bodyParams = {
      auth_method,
      regenerate,
      access_token: profile.token,
    };

    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method && !omit_elevate_token) {
      const elevatedTokens = yield* getElevatedTokens(actions.apiOfflineCodes, action.payload);

      bodyParams.elevated_token = elevatedTokens.idToken;
    } else {
      bodyParams.omit_elevate_token = omit_elevate_token;
    }

    const { offline_codes } = yield call(post, `${apiBase}/get-offline-codes`, bodyParams);

    yield put(actions.apiOfflineCodes.success(offline_codes));

    if (submitPromise) {
      submitPromise.resolve();
    }

    return offline_codes;
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiOfflineCodes.failure(null, { notification: e, reporting: e }));

    return null;
  }
}

function* apiSkipMfa(action) {
  const {
    payload: { submitPromise },
  } = action;

  try {
    const profile = yield select(getUserProfile);
    const bodyParams = {
      access_token: profile.token,
    };

    if (submitPromise) {
      submitPromise.resolve();
    }

    yield call(post, `${apiBase}/skip-mfa`, bodyParams);
    yield put(actions.apiSsoRedirect.request());
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSkipMfa.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSendSmsCode(action) {
  log('Request phone transactional code');

  const { payload: { submitPromise, params } = {} } = action;

  try {
    const bodyParams = {};

    if (params && params.authChallenge) {
      const challenge = yield select(getAuthChallenge);

      bodyParams.session = challenge.session;
    } else {
      const profile = yield select(getUserProfile);

      bodyParams.access_token = profile.token;
    }

    if (submitPromise) {
      submitPromise.resolve();
    }

    const response = yield call(post, `${apiBase}/send-phone-verification-code`, bodyParams);

    yield put(actions.apiSendSmsCode.success(response));
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSendSmsCode.failure(null, { notification: e, reporting: e }));
  }
}

function* apiResendSmsCode(action) {
  const {
    payload: { submitPromise, params },
  } = action;

  try {
    const bodyParams = {};

    if (params && params.authChallenge) {
      const challenge = yield select(getAuthChallenge);

      bodyParams.session = challenge.session;
    } else {
      const profile = yield select(getUserProfile);

      bodyParams.access_token = profile.token;
    }

    const smsTransaction = yield select(getSmsTransaction);

    if (smsTransaction) {
      bodyParams.request_id = smsTransaction.request_id;
    }

    if (submitPromise) {
      submitPromise.resolve();
    }

    const response = yield call(post, `${apiBase}/send-phone-verification-code`, bodyParams);

    yield put(actions.apiResendSmsCode.success(response));
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiResendSmsCode.failure(null, { notification: e, reporting: e }));
  }
}

function* apiSetupPhoneNumber(action) {
  const {
    payload: { submitPromise, params },
  } = action;
  const flags = getFlags();

  try {
    const profile = yield select(getUserProfile);
    const bodyParams = {
      flags,
      access_token: profile.token,
      phone: `${params.phoneCountryCode}${params.phone}`,
    };
    const user = yield* apiUserData({ fetchPolicy: 'cache-first' });

    if (user.primary_auth_method) {
      const elevatedTokens = yield* getElevatedTokens(actions.apiSetupPhoneNumber, action.payload);

      bodyParams.elevated_token = elevatedTokens.idToken;
    }

    const response = yield call(post, `${apiBase}/setup-phone-number`, bodyParams);

    if (submitPromise) {
      submitPromise.resolve();
    }

    yield put(actions.apiSetupPhoneNumber.success(response));
  } catch (e) {
    if (submitPromise) {
      submitPromise.reject(e);
    }

    yield* checkTokenExpiration(e);
    yield put(actions.apiSetupPhoneNumber.failure(null, { notification: e, reporting: e }));
  }
}

function* apiRemoveBackupAuthMethod(action) {
  const {
    payload: { method },
  } = action;

  try {
    const profile = yield select(getUserProfile);
    const elevatedTokens = yield* getElevatedTokens(
      actions.apiRemoveBackupAuthMethod,
      action.payload,
    );
    const bodyParams = {
      method,
      access_token: profile.token,
      elevated_token: elevatedTokens.idToken,
    };

    yield call(post, `${apiBase}/remove-backup-auth-method`, bodyParams);
    yield put(actions.apiRemoveBackupAuthMethod.success({ method }));
  } catch (e) {
    yield* checkTokenExpiration(e);
    yield put(actions.apiRemoveBackupAuthMethod.failure(null, { notification: e, reporting: e }));
  }
}

function* usersSagas() {
  yield takeLatest(accountRecoveryActions.UPDATE_EMAIL.REQUEST, apiEmailChangeRequest);
  yield takeLatest(actions.API_SIGNUP_NEW_REQUEST.REQUEST, apiSignUpNewRequest);
  yield takeLatest(actions.AUTH_CHALLENGE.REQUEST, apiAuthChallenge);
  yield takeLatest(actions.CHANGE_USERNAME.REQUEST, apiChangeUsername);
  yield takeLatest(actions.CHECK_USER.REQUEST, apiCheckUser);
  yield takeLatest(actions.ELEVATE.REQUEST, apiElevate);
  yield takeLatest(actions.EMAIL_CHANGE.REQUEST, apiEmailChangeRequest);
  yield takeLatest(actions.FORGOT_PASSWORD.REQUEST, apiForgotPasswordRequest);
  yield takeLatest(actions.FORGOT_PASSWORD_CONFIRM.REQUEST, apiForgotPasswordConfirm);
  yield takeLatest(actions.OFFLINE_CODES.REQUEST, apiOfflineCodes);
  yield takeLatest(actions.OFFLINE_CODES.SUCCESS, apiUserData);
  yield takeLatest(actions.PASSWORD_RESET_NEW_REQUEST.REQUEST, apiForgotPasswordRequest);
  yield takeLatest(actions.RECOVER_USERNAME.REQUEST, apiRecoverUsername);
  yield takeLatest(actions.REMOVE_BACKUP_AUTH_METHODS.REQUEST, apiRemoveBackupAuthMethod);
  yield takeLatest(actions.RESEND_SMS_CODE.REQUEST, apiResendSmsCode);
  yield takeLatest(actions.SEND_SMS_CODE.REQUEST, apiSendSmsCode);
  yield takeLatest(actions.SET_AUTH_METHODS.REQUEST, apiSetAuthMethods);
  yield takeLatest(actions.SET_AUTH_METHODS.SUCCESS, apiUserData);
  yield takeLatest(actions.SET_BACKUP_AUTH_METHODS.REQUEST, apiSetBackupAuthMethod);
  yield takeLatest(actions.SET_BACKUP_AUTH_METHODS.SUCCESS, apiUserData);
  yield takeLatest(actions.SET_PRIMARY_AUTH_METHODS.REQUEST, apiSetPrimaryAuthMethod);
  yield takeLatest(actions.SET_PRIMARY_AUTH_METHODS.SUCCESS, apiUserData);
  yield takeLatest(actions.SETUP_PASSPHRASE.REQUEST, apiSetupPassphrase);
  yield takeLatest(actions.SETUP_PHONE_NUMBER.REQUEST, apiSetupPhoneNumber);
  yield takeLatest(actions.SETUP_TOTP.REQUEST, apiSetupTotp);
  yield takeLatest(actions.SIGNIN.REQUEST, apiSignin);
  yield takeLatest(actions.SIGNOUT.REQUEST, apiSignOut);
  yield takeLatest(actions.SIGNUP.REQUEST, apiSignUp);
  yield takeLatest(actions.SIGNUP_CONFIRM.REQUEST, apiSignUpConfirm);
  yield takeLatest(actions.SKIP_MFA.REQUEST, apiSkipMfa);
  yield takeLatest(actions.SSO_REDIRECT.REQUEST, apiSsoRedirect);
  yield takeLatest(actions.USER_DATA.REQUEST, apiUserData);
  yield takeLatest(actions.VALIDATE_PASSPHRASE.REQUEST, apiPassphraseValidate);
  yield takeLatest(actions.VALIDATE_PASSPHRASE.SUCCESS, apiUserData);
  yield takeLatest(actions.VALIDATE_PHONE_NUMBER.REQUEST, apiValidatePhoneNumber);
  yield takeLatest(actions.VALIDATE_PHONE_NUMBER.SUCCESS, apiUserData);
  yield takeLatest(actions.VALIDATE_TOTP.REQUEST, apiValidateTotp);
  yield takeLatest(actions.VALIDATE_TOTP.SUCCESS, apiUserData);
}

export default usersSagas;
