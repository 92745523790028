import { generateActions, generateActionTypes } from '@ace/core-utils';

export const API_BUSINESS_REGISTER = generateActionTypes('API_BUSINESS_REGISTER');
export const API_BUSINESS_LOCATION = generateActionTypes('API_BUSINESS_LOCATION');
export const API_BUSINESS_LOCATION_PATCH = generateActionTypes('API_BUSINESS_LOCATION_PATCH');
export const API_BUSINESS_LOCATION_CURRENCY_PATCH = generateActionTypes(
  'API_BUSINESS_LOCATION_CURRENCY_PATCH'
);
export const API_EDIT_BUSINESS_REWARD = generateActionTypes('API_EDIT_BUSINESS_REWARD');

export const apiRegisterBusiness = generateActions(API_BUSINESS_REGISTER);
export const apiBusinessLocation = generateActions(API_BUSINESS_LOCATION);
export const apiBusinessLocationPatch = generateActions(API_BUSINESS_LOCATION_PATCH);
export const apiBusinessLocationCurrencyPatch = generateActions(
  API_BUSINESS_LOCATION_CURRENCY_PATCH
);
export const apiEditBusinessReward = generateActions(API_EDIT_BUSINESS_REWARD);
