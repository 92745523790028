import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { components } from '@ace/core-utils';

import s from './CustomLink.module.scss';

const CustomLink = ({ children, href, id, onClick, parentId, theme, className, ...rest }) => {
  const thisId = components.generateId(id, parentId);
  const thisClass = classNames(s.this, className, {
    [s[`_${theme}`]]: theme
  });

  const target = rest.target || '_blank';
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  if (href) {
    return (
      <a href={href} id={thisId} className={thisClass} {...rest} target={target} rel={rel}>
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <button type="button" id={thisId} className={thisClass} {...rest} onClick={onClick}>
        {children}
      </button>
    );
  }
  return (
    <Link className={thisClass} id={thisId} {...rest}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  id: PropTypes.string,
  parentId: PropTypes.string,
  theme: PropTypes.oneOf(['error', 'primary', 'success', 'white']),
  onClick: PropTypes.func
};

CustomLink.defaultProps = {
  href: null,
  id: 'custom-link',
  parentId: '',
  onClick: null,
  theme: 'success'
};

export default CustomLink;
