import React from 'react';
import { Icon, iconTypes } from '@ace/core-visuals';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import generateId from '../../utils/generateId';
import styles from './TextAndCheck.module.scss';

const TextAndCheck = ({ children, id, input, meta, parentId }) => {
  const textAndCheckClassName = classNames({
    [styles.this]: true,
    [styles.this_error]: meta.submitFailed && !input.value,
    [styles.this_success]: meta.submitFailed && input.value,
  });
  const thisId = generateId(id, parentId);

  return (
    <div className={textAndCheckClassName} id={thisId}>
      <span className={styles.content}>{children}</span>
      <label htmlFor={input.name} className={styles.checkbox}>
        <input
          {...input}
          checked={input.value}
          className={styles.input}
          form={meta.form}
          id={input.name}
          type="checkbox"
        />
        <span className={styles.tick}>
          <Icon parentId={thisId} type={iconTypes.tick} />
        </span>
      </label>
    </div>
  );
};

TextAndCheck.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string,
  }).isRequired,
  parentId: PropTypes.string,
};

TextAndCheck.defaultProps = {
  id: 'text-and-checkbox',
  parentId: '',
};

export default TextAndCheck;
