import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {
  Block,
  ButtonList,
  CustomLink,
  Menu,
  Page,
  PageFooter,
  PageMain,
} from '@ace/core-components';
import { iconTypes } from '@ace/core-visuals';
import { getLoading } from '@ace/core-components/src/ui/reducers';
import PageHeader from '../../components/PageHeader';
import SecurityMethodsInfoLink from '../../components/SecurityMethodsInfoLink';
import SetupBackupMethod from '../SetupBackupMethod';
import SetupPrimaryMethod from '../SetupPrimaryMethod';
import SublinkMenuItem from '../../components/SublinkMenuItem';
import Text from '../../components/Text';

import {
  apiSetAuthMethods,
  apiSkipMfa,
  apiSsoRedirect,
  apiUserData,
} from '../../store/actions/user';
import { getUserData } from '../../store/reducers/mfa';
import AppRoutes from '../../routes';

import messages from './messages';

const getMethodMessage = method => {
  switch (method) {
    case 'totp':
      return messages.authenticatorApp;
    case 'offline_codes':
      return messages.offlineCodes;
    case 'passphrase':
      return messages.passphrase;
    case 'phone':
      return messages.phone;
    default:
      return {};
  }
};

class ExtraSecurity extends PureComponent {
  componentDidMount() {
    if (!this.props.user) {
      this.props.requestUserData();
    }
  }

  onSwap = () => {
    const { requestSetAuthMethods, user } = this.props;

    requestSetAuthMethods({
      params: {
        backup_auth_method: user.primary_auth_method,
        primary_auth_method: user.backup_auth_method,
      },
    });
  };

  render() {
    const {
      history,
      intl: { formatMessage },
      loading,
      location,
      requestSkipMfa,
      requestSsoRedirect,
      user,
    } = this.props;
    const isFinishButtonDisabled = !user || !user.primary_auth_method;
    const isSkipButtonVisible = user && !user.primary_auth_method;
    const isSwapButtonVisible = user && user.backup_auth_method && user.primary_auth_method;
    const finishButtonLabel = formatMessage(messages.finishButton);
    const skipButtonLabel = formatMessage(messages.skipButton);
    const swapButtonLabel = formatMessage(messages.swapButton);

    const buttons = [
      isSwapButtonVisible && {
        onClick: this.onSwap,
        theme: 'success',
        title: swapButtonLabel,
        value: swapButtonLabel,
        view: 'cta',
      },
      {
        disabled: isFinishButtonDisabled,
        onClick: () => requestSsoRedirect(),
        theme: 'primary',
        title: finishButtonLabel,
        value: finishButtonLabel,
        view: 'cta',
      },
      isSkipButtonVisible && {
        disabled: loading,
        onClick: () => requestSkipMfa(requestSkipMfa),
        theme: 'success',
        title: skipButtonLabel,
        value: skipButtonLabel,
        view: 'cta',
      },
    ].filter(Boolean);

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader
          intro={
            <>
              <FormattedMessage {...messages.para1} />
              <br />
              <br />
              <FormattedMessage {...messages.para2} />
              <br />
              <br />
              <FormattedMessage {...messages.para3} />
              <br />
              <br />
              <FormattedMessage {...messages.para4} />
              <br />
              <br />
              <Text tag="span" theme="accent">
                <FormattedMessage {...messages.para5} />
              </Text>
            </>
          }
          title={formatMessage(messages.title)}
        />
        <PageMain>
          <Menu
            items={[
              {
                icon: iconTypes.menuOne,
                id: 'primary_method',
                isSelected: user && Boolean(user.primary_auth_method),
                label: (
                  <SublinkMenuItem
                    label={<FormattedMessage {...messages.primaryMethod} />}
                    sublabel={
                      user &&
                      user.primary_auth_method && (
                        <div
                          style={{
                            fontSize: '0.8rem',
                          }}
                        >
                          <CustomLink to="" onClick={e => e.preventDefault()}>
                            {formatMessage(getMethodMessage(user.primary_auth_method))}
                          </CustomLink>
                        </div>
                      )
                    }
                  />
                ),
                onClick:
                  user && !user.primary_auth_method
                    ? () => {
                        history.push({
                          ...location,
                          pathname: AppRoutes.signup.extraSecurity.primary,
                        });
                      }
                    : undefined,
              },
              {
                icon: iconTypes.menuTwo,
                id: 'backup_method',
                isDisabled: user && !user.primary_auth_method,
                isSelected: user && Boolean(user.backup_auth_method),
                label: (
                  <SublinkMenuItem
                    label={<FormattedMessage {...messages.backupMethod} />}
                    sublabel={
                      user &&
                      user.backup_auth_method && (
                        <div
                          style={{
                            fontSize: '0.8rem',
                          }}
                        >
                          <CustomLink to="" onClick={e => e.preventDefault()}>
                            {formatMessage(getMethodMessage(user.backup_auth_method))}
                          </CustomLink>
                        </div>
                      )
                    }
                  />
                ),
                onClick:
                  user && !user.backup_auth_method
                    ? () => {
                        history.push({
                          ...location,
                          pathname: AppRoutes.signup.extraSecurity.backup,
                        });
                      }
                    : undefined,
              },
            ]}
          />
        </PageMain>
        <PageFooter>
          <Block pv="small">
            <SecurityMethodsInfoLink to={AppRoutes.signup.extraSecurity.info} />
          </Block>
          <ButtonList buttons={buttons} />
        </PageFooter>
      </Page>
    );
  }
}

ExtraSecurity.propTypes = {
  intl: intlShape.isRequired,
  requestSkipMfa: PropTypes.func.isRequired,
  requestSsoRedirect: PropTypes.func.isRequired,
  requestUserData: PropTypes.func.isRequired,
};

ExtraSecurity.defaultProps = {};

const mapStateToProps = state => ({
  loading: getLoading(state),
  user: getUserData(state),
});

const mapDispatchToProps = {
  requestSetAuthMethods: apiSetAuthMethods.request,
  requestSkipMfa: apiSkipMfa.request,
  requestSsoRedirect: apiSsoRedirect.request,
  requestUserData: apiUserData.request,
};

const ExtraSecurityConnected = connect(mapStateToProps, mapDispatchToProps)(ExtraSecurity);

const ExtraSecurityTranslated = injectIntl(ExtraSecurityConnected);

export default () => (
  <Switch>
    <Route exact path={AppRoutes.signup.extraSecurity.index} component={ExtraSecurityTranslated} />
    <Route path={AppRoutes.signup.extraSecurity.primary} component={SetupPrimaryMethod} />
    <Route path={AppRoutes.signup.extraSecurity.backup} component={SetupBackupMethod} />
  </Switch>
);
