import { defineMessages } from 'react-intl';

export default defineMessages({
  depositComment: '(approx.)',
  depositNoAvatarIllustrationTitle: 'Bought Bitcoin',
  depositStatus: 'Bought',
  distributionIllustrationTitle: 'Sending Bitcoin',
  distributionNoAvatarIllustrationTitle: 'Avatar placeholder',
  distributionStatus: 'Sending:',
  title: 'All Done!'
});
