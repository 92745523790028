import React from 'react';

export const camera = (
  <g id="Icons/Camera" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <circle id="Oval" cx="21" cy="23.5" r="7.5" stroke="currentColor" strokeWidth="3" />
    <path id="Rectangle-49" stroke="currentColor" strokeWidth="3" d="M1.5 8.5h39v30h-39z" />
    <path id="Line-2" stroke="currentColor" strokeLinecap="square" strokeWidth="3" d="M1.5 2.5h9" />
  </g>
);

export const copied = (
  <g id="Icons/Copied">
    <defs>
      <rect id="copied-path-1" width="35.676" height="36.16" x="5.727" rx="5.727" />
      <rect id="copied-path-2" width="35.676" height="36.16" y="5.727" rx="5.727" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g id="Group-2">
        <g id="Group">
          <g id="Rectangle">
            <use fill="#FFF" xlinkHref="#copied-path-1" />
            <rect
              width="33.767"
              height="34.251"
              x="6.682"
              y=".955"
              stroke="currentColor"
              strokeWidth="1.909"
              rx="5.727"
            />
          </g>
          <g id="Rectangle">
            <use fill="#FFF" xlinkHref="#copied-path-2" />
            <rect
              width="33.767"
              height="34.251"
              x=".955"
              y="6.682"
              stroke="currentColor"
              strokeWidth="1.909"
              rx="5.727"
            />
          </g>
        </g>
        <path
          id="Fill-3"
          fill="currentColor"
          d="M16.228 25.169l-2.88-2.88a2.225 2.225 0 0 0-3.15 0 2.227 2.227 0 0 0 0 3.15l4.455 4.454a2.221 2.221 0 0 0 1.575.652c.57 0 1.14-.218 1.574-.652l8.909-8.909c.87-.87.87-2.278 0-3.15a2.225 2.225 0 0 0-3.15 0l-7.333 7.335z"
        />
      </g>
    </g>
  </g>
);

export const copy = (
  <g
    id="Icons/Copy"
    fill="none"
    fillRule="evenodd"
    stroke="#CDCDCD"
    strokeWidth="1.25"
    transform="scale(2.32) translate(2 2)"
  >
    <rect width="8.75" height="8.75" x="3.625" y=".625" rx="1" />
    <rect width="8.75" height="8.75" x=".625" y="3.625" fill="#FFF" rx="1" />
  </g>
);

export const iOSShare = (
  <g id="Icons/IOS" fill="none" fillRule="evenodd">
    <g id="ios-share" stroke="#3983AA" strokeWidth="1.667" transform="translate(7 1.2)">
      <path
        id="Shape"
        d="M8.262 6.809L15 0l6.738 6.809M15 25.532V.283M8.262 12.766H.682V40h28.636V12.766h-7.58"
      />
    </g>
  </g>
);

export const settings = (
  <g id="Icons/Settings" fill="none" fillRule="evenodd">
    <g id="settings" fill="currentColor" fillRule="nonzero">
      <path
        id="Shape"
        d="M39.876 17.238h-2.06A17.066 17.066 0 0 0 35.6 11.72l1.406-1.407c.83-.83.83-2.174 0-3.004L34.69 4.993a2.124 2.124 0 0 0-3.004 0L30.329 6.35a17.081 17.081 0 0 0-5.567-2.338V2.124A2.125 2.125 0 0 0 22.638 0h-3.276c-1.173 0-2.124.95-2.124 2.124v1.888A17.077 17.077 0 0 0 11.67 6.35l-1.358-1.357a2.124 2.124 0 0 0-3.004 0L4.993 7.309c-.83.83-.83 2.175 0 3.004l1.406 1.407a17.067 17.067 0 0 0-2.215 5.518h-2.06A2.125 2.125 0 0 0 0 19.363v3.275c0 1.173.95 2.124 2.124 2.124h2.164a17.084 17.084 0 0 0 2.287 5.343l-1.582 1.582c-.83.83-.83 2.175 0 3.004l2.316 2.316c.83.83 2.174.83 3.004 0l1.631-1.63a17.077 17.077 0 0 0 5.294 2.162v2.337c0 1.173.95 2.124 2.124 2.124h3.275c1.174 0 2.125-.95 2.125-2.124v-2.337a17.084 17.084 0 0 0 5.293-2.163l1.632 1.631c.83.83 2.174.83 3.004 0l2.316-2.316c.83-.83.83-2.174 0-3.004l-1.582-1.582a17.075 17.075 0 0 0 2.287-5.343h2.164c1.173 0 2.124-.95 2.124-2.124v-3.275c0-1.174-.95-2.125-2.124-2.125zM21 28.018a7.243 7.243 0 1 1 0-14.486 7.243 7.243 0 0 1 0 14.487z"
      />
    </g>
  </g>
);

export const badRate = (
  <g id="Icons/Ratings/Bad/Not-Selected" fill="none" fillRule="evenodd">
    <circle id="Oval-13" cx="22" cy="22" r="20" fill="currentColor" fillRule="nonzero" />
    <circle
      id="Oval-11"
      cx="20"
      cy="20"
      r="19.048"
      fillRule="nonzero"
      stroke="#2B2B2B"
      strokeWidth="1.905"
    />
    <g id="Group" fill="#2B2B2B" fillRule="nonzero" transform="translate(11.429 11.429)">
      <circle id="Oval-12" cx="2.381" cy="2.381" r="2.381" />
      <circle id="Oval-12" cx="14.762" cy="2.381" r="2.381" />
    </g>
    <path
      id="Rectangle-4"
      fill="#2B2B2B"
      fillRule="nonzero"
      d="M9.524 30.186s5-7.329 10-7.329 10 6.831 10 7.329a.257.257 0 0 1-.441.18c-3.186-3.261-6.373-4.891-9.56-4.891-3.186 0-6.372 1.63-9.558 4.89a.257.257 0 0 1-.441-.18z"
    />
  </g>
);

export const mediumRate = (
  <g id="Icons/Ratings/Average/Not-Selected" fill="none" fillRule="evenodd">
    <circle id="Oval-13" cx="22" cy="22" r="20" fill="currentColor" fillRule="nonzero" />
    <g id="Group-2" fillRule="nonzero">
      <circle id="Oval-11" cx="20" cy="20" r="19.048" stroke="#2B2B2B" strokeWidth="1.905" />
      <g id="Group" fill="#2B2B2B" transform="translate(11.429 11.429)">
        <circle id="Oval-12" cx="2.381" cy="2.381" r="2.381" />
        <circle id="Oval-12" cx="14.762" cy="2.381" r="2.381" />
      </g>
      <path
        id="Rectangle-4"
        fill="#2B2B2B"
        d="M9.524 26.667c4.127-.635 7.718-.953 10.772-.953 3.055 0 6.448.318 10.18.953-3.453.824-6.846 1.237-10.18 1.237-3.333 0-6.924-.413-10.772-1.237z"
      />
    </g>
  </g>
);

export const goodRate = (
  <g id="Icons/Ratings/Good/Not-Selected" fill="none" fillRule="evenodd">
    <circle id="Oval-13" cx="22" cy="22" r="20" fill="currentColor" fillRule="nonzero" />
    <g id="Group-2" fillRule="nonzero">
      <circle id="Oval-11" cx="20" cy="20" r="19.048" stroke="#2B2B2B" strokeWidth="1.905" />
      <g id="Group" fill="#2B2B2B" transform="translate(11.429 11.429)">
        <circle id="Oval-12" cx="2.381" cy="2.381" r="2.381" />
        <circle id="Oval-12" cx="14.762" cy="2.381" r="2.381" />
      </g>
      <path
        id="Rectangle-4"
        fill="#2B2B2B"
        d="M9.82 30.186s5-7.329 10-7.329 10 6.831 10 7.329a.257.257 0 0 1-.44.18c-3.187-3.261-6.373-4.891-9.56-4.891-3.186 0-6.372 1.63-9.559 4.89a.257.257 0 0 1-.44-.18z"
        transform="matrix(1 0 0 -1 0 53.333)"
      />
    </g>
  </g>
);

export const tick = (
  <g id="Icons/Tick/Selected" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Fill-3"
      fill="#FFF"
      d="M27.519 13.77l-8.644 8.643-3.394-3.394a2.622 2.622 0 0 0-3.711 0 2.622 2.622 0 0 0 0 3.712l5.25 5.25a2.618 2.618 0 0 0 3.711-.001l10.5-10.499a2.622 2.622 0 0 0 0-3.711 2.622 2.622 0 0 0-3.712 0"
    />
  </g>
);

export const cross = (
  <g id="Icons/Tick/Cross" fill="#fff" fillRule="evenodd" transform="scale(2)">
    <path d="M15.262 5.952c.51.509.51 1.335 0 1.844l-7.38 7.379a1.304 1.304 0 0 1-1.844-1.844l7.38-7.38c.51-.508 1.335-.508 1.844 0z" />
    <path d="M6.039 5.952c-.51.509-.51 1.335 0 1.844l7.379 7.379a1.304 1.304 0 0 0 1.844-1.844l-7.38-7.38a1.304 1.304 0 0 0-1.843 0z" />
  </g>
);

export const refresh = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    fill="#09C1C4"
    fillRule="evenodd"
    d="M10.475 1.65A6.225 6.225 0 0 0 6.25 0 6.257 6.257 0 0 0 0 6.25a6.257 6.257 0 0 0 6.25 6.25 6.23 6.23 0 0 0 4.412-1.827L9.425 9.436A4.486 4.486 0 0 1 6.25 10.75a4.505 4.505 0 0 1-4.5-4.5c0-2.481 2.019-4.5 4.5-4.5 1.146 0 2.192.43 2.987 1.138L7.75 4.375H12V.125L10.475 1.65z"
    transform="scale(2) translate(4,4)"
  />
);

export const print = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    fill="#09c1c4"
    fillRule="evenodd"
    transform="scale(2) translate(2,1)"
  >
    <path d="m14.321 12.549h-1.159v-2.353h-10.775v2.353h-1.16v-5.797h13.093v5.797zm-10.707 3.58h8.32v-4.705h-8.32zm0-10.605h8.32v-4.297h-8.32zm11.935 0h-2.386v-5.524h-10.776v5.524h-2.387v8.252h2.387v3.58h10.776v-3.58h2.386z" />
    <path d="m4.91 14.48h5.728v-1.203h-5.728z" mask="url(#a)" />
  </g>
);

export const timer = (
  // <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
  <path
    fill="#09C1C4"
    fillRule="nonzero"
    stroke="#09C1C4"
    strokeWidth=".1"
    d="M14.55 5.524c.048.1.033.218-.038.303L13.085 7.54a.286.286 0 0 1-.438 0l-1.426-1.712a.285.285 0 0 1 .22-.468h.838a5.14 5.14 0 0 0-5.003-3.993A5.141 5.141 0 0 0 2.141 6.5a5.141 5.141 0 0 0 5.135 5.135 5.156 5.156 0 0 0 4.448-2.568.57.57 0 1 1 .988.572 6.3 6.3 0 0 1-5.436 3.136A6.283 6.283 0 0 1 1 6.5 6.284 6.284 0 0 1 7.276.224c3.071 0 5.633 2.218 6.17 5.135h.846c.111 0 .212.064.259.165zM6.999 2c.315 0 .571.256.572.57v3.463a.994.994 0 0 1 .418.73L9.975 7.91a.57.57 0 1 1-.57.988L7.42 7.752a.986.986 0 0 1-.421.097.997.997 0 1 1-.57-1.816L6.427 2.57A.57.57 0 0 1 6.998 2z"
  />
  // </svg>
);

export const info = (
  <path
    transform="scale(3)"
    d="m7 0a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 1.273a5.727 5.727 0 1 0 0 11.454 5.727 5.727 0 0 0 0-11.454zm1.114 2.706c0 .264-.09.49-.273.674a.884.884 0 0 1 -.656.279.894.894 0 0 1 -.66-.279.923.923 0 0 1 -.275-.674c0-.263.091-.488.275-.676a.89.89 0 0 1 .66-.28c.255 0 .474.093.656.28a.935.935 0 0 1 .273.676zm-.091 6.277c-.274.131-.492.231-.655.3a1.448 1.448 0 0 1 -.567.103c-.331 0-.589-.098-.773-.294a1.053 1.053 0 0 1 -.275-.749c0-.117.007-.237.02-.36.014-.123.036-.261.067-.416l.342-1.47c.03-.142.056-.275.077-.402.02-.125.03-.24.03-.344 0-.188-.031-.32-.095-.393-.064-.074-.185-.112-.365-.112a.802.802 0 0 0 -.272.051c-.093.033-.148-.358-.148-.358.224-.11.438-.205.643-.284.204-.08.398-.119.58-.119.33 0 .583.097.761.29s.267.444.267.753c0 .064-.006.177-.018.338s-.035.309-.069.443l-.34 1.467a4.652 4.652 0 0 0 -.108.746c0 .195.036.327.107.398.072.07.196.106.373.106a.898.898 0 0 0 .281-.053c.105-.035.137.36.137.36z"
    fill="currentColor"
  />
);

export const statusError = (
  <g fill="none" fillRule="evenodd">
    <path d="M0 0H16V16H0z" />
    <circle cx="8" cy="8" r="8" fill="#F4528B" fillRule="nonzero" />
    <path
      fill="#FFF"
      d="M7.461 6.463h.727c.295 0 .533.24.533.534v4.28c0 .294-.238.533-.533.533h-.727c-.294 0-.533-.24-.533-.534v-4.28c0-.294.239-.533.533-.533zM7.81 3.81c.276 0 .504.086.683.259.18.172.269.396.269.67 0 .267-.09.488-.269.664-.179.176-.407.264-.683.264-.277 0-.505-.088-.684-.264-.18-.176-.269-.397-.269-.665 0-.273.09-.497.269-.67.179-.172.407-.258.684-.258z"
    />
  </g>
);

export const statusSuccess = (
  <g fill="none" fillRule="evenodd">
    <path d="M0 0H16V16H0z" />
    <path d="M0 0H16V16H0z" />
    <circle cx="8" cy="8" r="8" fill="#09C1C4" />
    <path
      fill="#FFF"
      d="M10.483 5.246L7.191 8.538 5.898 7.245c-.391-.39-1.023-.39-1.414 0-.391.391-.391 1.023 0 1.414l2 2c.195.195.45.293.707.293.256 0 .512-.098.706-.293l4-4c.391-.39.391-1.022 0-1.413-.39-.391-1.023-.391-1.414 0"
    />
  </g>
);
