import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, reduxForm, propTypes } from 'redux-form';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import asyncFormSubmit from '../../../../utils/asyncFormSubmit';
import ConfirmPopup from '../../../../components/ConfirmPopup';
import Block from '../../../../components/Block';
import PageHeader from '../../../../components/PageHeader';
import messages from './messages';

class NewEmailPage extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleConfirmPopup = this.toggleConfirmPopup.bind(this);
    this.state = {
      confirmPopup: false
    };
  }

  onConfirmSubmit = () => asyncFormSubmit(this.props.form, this.props.onSubmit).catch(() => {});

  toggleConfirmPopup() {
    this.setState(prevState => ({
      confirmPopup: !prevState.confirmPopup
    }));
  }

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
      invalid,
      newEmail,
      submitting
    } = this.props;
    const { confirmPopup } = this.state;

    return (
      <Page>
        <FormattedMessage {...messages.title}>{title => <Helmet title={title} />}</FormattedMessage>
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          <Block>
            <form id={CONFIG.formNames.recover} onSubmit={handleSubmit(this.onConfirmSubmit)}>
              <FieldInputs.NewEmail required />
            </form>
          </Block>
          <ConfirmPopup
            buttonType="submit"
            buttonValue={formatMessage(messages.confirmButton)}
            form={CONFIG.formNames.recover}
            intro={<FormattedHTMLMessage {...messages.confirmPopup} values={{ newEmail }} />}
            isActive={confirmPopup}
            onClose={this.toggleConfirmPopup}
            submitting={submitting}
            theme="error"
            title={formatMessage(messages.confirmPopupTitle)}
          />
        </PageMain>
        <PageFooter>
          <Button
            disabled={invalid}
            onClick={this.toggleConfirmPopup}
            theme="primary"
            title={formatMessage(messages.nextButton)}
            type="button"
            value={formatMessage(messages.nextButton)}
            view="cta"
          />
        </PageFooter>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  newEmail: formValueSelector(CONFIG.formNames.recover)(state, 'newEmail')
});

NewEmailPage.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
  newEmail: PropTypes.string
};

NewEmailPage.defaultProps = {
  newEmail: undefined
};

const NewEmailPageForm = reduxForm({
  form: CONFIG.formNames.recover
})(NewEmailPage);

const NewEmailPageFormConnected = connect(mapStateToProps)(NewEmailPageForm);

export default injectIntl(NewEmailPageFormConnected);
