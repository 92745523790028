import React from 'react';

export const zhCN = (
  <g fill="none" fillRule="evenodd">
    <circle fill="#DE2910" fillRule="nonzero" id="a" cx="21" cy="21" r="21" />
    <g fill="#FFDE02" fillRule="nonzero" mask="url(#b)">
      <path d="M14.875 14.152l1.626 4.583 4.945.1-3.942 2.93 1.433 4.647-4.062-2.772-4.062 2.772 1.434-4.646-3.943-2.93 4.945-.101zM25.375 8.276l.595 1.181 1.328.19-.96.92.225 1.296-1.188-.613-1.188.613.227-1.297-.962-.92 1.33-.189zM28.875 15.142l.595 1.18 1.328.19-.96.92.225 1.297-1.188-.613-1.188.613.227-1.297-.962-.92 1.33-.19zM28.875 22.007l.595 1.18 1.328.19-.96.92.225 1.297-1.188-.613-1.188.613.227-1.297-.962-.92 1.33-.19zM25.375 28.871l.595 1.183 1.328.188-.96.92.225 1.298-1.188-.613-1.188.613.227-1.299-.962-.919 1.33-.188z" />
    </g>
  </g>
);

export const enGB = (
  <g>
    <defs>
      <circle id="path-1" cx="21" cy="21" r="21" />
      <path
        id="path-3"
        d="M28.875 21H57.75v21L28.875 21zm0 0v21H0l28.875-21zm0 0H0V0l28.875 21zm0 0V0H57.75L28.875 21z"
      />
    </defs>
    <g id="Icons/Language/English" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path id="Padding" d="M0 0h42v42H0z" />
      <g id="English">
        <mask id="mask-2" fill="#fff">
          <use href="#path-1" />
        </mask>
        <use id="Mask" fill="#D8D8D8" fillRule="nonzero" href="#path-1" />
        <g id="Flag_of_the_United_Kingdom" mask="url(#mask-2)">
          <g transform="translate(-7.875)">
            <path id="Shape" fill="#00247D" fillRule="nonzero" d="M0 0v42h57.75V0z" />
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#FFF"
              strokeWidth="7"
              d="M0 0l57.75 42m0-42L0 42"
            />
            <g id="Clipped">
              <mask id="mask-4" fill="#fff">
                <use href="#path-3" />
              </mask>
              <path
                id="Shape"
                fill="#000"
                fillRule="nonzero"
                stroke="#CF142B"
                strokeWidth="4.667"
                d="M0 0l57.75 42m0-42L0 42"
                mask="url(#mask-4)"
              />
            </g>
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#FFF"
              strokeWidth="11.667"
              d="M28.875 0v42M0 21h57.75"
            />
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#CF142B"
              strokeWidth="7"
              d="M28.875 0v42M0 21h57.75"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
);

export const enUS = (
  <g fill="none" fillRule="evenodd">
    <path d="M0 0h42v42H0z" />
    <circle cx="21" cy="21" r="21" fill="#F0F0F0" />
    <g fill="#D80027">
      <path d="M20.087 21H42c0-1.895-.253-3.732-.723-5.478h-21.19V21zM20.087 10.043h18.83a21.11 21.11 0 0 0-4.845-5.478H20.087v5.478zM21 42a20.91 20.91 0 0 0 13.072-4.565H7.928A20.91 20.91 0 0 0 21 42zM3.082 31.957h35.836a20.875 20.875 0 0 0 2.359-5.479H.723a20.875 20.875 0 0 0 2.36 5.479z" />
    </g>
    <path
      fill="#0052B4"
      fillRule="nonzero"
      d="M9.728 3.28h1.913l-1.78 1.293.68 2.092-1.78-1.293-1.78 1.293.588-1.808a21.115 21.115 0 0 0-4.073 4.54h.613l-1.133.823c-.177.295-.346.594-.509.897l.542 1.666-1.01-.734a20.8 20.8 0 0 0-.687 1.63l.596 1.835h2.2l-1.78 1.293.68 2.093-1.78-1.293-1.066.774A21.202 21.202 0 0 0 0 21h21V0A20.9 20.9 0 0 0 9.728 3.28zm.813 15.62l-1.78-1.293-1.78 1.293.68-2.093-1.78-1.293h2.2l.68-2.092.68 2.092h2.2l-1.78 1.293.68 2.093zm-.68-8.21l.68 2.093-1.78-1.294-1.78 1.294.68-2.093-1.78-1.293h2.2l.68-2.093.68 2.093h2.2l-1.78 1.293zm8.213 8.21l-1.78-1.293-1.78 1.293.68-2.093-1.78-1.293h2.2l.68-2.092.68 2.092h2.2l-1.78 1.293.68 2.093zm-.68-8.21l.68 2.093-1.78-1.294-1.78 1.294.68-2.093-1.78-1.293h2.2l.68-2.093.68 2.093h2.2l-1.78 1.293zm0-6.117l.68 2.092-1.78-1.293-1.78 1.293.68-2.092-1.78-1.294h2.2l.68-2.092.68 2.092h2.2l-1.78 1.294z"
    />
  </g>
);
