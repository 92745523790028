import { generateActions, generateActionTypes } from '@ace/core-utils';

export const API_CONSUMER_REGISTER = generateActionTypes('API_CONSUMER_REGISTER');
export const API_USER_PROFILE = generateActionTypes('API_USER_PROFILE');
export const API_USER_PROFILE_PATCH = generateActionTypes('API_USER_PROFILE_PATCH');
export const API_USER_AVATAR_PATCH = generateActionTypes('API_USER_AVATAR_PATCH');
export const API_USER_LANG_PATCH = generateActionTypes('API_USER_LANG_PATCH');

export const apiConsumerRegister = generateActions(API_CONSUMER_REGISTER);
export const apiUserProfile = generateActions(API_USER_PROFILE);
export const apiUserProfilePatch = generateActions(API_USER_PROFILE_PATCH);
export const apiUserAvatarPatch = generateActions(API_USER_AVATAR_PATCH);
export const apiUserLangPatch = generateActions(API_USER_LANG_PATCH);
