import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPreventAclRedirects } from '@ace/core-modules/src/auth/reducers';
import { getUserRole } from '@ace/core-modules/src/user/reducers';
import s from './AclRoute.module.scss';

const AclRoute = ({
  component: ChildComponent,
  invalid,
  invalidRedirect,
  preventAclRedirects,
  route,
  userRole,
  ...compProps
}) => {
  const access = route.acl[userRole];
  // Keep for debugging
  // console.log('path: ', route.path);
  // console.log('userRole: ', userRole);
  // console.log('access: ', access);
  // console.log('preventAclRedirects: ', preventAclRedirects);
  const renderRoute = () => {
    if (!ChildComponent) {
      return null;
    }
    return (
      <Route
        path={route.path}
        render={routerProps => <ChildComponent {...compProps} {...routerProps} />}
      />
    );
  };
  if (CONFIG.ACL) {
    return renderRoute();
  }
  if (preventAclRedirects) {
    return <div className={s.disabledRoute}>{renderRoute()}</div>;
  }
  if (!access || typeof access === 'string') {
    // User doesn't have access
    return <Redirect to={access || '/'} />;
  }
  if (invalid) {
    // User is trying to access a middle step of a flow directly
    return <Redirect to={invalidRedirect} />;
  }
  return renderRoute();
};

const mapStateToProps = state => ({
  preventAclRedirects: getPreventAclRedirects(state),
  userRole: getUserRole(state)
});

AclRoute.propTypes = {
  component: PropTypes.func,
  invalid: PropTypes.bool,
  invalidRedirect: PropTypes.string,
  preventAclRedirects: PropTypes.bool,
  route: PropTypes.shape({}).isRequired,
  userRole: PropTypes.string.isRequired
};

AclRoute.defaultProps = {
  component: null,
  invalid: false,
  invalidRedirect: '',
  preventAclRedirects: false
};

export default connect(mapStateToProps)(AclRoute);
