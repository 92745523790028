import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import { iconTypes } from '@ace/core-visuals';
import Modal from '../Modal';
import Button from '../Button';
import s from './Popup.module.scss';

import i18n from './messages';

class Popup extends Component {
  constructor() {
    super();
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleCloseClick() {
    this.props.onClose();
  }

  render() {
    const {
      children,
      theme,
      id,
      intl: { formatMessage },
      intro,
      isActive,
      parentId,
      title
    } = this.props;

    const thisId = components.generateId(id, parentId);
    const thisClass = classNames({
      [s.main]: true,
      [s[`_${theme}`]]: theme
    });

    return (
      <Modal mounted={isActive} titleText={title}>
        <div className={thisClass} id={thisId}>
          <div className={s.intro}>{intro}</div>
          <div className={s.aside}>
            <Button
              icon={iconTypes.navigationClose}
              onClick={this.handleCloseClick}
              parentId={thisId}
              theme="transparent"
              title={formatMessage(i18n.closeButtonTitle)}
              view="icon"
            />
          </div>
        </div>
        <div className={s.content}>{children}</div>
      </Modal>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  intro: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isActive: PropTypes.bool,
  parentId: PropTypes.string,
  theme: PropTypes.oneOf(['black', 'error', 'success']),
  title: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired
};

Popup.defaultProps = {
  id: 'popup',
  intro: null,
  isActive: false,
  parentId: '',
  theme: 'black'
};

export default injectIntl(Popup);
