/*
 **  URL's for match
 **  https://docs.google.com/spreadsheets/d/13nStT8lDdIhFnMdKChc0IcilpIh_OJ1XduGhVGf7aYM/edit#gid=1037021353
 */

export const themeOptions = [
  {
    '--primary-color': '#0984e3',

    '--primary-color-active': '#0767b2',
    '--primary-color-focus': '#0876ca',
    '--primary-color-hover': '#086fbe',

    '--secondary-color': '#71c18a',
    '--error-color': '#d63031',

    '--primary-disabled-color': '#7fb9e6',
    '--secondary-disabled-color': '#71c18a',
    '--error-disabled-color': '#d63132',
    name: 'megopoly',

    urlNames: ['megopoly', 'cloudfront']
  }
];
