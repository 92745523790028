import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { FieldFileAdd, FieldFileDisplay } from '../fields/FieldFile';

import s from './AttachmentList.module.scss';

import i18n from './messages';

class AttachmentList extends PureComponent {
  render() {
    const {
      canEdit,
      canRemove,
      fileList,
      id,
      intl: { formatMessage },
      parentId,
      ...rest
    } = this.props;

    const thisId = components.generateId(id, parentId);
    const showAdd = canEdit && fileList.length < 3;
    return (
      <ul className={s.this} id={thisId}>
        {showAdd && (
          <li className={s.file}>
            <FieldFileAdd
              inputRef={el => {
                this.inputRef = el;
              }}
              parentId={thisId}
              {...rest}
            />
          </li>
        )}
        {fileList &&
          fileList.map((file, index) => {
            const title = file.name || `${formatMessage(i18n.mediaTitle)} ${index + 1}`;
            const key = file.size ? `${title}_${file.size}` : title;
            return (
              <li className={s.file} key={key}>
                <FieldFileDisplay
                  file={file}
                  fileList={fileList}
                  canEdit={canEdit}
                  canRemove={canRemove}
                  id={key}
                  inputRef={this.inputRef}
                  parentId={thisId}
                  title={title}
                  {...rest}
                />
              </li>
            );
          })}
      </ul>
    );
  }
}

AttachmentList.propTypes = {
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
  fileList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string
};

AttachmentList.defaultProps = {
  canEdit: false,
  canRemove: true,
  fileList: [],
  id: 'attachment-list',
  parentId: ''
};

export { AttachmentList as AttachmentListWithoutIntl };

export default injectIntl(AttachmentList);
