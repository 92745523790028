const asyncFormSubmit = (formName, action, params) =>
  new Promise((resolve, reject) => {
    action({
      formName,
      params,
      submitPromise: {
        resolve,
        reject
      }
    });
  });

export default asyncFormSubmit;
