import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import { owaspTest } from '../validators/validatorsFields';

import s from './PasswordIndicator.module.scss';

import i18n from './messages';

const PasswordIndicator = ({
  id,
  intl: { formatMessage },
  optionalTestOnly,
  owaspConfig,
  parentId,
  value
}) => {
  const thisId = components.generateId(id, parentId);

  const owaspResult = owaspTest(value, owaspConfig);
  const { errors, requiredTestErrors } = owaspResult;
  let strength;
  let label;
  const errorsCount = requiredTestErrors.length + (optionalTestOnly ? 0 : errors.length);
  if (errorsCount >= 2) {
    strength = 1;
    label = formatMessage(i18n.weakLabel);
  } else if (errorsCount === 1) {
    strength = 2;
    label = formatMessage(i18n.mediumLabel);
  } else if (errorsCount === 0) {
    strength = 3;
    label = formatMessage(i18n.strongLabel);
  }
  if (!value) {
    strength = 0;
    label = formatMessage(i18n.strengthLabel);
  }
  const thisClass = classNames({
    [s.this]: true,
    [s[`_${strength}`]]: true
  });

  return (
    <div className={thisClass} id={thisId}>
      <div className={s.indicator} aria-hidden />
      <span className={s.label}>{label}</span>
    </div>
  );
};

PasswordIndicator.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  optionalTestOnly: PropTypes.bool,
  owaspConfig: PropTypes.shape({}),
  parentId: PropTypes.string,
  value: PropTypes.string
};

PasswordIndicator.defaultProps = {
  id: 'password-indicator',
  value: '',
  owaspConfig: {},
  optionalTestOnly: false,
  parentId: ''
};

export default injectIntl(PasswordIndicator);
