import React from 'react';
import { confirmation, email, format, length, numericality } from 'redux-form-validators';
import { iconTypes } from '@ace/core-visuals';
import { Field } from 'redux-form';
import { numbers } from '@ace/core-utils';

import { PasswordIndicator } from '..';
import InputField from './InputField';
import InputLink from './InputLink';

import * as validators from '../validators/validatorsFields';
import messages from './messages';

const numericIdPattern = '[0-9]*';

/*
 * Note some browers don't fire change events for 'e' and '.' in number fields and can cause strange behaviour
 */

const renderPasswordField = ({ indicatorId, input, meta, ...rest }) => (
  <>
    <InputField input={input} meta={meta} {...rest} />
    {!meta.submitFailed && <PasswordIndicator id={indicatorId} value={input.value} />}
  </>
);

export default {
  BusinessName: props => (
    <Field
      component={InputField}
      name="name"
      type="text"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  CreatePassword: props => (
    <Field
      component={renderPasswordField}
      name="password"
      type="password"
      validate={validators.finalValidate(props.required, [validators.owaspValidator()])}
      {...props}
    />
  ),
  CreatePasswordConfirm: props => (
    <Field
      component={renderPasswordField}
      name="passwordConfirm"
      type="password"
      validate={validators.finalValidate(props.required, [
        confirmation({ field: 'password', fieldLabel: props.fieldLabel || 'passwords' }),
        validators.owaspValidator(),
      ])}
      {...props}
    />
  ),
  DepositAmount: props => (
    <Field
      component={InputField}
      errorOnBlur
      onBlurFormat={value => numbers.formatFiat(value)}
      name="depositAmount"
      type="number"
      validate={validators.finalValidate(props.required, [
        numericality({
          '<=': 999999.99, // Maximum deposit FIAT (Stripe requirement https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts),
          '>=': 20, // Minimum deposit FIAT,
        }),
      ])}
      {...props}
    />
  ),
  Description: props => (
    <Field
      component={InputField}
      name="description"
      type="textarea"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  Email: props => (
    <Field
      component={InputField}
      name="email"
      type="email"
      validate={validators.finalValidate(props.required, [
        email(),
        format({ with: /[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,22})/i }),
      ])}
      {...props}
    />
  ),
  EmailChangeLink: props => <InputLink name="email" {...props} />,
  EnterPassword: props => (
    <Field
      component={InputField}
      name="password"
      sublabel=""
      type="password"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  FirstName: props => (
    <Field
      component={InputField}
      name="firstName"
      type="text"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  IdOrPassportNumber: props => (
    <Field
      component={InputField}
      name="idOrPassportNumber"
      type="text"
      validate={validators.finalValidate(props.required, [
        format({
          message: { id: messages.invalidValue.id },
          with: /(^[a-z0-9$]{8}$)|(^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$)/i,
        }),
      ])}
      {...props}
    />
  ),
  LastName: props => (
    <Field
      component={InputField}
      name="lastName"
      type="text"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  Login: props => (
    <Field
      component={InputField}
      name="username"
      type="text"
      validate={validators.finalValidate(props.required, [
        format({
          message: { id: messages.invalidUsername.id },
          with: /(^[a-zA-Z0-9_$]{3,20}$)|(^[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,22})$)/i,
        }),
      ])}
      {...props}
    />
  ),
  Message: props => (
    <Field
      component={InputField}
      name="message"
      type="textarea"
      validate={validators.finalValidate(props.required, [length({ max: 500, min: 1 })])}
      {...props}
    />
  ),
  NewEmail: props => (
    <Field
      component={InputField}
      name="newEmail"
      type="email"
      validate={validators.finalValidate(props.required, [
        email(),
        format({ with: /[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,22})/i }),
      ])}
      {...props}
    />
  ),
  NumericId: props => (
    <Field
      component={InputField}
      name="numericId"
      parse={value => numbers.only(value)}
      pattern={numericIdPattern}
      placeholder=" " // Keep with a space so it doesn't show decimal
      type="number"
      validate={validators.finalValidate(props.required, [numericality({ '>': 0, int: true })])}
      {...props}
    />
  ),
  OfflineCode: props => (
    <Field
      component={InputField}
      name="offlineCode"
      type="text"
      validate={validators.finalValidate(props.required, [numericality()])}
      {...props}
    />
  ),
  OldEmail: props => (
    <Field
      component={InputField}
      name="oldEmail"
      type="email"
      validate={validators.finalValidate(props.required, [
        email(),
        format({ with: /[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,22})/i }),
      ])}
      {...props}
    />
  ),
  Phone: props => (
    <Field
      component={InputField}
      name="phone"
      type="text"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  PhoneCountryCode: props => (
    <Field
      component={InputField}
      name="phoneCountryCode"
      type="text"
      validate={validators.finalValidate(props.required, [format({ width: /^\+\d{3}$/ })])}
      {...props}
    />
  ),
  Rating: props => (
    <Field
      component={InputField}
      name="rating"
      parse={value => Number(value)}
      type="number"
      validate={validators.finalValidate(props.required, [
        numericality({ '<=': 2, '>=': 0, int: true }),
      ])}
      {...props}
    />
  ),
  RecoveredUsername: props => (
    <Field component={InputField} name="recoveredUsername" type="text" {...props} />
  ),
  ReferrerId: props => (
    <Field
      component={InputField}
      name="referrer"
      type="text"
      validate={validators.finalValidate(props.required)}
      {...props}
    />
  ),
  RewardPct: props => (
    <Field
      component={InputField}
      icon={iconTypes.BTC}
      name="rewardPct"
      normalize={value => numbers.normalizeNumber(value, 100)}
      onBlurFormat={value => numbers.formatPct(value, false)}
      placeholder="0" // "0" because we don't want the decimal
      type="number"
      validate={validators.finalValidate(props.required, [
        numericality({
          '<=': 35, // Maximum reward %,
          '>=': 2, // Minimum reward %,
        }),
      ])}
      unit="%"
      {...props}
    />
  ),
  SmsCode: props => (
    <Field
      component={InputField}
      name="smsCode"
      type="text"
      validate={validators.finalValidate(props.required, [numericality()])}
      {...props}
    />
  ),
  TotalAmount: ({ balance, factor, ...props }) => {
    const maximumDistribution = 100000; // Maximum distribution Fiat

    return (
      <Field
        component={InputField}
        errorOnBlur
        name="amount"
        normalize={value => numbers.normalizeNumber(value, maximumDistribution)}
        onBlurFormat={value => numbers.formatFiat(value, false)}
        type="number"
        validate={validators.finalValidate(props.required, [
          numericality(),
          validators.rewardAmount({ balance, factor, maximumDistribution }),
        ])}
        {...props}
      />
    );
  },
  TotpCode: props => (
    <Field
      component={InputField}
      name="totpCode"
      type="text"
      validate={validators.finalValidate(props.required, [numericality()])}
      {...props}
    />
  ),
  Username: props => (
    <Field
      component={InputField}
      name="username"
      type="text"
      validate={validators.finalValidate(props.required, [
        format({
          message: { id: messages.invalidUsername.id },
          with: /(^[a-zA-Z0-9_$]{4,20}$)/i,
        }),
        ...(props.extraValidate
          ? props.extraValidate.map(validator => format({ ...validator }))
          : []),
      ])}
      {...props}
    />
  ),
  UsernameOrEmail: props => (
    <Field
      component={InputField}
      name="username"
      type="text"
      validate={validators.finalValidate(props.required, [
        format({
          message: { id: messages.invalidUsername.id },
          with: /(^[a-zA-Z0-9_$]{4,20}$)|(^[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,22})$)/i,
        }),
        ...(props.extraValidate
          ? props.extraValidate.map(validator => format({ ...validator }))
          : []),
      ])}
      {...props}
    />
  ),
};
