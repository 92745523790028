import React from 'react';
import { Icon, iconTypes } from '@ace/core-visuals';
import PropTypes from 'prop-types';
import Styles from './Timer.module.scss';

const Timer = ({ timer }) => (
  <div className={Styles.timer}>
    <Icon id="timer" type={iconTypes.timer} width={15} height={15} viewBox="0 0 15 13" />
    <span className={Styles.time}>{timer}</span>
  </div>
);

Timer.propTypes = {
  timer: PropTypes.string.isRequired
};

export default Timer;
