// copied from @ace/core-components/src/SelectList as we don't want a field, but a menu (but in the same style)
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, iconTypes } from '@ace/core-visuals';
import s from '@ace/core-components/src/SelectList/SelectList.module.scss';

const SelectList = ({ items }) => (
  <ul className={s.this}>
    {items &&
      items.map(({ value, label, icon, onClick, active }) => (
        <li className={`${s.thisItem} ${active && s.active}`} key={value}>
          <button type="button" className={s.item} onClick={onClick}>
            <span className={s.icon}>
              <Icon type={icon} />
            </span>
            <span className={s.label}>{label}</span>
            <span className={s.tick}>
              <Icon type={iconTypes.tick} />
            </span>
          </button>
        </li>
      ))}
  </ul>
);

SelectList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired
};

export default SelectList;
