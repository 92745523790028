import { defineMessages } from 'react-intl';

export default defineMessages({
  closeButton: 'Close',
  copied: 'Code copied to clipboard',
  getNewCodes: 'Get New Codes',
  intro: 'Use these codes when you don’t have your phone with you',
  introNew: 'You have just used your last offline code. Here is the new set',
  okayButton: 'Okay',
  printCodes: 'Print Codes',
  remaining: '{notUsedCount} of {usedCount} remaining',
  successStatus: 'Success!',
  title: 'Offline codes.',
});
