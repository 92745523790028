import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Please Verify.',
  signup: 'We’ve just sent you an email to confirm it’s you. Please verify to continue.',
  intro:
    'We’ve just sent you an email to confirm your new email address. Please click the link and verify to continue.',
  changePassword:
    'If your account exists, you’ll receive an email with a link to reset your password.',
  changePasswordTitle: 'Email Sent.',
  changeEmail:
    'We’ve just sent you a link to confirm your new email address. Please click the link and verify to continue.',
  titleExpired: 'Link Expired.',
  expired:
    "Your verification link has expired, but don't worry - tap request and we'll send a new email to verify.",
  emailExpired:
    "Your verification link has expired. You'll have to re-start the Reset Email process. Please tap Reset Email to continue.",
  passphraseExpired: 'Your verification link has expired.',
  passwordResetLinkExpired:
    "The link to reset your password has expired. But don't worry, tap request and we'll send a new email to reset it",
  request: 'Request'
});
