import { AUTH_CHALLENGE, SIGNIN, FORGOT_PASSWORD, CHANGE_USERNAME } from '../actions/user';

export const getAuthChallenge = state => state.authChallenge.challenge;
export const getAuthChallengeResponse = state => state.authChallenge.challengeResponse;

const initialState = {
  challenge: null,
  challengeResponse: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USERNAME.PAUSE:
    case FORGOT_PASSWORD.PAUSE:
    case SIGNIN.PAUSE: {
      if (!action.payload || !action.payload.challenge) {
        return state;
      }
      return {
        ...state,
        challenge: action.payload.challenge
      };
    }

    case AUTH_CHALLENGE.SUCCESS: {
      return {
        ...state,
        challengeResponse: action.payload
      };
    }

    case CHANGE_USERNAME.SUCCESS:
    case FORGOT_PASSWORD.SUCCESS:
    case SIGNIN.SUCCESS: {
      return { ...state, challenge: null, challengeResponse: null };
    }

    default:
      return state;
  }
};

export default reducer;
