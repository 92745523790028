import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import BusinessRoutes from '@ace/core-modules/src/business/routes';
import ChatRoutes from '@ace/core-modules/src/chat/routes';
import WalletRoutes from '@ace/core-modules/src/wallet/routes';
import { iconTypes } from '@ace/core-visuals';
import ToolbarRow from './atoms/ToolbarRow';
import ToolbarItemAvatar from './atoms/ToolbarItemAvatar';
import ToolbarItemIcon from './atoms/ToolbarItemIcon';
import ToolbarItemInboxIcon from './atoms/ToolbarItemInboxIcon';
import i18n from './messages';

const MerchantToolbar = ({ intl: { formatMessage }, isChatPossible, logo }) => {
  const reward = {
    component: ToolbarItemIcon,
    icon: iconTypes.toolbarReward,
    linkTo: WalletRoutes.business.distribute.index.path,
    label: formatMessage(i18n.rewardLabel)
  };
  const wallet = {
    component: ToolbarItemIcon,
    icon: iconTypes.toolbarWallet,
    linkTo: WalletRoutes.business.index.path,
    label: formatMessage(i18n.walletLabel)
  };
  const inbox = {
    component: ToolbarItemInboxIcon,
    linkTo: ChatRoutes.index.path,
    label: formatMessage(i18n.inboxLabel)
  };
  const account = {
    component: ToolbarItemAvatar,
    avatar: logo,
    icon: iconTypes.menuMerchant,
    linkTo: BusinessRoutes.index.path,
    label: formatMessage(i18n.accountLabel)
  };
  const toolbarArray = isChatPossible
    ? [reward, wallet, inbox, account]
    : [reward, wallet, account];

  return <ToolbarRow id="merchant-toolbar" items={toolbarArray} />;
};

MerchantToolbar.propTypes = {
  intl: intlShape.isRequired,
  isChatPossible: PropTypes.bool.isRequired,
  logo: PropTypes.string
};

MerchantToolbar.defaultProps = {
  logo: ''
};

export { MerchantToolbar as MerchantToolbarWithoutIntl };

export default injectIntl(MerchantToolbar);
