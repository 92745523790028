import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Button, Page, PageFooter, PageMain } from '@ace/core-components';
import { getLoading } from '@ace/core-components/src/ui/reducers';

import { signUpConfirm } from '../../store/actions/user';
import { getUserProfile } from '../../store/reducers/profile';
import Text from '../../components/Text';
import PageHeader from '../../components/PageHeader';
import messages from './messages';
import AppRoutes from '../../routes';

class ConfirmSignUp extends React.Component {
  constructor(props) {
    super(props);

    if (!props.confirmed) {
      props.requestSignUpConfirm();
    }
  }

  render() {
    const {
      history,
      intl: { formatMessage },
      profile,
      loading
    } = this.props;

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader back={false} title={formatMessage(messages.title)} />
        {profile.confirmed ? (
          <PageMain>
            <Text>
              <FormattedMessage {...messages.para1} />
            </Text>
          </PageMain>
        ) : null}
        <PageFooter>
          <Button
            disabled={!profile.confirmed || loading}
            theme="primary"
            title={formatMessage(messages.signInButton)}
            type="button"
            value={formatMessage(messages.signInButton)}
            view="cta"
            linkTo={{ pathname: AppRoutes.signin.index, search: history.location.search }}
          />
        </PageFooter>
      </Page>
    );
  }
}

ConfirmSignUp.propTypes = {
  intl: intlShape.isRequired,
  requestSignUpConfirm: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: getUserProfile(state),
  loading: getLoading(state)
});

const mapDispatchToProps = {
  requestSignUpConfirm: signUpConfirm.request
};

const ConfirmSignUpConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmSignUp);

export default injectIntl(ConfirmSignUpConnected);
