import { defineMessages } from 'react-intl';

export default defineMessages({
  IdNumberMessage_cashInLink: 'Cash In',
  IdNumberMessage_legacyModeContent:
    'If you don’t see an image, just tap the screen to open your camera.',
  IdNumberMessage_legacyModeTitle: 'Scan the customer’s ID',
  IdNumberMessage_imageDialogButtonValue: 'Open Camera',
  IdNumberMessage_insufficientBalanceContent:
    'You have insufficient Bitcoin to make a reward. {cashInLink}?',
  IdNumberMessage_insufficientBalanceTitle: 'Need Bitcoin :(',
  IdNumberManualEntry_manualEntryLink: 'Enter manually?',
  IdNumberManualEntry_popupIntro:
    'If their ID can’t be scanned, manually enter the customer’s ID Number (you’ll find it under their QR code).',
  IdNumberManualEntry_popupNextButtonValue: 'Next',
  IdNumberManualEntry_popupTitle: 'Enter Manually',
  IdNumberMessage_title: 'Scan the customer’s ID'
});
