import { PureComponent } from 'react';
import observeFonts from './observeFonts';

class Scripts extends PureComponent {
  /** Main purpose of Page component is to load
   * our observeFonts script and any other external scripts
   */

  componentDidMount() {
    observeFonts();
  }

  render() {
    return null;
  }
}

export default Scripts;
