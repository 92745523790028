import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { components } from '@ace/core-utils';

import { getLoading } from '../ui/reducers';

import s from './ProgressBar.module.scss';

const ProgressBar = ({ id, lengthComputable, loading, loaded, parentId, total }) => {
  if (!loading) {
    return null;
  }

  const thisId = components.generateId(id, parentId);

  const view = lengthComputable ? 'computable' : 'infinite';
  let progressStyle = {};
  if (loaded <= total) {
    const width = (100 * loaded) / total;
    progressStyle = {
      width: `${width}%`
    };
  }

  return (
    <div className={s[view]} id={thisId}>
      <div className={s.progress} style={progressStyle} />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: getLoading(state)
});

ProgressBar.propTypes = {
  id: PropTypes.string,
  lengthComputable: PropTypes.bool,
  loaded: PropTypes.number,
  loading: PropTypes.bool,
  parentId: PropTypes.string,
  total: PropTypes.number
};

ProgressBar.defaultProps = {
  id: 'progress-bar',
  lengthComputable: false,
  loading: false,
  loaded: 0,
  parentId: '',
  total: 0
};

export default connect(mapStateToProps)(ProgressBar);
