import queryString from 'query-string';
import history from './history';

/**
 * Returns GET parameters from current URL
 */
export default (removeDuplicated = true) =>
  history.location.search
    ? queryString.parse(
        removeDuplicated
          ? Array.from(new Set(history.location.search.substring(1).split('&'))).join('&')
          : history.location.search.substring(1)
      )
    : {};
export function hasExpired() {
  if (!history.location.search || !queryString.parse(history.location.search).expires) {
    return false;
  }
  return Date.now() / 1000 > queryString.parse(history.location.search).expires;
}
