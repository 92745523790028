import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import ValidateMfa from '../../components/ValidateMfa';

import { apiAuthChallenge, apiResendSmsCode } from '../../store/actions/user';
import { getAuthChallenge } from '../../store/reducers/authChallenge';
import { getSmsTransaction } from '../../store/reducers/transactions';
import { log } from '../../utils/helpers';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

const BACKUP_AUTH_METHODS_TYPE = 'backup';
const PRIMARY_AUTH_METHODS_TYPE = 'primary';

class MfaAuthChallenge extends PureComponent {
  componentDidMount() {
    const { history, challenge } = this.props;

    if (!challenge) {
      history.goBack();
    }
  }

  onResendCode = async formData => {
    asyncFormSubmit(formData.formName, this.props.requestResendSmsCode, {
      ...formData.params,
      authChallenge: true,
    }).catch(error => {
      log('Resend code failed', error);
    });
  };

  onSubmit = formData => {
    const { requestMfaChallenge } = this.props;

    return asyncFormSubmit(formData.formName, requestMfaChallenge, formData.params)
      .then(data => {
        formData.submitPromise.resolve();

        return data;
      })
      .catch(e => {
        formData.submitPromise.reject(e);
      });
  };

  render() {
    const {
      match: { path, url },
      location,
      history,
      primaryAuthMethod,
      backupAuthMethod,
      smsTransaction,
      challenge,
      mode,
    } = this.props;

    const renderValidateMfa = ({ alternative, method, type }) => (
      <Route
        path={`${path}/${type}`}
        render={() => (
          <ValidateMfa
            authChallenge
            method={method}
            mode={mode}
            {...(backupAuthMethod && { alternative })}
            onAlternative={() => {
              history.replace({ ...location, pathname: `${url}/${alternative}` });
            }}
            onSubmit={this.onSubmit}
            onResendCode={this.onResendCode}
            transaction={smsTransaction}
            phone={
              (challenge &&
                challenge.challengeParameters &&
                challenge.challengeParameters.phoneNumber) ||
              ''
            }
          />
        )}
      />
    );

    return (
      <Switch>
        {primaryAuthMethod &&
          renderValidateMfa({
            alternative: BACKUP_AUTH_METHODS_TYPE,
            method: primaryAuthMethod,
            type: PRIMARY_AUTH_METHODS_TYPE,
          })}
        {backupAuthMethod &&
          renderValidateMfa({
            alternative: PRIMARY_AUTH_METHODS_TYPE,
            method: backupAuthMethod,
            type: BACKUP_AUTH_METHODS_TYPE,
          })}
        {primaryAuthMethod && (
          <Redirect to={{ ...location, pathname: `${url}/${PRIMARY_AUTH_METHODS_TYPE}` }} />
        )}
        {backupAuthMethod && (
          <Redirect to={{ ...location, pathname: `${url}/${BACKUP_AUTH_METHODS_TYPE}` }} />
        )}
      </Switch>
    );
  }
}

MfaAuthChallenge.propTypes = {
  backupAuthMethod: PropTypes.string,
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  primaryAuthMethod: PropTypes.string,
  requestMfaChallenge: PropTypes.func.isRequired,
  smsTransaction: PropTypes.shape({
    expires: PropTypes.number.isRequired,
    request_id: PropTypes.string.isRequired,
  }),
};

MfaAuthChallenge.defaultProps = {
  backupAuthMethod: '',
  primaryAuthMethod: '',
  smsTransaction: null,
};

const mapStateToProps = (state, props) => {
  const params = parse(props.location.search);

  return {
    backupAuthMethod: params.backup_auth_method,
    challenge: getAuthChallenge(state),
    mode: params.mode || 'confirm-signin-mfa',
    primaryAuthMethod: params.primary_auth_method,
    smsTransaction: getSmsTransaction(state),
  };
};

const mapDispatchToProps = {
  requestMfaChallenge: apiAuthChallenge.request,
  requestResendSmsCode: apiResendSmsCode.request,
};

const MfaConnected = connect(mapStateToProps, mapDispatchToProps)(MfaAuthChallenge);

export default MfaConnected;
