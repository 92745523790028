import React from 'react';
import PropTypes from 'prop-types';

import styles from './Icon.module.scss';

import email from './images/email.svg';
import error from './images/error.svg';
import error404 from './images/404.svg';
import fail from './images/fail.svg';
import gaClock from './images/ga-clock.svg';
import gaPhone from './images/ga-phone.svg';
import gaSuccess from './images/ga-success.svg';
import logout from './images/logout.svg';
import success from './images/success.svg';

export const icons = {
  email,
  error,
  error404,
  fail,
  logout,
  success,
  'ga-clock': gaClock,
  'ga-phone': gaPhone,
  'ga-success': gaSuccess,
};

const Icon = ({ icon }) => <img className={styles.icon} src={icons[icon]} alt="GRC" />;

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

export default Icon;
