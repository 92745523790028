import FontFaceObserver from 'fontfaceobserver';

const observeFonts = () => {
  const observableFonts = [
    {
      id: 'ff--base',
      family: 'Montserrat',
      weights: [300, 400, 700]
    }
  ];

  observableFonts.map(font => {
    const weights = font.weights.map(weight => new FontFaceObserver(font.family, { weight }));

    const observedWeights = weights.map(weight => weight.load(null, 15000));

    Promise.all(observedWeights).then(
      () => {
        document.body.classList.add(font.id);
      },
      () => {
        // Font family is not available after waiting 15 seconds
      }
    );

    return null;
  });
};

export default observeFonts;
