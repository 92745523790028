export const loadObject = name => {
  try {
    const serializedObject = localStorage.getItem(name);
    if (serializedObject === null) {
      return undefined;
    }
    return JSON.parse(serializedObject);
  } catch (err) {
    return undefined;
  }
};

export const saveObject = (name, object) => {
  try {
    const serializedObject = JSON.stringify(object);
    localStorage.setItem(name, serializedObject);
  } catch (err) {
    // Ignore write errors
  }
};
