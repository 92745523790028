import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { components, imgPreview } from '@ace/core-utils';
import { Icon, iconTypeArray, iconTypes } from '@ace/core-visuals';
import FieldFileAtom from '../FieldFileAtom';
import validatorMedia from '../validators/validatorMedia';
import { fileSelect } from '../FieldFile/actions';
import s from './FieldAvatar.module.scss';

import i18n from './messages';

class FieldAvatar extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.objectUrl = null;
    this.state = {
      imagePreviewUrl: imgPreview(props.avatar).src
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.avatar !== this.props.avatar) {
      if (this.objectUrl) {
        URL.revokeObjectURL(this.objectUrl);
      }
      this.setState({
        imagePreviewUrl: imgPreview(newProps.avatar).src
      });
    }
  }

  componentWillUnmount() {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
  }

  async handleChange(ev, filelist) {
    ev.preventDefault();
    const { failureFileSelect, form, fieldName } = this.props;
    try {
      // Check file type & size
      const blob = await validatorMedia(filelist);
      if (!blob) {
        return;
      }
      URL.revokeObjectURL(this.objectUrl);
      // Set form value
      form.change(fieldName, blob);
      // Setup preview
      this.objectUrl = URL.createObjectURL(blob);
      this.setState({
        imagePreviewUrl: this.objectUrl
      });
    } catch (error) {
      failureFileSelect(error, { notification: error });
    }
  }

  handleUnwantedEvents(ev) {
    // Need to block all normal redux-form & html events
    ev.preventDefault();
  }

  render() {
    const {
      canEdit,
      emptyIcon,
      fieldName,
      hasBorder,
      id,
      intl: { formatMessage },
      label,
      parentId
    } = this.props;
    const { imagePreviewUrl } = this.state;

    const thisId = components.generateId(id, parentId);

    let avatarStyle = {};
    if (imagePreviewUrl) {
      avatarStyle = {
        backgroundImage: `url(${imagePreviewUrl})`
      };
    }
    const thisClass = classNames({
      [s.this]: true,
      [s._hasBorder]: hasBorder,
      [s._isEmpty]: !imagePreviewUrl
    });

    return (
      <div className={thisClass} id={thisId} style={avatarStyle}>
        {canEdit && (
          // eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
          <label className={s.upload}>
            <Field
              accept="image/x-png, image/jpeg"
              className={s.input}
              component={FieldFileAtom}
              id={fieldName}
              name={fieldName}
              parentId={thisId}
              onBlur={this.handleUnwantedEvents}
              onChange={this.handleChange}
              onFocus={this.handleUnwantedEvents}
            />
            <span className={s.icon}>
              <Icon id="edit-icon" parentId={thisId} type={iconTypes.camera} />
            </span>
            {label && !imagePreviewUrl ? (
              <span className={s.label}>{label}</span>
            ) : (
              <span className={s.labelSr}>{formatMessage(i18n.label)}</span>
            )}
          </label>
        )}
        {!canEdit && !imagePreviewUrl && (
          <span className={s.emptyIcon}>
            <Icon id="empty-icon" parentId={thisId} type={emptyIcon || iconTypes.menuConsumer} />
          </span>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  failureFileSelect: fileSelect.failure
};

FieldAvatar.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  canEdit: PropTypes.bool,
  emptyIcon: PropTypes.oneOf(iconTypeArray),
  failureFileSelect: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  form: PropTypes.shape({
    change: PropTypes.func
  }),
  hasBorder: PropTypes.bool,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  parentId: PropTypes.string
};

FieldAvatar.defaultProps = {
  avatar: null,
  canEdit: false,
  emptyIcon: null,
  fieldName: null,
  form: {},
  hasBorder: false,
  id: 'field-avatar',
  label: null,
  parentId: ''
};

const FieldAvatarConnected = connect(
  null,
  mapDispatchToProps
)(FieldAvatar);

export { FieldAvatar as FieldAvatarWithoutConnectedIntl };

export default injectIntl(FieldAvatarConnected);
