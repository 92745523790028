import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { components } from '@ace/core-utils';
import { Icon, iconTypes, iconTypeArray } from '@ace/core-visuals';
import s from '../SelectList.module.scss';

class FieldRadio extends PureComponent {
  render() {
    const { icon, label, name, onChange, parentId, value } = this.props;
    const inputId = components.generateId(value, parentId);
    const labelId = components.generateId(inputId, 'label');

    return (
      <React.Fragment>
        <Field
          className={s.input}
          component="input"
          id={inputId}
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <label className={s.item} htmlFor={inputId} id={labelId}>
          {icon && (
            <span className={s.icon}>
              <Icon id="item-icon" parentId={labelId} type={icon} />
            </span>
          )}
          <span className={s.label}>{label}</span>
          <span className={s.tick}>
            <Icon parentId={labelId} type={iconTypes.tick} />
          </span>
        </label>
      </React.Fragment>
    );
  }
}

FieldRadio.propTypes = {
  icon: PropTypes.oneOf(iconTypeArray),
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

FieldRadio.defaultProps = {
  icon: undefined,
  parentId: '',
  onChange: null
};

export default FieldRadio;
