import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { CustomLink } from '@ace/core-components';
import PropTypes from 'prop-types';

import { Icon, iconTypes } from '@ace/core-visuals';
import messages from './messages';
import styles from './SecurityMethodsInfoLink.module.scss';
import AppRoutes from '../../routes';

function SecurityMethodsInfoLink(props) {
  const {
    intl: { formatMessage }
  } = props;

  return (
    <CustomLink
      theme="primary"
      to={props.to || AppRoutes.signup.extraSecurity.info}
      className={styles.this}
    >
      <Icon type={iconTypes.info} />
      {formatMessage(messages.text)}
    </CustomLink>
  );
}

SecurityMethodsInfoLink.propTypes = {
  intl: intlShape.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired
    }),
    PropTypes.string.isRequired
  ])
};

SecurityMethodsInfoLink.defaultProps = {
  to: AppRoutes.signup.extraSecurity.info
};

const SecurityMethodsInfoLinkTranslated = injectIntl(SecurityMethodsInfoLink);

export default SecurityMethodsInfoLinkTranslated;
