import React from 'react';

export const findCenterLocation = (
  <g fill="none" fillRule="evenodd">
    <circle fill="currentColor" cx="21" cy="21" r="21" />
    <circle cx="21" cy="21" r="9" stroke="#FFF" />
    <path stroke="#FFF" strokeLinecap="square" d="M10 21h4" />
    <path stroke="#FFF" strokeLinecap="square" d="M19 12h4" transform="rotate(90 21 12)" />
    <path stroke="#FFF" strokeLinecap="square" d="M19 30h4" transform="rotate(90 21 30)" />
    <path stroke="#FFF" strokeLinecap="square" d="M28 21h4" />
    <circle cx="21" cy="21" r="3" stroke="#FFF" />
  </g>
);

export const findInbox = (
  <g id="Icons/Find/Inbox/Empty" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Combined-Shape"
      stroke="currentColor"
      strokeWidth="2.67"
      d="M36.475 39.536l-2.592-7.486.696-.592c3.868-3.284 6.086-7.809 6.086-12.641 0-9.62-8.774-17.482-19.665-17.482S1.335 9.196 1.335 18.817c0 9.62 8.774 17.481 19.665 17.481 1.688 0 3.347-.189 4.95-.558l.397-.092 10.128 3.888z"
    />
  </g>
);

export const findList = (
  <g id="Icons/List" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <g id="Group-3" fill="currentColor" transform="translate(0 6)">
      <circle id="Oval-5" cx="2.5" cy="2.5" r="2.5" />
      <rect id="Rectangle" width="33" height="3" x="9" y="1" fillRule="nonzero" rx="1.5" />
    </g>
    <g id="Group-2" fill="currentColor" transform="translate(0 18)">
      <circle id="Oval-5" cx="2.5" cy="2.5" r="2.5" />
      <rect id="Rectangle" width="33" height="3" x="9" y="1" fillRule="nonzero" rx="1.5" />
    </g>
    <g id="Group" fill="currentColor" transform="translate(0 30)">
      <circle id="Oval-5" cx="2.5" cy="2.5" r="2.5" />
      <rect id="Rectangle" width="33" height="3" x="9" y="1" fillRule="nonzero" rx="1.5" />
    </g>
  </g>
);

export const findMap = (
  <g id="Icons/Map" fill="none" fillRule="evenodd">
    <g id="Group" stroke="currentColor" strokeWidth="2.367" transform="translate(5)">
      <ellipse id="Oval" cx="16.5" cy="16.333" rx="5.5" ry="5.444" />
      <path
        id="Oval-3"
        d="M16.5 40.272c7.532-8.043 12.14-13.81 13.784-17.212a15.24 15.24 0 0 0 1.532-6.625c0-8.422-6.856-15.251-15.316-15.251S1.184 8.013 1.184 16.434c0 2.325.521 4.573 1.512 6.618C4.342 26.45 8.957 32.22 16.5 40.272z"
      />
    </g>
  </g>
);
