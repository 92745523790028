import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import { detect } from 'detect-browser';
import classNames from 'classnames';
import { components } from '@ace/core-utils';
import s from './Modal.module.scss';
import { getIsAppStandalone } from '../AddToHomescreen/reducers';

const Modal = ({
  alert,
  applicationNodeName,
  children,
  fullscreen,
  id,
  isAppStandalone,
  parentId,
  ...rest
}) => {
  const thisId = components.generateId(id, parentId);
  const applicationNode = () => document.getElementById(applicationNodeName);
  const browser = detect();
  let forceFullscreen = false;
  // const modalUnderlayClass = fullscreen ? s.underlayFullscreen : s.underlay;
  switch (browser && browser.os) {
    case 'Android OS':
      // Android Standalone has an issue with the popups where the keyboard was hiding inputs in popups
      if (!alert && isAppStandalone) {
        // Don't need to modify alerts / notifications
        // If we're in fullscreen mode then change class of dialog so fields are at top of screen
        forceFullscreen = true;
      }
      break;

    default:
      break;
  }

  const dialogClass = classNames({
    [s.dialog]: true,
    [s._fullscreen]: fullscreen || forceFullscreen
  });

  return (
    <AriaModal
      alert={alert}
      dialogClass={dialogClass}
      dialogId={thisId}
      getApplicationNode={applicationNode}
      includeDefaultStyles={false}
      underlayClass={s.underlay}
      underlayClickExits={false}
      {...rest}
    >
      {children}
    </AriaModal>
  );
};

const mapStateToProps = state => ({
  isAppStandalone: getIsAppStandalone(state)
});

Modal.propTypes = {
  alert: PropTypes.bool,
  applicationNodeName: PropTypes.string,
  children: PropTypes.node,
  fullscreen: PropTypes.bool,
  id: PropTypes.string,
  parentId: PropTypes.string
};

Modal.defaultProps = {
  applicationNodeName: 'root',
  alert: false,
  children: null,
  fullscreen: false,
  id: 'modal',
  parentId: ''
};

export default connect(mapStateToProps)(Modal);
