import _ from 'lodash';
import { detect } from 'detect-browser';
import { API_INIT } from '@ace/core-modules/src/initialise/actions';
import {
  API_CHAT_SESSION_AUTH,
  API_CHAT_SESSION_DEAUTH,
  CHAT_ACTIVE_ID,
  CHAT_MESSAGE_UNREADS
} from '../actions';

const isChatPossible = () => {
  const browser = detect();
  if (!browser || (browser && browser.name === 'ie' && parseFloat(browser.version) < 11)) {
    return false;
  }
  return true;
};

const initialState = {
  activeChatId: '', // Used only in reducer, not by components
  chatAdminId: '',
  chatPossible: isChatPossible(),
  chatSessionToken: '',
  unreadIds: []
};

export const getChatAdminId = state => state.chat.chatAdminId;
export const getNewMessage = state => !_.isEmpty(state.chat.unreadIds);
export const getChatSessionToken = state => state.chat.chatSessionToken;
export const getIsChatAvailable = state => !!state.chat.chatSessionToken;
export const getIsChatPossible = state => state.chat.chatPossible;

const chat = (state = initialState, action) => {
  switch (action.type) {
    case API_INIT.SUCCESS:
      return {
        ...state,
        chatAdminId: action.payload.adminId
      };

    case API_CHAT_SESSION_AUTH.SUCCESS:
      return {
        ...state,
        chatSessionToken: action.payload
      };

    case API_CHAT_SESSION_DEAUTH.SUCCESS:
      return {
        ...state,
        chatSessionToken: ''
      };

    case CHAT_ACTIVE_ID.SAVE:
    case CHAT_ACTIVE_ID.REMOVE:
      return {
        ...state,
        activeChatId: action.payload
      };

    case CHAT_MESSAGE_UNREADS.SAVE: {
      const unreadsWithoutActive = _.without(action.payload, state.activeChatId);
      return {
        ...state,
        unreadIds: _.union(state.unreadIds, unreadsWithoutActive)
      };
    }

    case CHAT_MESSAGE_UNREADS.REMOVE:
      return {
        ...state,
        unreadIds: _.without(state.unreadIds, action.payload)
      };

    default:
      return state;
  }
};

export default chat;
