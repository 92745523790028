import _ from 'lodash';
import { removeItem } from './stateOps';

export const getLoading = state => state.ui.loading.length > 0;

const loading = (state = [], action) => {
  switch (action.meta && action.meta.loader) {
    case 'add': {
      if (_.includes(state, action.meta.id)) {
        return state;
      }
      return state.concat(action.meta.id);
    }
    case 'remove': {
      return removeItem(state, state.indexOf(action.meta.id));
    }
    default:
  }
  return state;
};

export default loading;
