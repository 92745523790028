import React from 'react';
import propTypes from 'prop-types';
import IconPage from '../../components/IconPage';
import { icons } from '../../components/Icon';
import messages from './messages';

const ErrorPage = ({ error, icon }) => (
  <IconPage
    title={messages[`${error}Title`]}
    intro={messages[`${error}Intro`]}
    icon={icon || (icons[error] && error) || 'error'}
  />
);

ErrorPage.propTypes = {
  error: propTypes.string.isRequired,
  icon: propTypes.string.isRequired
};

export default ErrorPage;
