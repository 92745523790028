import React, { memo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ScreenLoading } from '@ace/core-components';
import ValidateMfa from '../../components/ValidateMfa';

import { apiResendSmsCode, apiUserData, elevate } from '../../store/actions/user';
import { getUserData } from '../../store/reducers/mfa';
import { getSmsTransaction } from '../../store/reducers/transactions';
import { log } from '../../utils/helpers';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

const BACKUP_AUTH_METHODS_TYPE = 'backup';
const PRIMARY_AUTH_METHODS_TYPE = 'primary';

const Elevate = memo(
  ({
    history,
    location,
    match: { path, url },
    requestElevate,
    requestResendSmsCode,
    requestUserData,
    smsTransaction,
    user,
  }) => {
    useEffect(() => {
      if (user) {
        return;
      }

      requestUserData();
    }, []);

    const onResendCode = useCallback(async formData => {
      try {
        return await asyncFormSubmit(formData.formName, requestResendSmsCode, formData.params);
      } catch (e) {
        log('Resend code failed: ', e);
      }
    }, []);

    const onSubmit = useCallback(async formData => {
      try {
        const data = await asyncFormSubmit(formData.formName, requestElevate, formData.params);

        formData.submitPromise.resolve();

        return data;
      } catch (e) {
        formData.submitPromise.reject(e);
      }
    }, []);

    const redirectToAuthMethods = useCallback(
      type => history.replace({ ...location, pathname: `${url}/${type}` }),
      [],
    );

    return !user ? (
      <ScreenLoading />
    ) : (
      <Switch>
        {user.primary_auth_method && (
          <Route
            path={`${path}/${PRIMARY_AUTH_METHODS_TYPE}`}
            render={() => (
              <ValidateMfa
                alternative={user.backup_auth_method ? BACKUP_AUTH_METHODS_TYPE : null}
                method={user.primary_auth_method}
                onAlternative={() => redirectToAuthMethods(BACKUP_AUTH_METHODS_TYPE)}
                onResendCode={onResendCode}
                onSubmit={onSubmit}
                phone={user.phone}
                transaction={smsTransaction}
              />
            )}
          />
        )}
        {user.backup_auth_method && (
          <Route
            path={`${path}/${BACKUP_AUTH_METHODS_TYPE}`}
            render={() => (
              <ValidateMfa
                alternative={user.primary_auth_method ? PRIMARY_AUTH_METHODS_TYPE : null}
                method={user.backup_auth_method}
                onAlternative={() => redirectToAuthMethods(PRIMARY_AUTH_METHODS_TYPE)}
                onResendCode={onResendCode}
                onSubmit={onSubmit}
                phone={user.phone}
                transaction={smsTransaction}
              />
            )}
          />
        )}
        {user.primary_auth_method && (
          <Redirect
            to={{
              ...location,
              pathname: `${url}/${PRIMARY_AUTH_METHODS_TYPE}`,
            }}
          />
        )}
        {user.backup_auth_method && (
          <Redirect
            to={{
              ...location,
              pathname: `${url}/${BACKUP_AUTH_METHODS_TYPE}`,
            }}
          />
        )}
      </Switch>
    );
  },
);

Elevate.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  requestElevate: PropTypes.func.isRequired,
  requestUserData: PropTypes.func.isRequired,
  smsTransaction: PropTypes.shape({
    expires: PropTypes.number.isRequired,
    request_id: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({}),
};

Elevate.defaultProps = {
  smsTransaction: null,
  user: null,
};

const mapStateToProps = state => {
  const userData = getUserData(state);

  return {
    smsTransaction: getSmsTransaction(state),
    user: userData,
  };
};

const mapDispatchToProps = {
  requestElevate: elevate.request,
  requestResendSmsCode: apiResendSmsCode.request,
  requestUserData: apiUserData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Elevate);
