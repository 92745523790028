/* eslint-disable padding-line-between-statements */
import { generateActionTypes, generateActions } from './actionGenerators';

export const API_SIGNUP_NEW_REQUEST = generateActionTypes('API_SIGNUP_NEW_REQUEST');
export const apiSignUpNewRequest = generateActions(API_SIGNUP_NEW_REQUEST, true);

export const AUTH_CHALLENGE = generateActionTypes('AUTH_CHALLENGE');
export const apiAuthChallenge = generateActions(AUTH_CHALLENGE, true);

export const CHECK_USER = generateActionTypes('CHECK_USER');
export const checkUser = generateActions(CHECK_USER, true);

export const CHANGE_USERNAME = generateActionTypes('CHANGE_USERNAME');
export const changeUsername = generateActions(CHANGE_USERNAME, true);

export const CHECK_PHONE = 'CHECK_PHONE';
export const checkPhone = payload => ({ payload, type: CHECK_PHONE });

export const ELEVATE = generateActionTypes('ELEVATE');
export const elevate = generateActions(ELEVATE, true);

export const EMAIL_CHANGE = generateActionTypes('EMAIL_CHANGE');
export const emailChange = generateActions(EMAIL_CHANGE, true);

export const FORGOT_PASSWORD = generateActionTypes('FORGOT_PASSWORD');
export const forgotPassword = generateActions(FORGOT_PASSWORD, true);

export const FORGOT_PASSWORD_CONFIRM = generateActionTypes('FORGOT_PASSWORD_CONFIRM');
export const forgotPasswordConfirm = generateActions(FORGOT_PASSWORD_CONFIRM, true);

export const LOGOUT_USER = 'LOGOUT_USER';
export const logout = () => ({ type: LOGOUT_USER });

export const OFFLINE_CODES = generateActionTypes('OFFLINE_CODES');
export const apiOfflineCodes = generateActions(OFFLINE_CODES, true);

export const PASSWORD_RESET_NEW_REQUEST = generateActionTypes('PASSWORD_RESET_NEW_REQUEST');
export const passwordResetNewRequest = generateActions(PASSWORD_RESET_NEW_REQUEST, true);

export const RECOVER_USERNAME = generateActionTypes('RECOVER_USERNAME');
export const recoverUsername = generateActions(RECOVER_USERNAME, true);

export const REMOVE_BACKUP_AUTH_METHODS = generateActionTypes('REMOVE_BACKUP_AUTH_METHODS');
export const apiRemoveBackupAuthMethod = generateActions(REMOVE_BACKUP_AUTH_METHODS, true);

export const RESEND_SMS_CODE = generateActionTypes('RESEND_SMS_CODE');
export const apiResendSmsCode = generateActions(RESEND_SMS_CODE, true);

export const SEND_SMS_CODE = generateActionTypes('SEND_SMS_CODE');
export const apiSendSmsCode = generateActions(SEND_SMS_CODE, true);

export const SET_AUTH_METHODS = generateActionTypes('SET_AUTH_METHODS');
export const apiSetAuthMethods = generateActions(SET_AUTH_METHODS, true);

export const SET_BACKUP_AUTH_METHODS = generateActionTypes('SET_BACKUP_AUTH_METHODS');
export const apiSetBackupAuthMethod = generateActions(SET_BACKUP_AUTH_METHODS, true);

export const SET_PRIMARY_AUTH_METHODS = generateActionTypes('SET_PRIMARY_AUTH_METHODS');
export const apiSetPrimaryAuthMethod = generateActions(SET_PRIMARY_AUTH_METHODS, true);

export const SETUP_PASSPHRASE = generateActionTypes('SETUP_PASSPHRASE');
export const setupPassphrase = generateActions(SETUP_PASSPHRASE, true);

export const SETUP_PHONE_NUMBER = generateActionTypes('SETUP_PHONE_NUMBER');
export const apiSetupPhoneNumber = generateActions(SETUP_PHONE_NUMBER, true);

export const SIGNIN = generateActionTypes('SIGNIN');
export const signIn = generateActions(SIGNIN, true);

export const SIGNOUT = generateActionTypes('SIGNOUT');
export const signOut = generateActions(SIGNOUT, true);

export const SIGNUP = generateActionTypes('SIGNUP');
export const signUp = generateActions(SIGNUP, true);

export const SIGNUP_CONFIRM = generateActionTypes('SIGNUP_CONFIRM');
export const signUpConfirm = generateActions(SIGNUP_CONFIRM, true);

export const SKIP_MFA = generateActionTypes('SKIP_MFA');
export const apiSkipMfa = generateActions(SKIP_MFA, true);

export const SELECTION = generateActionTypes('SELECTION');
export const selection = generateActions(SELECTION);

export const SETUP_TOTP = generateActionTypes('SETUP_TOTP');
export const apiSetupTotp = generateActions(SETUP_TOTP, true);

export const SSO_REDIRECT = generateActionTypes('SSO_REDIRECT');
export const apiSsoRedirect = generateActions(SSO_REDIRECT, true);

export const USER_DATA = generateActionTypes('USER_DATA');
export const apiUserData = generateActions(USER_DATA, true);

export const UPDATE_EMAIL = generateActionTypes('UPDATE_EMAIL');
export const updateEmail = generateActions(UPDATE_EMAIL, true);

export const UPDATE_EMAIL_CONFIRM = generateActionTypes('UPDATE_EMAIL_CONFIRM');
export const updateEmailConfirm = generateActions(UPDATE_EMAIL_CONFIRM, true);

export const VALIDATE_PASSPHRASE = generateActionTypes('VALIDATE_PASSPHRASE');
export const validatePassphrase = generateActions(VALIDATE_PASSPHRASE, true);

export const VALIDATE_PHONE_NUMBER = generateActionTypes('VALIDATE_PHONE_NUMBER');
export const apiValidatePhoneNumber = generateActions(VALIDATE_PHONE_NUMBER, true);

export const VALIDATE_TOTP = generateActionTypes('VALIDATE_TOTP');
export const apiValidateTotp = generateActions(VALIDATE_TOTP, true);
