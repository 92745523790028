const ChatRoutes = {
  index: {
    matchPath: '/chats/:_id?',
    path: '/chats',
    acl: {
      g: false,
      c: true,
      m: true
    }
  },
  chat: {
    path: '/chats/:_id',
    acl: {
      g: false,
      c: true,
      m: true
    }
  },
  identity: 'layer:///identities'
};

export default ChatRoutes;
