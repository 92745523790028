import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import Block from '../structure/Block';
import FieldRadio from './atoms/FieldRadio';
import s from './SelectList.module.scss';

const SelectList = ({ items, name, onChange, parentId, ...rest }) => {
  const thisId = components.generateId(name, parentId);

  return (
    <Block id={thisId} {...rest}>
      <ul className={s.this}>
        {items &&
          items.map(item => (
            <li className={s.thisItem} key={item.value}>
              <FieldRadio {...item} onChange={onChange} name={name} parentId={thisId} />
            </li>
          ))}
      </ul>
    </Block>
  );
};

SelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  onChange: PropTypes.func
};

SelectList.defaultProps = {
  items: null,
  parentId: '',
  onChange: null
};

export default SelectList;
