const generateId = (origId, parentId) => {
  const id = parentId ? `${parentId}__${origId}` : origId;
  const encodedId = encodeURIComponent(id)
    .split(/%[a-zA-Z0-9]{2}/)
    .join('-')
    .replace('.', '')
    .replace('_-', '_');
  return encodedId;
};

export default generateId;
