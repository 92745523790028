import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Popup, ButtonList } from '@ace/core-components';
import messages from './messages';

const RemoveBackupMethodWarningPopup = ({
  intl: { formatMessage },
  isActive,
  onClose,
  onButtonClick
}) => {
  const confirmButtonText = formatMessage(messages.confirmButton);
  const cancelButtonText = formatMessage(messages.cancelButton);
  const buttons = [
    {
      onClick: () => onButtonClick(),
      theme: 'primary',
      title: confirmButtonText,
      value: confirmButtonText,
      view: 'cta'
    },
    {
      onClick: () => onClose(),
      theme: 'success',
      title: cancelButtonText,
      value: cancelButtonText,
      view: 'cta'
    }
  ];
  return (
    <Popup
      intro={formatMessage(messages.text)}
      isActive={isActive}
      onClose={onClose}
      theme="success"
      title={formatMessage(messages.title)}
    >
      <ButtonList buttons={buttons} />
    </Popup>
  );
};

RemoveBackupMethodWarningPopup.propTypes = {
  intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

RemoveBackupMethodWarningPopup.defaultProps = {
  isActive: false
};

export default injectIntl(RemoveBackupMethodWarningPopup);
