import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserProfileFromJwtToken } from '../../store/reducers/profile';
import { logout } from '../../store/actions/user';
import { buildUrlWithClientAndRedirect } from '../../utils/url';

const AclRoute = ({
  component: ChildComponent,
  render,
  invalid,
  invalidRedirect,
  route,
  profile,
  logoutUser,
  ...compProps
}) => {
  let access = profile;

  if (profile && profile.exp < Math.round(Date.now() / 1000)) {
    logoutUser();
    access = false;
  }

  const renderRoute = () => (
    <Route
      path={route.path}
      render={routerProps =>
        render ? (
          render({ ...compProps, ...routerProps })
        ) : (
          <ChildComponent {...compProps} {...routerProps} />
        )
      }
    />
  );

  if (CONFIG.ACL) {
    return renderRoute();
  }

  if (!access) {
    // User doesn't have access
    return (
      <Redirect
        to={buildUrlWithClientAndRedirect(
          '/signin',
          compProps.location.pathname,
          compProps.location.search.substring(1),
        )}
      />
    );
  }

  if (invalid) {
    // User is trying to access a middle step of a flow directly
    return <Redirect to={invalidRedirect} />;
  }

  return renderRoute();
};

const mapStateToProps = state => ({
  profile: getUserProfileFromJwtToken(state),
});

const mapDispatchToProps = {
  logoutUser: logout,
};

AclRoute.propTypes = {
  component: PropTypes.func,
  invalid: PropTypes.bool,
  invalidRedirect: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  render: PropTypes.func,
  route: PropTypes.shape({}).isRequired,
};

AclRoute.defaultProps = {
  component: null,
  invalid: false,
  invalidRedirect: '',
  render: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AclRoute);
