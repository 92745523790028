import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { reduxForm, formValueSelector, propTypes, Field, Form } from 'redux-form';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import rfActions from 'redux-form/es/actions';

import {
  Button,
  CustomLink,
  FieldInputs,
  Page,
  PageFooter,
  PageMain,
  Popup,
} from '@ace/core-components';
import Block from '../../components/Block';
import MtCaptcha from '../../components/MtCaptcha';
import PageHeader from '../../components/PageHeader';
import RadioButtons from '../../components/RadioButtons';
import Text from '../../components/Text';
import TextAndCheck from '../../components/TextAndCheck';

import { Flag } from '../../flags';
import { focusOnFirstInvalidField } from '../../utils/helpers';
import { signUp } from '../../store/actions/user';
import AppRoutes from '../../routes';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import getParams from '../../utils/getParams';
import messages from './messages';
import styles from './SignUp.module.scss';

const jemjarUrl = '//jemjar.com';
const JEMJAR_CLIENTS = [
  // int.
  '6sknktqa51dbn515cbrvvvg159',
  // stage.
  '253ncfme7u6egmq03994kj9rsn',
  // live.
  '4oa7ooqfco8d4g1stdd30ld5tv',
];

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      showReferrerOptionPopup: false,
    };
  }

  onConfirmSubmit = () =>
    asyncFormSubmit(this.props.form, this.props.requestSignUp).catch(() => {
      this.props.dispatch(rfActions.change(this.props.form, 'captcha', ''));
    });

  toggleReferrerOptionPopup = () => {
    this.setState(prevState => ({ showReferrerOptionPopup: !prevState.showReferrerOptionPopup }));
  };

  _renderGrcRules = () => {
    const { locale } = getParams();

    return (
      <Block>
        <Field
          required
          component={TextAndCheck}
          name="agreeTerms"
          validate={[value => (value ? undefined : 'Required')]}
        >
          <FormattedMessage
            {...messages.consentLabel}
            values={{
              privacy: (
                <CustomLink
                  href={`${jemjarUrl}${
                    locale === 'en-US'
                      ? '/settings/privacy'
                      : '/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/'
                  }`}
                >
                  <FormattedMessage {...messages.privacy} />
                </CustomLink>
              ),
              terms: (
                <CustomLink
                  href={`${jemjarUrl}${
                    locale === 'en-US'
                      ? '/settings/terms'
                      : '/%E6%9D%A1%E6%AC%BE%E5%92%8C%E6%9D%A1%E4%BB%B6/'
                  }`}
                >
                  <FormattedMessage {...messages.terms} />
                </CustomLink>
              ),
            }}
          />
        </Field>
      </Block>
    );
  };

  _renderMissingReferrerWarning = () => (
    <>
      <b className="referrer-not-selected">
        <FormattedMessage {...messages.referrerNotSelected} />
      </b>
      &nbsp;
      <FormattedMessage {...messages.referrerNotSelectedIntro} />
    </>
  );

  render() {
    const {
      captcha,
      email,
      handleSubmit,
      initialValues,
      intl: { formatMessage },
      location,
      password,
      passwordConfirm,
      pristine,
      role,
      submitting,
      username,
    } = this.props;

    const { showReferrerOptionPopup } = this.state;

    const dynamicSubmit = e => {
      e.preventDefault();

      const referrerValue = e.target.valueOf().referrer.value;

      if (!referrerValue && !showReferrerOptionPopup) {
        this.toggleReferrerOptionPopup();

        return false;
      }

      if (showReferrerOptionPopup) {
        this.toggleReferrerOptionPopup();
      }

      this.props.dispatch(rfActions.startSubmit(this.props.form));

      return handleSubmit(this.onConfirmSubmit)(e);
    };

    const { client_id: clientId } = getParams();
    const showGrcRules = JEMJAR_CLIENTS.includes(clientId) && role === 'c';
    const disableButton =
      pristine || !password || !passwordConfirm || (!username && !email) || !captcha;

    const intro = JEMJAR_CLIENTS.includes(clientId) ? messages.introJemjar : messages.intro;

    return (
      <Page>
        <FormattedMessage {...messages.title}>{title => <Helmet title={title} />}</FormattedMessage>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={formatMessage(intro)} title={formatMessage(messages.title)} />
        <PageMain>
          {initialValues.referrer && (
            <Text theme="grey">
              <FormattedMessage tagName="strong" {...messages.referrer} />: {initialValues.referrer}
            </Text>
          )}
          <Text theme="grey">
            <FormattedMessage
              {...messages.alreadyHaveAccount}
              values={{
                loginLink: (
                  <CustomLink
                    to={{
                      pathname: AppRoutes.signin.index,
                      search: location.search,
                    }}
                  >
                    {formatMessage(messages.loginLink)}
                  </CustomLink>
                ),
              }}
            />
          </Text>
          <Form id={CONFIG.formNames.signup} onSubmit={dynamicSubmit}>
            <Block>
              <RadioButtons
                legend={messages.roleLabel}
                name="role"
                options={[
                  { title: formatMessage(messages.customer), value: 'c' },
                  { title: formatMessage(messages.business), value: 'm' },
                ]}
              />
            </Block>
            <Block>
              <FieldInputs.ReferrerId
                type={initialValues.referrer ? 'hidden' : 'text'}
                isShowOptionalTitle
                sublabel={formatMessage(messages.referrerHint)}
              />
              <Flag
                name="username"
                render={() => (
                  <FieldInputs.UsernameOrEmail
                    autoComplete="username"
                    sublabel={formatMessage(messages.usernameHint)}
                    isRequiredStarVisible
                    isValidationStatusBottom
                    required
                    extraValidate={[
                      {
                        message: { id: messages.invalidUsernameWith86.id },
                        without: /^\+?86\d+$/,
                      },
                    ]}
                  />
                )}
                fallbackRender={() => (
                  <FieldInputs.Email autoComplete="username" isRequiredStarVisible required />
                )}
              />
              <FieldInputs.CreatePassword
                autoComplete="new-password"
                value={password}
                isRequiredStarVisible
                isValidationStatusBottom
                required
              />
              <FieldInputs.CreatePasswordConfirm
                autoComplete="confirm-password"
                fieldLabel={formatMessage(messages.confirmPasswordFieldLabel)}
                value={passwordConfirm}
                isRequiredStarVisible
                isValidationStatusBottom
                required
              />
            </Block>
            {showGrcRules ? this._renderGrcRules() : null}
            <Block>
              <Field name="captcha" required component={MtCaptcha} />
              <p className={styles.captchaDescription}>
                <FormattedMessage {...messages.captchaDescription} />
              </p>
            </Block>
          </Form>
          <Popup
            intro={this._renderMissingReferrerWarning()}
            isActive={showReferrerOptionPopup}
            onClose={this.toggleReferrerOptionPopup}
            title="help"
            theme="black"
          >
            <Button
              theme="primary"
              form={CONFIG.formNames.signup}
              value={formatMessage(messages.referrerContinue)}
              title={formatMessage(messages.referrerContinue)}
              view="cta"
              submitting={submitting}
              type="submit"
            />
          </Popup>
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.nextButton}>
            {label => (
              <Button
                disabled={disableButton}
                form={CONFIG.formNames.signup}
                submitting={submitting}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

SignUp.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
  role: PropTypes.string,
};

SignUp.defaultProps = {
  password: '',
  passwordConfirm: '',
  role: 'c',
};

const SignUpForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: CONFIG.formNames.signup,
  onSubmitFail: errors => {
    focusOnFirstInvalidField({
      errors,
      fields: ['username', 'password', 'passwordConfirm'],
    });
  },
})(SignUp);

const getFormValue = formValueSelector(CONFIG.formNames.signup);

const mapStateToProps = state => {
  const { referrer, role } = getParams();

  return {
    captcha: getFormValue(state, 'captcha'),
    email: getFormValue(state, 'email'),
    initialValues: {
      referrer,
      agreeTerms: false,
      role: role || 'c',
    },
    password: getFormValue(state, 'password'),
    passwordConfirm: getFormValue(state, 'passwordConfirm'),
    role: getFormValue(state, 'role'),
    username: getFormValue(state, 'username'),
  };
};

const mapDispatchToProps = {
  requestSignUp: signUp.request,
};

const SignUpFormConnected = connect(mapStateToProps, mapDispatchToProps)(SignUpForm);

export default injectIntl(SignUpFormConnected);
