import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Text.module.scss';

const Text = ({ theme, children, tag: Tag, ...props }) => {
  const thisClass = classNames({
    [styles.text]: true,
    [styles[`_${theme}`]]: theme
  });
  return (
    <Tag className={thisClass} {...props}>
      {children}
    </Tag>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  theme: PropTypes.oneOf(['black', 'grey', 'grey-no-margin', 'accent'])
};

Text.defaultProps = {
  theme: 'black',
  tag: 'p'
};

export default Text;
