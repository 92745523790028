import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Icon } from '@ace/core-visuals';
import s from './LanguageButton.module.scss';
import getParams from '../../utils/getParams';
import history from '../../utils/history';

const LanguageButton = ({ locale }) => {
  const { pathname } = history.location;
  const params = getParams();
  delete params.locale; // remove locale url param so it doesn't revert on refresh
  const returnURL = encodeURIComponent(`${pathname}?${queryString.stringify(params)}`);
  return (
    <button
      type="button"
      className={s.Button}
      onClick={() => history.push(`/locale?return=${returnURL}`)}
    >
      <div className={s.wrap}>
        <span className={s.Icon}>
          <Icon type={CONFIG.languageOptions.find(item => item.value === locale).icon} />
        </span>
        <span className={s.locale}>{locale}</span>
      </div>
    </button>
  );
};

LanguageButton.propTypes = {
  locale: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  locale: state.locale
});

export default connect(
  mapStateToProps,
  null
)(LanguageButton);
