import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';
import { Icon, iconTypes, iconTypeArray } from '@ace/core-visuals';
import Label from './Label';
import Status from './Status';

import i18n from '../messages';
import styles from '../Input.module.scss';

const Wrapper = ({
  children,
  currentType,
  disabled,
  errorOnBlur,
  hideLabel,
  icon,
  id,
  intl: { formatMessage },
  isShowOptionalTitle,
  isValidationStatusBottom,
  label,
  meta,
  parentId,
  placeholder,
  sublabel,
  toggleType,
  type,
  unit,
  value,
  valueId,
  view,
}) => {
  const thisId = components.generateId(id, parentId);

  // If errorOnBlur, show errors when the field has a value and has been touched
  // Or only show errors after submitting
  let fieldError;

  if ((errorOnBlur && meta.dirty && meta.touched) || meta.submitFailed) {
    fieldError = meta.error;
  }

  const wrapperClassName = classNames({
    [styles[view]]: true,
    [styles._disabled]: disabled,
    [styles._hasErrorTop]: !isValidationStatusBottom && fieldError,
    [styles._hasErrorBottom]: isValidationStatusBottom && fieldError,
    [styles._hasValue]: value || value > 0,
    [styles._isFocused]: meta.active,
    [styles._hidden]: type === 'hidden',
    [styles.textTransform]: isShowOptionalTitle,
  });
  const valueWrapperClassName = classNames({
    [styles.valueWrapper]: true,
    [styles._hasErrorBottom]: isValidationStatusBottom && fieldError,
  });

  const showAsideIcon = type === 'select' || currentType === 'link';
  const asideIcon = type === 'select' ? iconTypes.navigationDown : iconTypes.navigationNext;

  return (
    <>
      <div className={wrapperClassName} id={thisId}>
        <Label
          error={!isValidationStatusBottom && fieldError}
          hideLabel={hideLabel}
          htmlFor={valueId}
          label={label}
          parentId={thisId}
          sublabel={sublabel}
        />
        <div className={valueWrapperClassName}>
          {icon && (
            <span className={styles.icon}>
              <Icon parentId={thisId} type={icon} />
            </span>
          )}
          {children}
          {value && unit && (
            <span className={styles.unit}>
              <span className={styles.unitValue}>{value}</span>
              {unit}
            </span>
          )}
          {showAsideIcon && (
            <span className={styles.selectIcon}>
              <Icon parentId={thisId} type={asideIcon} aria-hidden="true" />
            </span>
          )}
          {type === 'select' && placeholder && !value && (
            <div className={styles.placeholderWrap}>
              <span className={styles.placeholder}>{placeholder}</span>
            </div>
          )}
          {type === 'password' && (
            <button type="button" className={styles.typeToggle} onClick={toggleType}>
              {currentType === 'password'
                ? formatMessage(i18n.passwordShowButton)
                : formatMessage(i18n.passwordHideButton)}
            </button>
          )}
        </div>
      </div>
      {isValidationStatusBottom && meta.submitFailed && (
        <Status message={fieldError} type={fieldError ? 'error' : 'success'} />
      )}
    </>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorOnBlur: PropTypes.bool,
  hideLabel: PropTypes.bool,
  icon: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  intl: intlShape.isRequired,
  isShowOptionalTitle: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    dirty: PropTypes.bool,
    error: PropTypes.shape({}),
    form: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
  parentId: PropTypes.string,
  placeholder: PropTypes.string,
  sublabel: PropTypes.string,
  toggleType: PropTypes.func,
  type: PropTypes.oneOf([
    'email',
    'hidden',
    'number',
    'password',
    'search',
    'select',
    'text',
    'textarea',
  ]),
  unit: PropTypes.string,
  value: PropTypes.string,
  valueId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['default', 'pin']),
};

Wrapper.defaultProps = {
  disabled: undefined,
  errorOnBlur: false,
  hideLabel: undefined,
  icon: undefined,
  id: 'wrapper',
  isShowOptionalTitle: false,
  label: undefined,
  meta: {},
  parentId: '',
  placeholder: undefined,
  sublabel: undefined,
  toggleType: undefined,
  type: 'text',
  unit: undefined,
  value: null,
  view: 'default',
};

export { Wrapper as WrapperWithoutIntl };

export default injectIntl(Wrapper);
