import _ from 'lodash';

// Taken from: https://stackoverflow.com/questions/39085399/lodash-remove-items-recursively
const deepOmit = (obj, keysToOmit) => {
  const keysToOmitIndex = _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]); // create an index object of the keys that should be omitted
  function omitFromObject(object) {
    // the inner function which will be called recursivley
    return _.transform(object, (result, value, key) => {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index, sanitize it
        // eslint-disable-next-line no-param-reassign
        result[key] = 'sanitized';
        return;
      }
      try {
        // if the key is an object run it through the inner function - omitFromObject
        // eslint-disable-next-line no-param-reassign
        result[key] = _.isObject(value) ? omitFromObject(value) : value;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('sanitizer - maximum call stack size exceeded');
        // if maximum call stack size exceeded field will be marked
        // eslint-disable-next-line no-param-reassign
        result[key] = 'Maximum call stack size exceeded';
      }
    });
  }
  // return the inner function result
  return omitFromObject(obj);
};

// Even if the logging software does sanitize on it's side too and is setup to obscure password, passphrases, emails etc.
// But seems sensible to also not sent them any of that information in the first place
const sanitizer = object => {
  if (typeof object !== 'object') {
    return object;
  }
  if (object instanceof Error && object.message && _.size(object) === 0) {
    // Object is a normal error and has no extra keys
    return object;
  }
  // Ensure no mutating object here
  const blacklistFields = [
    // Any additions here should also be made in the respective logging software UI (e.g. Sentry) - the server scrubbing
    'accessToken',
    'email',
    'idToken',
    'oldEmail',
    'password',
    'passphrase',
    'qrCode',
    'savedPassphrase',
    'savedQrCode',
    'security_code',
    'token',
    'ssoUrls',
    'refreshToken',
    'elevate'
  ];
  return deepOmit(object, blacklistFields);
};

export default sanitizer;
