import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { components } from '@ace/core-utils';

import { Icon, iconTypeArray } from '@ace/core-visuals';
import s from './Button.module.scss';

import i18n from './messages';

const Button = ({
  disabled,
  form,
  href,
  icon,
  id,
  intl: { formatMessage },
  linkTo,
  onClick,
  parentId,
  submitting,
  target,
  title,
  theme,
  type,
  value,
  view
}) => {
  const thisId = components.generateId(id, parentId);
  const thisClass = classNames({
    [s[view]]: true,
    [s[`_${theme}`]]: theme,
    [s._disabled]: disabled
  });

  let Tag = 'button';
  let targetValue;
  if (linkTo) {
    Tag = Link;
  }
  if (href) {
    Tag = 'a';
    targetValue = target;
  }

  return (
    <Tag
      className={thisClass}
      form={form}
      href={href}
      id={thisId}
      title={title || value}
      target={targetValue}
      to={linkTo}
      onClick={onClick}
      type={linkTo ? null : type}
      disabled={linkTo ? null : disabled || submitting}
    >
      {icon && <Icon parentId={thisId} type={icon} />}
      {view === 'icon' && <span className={s.valueSr}>{title}</span>}
      {value && (
        <span className={s.value}>{submitting ? formatMessage(i18n.submitting) : value}</span>
      )}
    </Tag>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.string,
  href: PropTypes.string,
  /** See all available icons of [Icon component](#icon)
   * Should be used like `icon={iconTypes.type}`, after importing iconTypes,
   * to prevent spelling errors etc. */
  icon: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  intl: intlShape.isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  parentId: PropTypes.string,
  submitting: PropTypes.bool,
  target: PropTypes.string,
  theme: PropTypes.oneOf(['additional', 'error', 'primary', 'success', 'transparent']),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  value: PropTypes.string,
  view: PropTypes.oneOf(['cta', 'default', 'icon']),
  onClick: PropTypes.func
};

Button.defaultProps = {
  disabled: false,
  form: null,
  href: undefined,
  icon: null,
  id: 'button',
  linkTo: undefined,
  onClick: undefined,
  parentId: '',
  submitting: false,
  target: undefined,
  theme: null,
  type: 'button',
  value: null,
  view: 'default'
};

export { Button as ButtonWithoutIntl };

export default injectIntl(Button);
