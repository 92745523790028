import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';
import { iconTypeArray } from '@ace/core-visuals';
import Wrapper from './atoms/Wrapper';

import * as validators from '../validators/validatorsFields';
import i18n from './messages';
import styles from './Input.module.scss';

class InputField extends PureComponent {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
    this.toggleType = this.toggleType.bind(this);

    this.setDefaultValues(props);
    this.state = {
      currentType: props.type === 'select' ? 'button' : props.type,
    };
  }

  setDefaultValues(props) {
    const {
      input,
      inputMode,
      intl,
      isRequiredStarVisible,
      isShowOptionalTitle,
      label,
      placeholder,
      required,
      sublabel,
      type,
      unit,
      view,
    } = props;
    this.tag = type === 'textarea' ? 'textarea' : 'input';
    this.inputMode = inputMode;
    this.label = components.getFieldMessage(label, `${input.name}Label`, intl, i18n);

    if (required && isRequiredStarVisible) {
      this.label += '*';
    }

    if (!required && isShowOptionalTitle) {
      const optionalTitle = components.getFieldMessage(undefined, 'optionalLabel', intl, i18n);
      this.label += ` (${optionalTitle})`;
    }

    this.min = null;
    this.placeholder = components.getFieldMessage(
      placeholder,
      `${input.name}Placeholder`,
      intl,
      i18n,
    );
    this.sublabel = components.getFieldMessage(sublabel, `${input.name}Sublabel`, intl, i18n);
    this.step = null;

    if (type === 'number') {
      this.placeholder = this.placeholder !== undefined ? this.placeholder : '0.00 ';

      if (unit) {
        this.placeholder += unit;
      }

      this.inputMode = 'numeric';
      this.step = 'any';
      this.min = 0;
    }

    if (view === 'pin') {
      const noop = e => {
        e.preventDefault();
      };

      this.noSelectProps = {
        autoComplete: 'off',
        onCopy: noop,
        onCut: noop,
        onDrag: noop,
        onDrop: noop,
        onPaste: noop,
        onSelect: noop,
      };
    }
  }

  toggleType() {
    this.setState(prevState => ({
      currentType: prevState.currentType === 'password' ? 'text' : 'password',
    }));
  }

  handleBlur({ target }) {
    const { input, onBlurFormat } = this.props;
    let { value } = target;

    value = validators.trim(value);

    if (onBlurFormat) {
      value = onBlurFormat(value);
    }

    // Ensures field is marked back as pristine if no number added
    if (value <= 0) {
      value = '';
    }

    input.onBlur(value);
  }

  render() {
    const {
      disabled,
      errorOnBlur,
      hideLabel,
      hintIcon,
      icon,
      id,
      input,
      inputRef,
      isShowOptionalTitle,
      isValidationStatusBottom,
      meta,
      onClick,
      parentId,
      pattern,
      required,
      title,
      type,
      unit,
      view,
    } = this.props;

    const thisId = components.generateId(id || input.name, parentId);

    return (
      <Wrapper
        className={styles[`${this.state.currentType}Label`]}
        currentType={this.state.currentType}
        disabled={disabled}
        errorOnBlur={errorOnBlur}
        hideLabel={hideLabel}
        hintIcon={hintIcon}
        icon={icon}
        id={thisId}
        isShowOptionalTitle={isShowOptionalTitle}
        isValidationStatusBottom={isValidationStatusBottom}
        label={this.label}
        meta={meta}
        placeholder={this.placeholder}
        sublabel={this.sublabel}
        toggleType={this.toggleType}
        type={type}
        unit={unit}
        value={input.value}
        valueId={`${thisId}__${this.tag}`}
        view={view}
      >
        <this.tag
          className={styles[`${this.state.currentType}Value`]}
          disabled={disabled}
          form={meta.form}
          id={`${thisId}__${this.tag}`}
          inputMode={this.inputMode}
          min={this.min}
          pattern={pattern}
          placeholder={this.placeholder}
          ref={inputRef}
          required={required}
          step={this.step}
          title={title}
          type={this.state.currentType}
          {...input}
          value={validators.doubleSpace(input.value)}
          onBlur={this.handleBlur}
          onClick={onClick}
          {...this.noSelectProps}
        />
      </Wrapper>
    );
  }
}

InputField.propTypes = {
  disabled: PropTypes.bool,
  errorOnBlur: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hintIcon: PropTypes.string,
  icon: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  inputMode: PropTypes.string,
  inputRef: PropTypes.func,
  intl: intlShape.isRequired,
  isRequiredStarVisible: PropTypes.bool,
  isShowOptionalTitle: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    dirty: PropTypes.bool,
    error: PropTypes.shape({}),
    form: PropTypes.string,
    submitFailed: PropTypes.bool,
  }).isRequired,
  onBlurFormat: PropTypes.func,
  onClick: PropTypes.func,
  parentId: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'hidden',
    'number',
    'password',
    'text',
    'textarea',
    'search',
    'select',
  ]),
  unit: PropTypes.string,
  view: PropTypes.oneOf(['default', 'pin']),
};

InputField.defaultProps = {
  disabled: undefined,
  errorOnBlur: false,
  hideLabel: undefined,
  hintIcon: undefined,
  icon: undefined,
  id: undefined,
  inputMode: undefined,
  inputRef: undefined,
  isRequiredStarVisible: false,
  isShowOptionalTitle: false,
  label: undefined,
  onBlurFormat: undefined,
  onClick: undefined,
  parentId: '',
  pattern: undefined,
  placeholder: undefined,
  required: undefined,
  sublabel: undefined,
  title: undefined,
  type: 'text',
  unit: undefined,
  view: 'default',
};

export { InputField as InputFieldWithoutIntl };

export default injectIntl(InputField);
