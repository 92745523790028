import { calculateBitcoinBought, calculateBitcoinRewarded } from '../../txs';

// General Events
export const OPEN_APP = 'Open App';
export const CLOSE_APP = 'Close App';
export const USER_VERIFIED = 'User Verified';

// Genaral Wallet events
const wallPrefix = 'Wallet';
export const WALLET_REFERRAL_CLICKED = `${wallPrefix}: Referral Url Clicked`;

// Business Discover Events
export const BUSINESS_VIEW_LIST = 'Business View (List)';
export const BUSINESS_VIEW_MAP = 'Business View (Map)';
export const BUSINESS_PROFILE_VIEW = 'Business Profiile View';
export const BUSINESS_CONTACT_CLICK = 'Business Contact Click';

// Deposit Events
const depPrefix = 'Deposit';
export const DEPOSIT_ENTER_AMOUNT = `${depPrefix}: Enter Amount`;

// Distribution Events
const distPrefix = 'Distribution';
export const DIST_ID_SCAN_SUCCESS = `${distPrefix}: ID Entry Scanned (Success)`;
export const DIST_ID_SCAN_FAIL = `${distPrefix}: ID Entry Scanned (Fail)`;
export const DIST_ID_ENTRY_MANUAL = `${distPrefix}: ID Entry Manual`;
export const DIST_ENTER_AMOUNT = `${distPrefix}: Enter Amount`;
export const DIST_SUBMIT_SUCCESS = `${distPrefix}: Submit (Jemjar - Success)`;
export const DIST_SUBMIT_FAIL = `${distPrefix}: Submit (Jemjar - Fail)`;

// Notification Events
export const BITCOIN_RECEIVED = 'Bitcoin Received';

// Register Events
const regPrefix = 'Register';
export const REGISTER_BUSINESS = `${regPrefix}: Business`;
export const REGISTER_BUSINESS_REWARD = `${regPrefix}: Business Reward`;

// Profile Events
const profPrefix = 'Profile';
export const PROFILE_ID_VIEWED = `${profPrefix}: ID Viewed`;

export const generateMeta = ({ alias, identify, people, reset, event, props }) => ({
  mixpanel: {
    alias,
    identify,
    people,
    reset,
    event,
    props
  }
});

export const generateUserProps = user => {
  if (!user) {
    return null;
  }
  return {
    _id: user._id,
    $name: `${user.firstName} ${user.lastName}`,
    Email: user.email ? user.email : '',
    'First Name': user.firstName ? user.firstName : '',
    'Last Name': user.lastName ? user.lastName : '',
    'Merchant BTC Address': user.btcAddress ? user.btcAddress : 'N/A',
    'User Type': user.aceRole === 'c' ? 'Consumer' : 'Merchant'
  };
};

export const generateBusinessProps = ({ location }) => ({
  'Business Name': location.name,
  'Business Address': location.address,
  'Business Email': location.email,
  'Business Description': location.description,
  'Reward %': location.rewardPct
});

export const generateBitcoinProps = ({ data }) => ({
  'Bitcoin Bought': calculateBitcoinBought(data),
  'Bitcoin Rewarded': calculateBitcoinRewarded(data)
});
