import { defineMessages } from 'react-intl';

export default defineMessages({
  expiredButtonLabel: 'Request',
  successTitle: 'Email Saved!',
  successIntro: 'Your new email address has been saved successfully!',
  successButtonLabel: 'Finish',
  verifyTitle: 'Please Verify.',
  verifyIntro:
    'We’ve just sent you a link to confirm your new email address. Please click the link and verify to continue.'
});
