class APIError extends Error {
  constructor(response) {
    super(response.message);

    this.name = 'APIError';
    this.code = `errors.${response.code}`;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }
  }
}

const request = (url, options) =>
  fetch(url, options).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response.status === 204 || response.status === 205 ? null : response.json();
    }

    return response.json().then(res => {
      throw new APIError(res);
    });
  });

export const makeRequest = (method, url, body, options) =>
  request(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    mode: 'cors',
    ...options,
  });

export const get = (url, body, options) => makeRequest('GET', url, body, options);

export const post = (url, body, options) => makeRequest('POST', url, body, options);

export default request;
