import React from 'react';
import PropTypes from 'prop-types';

import { components, numbers } from '@ace/core-utils';

import Amount from '../Amount';

import s from './RewardBadge.module.scss';

const RewardBadge = ({ id, parentId, rewardPct }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.this} id={thisId}>
      <Amount parentId={thisId} value={`${numbers.formatPctRange(rewardPct)}%`} view="small" />
    </div>
  );
};

RewardBadge.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  rewardPct: PropTypes.string
};

RewardBadge.defaultProps = {
  id: 'reward-badge',
  parentId: '',
  rewardPct: ''
};

export default RewardBadge;
