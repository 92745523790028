import _ from 'lodash';
import * as currencyIcons from './currency';
import * as findIcons from './find';
import * as languageIcons from './language';
import * as menuIcons from './menu';
import * as miscIcons from './misc';
import * as navigationIcons from './navigation';
import * as toolbarIcons from './toolbar';
import * as socialIcons from './social';

export const allIcons = {
  ...currencyIcons,
  ...findIcons,
  ...languageIcons,
  ...menuIcons,
  ...miscIcons,
  ...navigationIcons,
  ...socialIcons,
  ...toolbarIcons,
};

const mapIconTypes = iconSet =>
  _.mapValues(iconSet, (value, key) => {
    const val = key;

    return val;
  });

export const currencyIconTypes = mapIconTypes(currencyIcons);

export const findIconTypes = mapIconTypes(findIcons);

export const languageIconTypes = mapIconTypes(languageIcons);

export const menuIconTypes = mapIconTypes(menuIcons);

export const miscIconTypes = mapIconTypes(miscIcons);

export const navigationIconTypes = mapIconTypes(navigationIcons);

export const socialIconTypes = mapIconTypes(socialIcons);

export const toolbarIconTypes = mapIconTypes(toolbarIcons);

export const iconTypes = {
  ...currencyIconTypes,
  ...findIconTypes,
  ...languageIconTypes,
  ...menuIconTypes,
  ...miscIconTypes,
  ...navigationIconTypes,
  ...socialIconTypes,
  ...toolbarIconTypes,
};

export const currencyIconTypeArray = Object.keys(currencyIconTypes);

export const findIconTypeArray = Object.keys(findIconTypes);

export const languageIconTypeArray = Object.keys(languageIconTypes);

export const menuIconTypeArray = Object.keys(menuIconTypes);

export const miscIconTypeArray = Object.keys(miscIconTypes);

export const navigationIconTypeArray = Object.keys(navigationIconTypes);

export const socialIconTypeArray = Object.keys(socialIconTypes);

export const toolbarIconTypeArray = Object.keys(toolbarIconTypes);

export const iconTypeArray = Object.keys(iconTypes);
