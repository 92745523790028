import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { propTypes as formPropTypes } from 'redux-form';
import rfActions from 'redux-form/es/actions';
import { components } from '@ace/core-utils';
import { qrScan } from './actions';
import * as uiActions from '../../ui/actions';
import IdNumberManualEntry from './atoms/IdNumberManualEntry';
import IdNumberMessage from './atoms/IdNumberMessage';
import IdNumberQrReader from './atoms/IdNumberQrReader';
import s from './FieldIdNumber.module.scss';

const { submit } = rfActions;

class FieldIdNumber extends Component {
  constructor() {
    super();
    this.handleError = this.handleError.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleRefs = this.handleRefs.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
    this.toggleManualEntry = this.toggleManualEntry.bind(this);
    this.state = {
      legacyMode: false,
      manualEntry: false,
      success: false
    };
  }

  handleError(error) {
    // Handles errors from permissions / no camera access
    this.props.failureQrScan(error);
    switch (error.name) {
      case 'NoVideoInputDevicesError':
      case 'NotAllowedError':
        this.setState({ legacyMode: true });
        break;
      default:
    }
    this.props.onFailure(error);
  }

  handleScan(data) {
    // Ignore any scanning if insufficientBalance or in manual entry or already successful
    if (this.props.insufficientBalance || this.state.manualEntry || this.state.success) {
      return;
    }
    const parsedData = parseFloat(data);
    // To prevent UX confusion, if we've already errored, don't be successful
    if (!parsedData) {
      if (this.state.legacyMode) {
        const error = new Error('qrLegacyError');
        this.props.failureQrScan(error, { notification: error });
        this.props.onFailure(error);
      }
      return;
    }
    // Successful scan
    const numericId = parsedData.toString();
    this.props.form.change('numericId', numericId);
    this.props.successQrScan(null, { notification: { code: 'qrSuccess' } });

    this.setState({ success: true });
    setTimeout(() => {
      // HACK: Find better way of removing this message (refactor messages / notifications)
      this.props.remoteSubmit(this.props.form.form);
      this.props.removeUiNotification(0);
      this.props.onSuccess();
    }, 1000);
  }

  handleRefs(el) {
    this.scanner = el;
  }

  openImageDialog() {
    this.scanner.openImageDialog();
  }

  toggleManualEntry() {
    this.setState(prevState => ({
      manualEntry: !prevState.manualEntry
    }));
  }

  render() {
    const { id, form, insufficientBalance, onManualSubmit, parentId } = this.props;
    const { legacyMode, manualEntry } = this.state;

    const thisId = components.generateId(id, parentId);
    const thisClass = classNames({
      [s.this]: true,
      [s._insufficientFunds]: insufficientBalance
    });

    return (
      <form id={form.form} onSubmit={form.handleSubmit}>
        <div className={thisClass} id={thisId}>
          <IdNumberQrReader
            readerRef={this.handleRefs}
            insufficientBalance={insufficientBalance}
            legacyMode={legacyMode}
            parentId={thisId}
            onError={this.handleError}
            onScan={this.handleScan}
          />
          <IdNumberMessage
            insufficientBalance={insufficientBalance}
            legacyMode={legacyMode}
            openImageDialog={this.openImageDialog}
            parentId={thisId}
          />
          <IdNumberManualEntry
            form={form}
            insufficientBalance={insufficientBalance}
            manualEntry={manualEntry}
            parentId={thisId}
            onSubmit={onManualSubmit}
            toggleManualEntry={this.toggleManualEntry}
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  failureQrScan: qrScan.failure,
  successQrScan: qrScan.success,
  remoteSubmit: submit,
  removeUiNotification: uiActions.uiNotification.remove
};

FieldIdNumber.propTypes = {
  failureQrScan: PropTypes.func.isRequired,
  form: PropTypes.shape(formPropTypes).isRequired,
  id: PropTypes.string,
  insufficientBalance: PropTypes.bool.isRequired,
  parentId: PropTypes.string,
  remoteSubmit: PropTypes.func.isRequired,
  removeUiNotification: PropTypes.func.isRequired,
  successQrScan: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onManualSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

FieldIdNumber.defaultProps = {
  id: 'field-id-number',
  parentId: ''
};

export default connect(
  null,
  mapDispatchToProps
)(FieldIdNumber);
