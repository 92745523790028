import Big from 'big.js/big.mjs';

Big.NE = -10;

// Number functions

export const multiply = values => {
  const reducer = (acc, curr) => new Big(acc).times(new Big(curr)).toString();
  try {
    return values.reduce(reducer);
  } catch (e) {
    return '';
  }
};

export const divide = (x, y) => {
  try {
    return new Big(x).div(new Big(y)).toString();
  } catch (e) {
    return '';
  }
};

// Adds thousand comma separators
export const commas = value => {
  if (!value) {
    return '';
  }
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// Only allows numerals
export const only = value => {
  if (!value) {
    return '';
  }
  return value.replace(/[^\d]/g, '');
};

// Only allows numerals and single decimal
export const singleDecimal = value => {
  if (typeof value !== 'string') {
    return value;
  }
  if (!value) {
    return '';
  }
  const newValue = value.replace(/[^\d.]/g, '');
  return newValue.split('.', 2).join('.');
};

// Locks numbers below max values
export const normalizeNumber = (value, max) => {
  if (!value || value < 0) {
    return '';
  }
  if (value > max) {
    return max.toString();
  }
  return value;
};

// Number Formatters
export const formatCrypto = value => {
  try {
    const bigAmount = new Big(singleDecimal(value));
    if (bigAmount.gte(1000)) {
      return bigAmount.toFixed(0);
    }
    if (bigAmount.lt(1) && bigAmount.gt(0)) {
      return bigAmount.toPrecision(3);
    }
    return bigAmount.toFixed(2);
  } catch (error) {
    return Number(0).toFixed(2);
  }
};

export const formatFiat = (value, display = true) => {
  try {
    const bigAmount = new Big(singleDecimal(value));
    if (bigAmount.gte(10000) && display) {
      return bigAmount.toFixed(0);
    }
    if (bigAmount.lt(0.01) && bigAmount.gt(0)) {
      return '0.01';
    }
    return bigAmount.toFixed(2);
  } catch (error) {
    return Number(0).toFixed(2);
  }
};

export const formatPct = (value, display = true) => {
  try {
    const bigAmount = new Big(singleDecimal(value));
    return display ? bigAmount.round(0, 0).toFixed(0) : bigAmount.toFixed(0);
  } catch (e) {
    return Number(0);
  }
};

// Halves the rewardPct to create a range
export const formatPctRange = pct => {
  const minReward = divide(pct, 2);
  return `${formatPct(minReward)} - ${formatPct(pct)}`;
};
