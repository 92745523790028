import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Security Methods.',
  intro:
    'We have implemented the following measures to prevent unauthorized access to your account:',
  closeButton: 'Close',
  par1:
    'A unique passphrase that makes your account virtually inaccessible by anyone but yourself. This is the primary security method. Once you set it up, the system will ask you to provide it any time you want to cash out, change your username, change or restore your password. You also need to know your current passphrase if you want to reset it.',
  par2:
    'Because the loss of the passphrase would result in your account being not restorable, you can (but don’t have to) choose one of the three additional security methods: Offline Codes, Text Message, or Authenticator App.',
  par3:
    'Selecting an additional method is optional. If you choose to have it, pick one from the available options and set it up as default. In this case, it will serve as an alternative to the passphrase when you need to restore access to your account.'
});
