import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { propTypes } from 'redux-form';
import Helmet from 'react-helmet';

import { Page, PageMain } from '@ace/core-components';

import getParams from '../../utils/getParams';
import history from '../../utils/history';

import Block from '../../components/Block';
import Icon from '../../components/Icon';
import PageHeader from '../../components/PageHeader';

import messages from './messages';

class UpdateEmailConfirm extends Component {
  constructor(props) {
    super(props);
    const { expires } = getParams();
    if (Date.now() / 1000 > expires) {
      return this.props.history.push({
        pathname: '/update-email-link-expired',
        search: history.location.search
      });
    }
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          <Block spacing="xl">
            <Icon icon="success" />
          </Block>
        </PageMain>
      </Page>
    );
  }
}

UpdateEmailConfirm.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

export default injectIntl(UpdateEmailConfirm);
