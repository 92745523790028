import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import Button from '../../../Button';
import CustomLink from '../../../CustomLink';
import FieldInputs from '../../FieldInput';
import Popup from '../../../Popup';
import Block from '../../../structure/Block';
import s from '../FieldIdNumber.module.scss';
import i18n from '../messages';

const IdNumberManualEntry = ({
  form,
  id,
  insufficientBalance,
  intl: { formatMessage },
  manualEntry,
  parentId,
  toggleManualEntry
}) => {
  if (insufficientBalance) {
    return null;
  }

  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.manualEntry} id={thisId}>
      {!manualEntry && (
        <CustomLink theme="white" onClick={toggleManualEntry} parentId={thisId}>
          {formatMessage(i18n.IdNumberManualEntry_manualEntryLink)}
        </CustomLink>
      )}
      <Popup
        intro={formatMessage(i18n.IdNumberManualEntry_popupIntro)}
        isActive={manualEntry}
        parentId={thisId}
        onClose={toggleManualEntry}
        title={formatMessage(i18n.IdNumberManualEntry_popupTitle)}
      >
        <Block parentId={thisId}>
          <FieldInputs.NumericId parentId={thisId} required />
        </Block>
        <Button
          disabled={form.pristine}
          form={form.form}
          parentId={thisId}
          theme="primary"
          title={formatMessage(i18n.IdNumberManualEntry_popupNextButtonValue)}
          type="submit"
          value={formatMessage(i18n.IdNumberManualEntry_popupNextButtonValue)}
          view="cta"
        />
      </Popup>
    </div>
  );
};

IdNumberManualEntry.propTypes = {
  form: PropTypes.shape({
    pristine: PropTypes.bool
  }).isRequired,
  id: PropTypes.string,
  insufficientBalance: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  manualEntry: PropTypes.bool.isRequired,
  parentId: PropTypes.string,
  toggleManualEntry: PropTypes.func.isRequired
};

IdNumberManualEntry.defaultProps = {
  id: 'manual-entry',
  parentId: ''
};

export default injectIntl(IdNumberManualEntry);
