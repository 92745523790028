import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

const getDisplayName = WrappedComponent =>
  WrappedComponent.isActiveName || WrappedComponent.name || 'Component';

/*
 *
 * This HOC wraps components with the methods required to use the popup with forms.
 * Essentially it swaps the onSubmit function for one that just opens the popup,
 * and then, when it is open, reinstates the original onSubmit
 *
 */

const withPopup = WrappedComponent => {
  class WithPopup extends PureComponent {
    constructor(props) {
      super(props);
      this.onOpen = this.onOpen.bind(this);
      this.onClose = this.onClose.bind(this);
      this.dynamicOnSubmit = this.onOpen;
      this.dynamicOnSubmitSuccess = undefined;
      this.state = {
        isActive: false
      };
    }

    onOpen() {
      this.dynamicOnSubmit = this.props.onSubmit;
      this.dynamicOnSubmitSuccess = this.props.onSubmitSuccess;
      this.setState({
        isActive: true
      });
    }

    onClose() {
      this.dynamicOnSubmit = this.onOpen;
      this.dynamicOnSubmitSuccess = undefined;
      this.setState({
        isActive: false
      });
    }

    render() {
      // Replace onSubmit & onSubmitSuccess with our dynamic functions
      const { onSubmit, onSubmitSuccess, ...passThroughProps } = this.props;
      const { onClose } = this;
      const { isActive } = this.state;
      return (
        <WrappedComponent
          popup={{ onClose, isActive }}
          onSubmit={this.dynamicOnSubmit}
          onSubmitSuccess={this.dynamicOnSubmitSuccess}
          {...passThroughProps}
        />
      );
    }
  }
  WithPopup.isActiveName = `WithPopup(${getDisplayName(WrappedComponent)})`;
  WithPopup.propTypes = {
    onSubmit: PropTypes.func.isRequired
  };
  return WithPopup;
};

export default withPopup;
