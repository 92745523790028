import React from 'react';

export const navigationBack = (
  <g id="Icons/Navigation/Back" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Line-5"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="3.7"
      d="M30.743 2.7L12 20.01 30.743 39.2"
    />
  </g>
);

export const navigationClose = (
  <g id="Icons/Navigation/Close" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <g id="Group" stroke="currentColor" strokeWidth="3.818">
      <path id="Path-3" d="M21-5.727v53.454" transform="rotate(45 21 21)" />
      <path id="Path-4" d="M-5.727 21h53.454" transform="rotate(45 21 21)" />
    </g>
  </g>
);

export const navigationDown = (
  <g id="Icons/Navigation/Down" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Line-5"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="3.7"
      d="M39.2 11.878L21.89 30.622 2.7 11.878"
    />
  </g>
);

export const navigationEllipses = (
  <g id="Icons/Ellipses/Black" fill="none" fillRule="evenodd">
    <circle id="Oval" cx="5" cy="21" r="5" fill="currentColor" />
    <circle id="Oval" cx="21" cy="21" r="5" fill="currentColor" />
    <circle id="Oval" cx="37" cy="21" r="5" fill="currentColor" />
  </g>
);

export const navigationInformation = (
  <g id="Information" fill="none" fillRule="evenodd">
    <g id="Group-4" fillRule="nonzero" stroke="currentColor" strokeWidth="2.1">
      <circle id="Oval" cx="21" cy="21" r="19.95" />
    </g>
    <path
      id="i"
      fill="currentColor"
      d="M19.586 16.967h1.908a1.4 1.4 0 0 1 1.4 1.4V29.6a1.4 1.4 0 0 1-1.4 1.4h-1.908a1.4 1.4 0 0 1-1.4-1.4V18.367a1.4 1.4 0 0 1 1.4-1.4zM20.5 10c.727 0 1.325.227 1.795.68.47.454.705 1.04.705 1.758 0 .702-.235 1.284-.705 1.746-.47.462-1.068.693-1.795.693-.727 0-1.325-.231-1.795-.693-.47-.462-.705-1.044-.705-1.746 0-.718.235-1.304.705-1.758.47-.453 1.068-.68 1.795-.68z"
    />
  </g>
);

export const navigationNext = (
  <g id="Icons/Navigation/Next" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Line-5"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="3.7"
      d="M12 2.7l18.743 17.31L12 39.2"
    />
  </g>
);

export const navigationPlus = (
  <g id="Icons/Navigation/Plus" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path id="Path-3" stroke="currentColor" strokeWidth="4.2" d="M21 0v42" />
    <path id="Path-4" stroke="currentColor" strokeWidth="4.2" d="M0 21h42" />
  </g>
);

export const navigationSettings = (
  <g id="Icons/Settings/Grey" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <g id="settings" fill="currentColor" fillRule="nonzero">
      <path
        id="Shape"
        d="M39.876 17.238h-2.06A17.066 17.066 0 0 0 35.6 11.72l1.406-1.407c.83-.83.83-2.174 0-3.004L34.69 4.993a2.124 2.124 0 0 0-3.004 0L30.329 6.35a17.081 17.081 0 0 0-5.567-2.338V2.124A2.125 2.125 0 0 0 22.638 0h-3.276c-1.173 0-2.124.95-2.124 2.124v1.888A17.077 17.077 0 0 0 11.67 6.35l-1.358-1.357a2.124 2.124 0 0 0-3.004 0L4.993 7.309c-.83.83-.83 2.175 0 3.004l1.406 1.407a17.067 17.067 0 0 0-2.215 5.518h-2.06A2.125 2.125 0 0 0 0 19.363v3.275c0 1.173.95 2.124 2.124 2.124h2.164a17.084 17.084 0 0 0 2.287 5.343l-1.582 1.582c-.83.83-.83 2.175 0 3.004l2.316 2.316c.83.83 2.174.83 3.004 0l1.631-1.63a17.077 17.077 0 0 0 5.294 2.162v2.337c0 1.173.95 2.124 2.124 2.124h3.275c1.174 0 2.125-.95 2.125-2.124v-2.337a17.084 17.084 0 0 0 5.293-2.163l1.632 1.631c.83.83 2.174.83 3.004 0l2.316-2.316c.83-.83.83-2.174 0-3.004l-1.582-1.582a17.075 17.075 0 0 0 2.287-5.343h2.164c1.173 0 2.124-.95 2.124-2.124v-3.275c0-1.174-.95-2.125-2.124-2.125zM21 28.018a7.243 7.243 0 1 1 0-14.486 7.243 7.243 0 0 1 0 14.487z"
      />
    </g>
  </g>
);

export const navigationUp = (
  <g id="Icons/Navigation/Up" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <path
      id="Line-5"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="3.7"
      d="M39.2 30.622L21.89 11.878 2.7 30.622"
    />
  </g>
);
