// disabled camelcase due to snake-case vars in api
/* eslint-disable camelcase */
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { post } from '../../utils/request';
import getParams from '../../utils/getParams';
import history from '../../utils/history';
import {
  RESET_EMAIL,
  resetEmail,
  UPDATE_EMAIL_CONFIRM,
  UPDATE_EMAIL_CHECK,
  updateEmailConfirm,
  updateEmailCheck
} from './actions';
import { getLocale } from '../../store/reducers/locale';

const { apiBase } = CONFIG;

function* updateEmailRequest() {
  const { client_id, redirect_uri } = yield getParams();
  const { idToken } = yield select(state => state.recovery);
  let { email } = getParams();
  if (!email) {
    ({ newEmail: email } = yield select(getFormValues(CONFIG.formNames.recover)));
  }
  const locale = getParams().locale || (yield select(getLocale));
  try {
    yield call(post, `${apiBase}/change-email`, {
      idToken,
      new_email: email,
      client_id,
      redirect_uri,
      locale
    });
    yield put(resetEmail.success(null));
    history.push('/email-reset/verify');
  } catch (e) {
    yield put(resetEmail.failure(null, { notification: e }));
  }
}

function* updateEmailConfirmRequest() {
  const { client_id, security_code, id, redirect_uri, expires } = yield getParams();
  try {
    yield call(post, `${apiBase}/account-recovery-confirm`, {
      id,
      security_code,
      client_id,
      redirect_uri,
      expires
    });
    yield put(updateEmailConfirm.success(null));
    return history.push(`/email-reset/success?client_id=${client_id}&redirect_uri=${redirect_uri}`);
  } catch (e) {
    // TODO: handle errors other than expired
    yield put(updateEmailConfirm.failure(null));
    if (e.code === 'errors.EMAIL_VALIDATED') {
      return history.replace('/error?code=EMAIL_VALIDATED&icon=success');
    }
    return history.push(
      `/update-email-link-expired?client_id=${client_id}&redirect_uri=${redirect_uri}`
    );
  }
}

function* updateEmailCheckRequest({ payload: { submitPromise } }) {
  const { oldEmail, passphrase } = yield select(getFormValues(CONFIG.formNames.recover));
  const { client_id, redirect_uri } = yield getParams();
  try {
    const data = {
      client_id,
      redirect_uri,
      email: oldEmail,
      passphrase
    };

    const response = yield call(post, `${apiBase}/account-recovery`, data);
    yield put(
      updateEmailCheck.save({
        idToken: response.idToken
      })
    );
    yield put(updateEmailCheck.success(null));
    submitPromise.resolve();
    history.push(`/email-reset?client_id=${client_id}&redirect_uri=${redirect_uri}`);
  } catch (e) {
    submitPromise.reject();
    // TODO: handle errors other than expired
    yield put(updateEmailCheck.failure(null, { notification: e }));
    // history.push('/email-change/expired');
  }
}

export default function* watchAccountRecovery() {
  yield takeLatest(RESET_EMAIL.REQUEST, updateEmailRequest);
  yield takeLatest(UPDATE_EMAIL_CHECK.REQUEST, updateEmailCheckRequest);
  yield takeLatest(UPDATE_EMAIL_CONFIRM.REQUEST, updateEmailConfirmRequest);
}
