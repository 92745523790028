import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { zeroConfigCreateFlags } from 'feature-flags';
import { parse } from 'query-string';

const defaultConfig = {
  username: true,
  doNotValidatePhoneNumber: false
};

const {
  Dashboard,
  Flag,
  FlagsProvider,
  useFeaturesApi,
  useFlag,
  useFlags,
  usePersistFlags,
  api
} = zeroConfigCreateFlags()(defaultConfig);

const PersistedDashboard = props => {
  usePersistFlags();
  return <Dashboard {...props} />;
};

const PersistedFlagsPrivider = props => {
  const showUiDefault = useMemo(() => {
    const paras = parse(window.location.search);
    return paras['ff:show'] !== undefined;
  }, []);
  const [showUi, setShowUi] = useState(showUiDefault);
  return (
    <FlagsProvider>
      <>
        {props.children}
        <PersistedDashboard visible={showUi} onClose={() => setShowUi(false)} overlay />
      </>
    </FlagsProvider>
  );
};
PersistedFlagsPrivider.propTypes = {
  children: PropTypes.node
};
PersistedFlagsPrivider.defaultProps = {
  children: null
};

export {
  api,
  PersistedFlagsPrivider,
  Dashboard,
  Flag,
  FlagsProvider,
  useFeaturesApi,
  useFlag,
  useFlags
};
