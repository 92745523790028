// swagger form validation errors are nested levels. Get the last error message, as most descriptive
// the following functions are used to check for the errors and nested errors

export const hasErrors = data => {
  if (data.errors && data.errors instanceof Array && data.errors.length > 0) {
    return true;
  }
  return false;
};

export const hasNestedErrors = data => {
  if (
    data.errors[0].errors &&
    data.errors[0].errors instanceof Array &&
    data.errors[0].errors.length > 0
  ) {
    return true;
  }
  return false;
};
