import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserAvatar, getUserRole } from '@ace/core-modules/src/user/reducers';
import { getLocationLogo } from '@ace/core-modules/src/business/reducers';
import { getIsChatPossible } from '@ace/core-modules/src/chat/reducers';
import MerchantToolbar from './MerchantToolbar';
import UserToolbar from './UserToolbar';

const Toolbar = ({ avatar, isChatPossible, userRole }) => {
  if (userRole === 'm') {
    return <MerchantToolbar logo={avatar} isChatPossible={isChatPossible} />;
  }
  return <UserToolbar avatar={avatar} isChatPossible={isChatPossible} />;
};

const mapStateToProps = state => {
  const userRole = getUserRole(state);
  const avatar = userRole === 'm' ? getLocationLogo(state) : getUserAvatar(state);
  return {
    avatar,
    isChatPossible: getIsChatPossible(state),
    userRole
  };
};

Toolbar.propTypes = {
  avatar: PropTypes.string,
  isChatPossible: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired
};

Toolbar.defaultProps = {
  avatar: ''
};

const ToolbarConnected = connect(mapStateToProps)(Toolbar);

export { Toolbar as ToolbarWithoutConnected };

export default ToolbarConnected;
