export const log = (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args);
};

export const focusOnFirstInvalidField = ({ errors, fields }) => {
  // NOTE: there is no solution from the box for "react-redux-form": "1.16.10" to focus on first invalid field
  // "fields" parameter must contain an array of fields names in correct order
  for (let i = 0; i < fields.length; i++) {
    if (errors[fields[i]]) {
      setTimeout(() => {
        document.querySelector(`[name='${fields[i]}']`).focus();
      }, 0);

      return;
    }
  }
};
