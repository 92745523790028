import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Validate Passphrase.',
  titleConfirmMode: 'Please Confirm.',
  para1:
    'It is your responsibility to keep your Passphrase secure. You must never share them with anyone.',
  para1ConfirmMode: 'Please now enter your Passphrase. You saved both when you registered.',
  para2SignInMfaConfirmMode:
    'It looks like you are trying to login to GRC from a new device and location. Please confirm your identity via Primary or Backup authentication methods',
  para2: 'You will need one of them to be able to withdraw Bitcoin from your wallet.',
  para3: 'Please enter your Passphrase now so that we can ensure you’ve successfully saved them.',
  passphrasePlaceholder: 'Enter Passphrase',
  passphraseNoLonger: 'Passphrase should be no longer than 72 symbols',
  confirmButton: 'Confirm',
  useBackupMethod: 'Don’t Remember? Try to use Backup method',
  usePrimaryMethod: 'Don’t Remember? Try to use Primary method'
});
