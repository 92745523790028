import React from 'react';
import owasp from 'owasp-password-strength-test';
import Validators, { addValidator, format, required } from 'redux-form-validators';
import { Big } from 'big.js';
import { numbers } from '@ace/core-utils';
import { FormattedMessage } from 'react-intl';

import i18n from './messages';

const maxLength = 128;
const minLength = 8;

Validators.messages = {
  ...Validators.messages,
  ...i18n,
};

Validators.formatMessage = msg => <FormattedMessage {...(msg.props || msg)} />;

// owasp Password Tester
export const owaspTest = (pwString = {}) => {
  owasp.config({
    maxLength,
    minLength,
    allowPassphrases: false,
    minOptionalTestsToPass: 3,
  });

  return owasp.test(pwString);
};

// Add owasp tester to redux form validators
export const owaspValidator = () =>
  addValidator({
    validator: (options, value) => {
      const owaspResult = owaspTest(`${value}`);

      if (!owaspResult.strong) {
        return {
          ...i18n[`owasp_${owaspResult.failedTests[0]}`],
          values: {
            maxLength,
            minLength,
          },
        };
      }

      return null;
    },
  })();

// Reward validator
export const rewardAmount = addValidator({
  validator: (options, value) => {
    let bigFromBusinessCryptoAmount = new Big(0);
    const { balance, factor, maxAmount } = options;

    if (value && factor) {
      bigFromBusinessCryptoAmount = new Big(numbers.multiply([value, factor]));
    }

    if (bigFromBusinessCryptoAmount.gt(balance)) {
      return i18n.rewardAmountBalance;
    }

    if (bigFromBusinessCryptoAmount.gt(maxAmount)) {
      return {
        ...i18n.rewardAmountHigh,
        values: {
          maxAmount,
        },
      };
    }

    return null;
  },
});

// String trimmers
export const doubleSpace = value => `${value}`.replace('  ', ' ');

export const trim = value => `${value}`.trim();

// Emoji regex
const emojiRegex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;
export const emojiNotAllowed = format({
  message: i18n.emojiNotAllowed,
  without: emojiRegex,
});

export const finalValidate = (isRequired = false, validator = []) => {
  const finalValidator = [emojiNotAllowed];

  if (isRequired) {
    finalValidator.unshift(required());
  }

  if (validator) {
    finalValidator.push(...validator);
  }

  return finalValidator;
};
