import React from 'react';
import PropTypes from 'prop-types';
import ReactQRCode from 'qrcode.react';

import { components } from '@ace/core-utils';

import s from '../WalletId.module.scss';

const QrCode = ({ id, parentId, value }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.qrCode} id={thisId}>
      <ReactQRCode value={value} renderAs="svg" size={220} />
    </div>
  );
};

QrCode.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  value: PropTypes.string.isRequired
};

QrCode.defaultProps = {
  id: 'qr-code',
  parentId: ''
};

export default QrCode;
