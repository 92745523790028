import { generateActionTypes, generateActions } from '@ace/core-utils';

export const API_MEDIA_TOKEN = generateActionTypes('API_MEDIA_TOKEN');
export const APP_AUTH_CLEAR = generateActionTypes('APP_AUTH_CLEAR');
export const APP_AUTH_VALIDATE = generateActionTypes('APP_AUTH_VALIDATE');
export const SET_AUTHED_USER = generateActionTypes('SET_AUTHED_USER');
export const USER_LOGOUT = generateActionTypes('USER_LOGOUT');

export const apiMediaToken = generateActions(API_MEDIA_TOKEN);
export const appAuthClear = generateActions(APP_AUTH_CLEAR);
export const appAuthValidate = generateActions(APP_AUTH_VALIDATE);
export const setAuthedUser = generateActions(SET_AUTHED_USER);
export const userLogout = generateActions(USER_LOGOUT);
