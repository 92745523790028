import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import { allIcons, iconTypeArray } from './all';
import './Icon.scss';

const Icon = ({ id, parentId, type, style, viewBox, ...rest }) => {
  if (!type) {
    return null;
  }

  const thisId = components.generateId(id, parentId);

  const iconClass = classNames('icon', `icon_${type}`);

  return (
    <span className={iconClass} id={thisId} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-labelledby="title"
        fill="none"
        viewBox={viewBox}
        {...rest}
      >
        {allIcons[type]}
      </svg>
    </span>
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  style: PropTypes.shape({}),
  /** Pass the type of the icon required. Should be used like `type={iconTypes.type}`,
   * after importing iconTypes, to prevent spelling errors etc. */
  type: PropTypes.oneOf(iconTypeArray).isRequired,
  viewBox: PropTypes.string,
};

Icon.defaultProps = {
  id: 'icon',
  parentId: '',
  style: undefined,
  viewBox: '0 0 42 42',
};

export default Icon;
