import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import clipboard from 'clipboard-polyfill';

import { components } from '@ace/core-utils';

import { Icon, iconTypes } from '@ace/core-visuals';
import s from './ClickAndCopy.module.scss';

class ClickAndCopy extends PureComponent {
  constructor(props) {
    super(props);
    this.copyText = this.copyText.bind(this);
    this.state = {
      copied: false
    };
  }

  copyText() {
    clipboard.writeText(this.props.content);
    this.setState({
      copied: true
    });
    this.props.onClick();
  }

  render() {
    const { content, id, label, parentId } = this.props;
    const { copied } = this.state;

    const thisId = components.generateId(id, parentId);
    const iconType = copied ? 'copied' : 'copy';
    const thisClass = classNames({
      [s.this]: true,
      [s._copied]: copied
    });
    return (
      <div className={thisClass} id={thisId}>
        <button type="button" onClick={this.copyText} className={s.button}>
          {label && (
            <label className={s.label} htmlFor={content}>
              {label}
            </label>
          )}
          <div className={s.content}>
            <span className={s.text}>{content}</span>
            <span className={s.icon}>
              <Icon parentId={thisId} type={iconTypes[iconType]} />
            </span>
          </div>
        </button>
      </div>
    );
  }
}

ClickAndCopy.propTypes = {
  content: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  parentId: PropTypes.string,

  onClick: PropTypes.func
};

ClickAndCopy.defaultProps = {
  id: 'click-and-copy',
  label: null,
  parentId: '',

  onClick: () => {}
};

export default ClickAndCopy;
