import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Save Phone Number.',
  titleChange: 'Change Phone Number.',
  para1: 'Please specify your phone number and we will send SMS on it to confirm.',
  nextButton: 'Next',
  countryInputLable: 'Country',
  phoneCodeInputLable: 'Code',
  phoneNumberInputLable: 'Mobile Number'
});
