import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { components } from '@ace/core-utils';
import ProgressBar from '../../ProgressBar';
import PageFooter from '../PageFooter';
import s from './Page.module.scss';

const Page = ({ backgroundUrl, children, fullscreen, id, parentId }) => {
  const thisId = components.generateId(id, parentId);

  let addFooter = true;
  for (let i = 0; i < children.length; i += 1) {
    if (children[i] && children[i].type === PageFooter) {
      addFooter = false;
      break;
    }
  }
  const thisClass = classNames({
    [s.this]: true,
    [s._fullscreen]: fullscreen,
    [s._hasBackground]: backgroundUrl
  });
  let thisStyle = {};
  if (backgroundUrl) {
    thisStyle = {
      backgroundImage: `url(${backgroundUrl})`
    };
  }
  return (
    <div className={thisClass} id={thisId} style={thisStyle}>
      <ProgressBar />
      {children}
      {addFooter && <PageFooter parentId={thisId} />}
    </div>
  );
};

Page.propTypes = {
  backgroundUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  id: PropTypes.string,
  parentId: PropTypes.string
};

Page.defaultProps = {
  backgroundUrl: null,
  fullscreen: false,
  id: 'page',
  parentId: ''
};

export default Page;
