import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { components, goBack } from '@ace/core-utils';
import SettingsRoutes from '@ace/core-modules/src/settings/routes';

import { iconTypes } from '@ace/core-visuals';
import Button from '../../Button';

import s from './PageHeader.module.scss';

import i18n from './messages';

const PageHeader = ({
  back,
  border,
  id,
  intl: { formatMessage },
  leftButton,
  next,
  parentId,
  settingsButton,
  title,
  view
}) => {
  const thisId = components.generateId(id, parentId);

  const thisClass = classNames({
    [s[view]]: true,
    [s._hasBorder]: border
  });

  return (
    <header className={thisClass} id={thisId}>
      <div className={s.aside}>
        {back && (
          <Button
            icon={iconTypes.navigationBack}
            id="back-button"
            onClick={goBack}
            parentId={thisId}
            theme="transparent"
            title={formatMessage(i18n.backButtonTitle)}
            view="icon"
          />
        )}
        {!back && leftButton && (
          <Button
            {...leftButton}
            id="left-button"
            parentId={thisId}
            theme="transparent"
            view="icon"
          />
        )}
      </div>
      <div className={s.main}>{title && <h1 className={s.title}>{title}</h1>}</div>
      <div className={s.aside}>
        {settingsButton && (
          <span className={s.settingsButton}>
            <Button
              parentId={thisId}
              icon={iconTypes.settings}
              id="settings-button"
              linkTo={SettingsRoutes.indexAcl.path}
              theme="transparent"
              title={formatMessage(i18n.settingsButtonTitle)}
              view="icon"
            />
          </span>
        )}
        {!settingsButton && next && (
          <Button {...next} id="next-button" parentId={thisId} theme="transparent" view="icon" />
        )}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  /** PageHeader left button only ever has 1 action - to go back */
  back: PropTypes.bool,
  border: PropTypes.bool,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  leftButton: PropTypes.shape({}),
  next: PropTypes.shape({}),
  parentId: PropTypes.string,
  settingsButton: PropTypes.bool,
  title: PropTypes.string,
  view: PropTypes.oneOf(['default', 'transparent'])
};

PageHeader.defaultProps = {
  back: true,
  border: false,
  id: 'page-header',
  leftButton: null,
  next: null,
  parentId: '',
  settingsButton: false,
  title: null,
  view: 'default'
};

export default injectIntl(withRouter(PageHeader));
