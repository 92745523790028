import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Maintenance.',
  messagei:
    'Global Rewards City website is currently shut down for maintenance. During this period all citizens from Global Reward City will not able to login and perform any transaction.',
  messageii: 'We apologize for the inconvenience caused and thank you for your continuous support!',
  messageiii: 'Global Rewards City Operation Team',
  contactUsButton: 'Register Interest'
});
