import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Enter code.',
  titleConfirmMode: 'Please Confirm.',
  para1: 'Please, enter the 6 digit confirmation you see on your authentication app.',
  para2SignInMfaConfirmMode:
    'It looks like you are trying to login to GRC from a new device and location. Please confirm your identity via Primary or Backup authentication methods',
  confirmLabel: 'Confirmation code.',
  submitButton: 'Next',
  submitButtonConfirmMode: 'Confirm',
  useBackupMethod: 'Don’t Remember? Try to use Backup method',
  usePrimaryMethod: 'Don’t Remember? Try to use Primary method'
});
