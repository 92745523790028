const checkCanvasSupporting = () => {
  const canvas = document.createElement('canvas');

  return Boolean(canvas.getContext && canvas.getContext('2d'));
};

// NOTE: any changes of fields may lead to fingerprint hash invalidation
const dataHandlers = {
  isCanvasSupported: checkCanvasSupporting(),
  isCookiesEnabled: () => window.navigator.cookieEnabled,
  isIndexDBAvailable: () => Boolean(window.indexedDB),
  isStorageAvailable: () => Boolean(window.localStorage),
  isTrackedByWebsites: () => Boolean(window.navigator.doNotTrack),
  isWebGLSupported: () => {
    if (!checkCanvasSupporting()) {
      return false;
    }

    const canvas = document.createElement('canvas');
    const webgl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

    return webgl && webgl instanceof WebGLRenderingContext;
  },
  language: () => window.navigator.language,
  languagesPreferred: () => window.navigator.languages,
  pixelRatio: () => window.devicePixelRatio,
  resolutionAvailable: () =>
    window.screen.availWidth && [window.screen.availWidth, window.screen.availHeight],
  resolutionCurrent: () => [window.screen.width, window.screen.height]
};

export const getFingerprint = () => {
  const fingerprint = {};

  Object.keys(dataHandlers).forEach(key => {
    try {
      const data = dataHandlers[key]();

      if (data) {
        fingerprint[key] = data;
      }
    } catch (e) {}
  });

  return fingerprint;
};
