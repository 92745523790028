import { SWITCH_LOCALE } from '../actions/locale';

const initialState = CONFIG.defaultLocale;

export const getLocale = state => state.locale;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_LOCALE.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
