import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import IdNumber from './atoms/IdNumber';
import QrCode from './atoms/QrCode';

import s from './WalletId.module.scss';

const WalletId = ({ id, numericId, parentId }) => {
  const thisId = components.generateId(id, parentId);
  let numericIdString;
  try {
    numericIdString = numericId.toString();
  } catch (error) {
    return null;
  }

  return (
    <div className={s.this} id={thisId}>
      <QrCode value={numericIdString} parentId={thisId} />
      <IdNumber value={numericId} parentId={thisId} />
    </div>
  );
};

WalletId.propTypes = {
  id: PropTypes.string,
  numericId: PropTypes.number.isRequired,
  parentId: PropTypes.string
};

WalletId.defaultProps = {
  id: 'wallet-id',
  parentId: ''
};

export default WalletId;
