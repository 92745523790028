import { SELECTION, VALIDATE_PASSPHRASE } from '../actions/user';

export const getSelections = state => state.selection;

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTION.SAVE:
      return { ...state, ...action.payload };

    case VALIDATE_PASSPHRASE.SUCCESS:
      return {};

    default:
      return state;
  }
};
export default reducer;
