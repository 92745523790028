import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, formValueSelector, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {
  ButtonList,
  CustomLink,
  Page,
  PageFooter,
  PageMain,
  SelectList,
} from '@ace/core-components';
import { iconTypes } from '@ace/core-visuals';
import { getLoading } from '@ace/core-components/src/ui/reducers';

import PageHeader from '../../components/PageHeader';
import OfflineCodes from '../OfflineCodes';
import SetupPassphrase from '../SetupPassphrase';
import SetupSms from '../SetupSms';
import SetupTotp from '../SetupTotp';
import SublinkMenuItem from '../../components/SublinkMenuItem';
import Text from '../../components/Text';

import {
  apiUserData,
  apiSsoRedirect,
  apiSetPrimaryAuthMethod,
  apiSkipMfa,
} from '../../store/actions/user';
import { getUserData } from '../../store/reducers/mfa';
import AppRoutes from '../../routes';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import messages from './messages';

class SetupPrimaryMethod extends PureComponent {
  componentDidMount() {
    if (!this.props.user) {
      this.props.requestUserData();
    }
  }

  onSubmit = () => {
    const {
      history,
      location,
      match: { url },
      method,
      user,
      requestSetPrimaryAuthMethod,
      form,
    } = this.props;
    const userHasSelectedMethod = !!user[`${method}_auth_method`];

    if (userHasSelectedMethod) {
      if (user.primary_auth_method === method) {
        history.push({ ...location, pathname: AppRoutes.signup.extraSecurity.index });
      } else if (!user.primary_auth_method) {
        asyncFormSubmit(form, requestSetPrimaryAuthMethod, { method })
          .then(() => history.push({ ...location, pathname: AppRoutes.signup.extraSecurity.index }))
          .catch(() => {});
      }
    } else {
      const path = method === 'offline_codes' ? 'offline-codes' : method;

      history.push({
        ...location,
        pathname: `${url}/${path}`,
      });
    }
  };

  render() {
    const {
      intl: { formatMessage },
      user,
      match: { path },
      history,
      location,
      handleSubmit,
      submitting,
      form,
      method,
      loading,
      requestSkipMfa,
    } = this.props;
    const canSkip = user && (!user.primary_auth_method || !user.backup_auth_method);
    const nextButtonText = formatMessage(messages.nextButton);
    const skipButtonText = formatMessage(messages.skipButton);

    const buttons = [
      {
        form,
        disabled: !method || submitting,
        theme: 'primary',
        title: nextButtonText,
        type: 'submit',
        value: nextButtonText,
        view: 'cta',
      },
      canSkip && {
        disabled: loading,
        onClick: () => requestSkipMfa(requestSkipMfa),
        theme: 'success',
        title: skipButtonText,
        value: skipButtonText,
        view: 'cta',
      },
    ].filter(Boolean);

    const getSubLink = ({ isSubLabelVisible, label, subLabel, to = '/' }) => (
      <SublinkMenuItem
        label={formatMessage(label)}
        sublabel={
          isSubLabelVisible && (
            <CustomLink to={to} onClick={to ? null : e => e.preventDefault()}>
              {formatMessage(subLabel)}
            </CustomLink>
          )
        }
      />
    );

    const options = [
      {
        icon: iconTypes.menuId,
        label: getSubLink({
          isSubLabelVisible: user.passphrase_auth_method,
          label: messages.passphrase,
          subLabel: messages.installed,
        }),
        value: 'passphrase',
      },
      {
        icon: iconTypes.menuGoogleAuthenticator,
        label: getSubLink({
          isSubLabelVisible: user.totp_auth_method,
          label: messages.authApp,
          subLabel: messages.installed,
        }),
        value: 'totp',
      },
      {
        icon: iconTypes.menuOfflineCodes,
        label: getSubLink({
          isSubLabelVisible: user.offline_codes_auth_method,
          label: messages.offlineCodes,
          subLabel: messages.viewCodes,
          to: { ...location, pathname: AppRoutes.offlineCodes.index },
        }),
        value: 'offline_codes',
      },
      {
        icon: iconTypes.menuTextMessage,
        label: getSubLink({
          isSubLabelVisible: user.phone_auth_method,
          label: messages.phone,
          subLabel: messages.installed,
        }),
        value: 'phone',
      },
    ].filter(item => (user.backup_auth_method ? user.backup_auth_method !== item.value : true));

    return (
      <Switch>
        <Route
          path={`${path}/offline-codes`}
          render={props => (
            <OfflineCodes
              {...props}
              type="primary"
              onSuccess={() =>
                history.replace({
                  ...location,
                  pathname: AppRoutes.signup.extraSecurity.index,
                })
              }
            />
          )}
        />
        <Route
          path={`${path}/passphrase`}
          render={props => (
            <SetupPassphrase
              {...props}
              type="primary"
              onSuccess={() =>
                history.replace({ ...location, pathname: AppRoutes.signup.extraSecurity.index })
              }
            />
          )}
        />
        <Route
          path={`${path}/phone`}
          render={props => (
            <SetupSms
              {...props}
              type="primary"
              onSuccess={() =>
                history.replace({ ...location, pathname: AppRoutes.signup.extraSecurity.index })
              }
            />
          )}
        />
        <Route
          path={`${path}/totp`}
          render={props => (
            <SetupTotp
              {...props}
              type="primary"
              onSuccess={() =>
                history.replace({
                  ...location,
                  pathname: AppRoutes.signup.extraSecurity.index,
                })
              }
            />
          )}
        />
        <Route
          render={() => (
            <Page>
              <Helmet title={formatMessage(messages.title)} />
              <PageHeader
                intro={
                  <>
                    <FormattedMessage {...messages.para1} />
                    <br />
                    <br />
                    <Text tag="span" theme="accent">
                      <FormattedMessage {...messages.warn} />
                    </Text>
                    <br />
                    <FormattedMessage {...messages.para2} />
                  </>
                }
                title={formatMessage(messages.title)}
              />
              <PageMain>
                <Form id={form} onSubmit={handleSubmit(this.onSubmit)}>
                  <SelectList name="method" items={options} />
                </Form>
              </PageMain>
              <PageFooter>
                <ButtonList buttons={buttons} />
              </PageFooter>
            </Page>
          )}
        />
      </Switch>
    );
  }
}

SetupPrimaryMethod.propTypes = {
  intl: intlShape.isRequired,
  requestSetPrimaryAuthMethod: PropTypes.func.isRequired,
  requestSkipMfa: PropTypes.func.isRequired,
  requestSsoRedirect: PropTypes.func.isRequired,
  requestUserData: PropTypes.func.isRequired,
};

SetupPrimaryMethod.defaultProps = {};

const SetupPrimaryMethodForm = reduxForm({
  form: CONFIG.formNames.setupPrimaryMethod,
})(SetupPrimaryMethod);

const getFormValue = formValueSelector(CONFIG.formNames.setupPrimaryMethod);

const mapStateToProps = state => {
  const user = getUserData(state);

  return {
    user,
    initialValues: user && {
      method: user.primary_auth_method,
    },
    loading: getLoading(state),
    method: getFormValue(state, 'method'),
  };
};

const mapDispatchToProps = {
  requestSetPrimaryAuthMethod: apiSetPrimaryAuthMethod.request,
  requestSkipMfa: apiSkipMfa.request,
  requestSsoRedirect: apiSsoRedirect.request,
  requestUserData: apiUserData.request,
};

const SetupPrimaryMethodConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupPrimaryMethodForm);

export default injectIntl(SetupPrimaryMethodConnected);
