import React from 'react';
import PropTypes from 'prop-types';
import generateId from '../../utils/generateId';
import styles from './RadioButtons.module.scss';

const RadioButtons = ({ input, options, id, parentId }) => {
  const thisId = generateId(id, parentId);

  return (
    <div className={styles.radioButtons} id={thisId}>
      {options &&
        options.map(option => {
          const active = input.value === option.value;
          return (
            <label
              key={option.title}
              htmlFor={`${id}_${option.title}`}
              className={active ? styles.active : ''}
            >
              <input
                {...input}
                {...option}
                type="radio"
                name={input.name}
                id={`${id}_${option.title}`}
                autoComplete="off"
                checked={active}
              />{' '}
              {option.title}
            </label>
          );
        })}
    </div>
  );
};

RadioButtons.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  parentId: PropTypes.string
};

RadioButtons.defaultProps = {
  id: 'radio-buttons',
  parentId: ''
};

export default RadioButtons;
