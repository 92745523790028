import _ from 'lodash';
import { API_BUSINESS_REGISTER } from '@ace/core-modules/src/business/actions';
import { APP_AUTH_VALIDATE } from '@ace/core-modules/src/auth/actions';
import { getMediaToken } from '@ace/core-modules/src/auth/reducers';
import { tokeniseAvatarUser } from '@ace/core-utils';
import {
  API_CONSUMER_REGISTER,
  API_USER_PROFILE,
  API_USER_PROFILE_PATCH,
  API_USER_AVATAR_PATCH,
  API_USER_LANG_PATCH
} from '../actions';

const initialState = {
  _id: '',
  aceRole: 'g', // Start as guest
  balance: '',
  btcAddress: '',
  currency: CONFIG.currencies[0] ? CONFIG.currencies[0].value : null, // Default for fields
  insurance: '',
  locationId: '',
  lang: CONFIG.languageOptions[0].value,
  rewardPct: ''
};

// Profile Parts
export const getUserProfile = state => tokeniseAvatarUser(state.user, getMediaToken(state));
export const getEditableProfile = state =>
  _.pick(getUserProfile(state), ['avatar', 'email', 'firstName', 'lastName']);
export const getUserAvatar = state => getUserProfile(state).avatar;
export const getUserBalance = state => state.user.balance;
export const getUserInsuranceBalance = state => state.user.insurance;
export const getUserId = state => state.user._id;
export const getUserLocationId = state => state.user.locationId;
export const getUserLang = state => state.user.lang;
export const getUserNumericId = state => state.user.numericId;
export const getUserRole = state => {
  if (state.user.aceRole === 'm' && !state.user.locationId) {
    // If user has aceRole of 'm', but no locationId, it means they are a registering merchant: return 'rm' permission - registering merchant
    return 'rm';
  }
  if (state.user.aceRole === 'c' && !state.user.numericId) {
    // If user has aceRole of 'c', but no numeridId, it means they are a registering consumer.
    // However we'll assume valid user until known otherwise: return 'c' permission - consumer
    return 'c';
  }
  return state.user.aceRole;
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case APP_AUTH_VALIDATE.SUCCESS: {
      const {
        decodedIdToken: { aceRole, email, _id }
      } = action.payload;
      return {
        ...state,
        aceRole,
        email,
        _id
      };
    }

    case API_CONSUMER_REGISTER.SUCCESS: {
      const { numericId } = action.payload;
      return {
        ...state,
        numericId
      };
    }

    case API_USER_PROFILE.SUCCESS:
    case API_USER_PROFILE_PATCH.SUCCESS:
    case API_BUSINESS_REGISTER.SUCCESS:
      return {
        ...state,
        ...action.payload.profile
      };

    case API_USER_LANG_PATCH.REQUEST:
      return {
        ...state,
        ...action.payload
      };

    case API_USER_AVATAR_PATCH.SUCCESS:
      return {
        ...state,
        avatar: action.payload.newAvatars[0]
      };

    default:
      return state;
  }
};

export default user;
