import jwtDecode from 'jwt-decode';

import {
  CHANGE_USERNAME,
  ELEVATE,
  FORGOT_PASSWORD_CONFIRM,
  LOGOUT_USER,
  RECOVER_USERNAME,
  SIGNUP_CONFIRM,
  SIGNIN,
  UPDATE_EMAIL_CONFIRM
} from '../actions/user';

const initialState = {
  profile: false
};

export const getUserProfile = state => state.profile.profile;

export const getUserProfileFromJwtToken = state => {
  const profile = getUserProfile(state);
  return profile && profile.idToken && jwtDecode(profile.idToken);
};

export const getEmailFromJwtToken = state => {
  const profile = getUserProfileFromJwtToken(state);
  return profile && profile.email;
};

export const getMaskedUsernameFromDb = state => {
  const profile = getUserProfile(state);
  return profile && profile.maskedUsername;
};

export const getUsernameFromJwtToken = state => {
  const profile = getUserProfileFromJwtToken(state);
  return profile && (profile.username || profile.email);
};

export const getUserElevatedTokens = state =>
  state.profile.profile && state.profile.profile.elevate;

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ELEVATE.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          elevate: action.payload
        }
      };
    case FORGOT_PASSWORD_CONFIRM.SUCCESS:
    case RECOVER_USERNAME.SUCCESS:
    case SIGNUP_CONFIRM.SUCCESS:
    case SIGNIN.SUCCESS:
      return { ...state, profile: action.payload };
    case CHANGE_USERNAME.SUCCESS:
    case LOGOUT_USER:
    case UPDATE_EMAIL_CONFIRM.SUCCESS:
      return { ...state, profile: false };
    default:
      return state;
  }
};
export default profile;
