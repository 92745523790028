import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { reduxForm, propTypes, formValueSelector } from 'redux-form';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { Button, FieldInputs, Page, PageFooter, PageMain } from '@ace/core-components';
import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';

import { forgotPasswordConfirm } from '../../store/actions/user';
import { hasExpired } from '../../utils/getParams';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import history from '../../utils/history';
import messages from './messages';

class PasswordResetConfirm extends Component {
  constructor(props) {
    super(props);

    if (hasExpired()) {
      return this.props.history.replace({
        pathname: '/password-reset-link-expired',
        search: history.location.search,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  onConfirmSubmit = () => asyncFormSubmit(this.props.form, this.props.onSubmit).catch(() => {});

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
      password,
      passwordConfirm,
      pristine,
      submitting,
    } = this.props;

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader
          back={false}
          intro={formatMessage(messages.intro)}
          title={formatMessage(messages.title)}
        />
        <PageMain>
          <Block>
            <form id={CONFIG.formNames.newpassword} onSubmit={handleSubmit(this.onConfirmSubmit)}>
              <FieldInputs.CreatePassword value={password} required />
              <FieldInputs.CreatePasswordConfirm
                indicatorId="password-confirm-indicator"
                fieldLabel={formatMessage(messages.confirmPasswordFieldLabel)}
                value={passwordConfirm}
                required
              />
            </form>
          </Block>
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.confirmButton}>
            {label => (
              <Button
                disabled={pristine}
                form={CONFIG.formNames.newpassword}
                submitting={submitting}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

PasswordResetConfirm.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
};

PasswordResetConfirm.defaultProps = {
  password: '',
  passwordConfirm: '',
};

const PasswordResetConfirmForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: CONFIG.formNames.newpassword,
})(PasswordResetConfirm);

const getPassword = formValueSelector(CONFIG.formNames.newpassword);

const mapStateToProps = state => ({
  password: getPassword(state, 'password'),
  passwordConfirm: getPassword(state, 'passwordConfirm'),
});

const mapDispatchToProps = {
  onSubmit: forgotPasswordConfirm.request,
};

const PasswordResetConfirmFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetConfirmForm);

export default injectIntl(PasswordResetConfirmFormConnected);
