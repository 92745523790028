import { OFFLINE_CODES } from '../actions/user';

export const getCodes = state => state.offlineCodes.codes;

const initialState = {
  codes: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFLINE_CODES.SUCCESS:
      return {
        ...state,
        codes: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
