import root from 'window-or-global';
import { themeOptions } from './themeOptions';

const updateThemeForStyle = themeName => {
  const selectedTheme =
    themeOptions.find(t => t.name.toLowerCase() === themeName.toLowerCase()) || {};
  const html = document.getElementsByTagName('html')[0];
  Object.keys(selectedTheme).forEach(property => {
    if (property === 'name' || property === 'urlNames') {
      return;
    }
    html.style.setProperty(property, selectedTheme[property]);
  });
};

const findTheme = () => {
  const url = root.location.href;
  return themeOptions.find(theme => theme.urlNames.find(urlName => url.indexOf(urlName) >= 0));
};

const ThemeLoader = () => {
  const theme = findTheme();
  if (theme) {
    const { name } = theme;
    import(`../../theme/${name}.scss`);
    updateThemeForStyle(name);
  }
  return null;
};

export default ThemeLoader;
