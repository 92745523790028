import { generateActionTypes, generateActions } from '../../store/actions/actionGenerators';

export const UPDATE_EMAIL = generateActionTypes('UPDATE_EMAIL');
export const updateEmail = generateActions(UPDATE_EMAIL, true);

export const RESET_EMAIL = generateActionTypes('RESET_EMAIL');
export const resetEmail = generateActions(RESET_EMAIL, true);

export const UPDATE_EMAIL_CHECK = generateActionTypes('UPDATE_EMAIL_CHECK');
export const updateEmailCheck = generateActions(UPDATE_EMAIL_CHECK, true);

export const UPDATE_EMAIL_CONFIRM = generateActionTypes('UPDATE_EMAIL_CONFIRM');
export const updateEmailConfirm = generateActions(UPDATE_EMAIL_CONFIRM, true);
