import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import _ from 'lodash';
import { loadObject } from '../localstorage';

export const history = createBrowserHistory({ basename: CONFIG.routerBasename || '/' });

// Check if it's an iOS device
if (window.navigator.standalone) {
  // We should check if there's a persisted state then push the url
  const location = loadObject('location');
  if (location && location.pathname) {
    history.push(location);
  }
}

export const updateQuery = (q, push = true) => {
  const existingQ = queryString.parse(history.location.search);
  const action = _.isEmpty(existingQ) ? 'replace' : 'push';
  const newQ = queryString.stringify({ ...existingQ, ...q });
  // Don't push to the history when there's no change
  if (push && history.location.search !== `?${newQ}`) {
    history[action]({
      search: newQ
    });
  }
  return newQ;
};
