const mkIframe = url => {
  const el = document.createElement('iframe');
  el.setAttribute('src', url);
  el.setAttribute('width', '4');
  el.setAttribute('height', '4');
  el.setAttribute('class', 'iframe-sso');
  return el;
};

export const makeRedirectLoop = (urls = []) =>
  urls.reduce((finalUrl, url) => {
    if (!finalUrl) {
      return url;
    }
    const separator = url.indexOf('?') > -1 ? '&' : '?';
    return `${url}${separator}c=${encodeURIComponent(finalUrl)}`;
  }, '');

export const setSSOTokens = (urls = [], redirectLoop = false) => {
  if (redirectLoop) {
    const url = makeRedirectLoop(urls);
    if (url) {
      window.location.assign(url);
      return Promise.resolve();
    }
  }
  // create an iframe for each sso url
  const promises = urls.map(
    url =>
      new Promise(resolve => {
        const el = mkIframe(url);
        document.body.appendChild(el);
        setTimeout(resolve, 5000);
        el.onload = () => {
          resolve();
          document.body.removeChild(el);
        };
      })
  );
  return Promise.all(promises).catch(() => null);
};

export const logoutSSO = urls => {
  const promises = urls.map(
    url =>
      new Promise(resolve => {
        const el = mkIframe(url);
        document.body.appendChild(el);
        setTimeout(resolve, 5000);
        el.onload = () => {
          resolve();
          document.body.removeChild(el);
        };
      })
  );
  return Promise.all(promises).catch(() => null);
};

export default {
  setSSOTokens,
  logoutSSO
};
