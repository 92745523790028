import React from 'react';
import Helmet from 'react-helmet';
import { reduxForm, propTypes } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import AppRoutes from '../../routes';
import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import { getMaskedUsernameFromDb } from '../../store/reducers/profile';
import messages from './messages';

const mapStateToProps = state => ({
  initialValues: { recoveredUsername: getMaskedUsernameFromDb(state) }
});

let UsernameRecoverSuccess = props => {
  const {
    form,
    intl: { formatMessage },
    history,
    handleSubmit
  } = props;
  const onConfirmSubmit = React.useCallback(() => {
    history.replace(`${AppRoutes.signin.index}?${history.location.search}`);
  }, []);

  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader
        intro={<FormattedMessage {...messages.intro} />}
        title={<FormattedMessage {...messages.title} />}
      />
      <PageMain>
        <Block>
          <form id={CONFIG.formNames.recoverUsername} onSubmit={handleSubmit(onConfirmSubmit)}>
            <FieldInputs.RecoveredUsername disabled />
          </form>
        </Block>
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.confirmButton}>
          {label => (
            <Button
              form={CONFIG.formNames.recoverUsername}
              theme="primary"
              title={label}
              type="submit"
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
};

UsernameRecoverSuccess.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

UsernameRecoverSuccess = reduxForm({
  form: CONFIG.formNames.recoverUsername
})(UsernameRecoverSuccess);

const UsernameRecoverSuccessConnected = connect(mapStateToProps)(UsernameRecoverSuccess);

export default injectIntl(UsernameRecoverSuccessConnected);
