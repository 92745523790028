import { defineMessages } from 'react-intl';

export default defineMessages({
  alreadyVerifiedText: 'Already verified? {refreshLink} to refresh.',
  clickHere: 'Click here',
  illustrationTitle: 'Please Verify!',
  text:
    'You must verify your account to access this feature. {resendEmailLink} to re-send your verification email.',
  title: 'Please Verify!'
});
