import BusinessRoutes from '@ace/core-modules/src/business/routes';
import queryString from 'query-string';

const UserRoutes = {
  index: {
    path: '/user',
    acl: {
      g: false,
      c: true,
      m: false
    }
  },
  id: {
    path: '/user/id',
    acl: {
      g: false,
      c: true,
      m: false
    }
  },
  profile: {
    path: '/user/profile',
    acl: {
      g: false,
      c: true,
      m: false
    }
  },
  referrerRegister: {
    link: '/user/referrer',
    path: '/user/referrer/:referrerId',
    acl: {
      g: true,
      c: false,
      m: false
    }
  },
  // these are redirect_uris from ACE
  register: {
    path: '/user/register',
    acl: {
      g: false,
      c: '/user',
      rm: BusinessRoutes.register.index.path,
      m: BusinessRoutes.index.path
    }
  },
  login: {
    path: '/user/login',
    acl: {
      g: false,
      c: '/user',
      rm: BusinessRoutes.register.index.path,
      m: BusinessRoutes.index.path
    }
  },
  ace: {
    emailChange: locale =>
      `${CONFIG.ace.emailChange}?${queryString.stringify({
        client_id: CONFIG.ace.client_id,
        redirect_uri: `${CONFIG.appBase}/user/login`,
        locale
      })}`,
    signup: locale =>
      `${CONFIG.ace.signup}?${queryString.stringify({
        client_id: CONFIG.ace.client_id,
        redirect_uri: `${CONFIG.appBase}/user/register`,
        locale
      })}`,
    signin: locale =>
      `${CONFIG.ace.signin}?${queryString.stringify({
        client_id: CONFIG.ace.client_id,
        redirect_uri: `${CONFIG.appBase}/user/login`,
        locale
      })}`,
    passwordChange: locale =>
      `${CONFIG.ace.passwordChange}?${queryString.stringify({
        client_id: CONFIG.ace.client_id,
        redirect_uri: `${CONFIG.appBase}/user/login`,
        locale
      })}`
  }
};

export default UserRoutes;
