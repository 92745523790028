import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import s from '../Toolbar.module.scss';

const ToolbarRow = ({ id, parentId, items }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <nav className={s.this} id={thisId}>
      <ul className={s.list}>
        {items.map(item => (
          <item.component
            {...item}
            id={item.linkTo || item.href}
            key={item.linkTo || item.href}
            parentId={thisId}
          />
        ))}
      </ul>
    </nav>
  );
};

ToolbarRow.propTypes = {
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      linkTo: PropTypes.string
    })
  ).isRequired,
  parentId: PropTypes.string
};

ToolbarRow.defaultProps = {
  id: 'toolbar-row',
  parentId: ''
};

export default ToolbarRow;
