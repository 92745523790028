import { defineMessages } from 'react-intl';

export default defineMessages({
  ACCOUNT_LOCKED:
    'Warning! Your account has been temporarily locked. Please contact us to unlock it.',
  ACE_USER_NOT_INJECTED: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  ACL: 'Unfortunately the details you used to logged in appear invalid.',
  BAD_REQUEST: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  DEFAULT_ERROR: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  DEFAULT_SUCCESS: 'It worked!',
  EMAIL_IN_USE: 'Email in use',
  FILE_UPLOAD_FAIL: 'Sorry, it appears we weren’t able to upload your file. Please try again.',
  FINSYS_ERROR: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  FINSYS_UNEXPECTED: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  INTERNAL_SERVER_ERROR: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  INVALID_ROLE:
    'It appears your role does not allow you to make this action. If you think this is wrong, please contact us.',
  LAYER_IDENTITY_CREATE_ERROR:
    'Uh-oh! It appears something went wrong. Please contact us or try again.',
  LAYER_INVALID_NONCE: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  LAYER_SESSION_CREATE_ERROR:
    'Uh-oh! It appears something went wrong. Please contact us or try again.',
  LOCATION_IN_USE: 'Location in use',
  NETWORK_NOT_AVAILABLE:
    'Uh-oh! It appears the network is currently unavailable. Please try again later.',
  NOT_FOUND: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  NOT_FOUND_AVATAR: 'It appears we were unable to find your avatar.',
  USERNAME_IN_USE: 'Email in use',
  USERNAME_NOT_FOUND: "The username for the Passport Number provided doesn't exist.",
  USER_NOT_FOUND:
    'We seem to be having trouble finding you in our system. If it’s not resolved shortly, please contact us.',
  USER_NOT_INJECTED: 'Uh-oh! It appears something went wrong. Please contact us or try again.',
  businessProfilePatchSuccess: 'Your business details have been saved.',
  closeButtonTitle: 'Close',
  error: 'Error!',
  'errors.BAD_REQUEST':
    'There was an error with your request. Please check your entry and try again.',
  'errors.BAD_TOKEN': 'Invalid Token.',
  'errors.CodeMismatchException': 'Invalid code.',
  'errors.INVALID_CAPTCHA': 'Invalid captcha',
  'errors.INVALID_OFFLINE_CODE': 'Provided code is invalid.',
  'errors.INVALID_PASSPHRASE': 'Invalid passphrase',
  'errors.INVALID_QRCODE_OR_PASSPHRASE': 'The passphrase is invalid.',
  'errors.INVALID_REDIRECT_URI': 'Invalid redirect uri',
  'errors.INVALID_REFERRER': 'Invalid referrer',
  'errors.INVALID_TOTP_CODE': 'Provided code is invalid.',
  'errors.INVALID_VERIFICATION_CODE': 'The code inserted does not match the expected value.',
  'errors.INVALID_VERIFICATION_CODE_TOO_MANY_ATTEMPTS':
    'The wrong code was provided too many times.',
  'errors.InternalErrorException': 'Internal error, please try again.',
  'errors.InvalidPasswordException': 'Please enter a more secure password.',
  'errors.LimitExceededException': 'You exceeded the limit, please try again later.',
  'errors.MFA_NOT_ENABLED':
    'Selected action is unavailable because extra security methods aren’t installed. You can contact our support team to setup them.',
  'errors.MISSING_CONTENT_PARAMETER': 'You missed the passphrase parameter.',
  'errors.MISSING_EMAIL_PARAMETER': 'Missing email parameter.',
  'errors.MS_CHANGE_USERNAME_TIMEOUT':
    'Membership system is unavailable now. Please try again later.',
  'errors.MS_ERROR': 'Error while confirming your account',
  'errors.MS_ERROR_1008': 'Invalid Access Token.',
  'errors.MS_ERROR_1009': 'Invalid Id Token.',
  'errors.MS_ERROR_2005': 'Token has expired.',
  'errors.MS_ERROR_2910': 'Referral does not exist.',
  'errors.MS_ERROR_TIMEOUT': 'The request timed out',
  'errors.MS_REGISTRATION_TIMEOUT':
    'Membership system is unavailable now. Please continue registration after getting an email confirmation. If you don’t receive it, please try again tomorrow.',
  'errors.NOT_VERIFIED':
    'This account is not verified. Please click on the link in the confirmation email.',
  'errors.NotAuthorizedException': 'Incorrect email or password.',
  'errors.PHONE_NUMBER_BLACKLISTED':
    'The number you are trying to verify is blacklisted for verification.',
  'errors.PHONE_NUMBER_CONCURRENT_VERIFICATION':
    'Concurrent verifications to the same number are not allowed.',
  'errors.PHONE_NUMBER_EXISTS_EXCEPTION': 'This phone number is already registered in GRC.',
  'errors.PHONE_NUMBER_IS_ALREADY_SET':
    'This mobile number you entered appeared to be same as the current one. Please try again.',
  'errors.PHONE_NUMBER_UNSUPPORTED_REGION': 'The destination number is not in a supported network',
  'errors.PRIMARY_METHOD_IS_NOT_CONFIGURED': 'Primary method is not configured.',
  'errors.REFERRER_NOT_FOUND': 'Such referrer doesn’t exist in ecosystem',
  'errors.ResourceNotFoundException': 'User pool client does not exist',
  'errors.SAME_EMAIL': 'The email must be different.',
  'errors.SAME_PASSWORD': 'You need to provide a different password.',
  'errors.SAME_USERNAME': 'You need to provide a different username',
  'errors.SKIP_MFA_DISABLED':
    'After 48 hours, you can no longer skip this step. Please proceed to set up your preferred security methods now.',
  'errors.TOO_BUSY':
    'Due to high popularity of Global Rewards City, we are experiencing high volume of sign up requests. Please try again in a minute. We apologize for the inconvenience.',
  'errors.TooManyRequestsException': 'You reached the limit, please try again later.',
  'errors.UNAUTHORIZED': "You're not authorised to perform this action.",
  'errors.USERNAME_EXISTS_EXCEPTION': 'An account with the given email already exists.',
  'errors.USERNAME_NOT_FOUND': 'User not found',
  'errors.USER_NOT_FOUND': 'User not found',
  'errors.UserNotConfirmedException':
    'This account is not verified. Please click on the link in the confirmation email.',
  'errors.UserNotFoundException': 'User not found.',
  'errors.UsernameExistsException': 'An account with the given email already exists.',
  geoError:
    "Uh-oh, looks like we can't determine your location right now, please select an area to search in.",
  messageLocationFailure:
    'Unfortunately, there was an error sending your message. Please try again.',
  messageLocationSuccess:
    'Your message to {businessName} has been sent. Just so you know, they may not respond immediatelly.',
  passwordIncorrectFailure:
    'Unfortunately the Password you entered is incorrect. Please try again.',
  passwordResetFailure:
    'We were unable to reset your password. Please make another password reset request.',
  passwordResetRequestFailure: 'Invalid email address format.',
  passwordResetRequestSuccess:
    "We've just sent you an email with a link to reset your password. Please follow the steps and re-try the login process.",
  passwordResetSuccess: 'Your new password has been saved.',
  paymentProcessingFailure:
    'We were unable to process your payment. You have not been charged for this transaction.',
  pinChangeSuccess: 'Your Transaction PIN has been changed!',
  qrLegacyError: "Sorry, we couldn't read that QR code. Please try again or enter manually.",
  qrSuccess: 'QR Scanned, directing to next step...',
  referralUrlCopySuccess: 'Your referral URL has been copied to your clipboard!',
  referrerSuccess:
    "You've been referred by a friend. Hit 'Sign Up' to join, or 'Skip' if you want to take a look around first.",
  reportMessage: 'Your error has been logged with the id: {reportingEventId}.',
  reviewLocationFailure: 'Unfortunately, there was an error sending your review. Please try again.',
  reviewLocationSuccess: 'Your review has been submitted.',
  signOutExpired:
    "Your session has expired, please sign in again. You'll be redirected in a few seconds...",
  signOutUserSuccess: 'You will be logged out in a few seconds...',
  success: 'Success!',
  userProfilePatchSuccess: 'Your user details have been saved.',
  validateFile: '{content}',
  validatorFileSizeError: 'File is too large (max. {size}MB)',
  validatorFileTypeError: 'Invalid file format (supported formats: {formats})',
  validatorImgDimensionsError: 'Image is too small (min. {minWidth}x{minHeight}).',
  verifyAlready: 'Your account has already been verified.',
  verifyFail: 'We were unable to verify your email. Please request another verification email.',
  verifyPending: "We've just sent you an email with a link to verify your account.",
  verifySentError:
    'We were unable to send a verification email. Please try later or contact support.',
  verifySuccess:
    'Congratulations, your account has been verified. You can now claim rewards and start earning Bitcoin!',
});
