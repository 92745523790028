import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import root from 'window-or-global';
import createRavenMiddleware from 'raven-for-redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import sanitizer from './sanitizer';
import reportingMiddleware from './reportingMiddleware';
import reducers from './reducers';
import rootSaga from './sagas';

const configureStore = initialState => {
  // Redux Middlewares
  const middleware = [];

  // Sagas Middleware
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  // Note, this should come last to ensure it catches all errors
  // Add Raven Error Logging Middleware
  // If Raven is available log errors to Sentry.io
  if (root.Raven && CONFIG.features.raven) {
    const ravenOptions = {
      environment: CONFIG.appEnv,
      release: CONFIG.appCommitSHA,
      autoBreadcrumbs: {
        console: false
      },
      getUserContext: state => state.profile
    };

    root.Raven.config(CONFIG.features.raven, ravenOptions).install();
    const ravenMiddleware = createRavenMiddleware(root.Raven, {
      actionTransformer: sanitizer,
      stateTransformer: sanitizer
    });
    middleware.push(ravenMiddleware);
    // Add Extra Raven Error Logging Middleware
    middleware.push(reportingMiddleware);
  }

  // Compose enhancers attaching Middlewhare
  // Redux devTools enhancers
  // Don't run if we're in the live environment
  let composeEnhancers;
  if (CONFIG.features.reduxConsole) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  } else {
    composeEnhancers = compose;
  }
  const enhancers = composeEnhancers(applyMiddleware(...middleware));

  // Configure persistor
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['profile', 'locale', 'transactions']
  };

  // Wrap reducers with persistor
  const persistedReducers = persistReducer(persistConfig, reducers);

  // Create store
  const store = createStore(persistedReducers, initialState, enhancers);

  // Create persistor
  const persistor = persistStore(store);

  // Run saga as the last thing
  sagaMiddleware.run(rootSaga);

  return {
    ...store,
    persistor
  };
};

export default configureStore;
