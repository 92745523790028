import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, propTypes, Form } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageFooter, PageMain, Button, CustomLink } from '@ace/core-components';

import Textarea from '../Textarea';
import Block from '../Block';
import messages from './messages';
import PageHeader from '../PageHeader';
import SecurityMethodsInfoLink from '../SecurityMethodsInfoLink';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

import styles from './FillPassphrase.module.scss';

const MAX_LONG_PASSPHRASE = 72;

class FillPassphrase extends PureComponent {
  _onSubmit = params =>
    asyncFormSubmit(this.props.form, this.props.onSubmit, params).catch(() => {});

  render() {
    const {
      intl: { formatMessage },
      pristine,
      handleSubmit,
      submitting,
      form,
      alternative,
      onAlternative,
      mode
    } = this.props;

    const isConfirmMode = mode === 'confirm';
    const isConfirmSigninMfaMode = mode === 'confirm-signin-mfa';

    let pageTitle = formatMessage(messages.title);
    let pageIntro = (
      <>
        <FormattedMessage {...messages.para1} />
        <br />
        <br />
        <FormattedMessage {...messages.para2} />
        <br />
        <br />
        <FormattedMessage {...messages.para3} />
      </>
    );

    if (isConfirmMode) {
      pageTitle = formatMessage(messages.titleConfirmMode);
      pageIntro = <FormattedMessage {...messages.para1ConfirmMode} />;
    }
    if (isConfirmSigninMfaMode) {
      pageTitle = formatMessage(messages.titleConfirmMode);
      pageIntro = (
        <>
          <FormattedMessage {...messages.para1ConfirmMode} />
          <br />
          <br />
          <FormattedMessage {...messages.para2SignInMfaConfirmMode} />
        </>
      );
    }

    return (
      <Page>
        <Helmet title={pageTitle} />
        <PageHeader title={pageTitle} intro={pageIntro} />
        <PageMain>
          <Form id={form} onSubmit={handleSubmit(this._onSubmit)}>
            <Block>
              <FormattedMessage {...messages.passphrasePlaceholder}>
                {placeholder => <Textarea placeholder={placeholder} name="passphrase" />}
              </FormattedMessage>
            </Block>
          </Form>
        </PageMain>
        <PageFooter>
          {alternative && (
            <Block spacing="sm" align="center">
              <CustomLink onClick={() => onAlternative()} className={styles.smallFont}>
                {alternative === 'backup'
                  ? formatMessage(messages.useBackupMethod)
                  : formatMessage(messages.usePrimaryMethod)}
              </CustomLink>
            </Block>
          )}
          <Block spacing="sm" align="center">
            <SecurityMethodsInfoLink />
          </Block>
          <FormattedMessage {...messages.confirmButton}>
            {label => (
              <Button
                disabled={pristine || submitting}
                submitting={submitting}
                form={form}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

FillPassphrase.propTypes = {
  ...propTypes,
  alternative: PropTypes.oneOf(['primary', 'backup']),
  intl: intlShape.isRequired,
  mode: PropTypes.oneOf(['validate', 'confirm', 'confirm-signin-mfa']),
  onAlternative: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

FillPassphrase.defaultProps = {
  alternative: null,
  mode: 'confirm',
  onAlternative: () => {}
};

function warn(formProps, props) {
  const warning = {};
  const { passphrase } = formProps;
  const { intl, change, dispatch } = props;
  const { formatMessage } = intl;

  if (passphrase && passphrase.length >= MAX_LONG_PASSPHRASE) {
    warning.passphrase = `* ${formatMessage(messages.passphraseNoLonger)}`;
    dispatch(change('passphrase', passphrase.slice(0, MAX_LONG_PASSPHRASE)));
  }

  return warning;
}

const FillPassphraseForm = reduxForm({
  form: CONFIG.formNames.validatemfa,
  warn
})(FillPassphrase);

export default injectIntl(FillPassphraseForm);
