import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { updateEmail, updateEmailCheck, updateEmailConfirm, resetEmail } from './actions';
import NewEmailPage from './components/NewEmailPage';
import OldEmailPage from './components/OldEmailPage';
import PassphrasePage from './components/PassphrasePage';
import IconPage from '../../components/IconPage';
import messages from './messages';
import getParams from '../../utils/getParams';

class AccountRecovery extends Component {
  componentDidMount() {
    const params = getParams();
    if (params.security_code) {
      this.props.updateEmailConfirm();
    }
  }

  renderForm = () => {
    const { oldEmail, onSave, onSubmit, onSubmitEmailCheck, idToken } = this.props;
    if (!oldEmail) {
      return <OldEmailPage onSubmit={onSave} />;
    }
    if (!idToken) {
      return (
        <PassphrasePage
          onSubmit={(...params) => onSave(...params) && onSubmitEmailCheck(...params)}
        />
      );
    }
    return <NewEmailPage onSubmit={onSubmit} />;
  };

  render() {
    return (
      <Switch>
        <Route exact path="/email-reset" render={this.renderForm} />
        <Route
          exact
          path="/email-reset/verify"
          render={props => (
            <IconPage
              title={messages.verifyTitle}
              intro={messages.verifyIntro}
              icon="email"
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/email-reset/success"
          render={props => (
            <IconPage
              title={messages.successTitle}
              intro={messages.successIntro}
              icon="success"
              button={{
                label: messages.successButtonLabel,
                onClick: () => {
                  const params = getParams();
                  window.location.assign(
                    `/signin?redirect_uri=${params.redirect_uri}&client_id=${params.client_id}`
                  );
                }
              }}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

AccountRecovery.propTypes = {};

const mapStateToProps = state => ({
  // newEmail: state.recovery.newEmail,
  oldEmail: state.recovery.oldEmail,
  passphrase: state.recovery.passphrase,
  idToken: state.recovery.idToken
});

const mapDispatchToProps = {
  onSave: updateEmail.save,
  onSubmit: resetEmail.request,
  onSubmitEmailCheck: updateEmailCheck.request,
  updateEmailConfirm: updateEmailConfirm.request
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRecovery);
