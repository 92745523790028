import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { detect } from 'detect-browser';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as clipboard from 'clipboard-polyfill';

import { Page, PageMain, Button, PageFooter, CustomLink, Block } from '@ace/core-components';
import { iconTypes, Icon } from '@ace/core-visuals';
import { apiUserData, apiValidateTotp, apiSetupTotp } from '../../store/actions/user';
import { getUserData, getTotpSecret, getTotpQrCode, getTotpAppUri } from '../../store/reducers/mfa';
import PageHeader from '../../components/PageHeader';
import messages from './messages';

import GaInstructions from '../../components/GaInstructions';
import ValidateMfa from '../../components/ValidateMfa';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import TotpSecretCopiedPopup from './TotpSecretCopiedPopup';

import styles from './SetupTotp.module.scss';

class SetupTotpSecret extends PureComponent {
  browser = detect();

  state = {
    totpSecretCopiedPopup: false,
    showTotpPropmt: false
  };

  componentDidMount() {
    const { requestUserData, user, totpSecret, form, requestTotpSecret, match } = this.props;

    if (!user) {
      requestUserData();
    } else if (!totpSecret && match.isExact) {
      asyncFormSubmit(form, requestTotpSecret).catch(() => {});
    }
  }

  componentDidUpdate(prevProps) {
    const { user, form, requestTotpSecret, totpSecret, match } = this.props;
    if (!prevProps.user && Boolean(user) && !totpSecret && match.isExact) {
      asyncFormSubmit(form, requestTotpSecret).catch(() => {});
    }
  }

  onNextClick = () => {
    const {
      history,
      location,
      totpSecret,
      match: { url }
    } = this.props;
    if (totpSecret) {
      this.setState(
        {
          showTotpPropmt: true
        },
        () => {
          history.push({
            ...location,
            pathname: `${url}/validate`
          });
        }
      );
    }
  };

  onSubmit = formData => {
    const { requestTotpValidate, onSuccess, type } = this.props;
    return asyncFormSubmit(formData.form, requestTotpValidate, {
      ...formData.params,
      auth_method: type
    })
      .then(() => {
        if (formData.submitPromise) {
          formData.submitPromise.resolve();
        }
        onSuccess();
      })
      .catch(() => {
        if (formData.submitPromise) {
          formData.submitPromise.reject();
        }
      });
  };

  copyToClipboard = value => {
    clipboard.writeText(value);
    this.setState({ totpSecretCopiedPopup: true });
  };

  closeCopiedPopup = () => {
    this.setState({ totpSecretCopiedPopup: false });
  };

  render() {
    const { showTotpPropmt, totpSecretCopiedPopup } = this.state;
    const {
      intl: { formatMessage },
      match: { path, url },
      location,
      secretAppUri,
      qrCodeUrl,
      totpSecret
    } = this.props;
    const submitEnabled = Boolean(totpSecret);
    const title = formatMessage(messages.title);
    const renderPage = () => (
      <Page>
        <Helmet title={title} />
        <PageHeader
          title={title}
          intro={
            <>
              <span>
                <FormattedMessage
                  {...messages.para1}
                  values={{
                    link: secretAppUri && (
                      <CustomLink href={secretAppUri} target="_blank">
                        <FormattedMessage {...messages.clickHere} />
                      </CustomLink>
                    )
                  }}
                />
              </span>
              <br />
              <br />
              <span>
                <FormattedMessage
                  {...messages.para2}
                  values={{
                    secretKey:
                      (totpSecret && (
                        <b>
                          {totpSecret}{' '}
                          <span className={styles.iconWrapper}>
                            <Icon
                              onClick={() => this.copyToClipboard(totpSecret)}
                              type={iconTypes.copy}
                            />
                          </span>
                        </b>
                      )) ||
                      ''
                  }}
                />
              </span>
            </>
          }
        />
        <PageMain>
          {qrCodeUrl && (
            <Block>
              <img src={qrCodeUrl} alt="QR Code" />
            </Block>
          )}
          <TotpSecretCopiedPopup
            isActive={totpSecretCopiedPopup}
            onButtonClick={this.closeCopiedPopup}
            onClose={this.closeCopiedPopup}
          />
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.nextButton}>
            {label => (
              <Button
                disabled={!submitEnabled}
                onClick={this.onNextClick}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );

    return (
      <Switch>
        <Route
          path={`${path}/validate`}
          render={() => {
            if (!showTotpPropmt) {
              return <Redirect to={{ ...location, pathname: url }} />;
            }
            return <ValidateMfa onSubmit={this.onSubmit} method="totp" mode="validate" />;
          }}
        />
        <Route path={`${path}/resume`} render={renderPage} />
        <Route render={renderPage} />
      </Switch>
    );
  }
}

SetupTotpSecret.propTypes = {
  intl: intlShape.isRequired,
  qrCodeUrl: PropTypes.string,
  requestTotpSecret: PropTypes.func.isRequired,
  requestTotpValidate: PropTypes.func.isRequired,
  secretAppUri: PropTypes.string,
  totpSecret: PropTypes.string,
  onSuccess: PropTypes.func.isRequired
};

SetupTotpSecret.defaultProps = {
  totpSecret: '',
  qrCodeUrl: '',
  secretAppUri: ''
};

const mapStateToProps = state => ({
  user: getUserData(state),
  totpSecret: getTotpSecret(state),
  qrCodeUrl: getTotpQrCode(state),
  secretAppUri: getTotpAppUri(state)
});

const mapDispatchToProps = {
  requestTotpSecret: apiSetupTotp.request,
  requestUserData: apiUserData.request,
  requestTotpValidate: apiValidateTotp.request
};

const SetupTotpSecretConnected = injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupTotpSecret)
);

export default ({ location, match: { path, url }, history, ...rest }) => (
  <Switch>
    <Route
      exact
      path={path}
      render={() => (
        <GaInstructions onNext={() => history.push({ ...location, pathname: `${url}/secret` })} />
      )}
    />
    <Route
      path={`${path}/secret`}
      render={props => <SetupTotpSecretConnected {...rest} {...props} />}
    />
  </Switch>
);
