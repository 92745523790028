import {
  SETUP_PASSPHRASE,
  SETUP_TOTP,
  USER_DATA,
  VALIDATE_PASSPHRASE,
  VALIDATE_TOTP,
  REMOVE_BACKUP_AUTH_METHODS
} from '../actions/user';

export const getPassphrase = state => state.mfa.passphrase;
export const getTotpSecret = state => state.mfa.b32Secret;
export const getTotpQrCode = state => state.mfa.qrCodeUrl;
export const getTotpAppUri = state => state.mfa.appUri;
export const getUserData = state => state.mfa.userData;

const initialState = {
  passphrase: null,
  userData: null,
  b32Secret: null,
  qrCodeUrl: null,
  appUri: null
};

const mfa = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_PASSPHRASE.SUCCESS:
      return {
        ...state,
        passphrase: null
      };

    case VALIDATE_TOTP.SUCCESS:
      return {
        ...state,
        b32Secret: null,
        qrCodeUrl: null,
        appUri: null
      };

    case REMOVE_BACKUP_AUTH_METHODS.SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          [`${action.payload.method}_auth_method`]: false
        }
      };

    case SETUP_PASSPHRASE.SUCCESS:
      return {
        ...state,
        passphrase: action.payload
      };

    case USER_DATA.SUCCESS:
      return { ...state, userData: action.payload };

    case SETUP_TOTP.SUCCESS:
      return {
        ...state,
        b32Secret: action.payload.b32Secret,
        qrCodeUrl: action.payload.qrCodeUrl,
        appUri: action.payload.appUri
      };

    default:
      return state;
  }
};

export default mfa;
