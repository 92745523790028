import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import Block from '../structure/Block';
import MenuItem from '../MenuItem';

import s from './Menu.module.scss';

const Menu = ({ children, id, items, parentId, title, ...rest }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <Block id={thisId} title={title} {...rest}>
      <ul className={s.this}>
        {items &&
          items.map(item => (
            <MenuItem
              {...item}
              id={item.id || item.label}
              key={item.id || item.label}
              parentId={thisId}
            />
          ))}
        {children}
      </ul>
    </Block>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired])
        .isRequired
    })
  ),
  parentId: PropTypes.string,
  title: PropTypes.string
};

Menu.defaultProps = {
  children: null,
  id: 'menu',
  items: null,
  parentId: '',
  title: null
};

export default Menu;
