import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Please Authenticate.',
  para1:
    'After installing Google Authenticator, please scan this code. If you are setting it up on the same device, {link}',
  para2: 'You can also enter this secret key directly into the app: {secretKey}',
  clickHere: 'Click here',
  totpSecretCopiedPopup: '<strong>Success!</strong> The secret has been copied to your clipboard.',
  successStatus: 'Success!',
  nextButton: 'Next',
  okayButton: 'Okay'
});
