import { takeLatest, put, select, call } from 'redux-saga/effects';
import { stringify } from 'query-string';
import { SWITCH_LOCALE, switchLocale } from '../actions/locale';
import getParams from '../../utils/getParams';
import { locales } from '../../i18n';
import { getLocale } from '../reducers/locale';
import { post } from '../../utils/request';
import { getUserProfile, getUserProfileFromJwtToken } from '../reducers/profile';

const { apiBase } = CONFIG;

function* switchLocaleRequest({ payload }) {
  let locale = payload || getParams().locale || (yield select(getLocale));
  if (!locale || !locales.includes(locale)) {
    locale = CONFIG.defaultLocale;
  }
  yield put(switchLocale.success(locale));

  try {
    const profile = yield select(getUserProfile);
    const { exp } = yield select(getUserProfileFromJwtToken);

    if (!profile || Date.now() > exp * 1000) {
      return;
    }
    const queryParams = {
      locale,
      token: profile.token
    };
    yield call(post, `${apiBase}/save-locale?${stringify(queryParams)}`);
  } catch (e) {}
}

function* watchLocale() {
  yield takeLatest(SWITCH_LOCALE.REQUEST, switchLocaleRequest);
}

export default watchLocale;
