import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { components, imgPreview } from '@ace/core-utils';
import { Icon, iconTypes } from '@ace/core-visuals';
import FieldFileAtom from '../FieldFileAtom';
import validatorMedia from '../validators/validatorMedia';
import { fileSelect } from '../FieldFile/actions';
import s from './FieldHero.module.scss';

import i18n from './messages';

class FieldHero extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.objectUrl = null;
    this.state = {
      imagePreviewUrl: imgPreview(props.image).src
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.image !== this.props.image) {
      if (this.objectUrl) {
        URL.revokeObjectURL(this.objectUrl);
      }
      this.setState({
        imagePreviewUrl: imgPreview(newProps.image).src
      });
    }
  }

  componentWillUnmount() {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
  }

  async handleChange(ev, filelist) {
    ev.preventDefault();
    const { failureFileSelect, form, fieldName } = this.props;
    try {
      // Check file type & size
      const blob = await validatorMedia(filelist);
      if (!blob) {
        return;
      }
      URL.revokeObjectURL(this.objectUrl);
      // Set form value
      form.change(fieldName, blob);
      // Setup preview
      this.objectUrl = URL.createObjectURL(blob);
      this.setState({
        imagePreviewUrl: this.objectUrl
      });
    } catch (error) {
      failureFileSelect(error, { notification: error });
    }
  }

  handleUnwantedEvents(ev) {
    // Need to block all normal redux-form & html events
    ev.preventDefault();
  }

  render() {
    const {
      canEdit,
      fieldName,
      id,
      intl: { formatMessage },
      label,
      parentId
    } = this.props;
    const { imagePreviewUrl } = this.state;

    const thisId = components.generateId(id, parentId);

    let imageStyle = {};
    if (imagePreviewUrl) {
      imageStyle = {
        backgroundImage: `url(${imagePreviewUrl})`
      };
    }

    return (
      <div className={s.this} id={thisId} style={imageStyle}>
        {canEdit && (
          // eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
          <label className={s.upload}>
            <Field
              accept="image/*"
              className={s.input}
              component={FieldFileAtom}
              id={fieldName}
              name={fieldName}
              onBlur={this.handleUnwantedEvents}
              onChange={this.handleChange}
              onFocus={this.handleUnwantedEvents}
              parentId={thisId}
            />
            <span className={s.icon}>
              <Icon parentId={thisId} type={iconTypes.camera} />
            </span>
            {label && !imagePreviewUrl ? (
              <span className={s.label}>{label}</span>
            ) : (
              <span className={s.labelSr}>{formatMessage(i18n.label)}</span>
            )}
          </label>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  failureFileSelect: fileSelect.failure
};

FieldHero.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  failureFileSelect: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    change: PropTypes.func
  }),
  id: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  intl: intlShape.isRequired,
  label: PropTypes.string,
  parentId: PropTypes.string
};

FieldHero.defaultProps = {
  id: 'field-hero',
  image: null,
  form: {},
  label: null,
  parentId: ''
};

const FieldHeroConnected = connect(
  null,
  mapDispatchToProps
)(FieldHero);

export { FieldHeroConnected as FieldHeroWithoutIntl };

export default injectIntl(FieldHeroConnected);
