import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Log In.',
  intro: 'Please enter your email address and password to log in.',
  dontHaveAccount: "Don't have an account? {signupLink}",
  signupLink: 'Sign Up.',
  helpLink: 'Trouble logging in?',
  signInButton: 'Log In',
  helpPopup:
    'If you’ve forgotten your password, or lost access to your email address please click your option below.',
  notConfirmedPopup:
    'Sorry your email address hasn’t been verified yet, please tap request below to receive a new verification email.',
  notConfirmedPopupTitle: 'Email address hasn’t been verified yet.',
  recoverUsernameButton: 'Recover Username',
  requestButton: 'Request',
  resetEmailButton: 'Reset Email',
  resetPasswordButton: 'Reset Password'
});
