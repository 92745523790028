import axios from 'axios';
import _ from 'lodash';

// TODO: could be removed if we make it required to 'configure' the request with the store
axios.defaults.baseURL = CONFIG.apiBase;

let store = {};

export const configure = options => {
  axios.defaults.baseURL = options.baseURL;
  store = options.reduxStore;
};

export const API_NOT_AUTHORIZED = 'API_NOT_AUTHORIZED';

axios.interceptors.response.use(
  response => response, // No need to do anything
  error => {
    const jjError = error;
    // BE 'Not Authorised' returns status 403
    if (
      store &&
      store.dispatch &&
      error.response &&
      error.response.data &&
      error.response.data.code === 'TOKEN'
    ) {
      jjError._jjHandled = true;
      store.dispatch({ type: API_NOT_AUTHORIZED });
    }
    return Promise.reject(jjError);
  }
);

export const postFormData = (url, data, token, method = 'post') => {
  const formData = new FormData();
  _.forOwn(data, (value, key) => {
    if (_.isArray(value)) {
      formData.append(`${key}[]`, value);
    } else if (value) {
      formData.append(key, value);
    }
  });

  const payload = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData,
    url
  };
  if (token) {
    payload.headers.Authorization = `Bearer ${token}`;
  }
  return axios(payload);
};

export const post = (url, data, token, method = 'post') => {
  const payload = {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    data,
    url
  };
  if (token) {
    payload.headers.Authorization = `Bearer ${token}`;
  }
  return axios(payload);
};

export const get = (url, params, token) => {
  const payload = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'get',
    // onDownloadProgress: progressEvent => {
    //   console.log(progressEvent);
    // },
    // onUploadProgress: (progressEvent) => {
    //   console.log(progressEvent);
    // },
    params,
    url
  };
  if (token) {
    payload.headers.Authorization = `Bearer ${token}`;
  }
  return axios(payload);
};
