import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import ui from '@ace/core-components/src/ui/reducers';
import mfa from './reducers/mfa';
import locale from './reducers/locale';
import recovery from '../containers/AccountRecovery/reducer';
import profile from './reducers/profile';
import selection from './reducers/selection';
import install from './reducers/install';
import offlineCodes from './reducers/offlineCodes';
import authChallenge from './reducers/authChallenge';
import transactions from './reducers/transactions';

const appReducer = combineReducers({
  install,
  form,
  locale,
  mfa,
  profile,
  recovery,
  selection,
  ui,
  offlineCodes,
  authChallenge,
  transactions
});

export default appReducer;
