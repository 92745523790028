import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Save Passphrase',
  intro: 'You are about to be assigned a random, secure passphrase.',
  copyPassphrase: 'Copy Passphrase',
  passphraseCopiedPopup:
    '<strong>Success!</strong> The text version of your Passphrase has been copied to your clipboard. It is important to paste it somewhere safe.',
  successStatus: 'Success!',
  okayButton: 'Okay',
  finishButton: 'Finish'
});
