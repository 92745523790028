import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Page, PageFooter, PageMain, Button } from '@ace/core-components';
import PageHeader from '../../components/PageHeader';
import Text from '../../components/Text';
import i18n from './messages';

const MaintenanceScreen = ({ intl: { formatMessage } }) => (
  <Page>
    <FormattedMessage {...i18n.title}>{title => <Helmet title={title} />}</FormattedMessage>
    <PageHeader title={formatMessage(i18n.title)} />
    <PageMain>
      <Text theme="grey">
        <FormattedMessage {...i18n.messagei} />
      </Text>
      <Text theme="grey">
        <FormattedMessage {...i18n.messageii} />
      </Text>
      <Text theme="grey">
        <FormattedMessage {...i18n.messageiii} />
      </Text>
    </PageMain>
    <PageFooter>
      <FormattedMessage {...i18n.contactUsButton}>
        {buttonLabel => (
          <Button
            theme="primary"
            href="mailto:support@grc2u.net?subject=Interested in GRC Rewards"
            target="_blank"
            title={buttonLabel}
            type="submit"
            value={buttonLabel}
            view="cta"
          />
        )}
      </FormattedMessage>
    </PageFooter>
  </Page>
);

MaintenanceScreen.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(MaintenanceScreen);
