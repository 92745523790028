import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import Loadable from 'react-loadable';

import { Page, PageMain } from '@ace/core-components';

import PageHeader from '../../components/PageHeader';
import messages from './messages';

const GRCRules = ({ intl: { formatMessage, locale } }) => {
  const RulesContent = Loadable({
    loader: () => import(`./GRCRules-${locale}`),
    loading: () => null
  });
  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader title={formatMessage(messages.title)} />
      <PageMain>
        <RulesContent />
      </PageMain>
    </Page>
  );
};

GRCRules.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(GRCRules);
