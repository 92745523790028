import { defineMessages } from 'react-intl';

export default defineMessages({
  error404Title: 'Page not found',
  error404Intro: 'Page not found',
  USER_CONFIRMEDTitle: 'Error: Already verified.',
  USER_CONFIRMEDIntro:
    'This account has already been verified and it’s associated passphrase has already been generated and saved.',
  RESET_PASSWORDTitle: 'You already changed your password.',
  RESET_PASSWORDIntro: 'The password has already been changed.',
  EMAIL_VALIDATEDTitle: 'Already verified!',
  EMAIL_VALIDATEDIntro: 'Your email address has already been verified.'
});
