import _ from 'lodash';

// TODO: move the tokenising to when we're getting avatars
export const tokeniseAvatarUser = (user, mediaToken = '') =>
  // If we don't have a token or avatar, just return the user
  user.avatar && mediaToken
    ? {
        ...user,
        avatar: `${CONFIG.apiBase}/user/${user._id}/avatar?mediaToken=${mediaToken}&${user.avatar}`
      }
    : user;

export const tokeniseAvatarUsers = (users, mediaToken = '') =>
  _.isArray(users) ? users.map(user => tokeniseAvatarUser(user, mediaToken)) : users;
