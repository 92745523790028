import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Button } from '@ace/core-components';
import LanguageButton from '../LanguageButton';

import messages from './messages';
import s from './PageHeader.module.scss';

const PageHeader = ({ back, history, onBackClick, intl: { formatMessage }, intro, title }) => (
  <header className={s.this}>
    <div className={s.aside}>
      <LanguageButton />
    </div>
    <div className={s.main}>
      {back && (
        <div className={s.backButton}>
          <Button
            icon="navigationBack"
            onClick={() => (onBackClick ? onBackClick() : history.goBack())}
            theme="transparent"
            title={formatMessage(messages.backButton)}
            view="icon"
          />
        </div>
      )}
      <div className={s.content}>
        <h1 className={s.title}>{title}</h1>
        {intro && <p className={s.intro}>{intro}</p>}
      </div>
    </div>
  </header>
);

PageHeader.propTypes = {
  back: PropTypes.bool,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  intl: intlShape.isRequired,
  intro: PropTypes.node,
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func
};

PageHeader.defaultProps = {
  back: true,
  intro: undefined,
  onBackClick: null
};

export default injectIntl(withRouter(PageHeader));
