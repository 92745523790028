import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import { components } from '@ace/core-utils';

import { Icon, iconTypes } from '@ace/core-visuals';
import FieldFileAtom from '../FieldFileAtom';
import validatorMedia from '../validators/validatorMedia';
import { fileSelect } from './actions';
import s from './FieldFile.module.scss';

import i18n from './messages';

const FieldFileAdd = ({
  failureFileSelect,
  fieldName,
  form,
  id,
  intl: { formatMessage },
  parentId,
  ...rest
}) => {
  const thisId = components.generateId(id, parentId);

  const handleChange = async (ev, filelist, prevFilelist = []) => {
    ev.preventDefault();
    try {
      // Check file type & size
      const file = await validatorMedia(filelist, true);
      if (!file) {
        return;
      }
      form.change(fieldName, prevFilelist.concat(file));
    } catch (error) {
      failureFileSelect(error, { notification: error });
    }
  };

  const handleUnwantedEvents = ev => {
    // Need to block all normal redux-form & html events
    ev.preventDefault();
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
    <label className={s.this} id={thisId}>
      <span className={s.content}>
        <Field
          accept="image/x-png, image/jpeg, application/pdf"
          className={s.input}
          component={FieldFileAtom}
          id={fieldName}
          name={fieldName}
          onBlur={handleUnwantedEvents}
          onChange={handleChange}
          onFocus={handleUnwantedEvents}
          parentId={thisId}
          {...rest}
        />
        <span className={s.title}>
          <span className={s.icon}>
            <Icon parentId={thisId} type={iconTypes.navigationPlus} />
          </span>
          <span className={s.label}>{formatMessage(i18n.label)}</span>
        </span>
      </span>
    </label>
  );
};

const mapDispatchToProps = {
  failureFileSelect: fileSelect.failure
};

FieldFileAdd.propTypes = {
  failureFileSelect: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string
};

FieldFileAdd.defaultProps = {
  id: 'field-file-add',
  parentId: ''
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(FieldFileAdd)
);
