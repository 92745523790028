import validatorFileSize from './validatorFileSize';
import validatorFileType from './validatorFileType';
import validatorImgDimensions from './validatorImgDimensions';

const getCanvasBlob = async canvas =>
  new Promise(resolve => {
    canvas.toBlob(
      blob => {
        resolve(blob);
      },
      'image/jpeg', // Type
      0.9 // Quality
    );
  });

const validatorMedia = async (filelist, allowPDF) => {
  const file = filelist[0];
  if (!file) {
    return false;
  }
  const formats = ['jpg', 'jpeg', 'png'];
  const maxFileSize = 8; // MB

  if (allowPDF) {
    formats.push('pdf');
  }
  validatorFileType(file, formats);
  validatorFileSize(file, maxFileSize);
  if (file.type !== 'application/pdf') {
    const canvas = await validatorImgDimensions(file);
    const blob = await getCanvasBlob(canvas);
    blob.name = file.name;
    return blob;
  }
  return file;
};

export default validatorMedia;
