import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Popup, ButtonList } from '@ace/core-components';
import messages from './messages';

const PassphraseCopiedPopup = ({ intl: { formatMessage }, isActive, onClose, onButtonClick }) => {
  const setupButtonText = formatMessage(messages.setupButton);
  const skipButtonText = formatMessage(messages.skipButton);
  const buttons = [
    {
      onClick: () => onButtonClick(),
      theme: 'primary',
      title: setupButtonText,
      value: setupButtonText,
      view: 'cta'
    },
    {
      onClick: () => onClose(),
      theme: 'success',
      title: skipButtonText,
      value: skipButtonText,
      view: 'cta'
    }
  ].filter(Boolean);
  return (
    <Popup
      intro={formatMessage(messages.text)}
      isActive={isActive}
      onClose={onClose}
      theme="success"
      title={formatMessage(messages.title)}
    >
      <ButtonList buttons={buttons} />
    </Popup>
  );
};

PassphraseCopiedPopup.propTypes = {
  intl: intlShape.isRequired,
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

PassphraseCopiedPopup.defaultProps = {
  isActive: false
};

export default injectIntl(PassphraseCopiedPopup);
