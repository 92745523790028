import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import * as rfv from 'redux-form-validators';
import _ from 'lodash';
import { iconTypes } from '@ace/core-visuals';
import { components } from '@ace/core-utils';
import { sortItems, matchItemToTerm } from './fieldSelectUtils';
// TODO: switch back to npm module but see: https://github.com/reactjs/react-autocomplete/issues/289
import Autocomplete from '../Autocomplete';
import Button from '../../Button';
import InputField from '../FieldInput/InputField';
import Menu from '../../Menu';
import MenuItem from '../../MenuItem';
import Modal from '../../Modal';
import NoResults from '../../NoResults';
import s from './FieldSelect.module.scss';

import i18n from './messages';

class FieldSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.onAutoInputChange = this.onAutoInputChange.bind(this);
    this.onAutoInputSelect = this.onAutoInputSelect.bind(this);
    this.onClose = this.onClose.bind(this);
    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
    this.shouldItemRender = this.shouldItemRender.bind(this);
    this.sortItems = this.sortItems.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.handleFormat = this.handleFormat.bind(this);
    this.handleParse = this.handleParse.bind(this);

    this.optionsHaveValueAndLabel = this.checkOptionsIsArrayWithLabelAndValue(props);
    this.setDefaultValues(props);
    this.state = {
      modalActive: false
    };
  }

  componentWillReceiveProps(newProps) {
    this.optionsHaveValueAndLabel = this.checkOptionsIsArrayWithLabelAndValue(newProps);
  }

  onAutoInputChange(e) {
    const { value } = e.target;
    // Here we update the store as the user is typing so other components can listen to the field changing (such as the address predictons service)
    // This is not the final value that gets stored, once an item is select
    this.props.form.change(this.props.name, value);
  }

  onAutoInputSelect(autoInputValue, item) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(item);
    }

    // Handle when item is selected
    this.updateStore(autoInputValue);
    this.deactivateModal();
  }

  onClose() {
    this.deactivateModal();
  }

  setDefaultValues(props) {
    const { id, parentId } = props;
    this.id = components.generateId(id, parentId);
  }

  activateModal() {
    this.cacheScroll = document.documentElement.scrollTop || document.body.scrollTop;
    this.setState({ modalActive: true }, () => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    });
  }

  deactivateModal() {
    this.setState({ modalActive: false }, () => {
      window.scrollTo({
        top: this.cacheScroll,
        behavior: 'instant'
      });
    });
  }

  shouldItemRender(item, value) {
    if (!item.label || !item.value) {
      return false;
    }
    if (this.props.sort && this.props.autocomplete) {
      return matchItemToTerm(item, value);
    }
    return true;
  }

  sortItems(itemA, itemB, value) {
    if (this.props.sort && this.props.autocomplete) {
      return sortItems(itemA, itemB, value);
    }
    return true;
  }

  checkOptionsIsArrayWithLabelAndValue({ options }) {
    if (!_.isArray(options) || !options[0]) {
      return false;
    }
    if (!options[0].label || !options[0].value) {
      return false;
    }
    return true;
  }

  handleFormat(value) {
    // See: https://redux-form.com/7.4.2/docs/valuelifecycle.md/
    const { options } = this.props;
    if (this.optionsHaveValueAndLabel) {
      const selected = options.filter(option => option.value === value)[0];
      if (selected && selected.label) {
        return selected.label;
      }
    }
    return value || '';
  }

  handleParse(inputValue) {
    // See: https://redux-form.com/7.4.2/docs/valuelifecycle.md/
    let parsedValue = inputValue;
    let selectedItem;
    let parsedSelected = { label: '', value: '' }; // Sets a blank options object as default
    const { options } = this.props;
    // If we've been supplied with a valid options object, filter it based on its label matching the input value
    if (this.optionsHaveValueAndLabel) {
      [selectedItem] = options.filter(option => option.label === inputValue);
    }
    // If there is a matching item, set the stored value in Redux Forms to be the item value and...
    if (selectedItem && selectedItem.value) {
      parsedValue = selectedItem.value;
      parsedSelected = selectedItem;
    }
    // ...store the whole item too
    this.props.form.change(`${this.props.name}SelectedItem`, parsedSelected);
    return parsedValue || '';
  }

  updateStore(value) {
    const storedValue = this.handleParse(value);
    this.props.form.change(this.props.name, storedValue);
  }

  renderEmptyInput(props) {
    return <div {...props} />;
  }

  renderInput(props) {
    return (
      <div className={s.autocompleteFieldInput}>
        <input {...props} />
      </div>
    );
  }

  renderItem(item, isHighlighted) {
    return (
      <MenuItem
        {...item}
        id={item.id || item.value}
        isHighlighted={isHighlighted}
        key={item.id || item.value}
        parentId={this.id}
      />
    );
  }

  renderMenu(items) {
    const { noResultsMessage, noResultsTitle } = this.props;
    // Needs to be in a div because of refs
    if (items.length === 0 && this.props.value) {
      return (
        <div>
          <NoResults
            message={noResultsMessage}
            parentId={this.id}
            title={noResultsTitle}
            view="select"
          />
        </div>
      );
    }
    return (
      <div className={s.options}>
        <Menu parentId={this.id} pv="none">
          {items}
        </Menu>
      </div>
    );
  }

  render() {
    const {
      autocomplete,
      autocompletePlaceholder,
      form,
      id,
      intl: { formatMessage },
      label,
      modalTitle,
      name,
      options,
      parentId,
      placeholder,
      required,
      sort,
      text,
      title,
      value,
      ...rest
    } = this.props;

    return (
      <div className={s.this} id={this.id}>
        {text ? (
          <button className={s.textButton} type="button" onClick={this.activateModal} title={title}>
            {text}
          </button>
        ) : (
          <Field
            component={InputField}
            form={form.form}
            format={this.handleFormat}
            id={name}
            label={label}
            name={name}
            onClick={this.activateModal}
            parentId={this.id}
            placeholder={placeholder}
            title={title}
            type="select"
            parse={this.handleParse}
            validate={required ? [rfv.required()] : undefined}
            {...rest}
          />
        )}
        <Modal
          fullscreen
          initialFocus={`#autocomplete-${name}`}
          mounted={this.state.modalActive}
          titleText={title}
        >
          <div className={s.modalHeader}>
            <div className={s.modalAside}>
              <Button
                icon={iconTypes.navigationClose}
                onClick={this.onClose}
                parentId={this.id}
                theme="transparent"
                title={formatMessage(i18n.closeButtonTitle)}
                view="icon"
              />
            </div>
            {modalTitle && (
              <div className={s.modalHeaderMain}>
                <h1 className={s.modalTitle}>{modalTitle}</h1>
              </div>
            )}
            <div className={s.modalAside} />
          </div>
          <Autocomplete
            getItemValue={item => item.value}
            inputProps={{
              className: s.autocompleteInput,
              component: 'input',
              form: form.form,
              id: `autocomplete-${name}`,
              name: `autocomplete-${name}`,
              onKeyUp: this.onAutoInputKeyUp,
              placeholder: autocompletePlaceholder || placeholder,
              type: 'search'
            }}
            items={options}
            onChange={this.onAutoInputChange}
            onSelect={this.onAutoInputSelect}
            open
            renderInput={autocomplete ? this.renderInput : this.renderEmptyInput}
            renderItem={this.renderItem}
            renderMenu={this.renderMenu}
            shouldItemRender={this.shouldItemRender}
            sortItems={this.sortItems}
            value={value}
            wrapperProps={{
              className: s.modalMain
            }}
            wrapperStyle={{}}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const valueSelector = formValueSelector(ownProps.form.form);
  return {
    value: valueSelector(state, ownProps.name)
  };
};

FieldSelect.propTypes = {
  autocomplete: PropTypes.bool,
  autocompletePlaceholder: PropTypes.string,
  form: PropTypes.shape({
    change: PropTypes.func
  }),
  id: PropTypes.string,
  intl: intlShape.isRequired,
  label: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string,
  noResultsTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  parentId: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sort: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func
};

FieldSelect.defaultProps = {
  autocompletePlaceholder: '',
  form: {},
  autocomplete: false,
  id: 'field-select',
  modalTitle: undefined,
  noResultsMessage: '',
  noResultsTitle: '',
  onChange: null,
  parentId: '',
  required: false,
  text: undefined,
  sort: true,
  value: ''
};

export default injectIntl(connect(mapStateToProps)(FieldSelect));
