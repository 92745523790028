import _ from 'lodash';
import { numbers, roundGeoInLocation } from '@ace/core-utils';
import {
  API_BUSINESS_REGISTER,
  API_BUSINESS_LOCATION,
  API_BUSINESS_LOCATION_PATCH,
  API_BUSINESS_LOCATION_CURRENCY_PATCH,
  API_EDIT_BUSINESS_REWARD
} from '../actions';

const initialState = {
  PDF: [],
  _id: '',
  currency: CONFIG.currencies[0] ? CONFIG.currencies[0].value : null,
  logo: '',
  name: ''
};

export const getLocation = state => state.location;
export const getLocationCurrency = state => getLocation(state).currency;
export const getLocationLogo = state => getLocation(state).logo;

export const getEditableBusinessProfile = state => {
  const location = getLocation(state);
  let values = _.pick(location, [
    'logo',
    'address',
    'currency',
    'locationType',
    'description',
    'geolocation',
    'phone',
    'email',
    'name',
    'PDF',
    'images'
  ]);
  if (_.isArray(values.phone)) {
    const [phone] = values.phone;
    values.phone = phone;
  }
  if (_.isArray(values.images)) {
    const [image] = values.images;
    values.image = image;
    delete values.images;
  }
  if (values.geolocation) {
    values = roundGeoInLocation(values);
  }
  return values;
};

export const getEditableReward = state => {
  const location = getLocation(state);
  return _.pick(location, ['rewardPct']);
};

const normaliseBusinessLocation = location => {
  // Fix for https://flgalaxy.atlassian.net/browse/JJA-1756
  // Could be removed if we switch to using only integers for rewardPct
  const normalisedLocation = location;
  if (normalisedLocation.rewardPct) {
    normalisedLocation.rewardPct = numbers.formatPct(normalisedLocation.rewardPct);
  }
  return normalisedLocation;
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case API_BUSINESS_LOCATION_PATCH.SUCCESS:
    case API_BUSINESS_LOCATION_CURRENCY_PATCH.SUCCESS:
    case API_EDIT_BUSINESS_REWARD.SUCCESS:
    case API_BUSINESS_LOCATION.SUCCESS:
    case API_BUSINESS_REGISTER.SUCCESS:
      return {
        ...state,
        ...normaliseBusinessLocation(action.payload.location)
      };

    default:
      return state;
  }
};

export default location;
