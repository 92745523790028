import PropTypes from 'prop-types';

export const locationRecord = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  addressHtml: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  geolocation: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
  }).isRequired,
  googlePlaceId: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationType: PropTypes.arrayOf(PropTypes.string.isRequired),
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  PDF: PropTypes.arrayOf(PropTypes.string).isRequired,
  phone: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  reviewCount: PropTypes.number.isRequired,
  reviewPercent: PropTypes.number.isRequired,
  rewardPct: PropTypes.string.isRequired
});
