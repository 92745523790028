import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Please confirm.',
  para1: 'Enter offline code',
  para2SignInMfaConfirmMode:
    'It looks like you are trying to login to GRC from a new device and location. Please confirm your identity via Primary or Backup authentication methods',
  confirmLabel: 'Enter offline code',
  nextButton: 'Confirm',
  useBackupMethod: 'Don’t Remember? Try to use Backup method',
  usePrimaryMethod: 'Don’t Remember? Try to use Primary method'
});
