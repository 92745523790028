import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Block, Button, CustomLink, Page, PageFooter, PageMain, Popup } from '@ace/core-components';
import { iconTypes, Icon } from '@ace/core-visuals';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import * as clipboard from 'clipboard-polyfill';

import { getCodes } from '../../store/reducers/offlineCodes';
import PageHeader from '../../components/PageHeader';
import OfflineCodesList from './components/Menu';
import messages from './messages';
import { apiOfflineCodes } from '../../store/actions/user';
import styles from './OfflineCodes.module.scss';

class OfflineCodes extends Component {
  constructor(props) {
    super(props);
    this.browser = detect();
    this.state = {
      copied: false,
    };
  }

  componentDidMount = () => {
    const { requestOfflineCodes, type, regenerate } = this.props;
    requestOfflineCodes({ params: { regenerate, auth_method: type } });
  };

  onClose = () => {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    } else {
      this.props.history.goBack();
    }
  };

  regenerateCodes = () => {
    this.props.requestOfflineCodes({ params: { auth_method: this.props.type, regenerate: true } });
  };

  copyToClipboard = value => {
    clipboard.writeText(value);
    this.setState({ copied: true });
  };

  render() {
    const {
      intl: { formatMessage },
      codes,
      submitting,
      regenerate,
    } = this.props;
    const notUsedCount = (codes || []).filter(c => !c.used).length;

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader
          intro={formatMessage(regenerate ? messages.introNew : messages.intro)}
          title={formatMessage(messages.title)}
        />
        <PageMain>
          <section className={styles['info-block']}>
            <Block>
              <FormattedMessage
                {...messages.remaining}
                values={{ notUsedCount, usedCount: (codes || []).length }}
              />
            </Block>
            <Block>
              <FormattedMessage {...messages.getNewCodes}>
                {label => (
                  <CustomLink onClick={() => this.regenerateCodes()}>
                    <span className={styles['link-with-icon']}>
                      <span className={styles.icon}>
                        <Icon type={iconTypes.refresh} />
                      </span>
                      {label}
                    </span>
                  </CustomLink>
                )}
              </FormattedMessage>
              <FormattedMessage {...messages.printCodes}>
                {label => (
                  <CustomLink
                    onClick={e => {
                      window.print();
                      e.preventDefault();
                    }}
                  >
                    <span className={styles['link-with-icon']}>
                      <span className={styles.icon}>
                        <Icon type={iconTypes.print} />
                      </span>
                      {label}
                    </span>
                  </CustomLink>
                )}
              </FormattedMessage>
            </Block>
          </section>
          <OfflineCodesList
            items={(codes || []).map((item, i) => ({
              active: !item.used,
              'data-used': item.used,
              key: i,
              label: (
                <>
                  {item.code}
                  <span className={styles['copy-icon']}>
                    <Icon onClick={() => this.copyToClipboard(item.code)} type={iconTypes.copy} />
                  </span>
                </>
              ),
              onClick: () => this.copyToClipboard(item.code),
            }))}
          />
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.closeButton}>
            {label => (
              <Button
                disabled={submitting}
                onClick={this.onClose}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
        <Popup
          intro={formatMessage(messages.copied)}
          isActive={this.state.copied}
          onClose={() => this.setState({ copied: false })}
          theme="success"
          title={formatMessage(messages.successStatus)}
        >
          <Button
            onClick={() => this.setState({ copied: false })}
            theme="primary"
            title={formatMessage(messages.copied)}
            value={formatMessage(messages.okayButton)}
            view="cta"
          />
        </Popup>
      </Page>
    );
  }
}

OfflineCodes.propTypes = {
  intl: intlShape.isRequired,
  regenerate: PropTypes.bool,
  requestOfflineCodes: PropTypes.func.isRequired,
};

OfflineCodes.defaultProps = {
  regenerate: false,
};

const mapStateToProps = state => ({
  codes: getCodes(state),
});

const mapDispatchToProps = {
  requestOfflineCodes: apiOfflineCodes.request,
};

const OfflineCodesConnected = connect(mapStateToProps, mapDispatchToProps)(OfflineCodes);

export default injectIntl(OfflineCodesConnected);
