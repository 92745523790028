import React from 'react';
import PropTypes from 'prop-types';

import s from './Form.module.scss';

const Form = ({ children, ...props }) => (
  <form className={s.this} {...props}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired
};

export default Form;
