import React, { Component } from 'react';
import { Block, Button, CustomLink, Page, PageFooter, PageMain } from '@ace/core-components';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { detect } from 'detect-browser';

import Text from '../Text';
import ListItemBig from '../ListItemBig';
import PageHeader from '../PageHeader';
import Icon from '../Icon';
import messages from './messages';

const AUTHENTICATOR_ANDROID =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
const AUTHENTICATOR_IOS = 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8';

class GaInstructions extends Component {
  browser = detect();

  render() {
    const {
      intl: { formatMessage },
      onNext
    } = this.props;

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          <ListItemBig id="create-wallet" marker={1}>
            <Text theme="grey-no-margin">
              <FormattedMessage
                {...messages.par1}
                values={{
                  googleAuthenticator: (
                    <CustomLink
                      to={{
                        pathname: `${
                          this.browser.os === 'iOS' ? AUTHENTICATOR_IOS : AUTHENTICATOR_ANDROID
                        }`
                      }}
                      target="_blank"
                    >
                      <FormattedMessage {...messages.googleAuthenticator} />
                    </CustomLink>
                  )
                }}
              />
            </Text>
            <Block pv="small" />
            <Text>
              <Icon icon="ga-phone" />
            </Text>
            <Block pv="small" />
          </ListItemBig>
          <ListItemBig id="par2" marker={2}>
            <Text theme="grey-no-margin">
              <FormattedMessage {...messages.par2} />
            </Text>
            <Block pv="small" />
            <Text>
              <Icon icon="ga-success" />
            </Text>
            <Block pv="small" />
          </ListItemBig>
          <ListItemBig id="par3" marker={3}>
            <Text theme="grey-no-margin">
              <FormattedMessage {...messages.par3} />
            </Text>
            <Block pv="small" />
            <Text>
              <Icon icon="ga-clock" />
            </Text>
            <Block pv="small" />
          </ListItemBig>
        </PageMain>
        <PageFooter>
          <FormattedMessage {...messages.nextButton}>
            {label => (
              <Button
                onClick={() => onNext()}
                theme="primary"
                title={label}
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

GaInstructions.defaultProps = {};

GaInstructions.propTypes = {
  intl: intlShape.isRequired,
  onNext: PropTypes.func.isRequired
};

export default injectIntl(GaInstructions);
