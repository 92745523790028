import root from 'window-or-global';

const reportingMiddleware = () => next => action => {
  // Only gets run if Raven is loaded on the Window and configured in the store middleware
  if (action.meta && action.meta.reporting) {
    root.Raven.captureException(action.meta.reporting);
    action.meta.reportingEventId = root.Raven.lastEventId(); // eslint-disable-line no-param-reassign
  }
  return next(action);
};

export default reportingMiddleware;
