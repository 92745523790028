import { defineMessages } from 'react-intl';

export default defineMessages({
  authApp: 'Authenticator App',
  installed: 'Installed',
  nextButton: 'Next',
  offlineCodes: 'Offline Codes',
  para1: 'For primary, you can choose between an authenticator app and a secure passphrase.',
  para2: 'the authentication information on your device!',
  passphrase: 'Passphrase',
  phone: 'Text Message',
  skipButton: 'Skip for now',
  title: 'Primary Method.',
  viewCodes: 'View Codes',
  warn: 'Please remember to store',
});
