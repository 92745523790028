import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes, Field, Form, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { Page, PageFooter, PageMain, Button, FieldInputs, CustomLink } from '@ace/core-components';
import { Icon, iconTypes } from '@ace/core-visuals';

import Block from '../Block';
import messages from './messages';
import PageHeader from '../PageHeader';
import SecurityMethodsInfoLink from '../SecurityMethodsInfoLink';

import styles from './FillOfflineCode.module.scss';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

class FillOfflineCode extends PureComponent {
  _onSubmit = params => asyncFormSubmit(this.props.form, this.props.onSubmit, params);

  render() {
    const {
      intl: { formatMessage },
      form,
      invalid,
      offlineCode,
      handleSubmit,
      alternative,
      onAlternative,
      submitting,
      mode,
    } = this.props;

    const isConfirmSigninMfaMode = mode === 'confirm-signin-mfa';
    let intro = formatMessage(messages.para1);

    if (isConfirmSigninMfaMode) {
      intro = (
        <>
          <FormattedMessage {...messages.para1} />
          <br />
          <br />
          <FormattedMessage {...messages.para2SignInMfaConfirmMode} />
        </>
      );
    }

    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={intro} title={formatMessage(messages.title)} />
        <PageMain>
          <div className={styles['confirm-code']}>
            <Form id={form} onSubmit={handleSubmit(this._onSubmit)}>
              <Block>
                <section className={styles['text-field-block']}>
                  <FormattedMessage {...messages.confirmLabel}>
                    {label => (
                      <React.Fragment>
                        <span className={styles.icon}>
                          <Icon
                            id="item-icon"
                            parentId="parent-id"
                            type={iconTypes.menuOfflineCodes}
                          />
                        </span>
                        <Field
                          component={FieldInputs.OfflineCode}
                          label={label}
                          name="offlineCode"
                        />
                      </React.Fragment>
                    )}
                  </FormattedMessage>
                </section>
              </Block>
            </Form>
          </div>
        </PageMain>
        <PageFooter>
          {alternative && (
            <Block spacing="sm" align="center">
              <CustomLink onClick={() => onAlternative()} className={styles.smallFont}>
                {alternative === 'backup'
                  ? formatMessage(messages.useBackupMethod)
                  : formatMessage(messages.usePrimaryMethod)}
              </CustomLink>
            </Block>
          )}
          <Block spacing="sm" align="center">
            <SecurityMethodsInfoLink />
          </Block>
          <FormattedMessage {...messages.nextButton}>
            {label => (
              <Button
                disabled={!(offlineCode && offlineCode.length >= 6) || invalid}
                form={form}
                submitting={submitting}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

FillOfflineCode.propTypes = {
  ...propTypes,
  alternative: PropTypes.oneOf(['primary', 'backup', 'confirm-signin-mfa']),
  intl: intlShape.isRequired,
  onAlternative: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};
FillOfflineCode.defaultProps = {
  alternative: null,
  onAlternative: () => {},
};

const getFormValue = formValueSelector(CONFIG.formNames.validatemfa);

const FillTotpForm = reduxForm({
  form: CONFIG.formNames.validatemfa,
})(FillOfflineCode);

const mapStateToProps = state => ({
  offlineCode: getFormValue(state, 'offlineCode'),
});

const FillTotpFormConnected = connect(mapStateToProps)(FillTotpForm);

export default injectIntl(FillTotpFormConnected);
