import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Illustration } from '@ace/core-visuals';
import Block from '../../structure/Block';
import CustomLink from '../../CustomLink';
import Page from '../../structure/Page';
import PageHeader from '../../structure/PageHeader';
import PageMain from '../../structure/PageMain';
import TextBlock from '../../structure/TextBlock';

import i18n from './messages';
// TODO DELETE - CHECK ACE
const ScreenVerify = ({ intl: { formatMessage } }) => (
  <Page>
    <PageHeader title={formatMessage(i18n.title)} />
    <PageMain>
      <TextBlock>
        <FormattedMessage
          {...i18n.text}
          values={{
            resendEmailLink: (
              <CustomLink onClick={() => {}}>{formatMessage(i18n.clickHere)}</CustomLink>
            )
          }}
        />
      </TextBlock>
      <Block view="image">
        <Illustration title={formatMessage(i18n.illustrationTitle)} type="verify" />
      </Block>
      <TextBlock>
        <FormattedMessage
          {...i18n.alreadyVerifiedText}
          values={{
            refreshLink: (
              <CustomLink onClick={() => window.location.reload()}>
                {formatMessage(i18n.clickHere)}
              </CustomLink>
            )
          }}
        />
      </TextBlock>
    </PageMain>
  </Page>
);

ScreenVerify.propTypes = {
  apiRequestResendVerificationEmail: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ScreenVerify);
