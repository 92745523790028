import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import { Icon, iconTypes } from '@ace/core-visuals';
import Modal from '../Modal';
import logo from './logo.svg';
import s from './AddToHomescreen.module.scss';
import PageHeader from '../structure/PageHeader';
import i18n from './messages';
import { appInstaller, appInstallerCount } from './actions';
import { getEnableIOSInstallableCount, getDisplayIOSInstaller } from './reducers';

class AddToHomescreen extends PureComponent {
  constructor() {
    super();
    this.state = {
      deferredPrompt: null
    };
    this.handlePrompt = this.handlePrompt.bind(this);
    this.handlePromptChoice = this.handlePromptChoice.bind(this);
    this.isCountUrl = this.isCountUrl.bind(this);
    this.maybeIncrementCount = this.maybeIncrementCount.bind(this);
    this.renderIOSPrompt = this.renderIOSPrompt.bind(this);
  }

  componentDidMount() {
    // Always listen for Chrome / Android Add To Homescreen prompts, so we can capture and store the event to display when we want
    // We'll rely on their native logic for displaying, so long as user is on one of our 'counted' urls
    window.addEventListener('beforeinstallprompt', this.handlePrompt);
    if (this.props.enableIOSInstallableCount) {
      // Start the saga listening for count events on iOS
      this.props.requestAppInstallerCount();
      // Maybe increment the count of interested URLs
      this.maybeIncrementCount();
    }
  }

  componentDidUpdate() {
    if (this.props.enableIOSInstallableCount) {
      this.maybeIncrementCount();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handlePrompt);
  }

  maybeIncrementCount() {
    if (this.isCountUrl()) {
      // If we're on a counting url, increment the count
      this.props.saveAppInstallerCount();
    }
  }

  handlePrompt(deferredPrompt) {
    // Store the native prompt event
    deferredPrompt.preventDefault();
    this.setState(
      {
        deferredPrompt
      },
      this.handlePromptChoice
    );
  }

  handlePromptChoice() {
    this.state.deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        // console.log('User accepted the A2HS prompt');
      } else {
        // console.log('User dismissed the A2HS prompt');
      }
      // Set the deferred prompt back to null
      this.setState({
        deferredPrompt: null
      });
    });
  }

  isCountUrl() {
    // Check if the current url is one we're interested in
    return _.some(this.props.countUrlsRegex, urlRegex =>
      urlRegex.test(this.props.location.pathname)
    );
  }

  renderIOSPrompt() {
    const {
      closeAppInstaller,
      id,
      intl: { formatMessage },
      parentId
    } = this.props;
    const thisId = components.generateId(id, parentId);
    const closeButton = {
      icon: iconTypes.navigationClose,
      title: formatMessage(i18n.closeButtonTitle),
      onClick: closeAppInstaller
    };

    return (
      <Modal titleText={formatMessage(i18n.title)}>
        <div className={s.this} id={thisId}>
          <PageHeader back={false} border title={formatMessage(i18n.title)} next={closeButton} />
          <div className={s.content}>
            <div className={s.text}>
              <FormattedHTMLMessage {...i18n.intro} />
            </div>
            <img src={logo} alt={formatMessage(i18n.logoAlt)} className={s.logo} />
          </div>
          <div className={s.hint}>
            <FormattedMessage
              {...i18n.instruction}
              values={{
                shareIcon: (
                  <span className={s.icon}>
                    <Icon parentId={thisId} type={iconTypes.iOSShare} />
                  </span>
                ),
                addToHomeScreen: <b>{formatMessage(i18n.iOSAddToHomeScreen)}</b>
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { displayIOSInstaller } = this.props;

    if (!this.isCountUrl()) {
      return null;
    }
    // All non-iOS devices will do this
    if (this.state.deferredPrompt) {
      this.state.deferredPrompt.prompt();
    }
    // For iOS devices
    if (displayIOSInstaller) {
      return this.renderIOSPrompt();
    }

    return null;
  }
}

const mapStateToProps = state => ({
  displayIOSInstaller: getDisplayIOSInstaller(state),
  enableIOSInstallableCount: getEnableIOSInstallableCount(state)
});

const mapDispatchToProps = {
  closeAppInstaller: appInstaller.close,
  requestAppInstallerCount: appInstallerCount.request,
  saveAppInstallerCount: appInstallerCount.save
};

AddToHomescreen.propTypes = {
  closeAppInstaller: PropTypes.func.isRequired,
  // Should be a RegEx
  countUrlsRegex: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayIOSInstaller: PropTypes.bool.isRequired,
  enableIOSInstallableCount: PropTypes.bool.isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  parentId: PropTypes.string,
  saveAppInstallerCount: PropTypes.func.isRequired
};

AddToHomescreen.defaultProps = {
  id: 'add-to-homescreen',
  parentId: ''
};

const AddToHomescreenConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToHomescreen);

export { AddToHomescreen as AddToHomescreenWithoutConnectedIntl };

export default injectIntl(withRouter(AddToHomescreenConnected));
