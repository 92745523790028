const AppRoutes = {
  elevate: {
    index: '/elevate',
  },

  email: {
    change: {
      checkEmail: '/email-change-check-email',
      confirm: '/email-change-confirm',
      index: '/email-change',
    },
    index: '/check-email',
    reset: '/email-reset',
    update: {
      link: {
        expired: '/update-email-link-expired',
      },
    },
  },
  error: {
    index: '/error',
  },
  grcRules: {
    index: '/grc-rules',
  },
  locale: {
    index: '/locale',
  },
  mfaAuthChallenge: {
    index: '/mfa',
  },
  offlineCodes: {
    index: '/offline-codes',
    regenerate: '/offline-codes-regenerate',
  },
  passphrase: {
    reset: {
      index: '/passphrase-reset',
      link: {
        expired: '/passphrase-reset-link-expired',
      },
    },
    setupMfa: '/setup-mfa',
  },
  password: {
    change: {
      checkEmail: '/password-change-check-email',
      index: '/password-change',
    },
    reset: {
      confirm: '/password-reset-confirm',
      index: '/password-reset',
      link: {
        expired: '/password-reset-link-expired',
      },
    },
  },
  signin: {
    backupWarning: '/signin/backup-warning',
    index: '/signin',
  },
  signout: {
    index: '/signout',
  },
  signup: {
    confirm: {
      index: '/signup-confirm',
    },
    extraSecurity: {
      backup: '/extra-security/backup',
      index: '/extra-security',
      info: '/extra-security/info',
      primary: '/extra-security/primary',
    },
    index: '/signup',
    link: {
      expired: '/signup-link-expired',
    },
    plan: '/signup-plan',
    success: '/signup-success',
  },
  username: {
    change: {
      index: '/username-change',
      success: '/username-change-success',
    },
    recover: {
      index: '/username-recover',
      success: '/username-recover-success',
    },
  },
};

export default AppRoutes;
