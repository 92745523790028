import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { components } from '@ace/core-utils';
import * as actions from '../ui/actions';
import { getNotifications } from '../ui/reducers';
import s from './Notifications.module.scss';
import Modal from '../Modal';
import Notification from '../Notification';
import i18n from './messages';

const Notifications = ({
  id,
  intl: { formatMessage },
  notifications,
  parentId,
  removeNotification
}) => {
  if (notifications.length <= 0) {
    return null;
  }

  const thisId = components.generateId(id, parentId);

  return (
    <Modal alert titleText={formatMessage(i18n.popupTitle)}>
      <div className={s.this} id={thisId}>
        <ul className={s.list}>
          {notifications.map((item, index) => {
            if (index >= 2) {
              return null;
            }
            return (
              <li key={item.code || item.message} className={s.item}>
                <Notification
                  notification={item}
                  id={item.code || item.message}
                  index={index}
                  parentId={thisId}
                  onDismiss={removeNotification}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

const mapDispatchToProps = {
  removeNotification: actions.uiNotification.remove
};

Notifications.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string
    })
  ).isRequired,
  parentId: PropTypes.string,
  removeNotification: PropTypes.func.isRequired
};

Notifications.defaultProps = {
  id: 'notifications',
  parentId: ''
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Notifications)
  )
);
