import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import Block from '../Block';

import s from './TextBlock.module.scss';

const TextBlock = ({ align, children, id, parentId, tag, title, view, ...rest }) => {
  const thisId = components.generateId(id, parentId);
  const ContentTag = view === 'html' ? 'article' : tag;
  const contentClass = classNames({
    [s[view]]: true,
    [s._leftAlign]: align === 'left' || view === 'html'
  });

  switch (view) {
    case 'helper':
      return (
        <Block id={thisId} pv="small" {...rest}>
          {title && <h1 className={s.title}>{title}</h1>}
          {children && <ContentTag className={s.helper}>{children}</ContentTag>}
        </Block>
      );

    default:
      return (
        <Block id={thisId} ph="large" pv="small" {...rest}>
          {title && <h1 className={s.title}>{title}</h1>}
          {children && <ContentTag className={contentClass}>{children}</ContentTag>}
        </Block>
      );
  }
};

TextBlock.propTypes = {
  align: PropTypes.oneOf(['left']),
  children: PropTypes.node,
  id: PropTypes.string,
  parentId: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.oneOf(['block', 'helper', 'html'])
};

TextBlock.defaultProps = {
  align: null,
  children: null,
  id: 'text-block',
  parentId: '',
  tag: 'p',
  title: null,
  view: 'block'
};

export default TextBlock;
