import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import s from '../Amount.module.scss';

const Value = ({ id, parentId, value }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <span className={s.value} id={thisId}>
      {value}
    </span>
  );
};

Value.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  value: PropTypes.string.isRequired
};

Value.defaultProps = {
  id: 'value',
  parentId: ''
};

export default Value;
