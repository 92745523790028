import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { components } from '@ace/core-utils';
import { getNewMessage } from '@ace/core-modules/src/chat/reducers';
import { Icon, iconTypes } from '@ace/core-visuals';
import ToolbarItemWrap from './ToolbarItemWrap';
import s from '../Toolbar.module.scss';

const ToolbarItemInboxIcon = ({ hasNewMessage, id, parentId, ...props }) => {
  const thisId = components.generateId(id, parentId);
  const iconClass = classNames({
    [s.inboxIcon]: true,
    [s._hasNewMessage]: hasNewMessage
  });

  return (
    <ToolbarItemWrap {...props} id={thisId}>
      <span className={iconClass}>
        <Icon parentId={thisId} type={iconTypes.findInbox} />
      </span>
    </ToolbarItemWrap>
  );
};

const mapStateToProps = state => ({
  hasNewMessage: getNewMessage(state)
});

ToolbarItemInboxIcon.propTypes = {
  hasNewMessage: PropTypes.bool.isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string
};

ToolbarItemInboxIcon.defaultProps = {
  id: 'item-inbox-icon',
  parentId: ''
};

const ToolbarItemInboxIconConnected = connect(mapStateToProps)(ToolbarItemInboxIcon);

export { ToolbarItemInboxIcon as ToolbarItemInboxIconWithoutConnected };

export default ToolbarItemInboxIconConnected;
