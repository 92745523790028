import React from 'react';

export const inviteFacebook = (
  <g id="Icons/Social/Facebok/Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <rect id="Padding" x="0" y="0" width="42" height="42" />
    <circle id="Oval-10" fill="#3B5998" cx="21" cy="21" r="21" />
    <path
      d="M30.4362333,26.9711077 L26.2836909,26.9711077 L26.2836909,42 L19.9922366,42 L19.9922366,26.9711077 L17,26.9711077 L17,21.689357 L19.9922366,21.689357 L19.9922366,18.2714643 C19.9922366,15.8273022 21.1674766,12 26.3396942,12 L31,12.0192615 L31,17.1461006 L27.6186441,17.1461006 C27.0640038,17.1461006 26.2841057,17.4198598 26.2841057,18.5857957 L26.2841057,21.6942748 L30.9858955,21.6942748 L30.4362333,26.9711077 Z"
      id="Shape"
      fill="#FFFFFF"
      fillRule="nonzero"
    />
  </g>
);

export const inviteTwitter = (
  <g id="Icons/Social/Twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <rect id="Padding" x="0" y="0" width="42" height="42" />
    <g id="Twitter_Social_Icon_Circle_Color" fillRule="nonzero">
      <g id="Dark_Blue" fill="#1DA1F2">
        <circle id="Oval" cx="21" cy="21" r="21" />
      </g>
      <g id="Logo__x2014__FIXED" transform="translate(9.345000, 11.970000)" fill="#FFFFFF">
        <path
          d="M7.812,20.1075 C17.1255,20.1075 22.218,12.39 22.218,5.7015 C22.218,5.481 22.218,5.2605 22.2075,5.0505 C23.1945,4.3365 24.0555,3.444 24.738,2.4255 C23.835,2.8245 22.8585,3.0975 21.8295,3.2235 C22.8795,2.5935 23.6775,1.6065 24.0555,0.42 C23.079,0.9975 21.9975,1.4175 20.8425,1.6485 C19.9185,0.6615 18.606,0.0525 17.1465,0.0525 C14.3535,0.0525 12.0855,2.3205 12.0855,5.1135 C12.0855,5.5125 12.1275,5.901 12.222,6.2685 C8.0115,6.0585 4.284,4.0425 1.785,0.9765 C1.3545,1.722 1.1025,2.5935 1.1025,3.5175 C1.1025,5.271 1.995,6.825 3.36,7.728 C2.5305,7.707 1.7535,7.476 1.071,7.098 C1.071,7.119 1.071,7.14 1.071,7.161 C1.071,9.618 2.814,11.655 5.1345,12.1275 C4.7145,12.243 4.263,12.306 3.801,12.306 C3.4755,12.306 3.1605,12.2745 2.8455,12.2115 C3.486,14.2275 5.355,15.687 7.5705,15.729 C5.838,17.0835 3.654,17.892 1.281,17.892 C0.8715,17.892 0.4725,17.871 0.0735,17.8185 C2.289,19.2675 4.956,20.1075 7.812,20.1075"
          id="Shape"
        />
      </g>
    </g>
  </g>
);

export const inviteWhatsApp = (
  <g id="Icons/Social/Whatsapp/Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <rect id="Padding" x="0" y="0" width="42" height="42" />
    <g id="Group-12">
      <circle id="Oval-9" fill="#25D465" cx="21" cy="21" r="21" />
      <g id="Group" transform="translate(11.000000, 11.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path
          d="M9.51118992,0.0115710811 C4.18615301,0.262848777 -0.0159916661,4.66183309 4.57516675e-05,9.94969871 C0.0049593435,11.5602588 0.396954781,13.0804482 1.08854283,14.4238903 L0.0268657737,19.5356856 C-0.0305277086,19.8122806 0.220884407,20.0544197 0.497819902,19.9892987 L5.54755917,18.8026343 C6.84495215,19.443758 8.30217335,19.8139052 9.84435874,19.8372594 C15.2870489,19.9197776 19.8251604,15.6331642 19.9950205,10.23638 C20.1766869,4.45144217 15.3634143,-0.264956232 9.51118992,0.0115710811 Z M15.5366866,15.4110624 C14.0579002,16.8779095 12.0915081,17.6858295 10.0000241,17.6858295 C8.77544778,17.6858295 7.60287369,17.4132284 6.51505905,16.875811 L5.81200596,16.5282733 L2.71576066,17.2557737 L3.36742578,14.117781 L3.0210858,13.4447061 C2.45629571,12.3476678 2.1699425,11.161545 2.1699425,9.91896593 C2.1699425,7.84430031 2.98443859,5.89398735 4.463225,4.42700488 C5.92884025,2.97308722 7.92723899,2.15223772 10.0001605,2.15223772 C12.0914398,2.15223772 14.057832,2.96015779 15.5366184,4.42700488 C17.0154048,5.89385196 17.8299009,7.844368 17.8299009,9.91876285 C17.8300374,11.9750159 17.0023701,13.9572124 15.5366866,15.4110624 Z"
          id="Shape"
        />
        <path
          d="M14.4980348,12.2149728 L12.6380368,11.6408416 C12.3936762,11.565526 12.1302464,11.640137 11.9520708,11.8352952 L11.4971629,12.3334063 C11.3054227,12.5436416 11.0144704,12.6110663 10.7606079,12.5006645 C9.88073921,12.1178165 8.02984987,10.3484299 7.55744551,9.46366614 C7.4208814,9.20834015 7.44348918,8.88932357 7.61098336,8.6561202 L8.00796292,8.10382979 C8.16353068,7.88753542 8.19629558,7.59677097 8.09347931,7.34665859 L7.31098791,5.44383177 C7.12357267,4.98813406 6.5819033,4.85539836 6.22797683,5.17723311 C5.70891524,5.64934665 5.09300061,6.36649987 5.0182311,7.16122315 C4.88618854,8.56234567 5.44515778,10.3284209 7.5582974,12.4491625 C9.99974134,14.8994184 11.9549541,15.2228736 13.2281327,14.8913866 C13.9502056,14.7034853 14.5272611,13.9497664 14.8912136,13.3325172 C15.1396371,12.9114125 14.9433098,12.3525698 14.4980348,12.2149728 Z"
          id="Shape"
        />
      </g>
    </g>
  </g>
);

export const inviteWeChat = (
  <g id="Icons/Social/Wechat" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <circle id="Oval-9" cx="21" cy="21" r="21" fill="#00C80C" />
    <path
      id="Path"
      fill="#FFF"
      d="M34 24.143c0-3.471-3.638-6.286-8.125-6.286s-8.125 2.815-8.125 6.286c0 3.471 3.638 6.286 8.125 6.286.738 0 1.44-.101 2.12-.244L32.376 32l-1.515-2.928c1.898-1.15 3.14-2.92 3.14-4.93z"
    />
    <path
      id="Path"
      fill="#FFF"
      d="M17.75 10C12.365 10 8 13.518 8 17.857c0 2.502 1.46 4.72 3.723 6.157L10.437 26.5l3.502-1.45c.751.257 1.531.47 2.365.568a6.373 6.373 0 0 1-.179-1.475c0-4.333 4.373-7.857 9.75-7.857.492 0 .973.039 1.448.096C26.465 12.748 22.508 10 17.75 10z"
    />
    <ellipse id="Oval" cx="14.5" cy="14.714" fill="#00C80C" rx="1.219" ry="1.179" />
    <ellipse id="Oval" cx="21" cy="14.714" fill="#00C80C" rx="1.219" ry="1.179" />
    <ellipse id="Oval" cx="23.438" cy="22.571" fill="#00C80C" rx="1.219" ry="1.179" />
    <ellipse id="Oval" cx="28.313" cy="22.571" fill="#00C80C" rx="1.219" ry="1.179" />
  </g>
);

export const inviteWeibo = (
  <g id="Icons/Social/Weibo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path id="Padding" d="M0 0h42v42H0z" />
    <circle id="Oval-9" cx="21" cy="21" r="21" fill="#E6162D" />
    <path
      id="Path"
      fill="#E6162D"
      d="M8.206 25.49c0 3.44 4.547 6.226 10.154 6.226s10.154-2.787 10.154-6.226-4.547-6.226-10.154-6.226S8.206 22.051 8.206 25.49L16 26.5"
    />
    <path
      id="Shape"
      fill="#FFF"
      fillRule="nonzero"
      d="M18.6 31.211c-4.966.485-9.252-1.727-9.575-4.934-.323-3.211 3.443-6.202 8.404-6.687 4.965-.485 9.252 1.728 9.571 4.935.327 3.21-3.439 6.205-8.4 6.686m9.927-10.655c-.422-.126-.712-.208-.493-.754.48-1.186.53-2.208.009-2.942-.973-1.369-3.637-1.296-6.691-.037 0 0-.96.412-.712-.334.468-1.487.397-2.73-.331-3.451-1.655-1.634-6.062.061-9.84 3.781C7.643 19.602 6 22.557 6 25.111c0 4.882 6.36 7.852 12.58 7.852 8.155 0 13.58-4.665 13.58-8.37.004-2.24-1.912-3.512-3.633-4.037"
    />
    <path
      id="Path"
      fill="#FFF"
      d="M33.943 11.62c-1.97-2.151-4.874-2.97-7.555-2.408a1.13 1.13 0 0 0-.882 1.34c.133.612.74.999 1.362.868 1.907-.399 3.972.184 5.37 1.712a5.491 5.491 0 0 1 1.18 5.435 1.125 1.125 0 0 0 .74 1.422 1.151 1.151 0 0 0 1.445-.725v-.004a7.71 7.71 0 0 0-1.66-7.64"
    />
    <path
      id="Path"
      fill="#FFF"
      d="M30.919 14.31a3.891 3.891 0 0 0-3.679-1.17.977.977 0 0 0-.761 1.157.986.986 0 0 0 1.17.746 1.912 1.912 0 0 1 1.8.57c.468.514.597 1.21.394 1.822a.974.974 0 0 0 .637 1.226.993.993 0 0 0 1.246-.627 3.761 3.761 0 0 0-.807-3.725"
    />
    <path
      id="Path"
      fill="#FFF"
      fillRule="nonzero"
      d="M19.092 22.06c-2.362-.608-5.031.554-6.058 2.603-1.046 2.09-.033 4.413 2.35 5.171 2.475.786 5.388-.42 6.402-2.673 1.002-2.209-.248-4.478-2.694-5.102"
    />
    <path
      id="Path"
      fill="#E6162D"
      fillRule="nonzero"
      d="M18.873 25.4c-.174.294-.559.433-.856.31-.298-.118-.39-.448-.22-.733.174-.285.542-.424.836-.31.298.106.406.436.24.734m-1.585 1.996c-.48.754-1.51 1.084-2.284.738-.761-.342-.989-1.219-.509-1.956.476-.733 1.47-1.06 2.239-.742.778.33 1.026 1.198.554 1.96"
    />
  </g>
);

export const inviteCopy = (
  <g id="Icons/Social/Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <rect id="Padding" x="0" y="0" width="42" height="42" />
    <circle
      id="Oval-9"
      stroke="#27628E"
      strokeWidth="0.5"
      fill="#FFFFFF"
      cx="21"
      cy="21"
      r="20.75"
    />
    <g id="Icons/Copy" transform="translate(12.000000, 12.000000)">
      <g id="Group" strokeWidth="1" fillRule="evenodd" fill="#FFFFFF" stroke="#27628E">
        <rect
          id="Rectangle"
          strokeWidth="0.818181818"
          x="2.86363636"
          y="0.409090909"
          width="14.4715909"
          height="14.6791444"
          rx="2.45454545"
        />
        <rect
          id="Rectangle"
          strokeWidth="0.818181818"
          x="0.409090909"
          y="2.86363636"
          width="14.4715909"
          height="14.6791444"
          rx="2.45454545"
        />
      </g>
      <rect id="Padding" x="0" y="0" width="18" height="18" />
    </g>
  </g>
);

export const inviteCopied = (
  <g id="Icons/Social/Copied" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <rect id="Padding" x="0" y="0" width="42" height="42" />
    <circle
      id="Oval-9"
      stroke="#09C1C4"
      strokeWidth="0.5"
      fill="#FFFFFF"
      cx="21"
      cy="21"
      r="20.75"
    />
    <g id="Icons/Copied" transform="translate(12.000000, 12.000000)">
      <rect id="Padding" x="0" y="0" width="18" height="18" />
      <g id="Group-2" strokeWidth="1" fillRule="evenodd">
        <g id="Group" fill="#FFFFFF" stroke="#09C1C4" strokeWidth="0.818181818">
          <rect
            id="Rectangle"
            x="2.86363636"
            y="0.409090909"
            width="14.4715909"
            height="14.6791444"
            rx="2.45454545"
          />
          <rect
            id="Rectangle"
            x="0.409090909"
            y="2.86363636"
            width="14.4715909"
            height="14.6791444"
            rx="2.45454545"
          />
        </g>
        <path
          d="M6.95466477,10.7867324 L5.72048892,9.55255652 C5.3472772,9.1793448 4.7440296,9.1793448 4.37081788,9.55255652 C3.99760616,9.92576824 3.99760616,10.5290158 4.37081788,10.9022276 L6.27982925,12.8112389 C6.46595786,12.9973675 6.71031131,13.0909091 6.95466477,13.0909091 C7.19901822,13.0909091 7.44337168,12.9973675 7.62950029,12.8112389 L11.447523,8.99321619 C11.8207347,8.62000447 11.8207347,8.01675688 11.447523,7.64354516 C11.0743113,7.27033343 10.4710637,7.27033343 10.097852,7.64354516 L6.95466477,10.7867324 Z"
          id="Fill-3"
          fill="#09C1C4"
        />
      </g>
    </g>
  </g>
);
