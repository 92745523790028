import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import UserInfo from '../UserInfo';
import s from './UserMediaObject.module.scss';

import i18n from './messages';

class UserMediaObject extends PureComponent {
  render() {
    const {
      avatar,
      firstName,
      id,
      intl: { formatMessage },
      lastName,
      parentId
    } = this.props;

    const thisId = components.generateId(id, parentId);

    const userInfo = {
      avatar,
      name: firstName ? `${firstName} ${lastName}` : formatMessage(i18n.unknownName)
    };

    return (
      <div className={s.this} id={thisId}>
        <div className={s.user}>
          <UserInfo {...userInfo} parentId={thisId} view="row" />
        </div>
      </div>
    );
  }
}

UserMediaObject.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  lastName: PropTypes.string.isRequired,
  parentId: PropTypes.string
};

UserMediaObject.defaultProps = {
  avatar: null,
  id: 'user-media-object',
  parentId: ''
};

export { UserMediaObject as UserMediaObjectWithoutIntl };

export default injectIntl(UserMediaObject);
