const BusinessRoutes = {
  index: {
    path: '/business',
    acl: {
      g: false,
      c: false,
      m: true
    }
  },
  profile: {
    path: '/business/profile',
    acl: {
      g: false,
      c: false,
      m: true
    }
  },
  profileReward: {
    path: '/business/profile/reward',
    acl: {
      g: false,
      c: false,
      m: true
    }
  },
  register: {
    index: {
      path: '/business/register',
      acl: {
        g: false,
        rm: true,
        c: false,
        m: '/business'
      }
    },
    pin: {
      path: '/business/register/pin',
      acl: {
        g: false,
        rm: true,
        c: false,
        m: '/business'
      }
    },
    offer: {
      path: '/business/register/offer',
      acl: {
        g: false,
        rm: true,
        c: false,
        m: '/business'
      }
    }
  }
};

export default BusinessRoutes;
