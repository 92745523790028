/*
 * Accepts a file or string
 * Returns an img object with src property
 */

export const imgPreview = file => {
  if (!file) {
    return false;
  }
  let img = {};
  if (typeof file === 'string') {
    img.src = file;
    return img;
  }
  if (typeof file.type === 'string') {
    img = new Image();
    const _URL = window.URL || window.webkitURL;
    img.src = _URL.createObjectURL(file);
    return img;
  }
  return false;
};
