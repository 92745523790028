import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes, Field, Form, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { Page, PageFooter, PageMain, Button, FieldInputs, CustomLink } from '@ace/core-components';

import Block from '../Block';
import messages from './messages';
import PageHeader from '../PageHeader';
import SecurityMethodsInfoLink from '../SecurityMethodsInfoLink';

import styles from './FillTotp.module.scss';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

class FillTotp extends PureComponent {
  _onSubmit = params =>
    asyncFormSubmit(this.props.form, this.props.onSubmit, params).catch(() => {});

  render() {
    const {
      intl: { formatMessage },
      form,
      invalid,
      totpCode,
      handleSubmit,
      alternative,
      onAlternative,
      mode,
      submitting
    } = this.props;

    const isConfirmMode = mode === 'confirm';
    const isConfirmSigninMfaMode = mode === 'confirm-signin-mfa';
    let pageTitle = formatMessage(messages.title);
    let intro = formatMessage(messages.para1);
    const submitLabel =
      isConfirmMode || isConfirmSigninMfaMode
        ? formatMessage(messages.submitButtonConfirmMode)
        : formatMessage(messages.submitButton);

    if (isConfirmMode) {
      pageTitle = formatMessage(messages.titleConfirmMode);
    }
    if (isConfirmSigninMfaMode) {
      pageTitle = formatMessage(messages.titleConfirmMode);
      intro = (
        <>
          <FormattedMessage {...messages.para1} />
          <br />
          <br />
          <FormattedMessage {...messages.para2SignInMfaConfirmMode} />
        </>
      );
    }

    return (
      <Page>
        <Helmet title={pageTitle} />
        <PageHeader intro={intro} title={pageTitle} />
        <PageMain>
          <div className={styles['confirm-code']}>
            <Form id={form} onSubmit={handleSubmit(this._onSubmit)}>
              <Block>
                <FormattedMessage {...messages.confirmLabel}>
                  {label => (
                    <Field component={FieldInputs.TotpCode} label={label} name="totpCode" />
                  )}
                </FormattedMessage>
              </Block>
            </Form>
          </div>
        </PageMain>
        <PageFooter>
          {alternative && (
            <Block spacing="sm" align="center">
              <CustomLink onClick={() => onAlternative()} className={styles.smallFont}>
                {alternative === 'backup'
                  ? formatMessage(messages.useBackupMethod)
                  : formatMessage(messages.usePrimaryMethod)}
              </CustomLink>
            </Block>
          )}
          <Block spacing="sm" align="center">
            <SecurityMethodsInfoLink />
          </Block>

          <Button
            disabled={!(totpCode && totpCode.length >= 6) || invalid}
            submitting={submitting}
            form={form}
            theme="primary"
            title={submitLabel}
            type="submit"
            value={submitLabel}
            view="cta"
          />
        </PageFooter>
      </Page>
    );
  }
}

FillTotp.propTypes = {
  ...propTypes,
  alternative: PropTypes.oneOf(['primary', 'backup']),
  intl: intlShape.isRequired,
  mode: PropTypes.oneOf(['validate', 'confirm', 'confirm-signin-mfa']),
  onAlternative: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};
FillTotp.defaultProps = {
  alternative: null,
  onAlternative: () => {},
  mode: 'confirm'
};

const getFormValue = formValueSelector(CONFIG.formNames.validatemfa);

const FillTotpForm = reduxForm({
  form: CONFIG.formNames.validatemfa
})(FillTotp);

const mapStateToProps = state => ({
  totpCode: getFormValue(state, 'totpCode')
});

const FillTotpFormConnected = connect(mapStateToProps)(FillTotpForm);

export default injectIntl(FillTotpFormConnected);
