import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { iconTypeArray } from '@ace/core-visuals';
import Wrapper from './atoms/Wrapper';
import s from './Input.module.scss';

import i18n from './messages';

const InputLink = ({
  disabled,
  hideLabel,
  href,
  icon,
  id,
  intl,
  label,
  name,
  parentId,
  sublabel,
  title,
  unit,
  value
}) => {
  const thisId = components.generateId(id || name, parentId);

  const fieldLabel = components.getFieldMessage(label, `${name}Label`, intl, i18n);
  const fieldSublabel = components.getFieldMessage(sublabel, `${name}Sublabel`, intl, i18n);

  return (
    <Wrapper
      currentType="link"
      disabled={disabled}
      hideLabel={hideLabel}
      icon={icon}
      label={fieldLabel}
      name={name}
      parentId={thisId}
      sublabel={fieldSublabel}
      unit={unit}
      valueId={`${thisId}__link`}
      value={value}
    >
      <a
        className={s.linkValue}
        id={`${thisId}__link`}
        href={href}
        title={title}
        target="_self"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </Wrapper>
  );
};

InputLink.propTypes = {
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  sublabel: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.string
};

InputLink.defaultProps = {
  disabled: undefined,
  hideLabel: undefined,
  icon: undefined,
  id: 'input-link',
  label: undefined,
  parentId: '',
  sublabel: undefined,
  title: undefined,
  unit: undefined,
  value: null
};

export { InputLink as FieldLinkWithoutIntl };

export default injectIntl(InputLink);
