import { defineMessages } from 'react-intl';

export default defineMessages({
  authenticatorApp: 'Authenticator App',
  backupMethod: 'Backup Method',
  finishButton: 'Finish',
  offlineCodes: 'Offline Codes',
  para1:
    'To enable two-factor authentication, we will ask you to set up a passphrase. You can do this now or during the next 48 hours.',
  para2:
    'Keep your passphrase secure. You will be asked to input it if you decide to change or reset your password, change your username or cash out.',
  para3:
    'If you forget your passphrase you will be able to reset it only if you have a backup authentication method.',
  para4:
    'You can set up the backup authentication method either now or later through Membership System -> My Profile -> My Security -> Two-Factor Authentication.',
  para5: 'Please keep your authentication method safe!',
  passphrase: 'Passphrase',
  phone: 'Text Message',
  primaryMethod: 'Primary Method',
  skipButton: 'Skip for now',
  swapButton: 'Swap',
  title: 'Extra security',
});
