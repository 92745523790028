import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { Icon, iconTypes, iconTypeArray } from '@ace/core-visuals';

import Value from './atoms/Value';

import s from './Amount.module.scss';

const Amount = ({ currency, id, parentId, value, view }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s[view]} id={thisId}>
      <span className={s.icon}>
        <Icon parentId={thisId} type={currency} />
      </span>
      <Value parentId={thisId} value={value} />
    </div>
  );
};

Amount.propTypes = {
  currency: PropTypes.oneOf(iconTypeArray),
  id: PropTypes.string,
  parentId: PropTypes.string,
  value: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['large', 'small']).isRequired
};

Amount.defaultProps = {
  currency: iconTypes.BTC,
  id: 'amount',
  parentId: ''
};

export default Amount;
