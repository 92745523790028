import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import WalletRoutes from '@ace/core-modules/src/wallet/routes';

import CustomLink from '../../../CustomLink';

import s from '../FieldIdNumber.module.scss';

import i18n from '../messages';

const IdNumberMessage = ({
  id,
  insufficientBalance,
  intl: { formatMessage },
  legacyMode,
  openImageDialog,
  parentId
}) => {
  const thisId = components.generateId(id, parentId);

  if (insufficientBalance) {
    return (
      <div className={s.message} id={thisId}>
        <h1 className={s.messageTitle}>
          {formatMessage(i18n.IdNumberMessage_insufficientBalanceTitle)}
        </h1>
        <p className={s.messageContent}>
          <FormattedMessage
            {...i18n.IdNumberMessage_insufficientBalanceContent}
            values={{
              cashInLink: (
                <CustomLink parentId={thisId} to={WalletRoutes.business.index.path}>
                  {formatMessage(i18n.IdNumberMessage_cashInLink)}
                </CustomLink>
              )
            }}
          />
        </p>
      </div>
    );
  }

  if (legacyMode) {
    return (
      <div className={s.message} id={thisId}>
        <h1 className={s.messageTitle}>{formatMessage(i18n.IdNumberMessage_legacyModeTitle)}</h1>
        <p className={s.messageContent}>
          <label htmlFor="qrCamera" className={s.imageDialogLabel}>
            {formatMessage(i18n.IdNumberMessage_legacyModeContent)}
            <input
              className={s.imageDialogButton}
              id="qrCamera"
              type="button"
              value={formatMessage(i18n.IdNumberMessage_imageDialogButtonValue)}
              onClick={openImageDialog}
            />
          </label>
        </p>
      </div>
    );
  }

  return (
    <div className={s.message} id={thisId}>
      <h1 className={s.messageTitle}>{formatMessage(i18n.IdNumberMessage_title)}</h1>
    </div>
  );
};

IdNumberMessage.propTypes = {
  id: PropTypes.string,
  insufficientBalance: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  legacyMode: PropTypes.bool.isRequired,
  openImageDialog: PropTypes.func.isRequired,
  parentId: PropTypes.string
};

IdNumberMessage.defaultProps = {
  id: 'message',
  parentId: ''
};

export default injectIntl(IdNumberMessage);
