import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import s from '../WalletId.module.scss';

import i18n from '../messages';

const IdNumber = ({ id, parentId, value }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <span className={s.idNumber} id={thisId}>
      <FormattedMessage
        {...i18n.idNumber}
        values={{
          value
        }}
      />
    </span>
  );
};

IdNumber.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  value: PropTypes.number.isRequired
};

IdNumber.defaultProps = {
  id: 'id-number',
  parentId: ''
};

export default injectIntl(IdNumber);
