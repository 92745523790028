import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Confirm Number.',
  titleConfirmMode: 'Please Confirm.',
  para1:
    'We have sent you a verification code to {phoneNumber}. Please enter the 6-digit confirmation code from SMS.',
  para2SignInMfaConfirmMode:
    'It looks like you are trying to login to GRC from a new device and location. Please confirm your identity via Primary or Backup authentication methods',
  confirmLabel: 'Confirmation code.',
  submitButton: 'Next',
  submitButtonConfirmMode: 'Confirm',
  useBackupMethod: 'Don’t Remember? Try to use Backup method',
  usePrimaryMethod: 'Don’t Remember? Try to use Primary method',
  resendCode: 'Resend code'
});
