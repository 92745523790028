import { defineMessages } from 'react-intl';

export default defineMessages({
  closeButtonTitle: 'Close',
  instruction: 'Tap {shareIcon} and then {addToHomeScreen}',
  intro: 'Want quick access? Why not install <b>Jemjar</b> straight to your phone.',
  iOSAddToHomeScreen: 'Add to Home Screen',
  logoAlt: 'Jemjar app logo',
  title: 'Install Jemjar'
});
