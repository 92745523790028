import { defineMessages } from 'react-intl';

export default defineMessages({
  // our errors
  BAD_REQUEST: 'There was an error with your request. Please check your entry and try again.',
  INVALID_REDIRECT_URI: 'Invalid redirect uri.',
  INVALID_REFERRER: 'Invalid referrer.',
  MISSING_EMAIL_PARAMETER: 'Missing email parameter.',
  MS_ERROR: 'MS_ERROR',
  MS_REGISTRATION_TIMEOUT:
    'Membership system is unavailable now. Please continue registration after getting an email confirmation. If you don’t receive it, please try again tomorrow.',
  NOT_VERIFIED: 'This account is not verified. Please click on the link in the confirmation email.',
  TOO_BUSY:
    'Due to high popularity of Global Rewards City, we are experiencing high volume of sign up requests. Please try again in a minute. We apologize for the inconvenience.',
  UNAUTHORIZED: "You're not authorised to perform this action.",
  USER_NOT_FOUND: 'User not found.',
  USERNAME_NOT_FOUND: 'Username not found.',
  INVALID_NEW_PASSPHRASE_CODE: 'Provided new passphrase code is invalid',
  MISSING_PASSPHRASE_OR_QR_PARAMETER: 'Missing passphrase or qr parameter',
  PASSPHRASE_SETUP_SESSION_HAS_EXPIRED: 'Passphrase setup session has expired',
  TOTP_SETUP_SESSION_HAS_EXPIRED: 'Totp setup session has expired',
  PASSPHRASE_NOT_ENABLED: 'Passphrase not enabled',
  TOTP_NOT_ENABLED: 'Totp not enabled',
  MISSING_TOTP_CODE_PARAMETER: 'Missing totp code parameter',
  INVALID_TOTP_CODE: 'Provided code is invalid.',
  INVALID_PASSPHRASE: 'Invalid passphrase',
  PRIMARY_METHOD: 'Invalid passphrase',
  PRIMARY_METHOD_IS_ALREADY_TOTP: 'Primary method is already set.',
  PRIMARY_METHOD_IS_NOT_CONFIGURED: 'Primary method is not configured.',
  PRIMARY_METHOD_IS_ALREADY_PASSPHRASE: 'Primary method is already passphrase',
  NO_AUTH_METHOD: 'Auth method is not defined',
  SKIP_MFA_DISABLED:
    'After 48 hours, you can no longer skip this step. Please proceed to set up your preferred security methods now.',

  // cognito errors
  InvalidPasswordException: 'Please enter a more secure password.',
  NotAuthorizedException: 'Incorrect email or password.',
  UsernameExistsException: 'An account with the given email already exists.',
  UserNotConfirmedException:
    'This account is not verified. Please click on the link in the confirmation email.'
});
