import { generateActionTypes, generateActions } from '@ace/core-utils';

export const API_CHAT_SESSION_AUTH = generateActionTypes('API_CHAT_SESSION_AUTH');
export const API_CHAT_SESSION_DEAUTH = generateActionTypes('API_CHAT_SESSION_DEAUTH');
export const CHAT_CREATE_CONVERSATION = generateActionTypes('CHAT_CREATE_CONVERSATION');
export const CHAT_MESSAGE_UNREADS = generateActionTypes('CHAT_MESSAGE_UNREADS');
export const CHAT_ACTIVE_ID = generateActionTypes('CHAT_ACTIVE_ID');

export const apiChatSessionAuth = generateActions(API_CHAT_SESSION_AUTH);
export const apiChatSessionDeAuth = generateActions(API_CHAT_SESSION_DEAUTH);
export const chatCreateConversation = generateActions(CHAT_CREATE_CONVERSATION);
export const chatMessageUnreads = generateActions(CHAT_MESSAGE_UNREADS);
export const chatActiveId = generateActions(CHAT_ACTIVE_ID);
