import { defineMessages } from 'react-intl';

export default defineMessages({
  addressLabel: 'Business Address',
  amountLabel: 'Total Spent',
  calculateLabel: 'Approx. Amount',
  currencyLabel: 'Currency',
  depositAmountLabel: 'To Spend',
  descriptionLabel: 'Description',
  descriptionPlaceholder: 'Tell customers about your business...',
  emailLabel: 'Email',
  firstNameLabel: 'First Name',
  idOrPassportNumberLabel: 'Specify Value',
  invalidUsername:
    "Is Invalid. Username should consist of 4–20 characters. Please use letters (a-z), numbers (0-9) and '_'.",
  invalidValue: 'Invalid value',
  lastNameLabel: 'Last Name',
  messageLabel: 'Message',
  nameLabel: 'Business Name',
  newEmailLabel: 'New Email Address',
  numericIdLabel: 'ID Number',
  oldEmailLabel: 'Current Email Address',
  optionalLabel: 'optional',
  passwordConfirmLabel: 'Confirm Password',
  passwordConfirmSublabel: 'Must contain upper-case, lower-case and numbers',
  passwordHideButton: 'Hide',
  passwordLabel: 'Password',
  passwordShowButton: 'Show',
  passwordSublabel: 'Must contain upper-case, lower-case and numbers',
  phoneLabel: 'Business Phone',
  publicAddressLabel: 'External Bitcoin Wallet',
  ratingLabel: 'Your Rating',
  recoveredUsernameLabel: 'Your Username',
  referrerLabel: 'Referrer',
  rewardPctLabel: 'You Give',
  totalAmountLabel: 'Total Bill',
  transactionPinLabel: 'Transaction PIN',
  transactionPinSublabel: 'Must be 4 numbers',
  usernameLabel: 'Email',
  valid: 'Correct',
});
