const validatorFileSize = (file, size) => {
  if (!file || !file.size) {
    return false;
  }
  if (file.size <= size * 1024 * 1024) {
    return true;
  }

  const error = new Error('validatorFileSizeError');
  error.values = { size };
  throw error;
};

export default validatorFileSize;
