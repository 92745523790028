import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { parse } from 'query-string';
import { Route, Switch } from 'react-router-dom';
import { Form, formValueSelector, propTypes, reduxForm } from 'redux-form';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {
  Block,
  ButtonList,
  CustomLink,
  MenuItem,
  Page,
  PageFooter,
  PageMain,
  SelectList,
} from '@ace/core-components';
import { iconTypes } from '@ace/core-visuals';

import OfflineCodes from '../OfflineCodes';
import PageHeader from '../../components/PageHeader';
import RemoveBackupMethodWarningPopup from './RemoveBackupMethodWarningPopup';
import SecurityMethodsInfoLink from '../../components/SecurityMethodsInfoLink';
import SetupPassphrase from '../SetupPassphrase';
import SetupSms from '../SetupSms';
import SetupTotp from '../SetupTotp';
import SublinkMenuItem from '../../components/SublinkMenuItem';

import {
  apiRemoveBackupAuthMethod,
  apiSetAuthMethods,
  apiUserData,
} from '../../store/actions/user';
import { getUserData } from '../../store/reducers/mfa';
import AppRoutes from '../../routes';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import messages from './messages';

import styles from './SetupMfa.module.scss';

class SetupMfa extends PureComponent {
  state = {
    isOpenWarningPopup: false,
  };

  componentDidMount() {
    const { requestUserData, user } = this.props;

    if (!user) {
      requestUserData();
    }

    window.getC = () => this;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user) {
      if (
        prevProps.user.primary_auth_method !== this.props.user.primary_auth_method ||
        prevProps.user.backup_auth_method !== this.props.user.backup_auth_method
      ) {
        this.props.reset();
        setTimeout(() => this.props.initialize(this.props.initialValues));
      }
    }
  }

  onClickRemoveBackupMethod = e => {
    const { method } = e.target.dataset;
    const { requestRemoveBackupMethod, user } = this.props;

    if (user.backup_auth_method === method) {
      this.setState({
        ...this.state,
        isOpenWarningPopup: true,
      });

      return;
    }

    if (method) {
      requestRemoveBackupMethod({ method });
    }
  };

  onCloseWarningPopup = () => {
    this.setState({
      ...this.state,
      isOpenWarningPopup: false,
    });
  };

  onConfirmSubmit = () => {
    const { form, requestSetAuthMethods, selectedBackupMethod, selectedPrimaryMethod } = this.props;

    return asyncFormSubmit(form, requestSetAuthMethods, {
      backup_auth_method: selectedBackupMethod,
      primary_auth_method: selectedPrimaryMethod,
    }).catch(() => {});
  };

  onSetupMethod = () => {
    const {
      history,
      location,
      match: { url },
    } = this.props;

    history.replace({
      ...location,
      pathname: url,
    });
  };

  onSwap = () => {
    const { requestSetAuthMethods, user } = this.props;

    requestSetAuthMethods({
      params: {
        backup_auth_method: user.primary_auth_method,
        primary_auth_method: user.backup_auth_method,
      },
    });
  };

  redirectToOrigin = () => {
    const { redirect_uri: redirectUri } = parse(this.props.location.search);

    window.location.assign(`${redirectUri}`);
  };

  removeActiveBackupMethod = async () => {
    const { requestRemoveBackupMethod, user } = this.props;

    await requestRemoveBackupMethod({ method: user.backup_auth_method });
    this.setState({
      ...this.state,
      isOpenWarningPopup: false,
    });
  };

  render() {
    const {
      form,
      handleSubmit,
      history,
      intl: { formatMessage },
      location,
      match: { path, url },
      selectedBackupMethod,
      submitting,
      user: userData,
    } = this.props;
    const { isOpenWarningPopup } = this.state;
    const user = userData || {};
    const isChangeButtonDisabled =
      !user.backup_auth_method ||
      !selectedBackupMethod ||
      user.backup_auth_method === selectedBackupMethod;
    const isPrimaryMethodOfflineCodes = user.primary_auth_method === 'offline_codes';
    const isPrimaryMethodPassphrase = user.primary_auth_method === 'passphrase';
    const isPrimaryMethodPhone = user.primary_auth_method === 'phone';
    const isPrimaryMethodTotp = user.primary_auth_method === 'totp';
    const isSwapButtonVisible = user.backup_auth_method && user.primary_auth_method;
    const submitButtonLabel = formatMessage(messages.submitButton);
    const swapButtonLabel = formatMessage(messages.swapButton);

    const buttons = [
      isSwapButtonVisible && {
        onClick: this.onSwap,
        theme: 'success',
        title: swapButtonLabel,
        value: swapButtonLabel,
        view: 'cta',
      },
      {
        form,
        submitting,
        disabled: isChangeButtonDisabled,
        theme: 'primary',
        title: submitButtonLabel,
        type: 'submit',
        value: submitButtonLabel,
        view: 'cta',
      },
    ].filter(Boolean);

    const methods = {
      offlineCodes: {
        icon: iconTypes.menuOfflineCodes,
        label: messages.offlineCodes,
        linkLabel: messages.viewCodes,
        linkPath: 'offline-codes',
        value: 'offline_codes',
      },
      passphrase: {
        icon: iconTypes.menuId,
        label: messages.passphrase,
        linkLabel: messages.reinstall,
        linkPath: 'passphrase',
        value: 'passphrase',
      },
      phone: {
        icon: iconTypes.menuTextMessage,
        label: messages.phone,
        linkLabel: messages.reinstall,
        linkPath: 'phone',
        value: 'phone',
      },
      totp: {
        icon: iconTypes.menuGoogleAuthenticator,
        label: messages.authApp,
        linkLabel: messages.reinstall,
        linkPath: 'totp',
        value: 'totp',
      },
    };

    const getAvailablePrimaryMethod = ({ icon, label, linkLabel, linkPath, value }) => ({
      icon,
      value,
      label: (
        <SublinkMenuItem
          label={formatMessage(label)}
          sublabel={
            <CustomLink to={{ ...location, pathname: `${url}/primary/${linkPath}` }}>
              {formatMessage(linkLabel)}
            </CustomLink>
          }
        />
      ),
    });

    const getUnavailablePrimaryMethod = ({ icon, label, linkPath, value }) => ({
      icon,
      value,
      label: formatMessage(label),
      onClick: () =>
        history.push({
          ...location,
          pathname: `${url}/primary/${linkPath}`,
        }),
    });

    const getAvailableBackupMethod = ({ icon, label, linkLabel, linkPath, value }) => ({
      icon,
      value,
      label: (
        <SublinkMenuItem
          label={formatMessage(label)}
          sublabel={
            <>
              <CustomLink to={{ ...location, pathname: `${url}/backup/${linkPath}` }}>
                {formatMessage(linkLabel)}
              </CustomLink>
              <CustomLink
                className={styles.remove}
                data-method={value}
                onClick={this.onClickRemoveBackupMethod}
                theme="error"
              >
                {formatMessage(messages.remove)}
              </CustomLink>
            </>
          }
        />
      ),
    });

    const getUnavailableBackupMethod = ({ icon, label, linkPath, value }) => ({
      icon,
      value,
      label: formatMessage(label),
      onClick: () =>
        history.push({
          ...location,
          pathname: `${url}/backup/${linkPath}`,
        }),
    });

    const primaryMethods = {
      available: user.primary_auth_method
        ? [
            isPrimaryMethodPassphrase && methods.passphrase,
            isPrimaryMethodPhone && methods.phone,
            isPrimaryMethodTotp && methods.totp,
            isPrimaryMethodOfflineCodes && methods.offlineCodes,
          ]
            .filter(Boolean)
            .map(props => getAvailablePrimaryMethod(props))
        : [],
      unavailable: !user.primary_auth_method
        ? [methods.passphrase, methods.phone, methods.totp, methods.offlineCodes]
            .filter(method => user.backup_auth_method !== method.value)
            .map(props => getUnavailablePrimaryMethod(props))
        : [],
    };

    const backupMethods = {
      available: [
        !isPrimaryMethodPassphrase && user.passphrase_auth_method && methods.passphrase,
        !isPrimaryMethodPhone && user.phone_auth_method && methods.phone,
        !isPrimaryMethodTotp && user.totp_auth_method && methods.totp,
        !isPrimaryMethodOfflineCodes && user.offline_codes_auth_method && methods.offlineCodes,
      ]
        .filter(Boolean)
        .map(props => getAvailableBackupMethod(props)),
      unavailable: user.primary_auth_method
        ? [
            !isPrimaryMethodPassphrase && !user.passphrase_auth_method && methods.passphrase,
            !isPrimaryMethodPhone && !user.phone_auth_method && methods.phone,
            !isPrimaryMethodTotp && !user.totp_auth_method && methods.totp,
            !isPrimaryMethodOfflineCodes && !user.offline_codes_auth_method && methods.offlineCodes,
          ]
            .filter(Boolean)
            .map(props => getUnavailableBackupMethod(props))
        : [],
    };

    return (
      <Switch>
        <Route
          path={`${path}/:type/offline-codes`}
          render={props => (
            <OfflineCodes
              {...props}
              type={props.match.params.type}
              onSuccess={this.onSetupMethod}
            />
          )}
        />
        <Route
          path={`${path}/:type/passphrase`}
          render={props => (
            <SetupPassphrase
              {...props}
              type={props.match.params.type}
              onSuccess={this.onSetupMethod}
            />
          )}
        />
        <Route
          path={`${path}/:type/phone`}
          render={props => (
            <SetupSms {...props} type={props.match.params.type} onSuccess={this.onSetupMethod} />
          )}
        />
        <Route
          path={`${path}/:type/totp`}
          render={props => (
            <SetupTotp {...props} type={props.match.params.type} onSuccess={this.onSetupMethod} />
          )}
        />
        <Route
          render={() => (
            <Page>
              <Helmet title={formatMessage(messages.title)} />
              <PageHeader
                onBackClick={this.redirectToOrigin}
                intro={formatMessage(messages.para1)}
                title={formatMessage(messages.title)}
              />
              <PageMain classes={[styles.setupMfaPageMain]}>
                <Form id={form} onSubmit={handleSubmit(this.onConfirmSubmit)}>
                  <Block pv="none">
                    <h4 className={styles.setupMfaSectionHeading}>
                      {formatMessage(messages.primaryMethod)}
                    </h4>
                  </Block>
                  <SelectList pv="none" name="primaryMethod" items={primaryMethods.available} />
                  {primaryMethods.unavailable.length > 0 && (
                    <Block pv="none">
                      {primaryMethods.unavailable.map(item => (
                        <MenuItem key={item.value} {...item} />
                      ))}
                    </Block>
                  )}
                  {user.primary_auth_method && (
                    <>
                      <Block pv="none">
                        <h4 className={styles.setupMfaSectionHeading}>
                          {formatMessage(messages.backupMethod)}
                        </h4>
                      </Block>
                      <SelectList pv="none" name="backupMethod" items={backupMethods.available} />
                      {backupMethods.unavailable.length > 0 && (
                        <Block pv="none">
                          {backupMethods.unavailable.map(item => (
                            <MenuItem
                              key={item.value}
                              isDisabled={primaryMethods.length === 0}
                              {...item}
                            />
                          ))}
                        </Block>
                      )}
                    </>
                  )}
                </Form>
                <RemoveBackupMethodWarningPopup
                  isActive={isOpenWarningPopup}
                  onClose={this.onCloseWarningPopup}
                  onButtonClick={this.removeActiveBackupMethod}
                />
              </PageMain>
              <PageFooter>
                <Block pv="small">
                  <SecurityMethodsInfoLink to={AppRoutes.signup.extraSecurity.info} />
                </Block>
                <ButtonList buttons={buttons} />
              </PageFooter>
            </Page>
          )}
        />
      </Switch>
    );
  }
}

SetupMfa.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
  requestSetAuthMethods: PropTypes.func.isRequired,
  requestUserData: PropTypes.func.isRequired,
};

const getFormValue = formValueSelector(CONFIG.formNames.setupmfa);

const SetupMfaValidateForm = reduxForm({
  destroyOnUnmount: false,
  form: CONFIG.formNames.setupmfa,
})(SetupMfa);

const mapStateToProps = state => {
  const user = getUserData(state);

  return {
    user,
    initialValues: user && {
      backupMethod: user.backup_auth_method,
      primaryMethod: user.primary_auth_method,
    },
    selectedBackupMethod: getFormValue(state, 'backupMethod'),
    selectedPrimaryMethod: getFormValue(state, 'primaryMethod'),
  };
};

const mapDispatchToProps = {
  requestRemoveBackupMethod: apiRemoveBackupAuthMethod.request,
  requestSetAuthMethods: apiSetAuthMethods.request,
  requestUserData: apiUserData.request,
};

const SetupMfaConnected = connect(mapStateToProps, mapDispatchToProps)(SetupMfaValidateForm);

export default injectIntl(SetupMfaConnected);
