import React from 'react';
import Helmet from 'react-helmet';
import { reduxForm, propTypes } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Button, FieldInputs, Page, PageFooter, PageMain } from '@ace/core-components';

import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import { recoverUsername } from '../../store/actions/user';
import messages from './messages';

const mapDispatchToProps = {
  onSubmit: recoverUsername.request
};

let UsernameRecover = props => {
  const {
    form,
    intl: { formatMessage },
    pristine,
    submitting,
    handleSubmit,
    onSubmit
  } = props;
  const onConfirmSubmit = React.useCallback(
    () => asyncFormSubmit(form, onSubmit).catch(() => {}),
    []
  );

  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader
        intro={<FormattedMessage {...messages.intro} />}
        title={<FormattedMessage {...messages.title} />}
      />
      <PageMain>
        <Block>
          <form id={CONFIG.formNames.recoverUsername} onSubmit={handleSubmit(onConfirmSubmit)}>
            <FieldInputs.IdOrPassportNumber />
          </form>
        </Block>
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.confirmButton}>
          {label => (
            <Button
              disabled={pristine}
              form={CONFIG.formNames.recoverUsername}
              submitting={submitting}
              theme="primary"
              title={label}
              type="submit"
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
};

UsernameRecover.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

UsernameRecover = reduxForm({
  destroyOnUnmount: false,
  form: CONFIG.formNames.recoverUsername
})(UsernameRecover);

const UsernameRecoverConnected = connect(
  null,
  mapDispatchToProps
)(UsernameRecover);

export default injectIntl(UsernameRecoverConnected);
