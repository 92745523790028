import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@ace/core-components';

import s from './ListItemBig.module.scss';

const ListItemBig = ({ id, marker, children }) => (
  <>
    <div className={s.this}>
      <ListItem id={id} marker={marker}>
        {children}
      </ListItem>
    </div>
    <div className={s.separator} />
  </>
);

ListItemBig.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  marker: PropTypes.number
};

export default ListItemBig;
