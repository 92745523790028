import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';

import { Illustration } from '@ace/core-visuals';
import s from './HeroBg.module.scss';

const HeroBg = ({ children, id, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div id={thisId} className={s.this}>
      <div className={s.image}>
        <Illustration title="" type="heroBg" />
      </div>
      {children && <div className={s.content}>{children}</div>}
    </div>
  );
};

HeroBg.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  parentId: PropTypes.string
};

HeroBg.defaultProps = {
  children: null,
  id: 'hero-bg',
  parentId: ''
};

export default HeroBg;
