import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import UserRoutes from '@ace/core-modules/src/user/routes';
import WalletRoutes from '@ace/core-modules/src/wallet/routes';
import ChatRoutes from '@ace/core-modules/src/chat/routes';
import { iconTypes } from '@ace/core-visuals';
import ToolbarRow from './atoms/ToolbarRow';
import ToolbarItemAvatar from './atoms/ToolbarItemAvatar';
import ToolbarItemIcon from './atoms/ToolbarItemIcon';
import ToolbarItemInboxIcon from './atoms/ToolbarItemInboxIcon';

import i18n from './messages';

const UserToolbar = ({ avatar, intl: { formatMessage }, isChatPossible }) => {
  const search = {
    component: ToolbarItemIcon,
    icon: iconTypes.toolbarFind,
    linkTo: '/search',
    label: formatMessage(i18n.findLabel)
  };
  const wallet = {
    component: ToolbarItemIcon,
    icon: iconTypes.toolbarWallet,
    linkTo: WalletRoutes.user.index.path,
    label: formatMessage(i18n.walletLabel)
  };
  const inbox = {
    component: ToolbarItemInboxIcon,
    linkTo: ChatRoutes.index.path,
    label: formatMessage(i18n.inboxLabel)
  };
  const account = {
    component: ToolbarItemAvatar,
    avatar,
    icon: iconTypes.menuConsumer,
    linkTo: UserRoutes.index.path,
    label: formatMessage(i18n.accountLabel)
  };
  const toolbarArray = isChatPossible
    ? [search, wallet, inbox, account]
    : [search, wallet, account];

  return <ToolbarRow id="user-toolbar" items={toolbarArray} />;
};

UserToolbar.propTypes = {
  avatar: PropTypes.string,
  intl: intlShape.isRequired,
  isChatPossible: PropTypes.bool.isRequired
};

UserToolbar.defaultProps = {
  avatar: ''
};

export { UserToolbar as UserToolbarWithoutIntl };

export default injectIntl(UserToolbar);
