import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { length, numericality } from 'redux-form-validators';
import { connect } from 'react-redux';
import { components } from '@ace/core-utils';
import * as v from '../validators/validatorsFields';
import InputField from '../FieldInput/InputField';
import s from './FieldPin.module.scss';

const FieldPin = ({ form, id, parentId, pinValue, ...rest }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.this} id={thisId}>
      <div className={s.pin}>
        <Field
          component={InputField}
          errorOnBlur
          name="transactionPin"
          parentId={thisId}
          pattern="[0-9]{4}"
          placeholder=""
          type="number"
          validate={v.finalValidate([length({ is: 4 }), numericality({ int: true, '>': 0 })])}
          view="pin"
          {...rest}
        />
      </div>
      <div className={s.password}>
        <input
          aria-hidden="true"
          autoComplete="off"
          className={s.passwordInput}
          type="password"
          name="password-transactionPin"
          readOnly
          tabIndex="-1"
          value={pinValue}
          id={`${thisId}__transactionPin__password`}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  pinValue: formValueSelector(ownProps.form.form)(state, 'transactionPin')
});

FieldPin.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func
  }).isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string,
  pinValue: PropTypes.string
};

FieldPin.defaultProps = {
  id: 'field-pin',
  parentId: '',
  pinValue: ''
};

export default connect(mapStateToProps)(FieldPin);
