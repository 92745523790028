import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Illustration } from '@ace/core-visuals';
import Block from '../../structure/Block';
import Page from '../../structure/Page';
import PageHeader from '../../structure/PageHeader';
import PageMain from '../../structure/PageMain';
import TextBlock from '../../structure/TextBlock';

import i18n from './messages';

const ScreenMaintenance = ({ intl: { formatMessage } }) => (
  <Page>
    <PageHeader title={formatMessage(i18n.title)} />
    <PageMain>
      <TextBlock>{formatMessage(i18n.texti)}</TextBlock>
      <TextBlock>{formatMessage(i18n.textii)}</TextBlock>
      <Block view="image">
        <Illustration title={formatMessage(i18n.illustrationTitle)} type="maintenance" />
      </Block>
    </PageMain>
  </Page>
);

ScreenMaintenance.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ScreenMaintenance);
