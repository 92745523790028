import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Captcha.module.scss';

const mtcaptchaConfig = {
  sitekey: CONFIG.captcha.mtCaptchaPublicKey,
  enableTestMode: CONFIG.captcha.mtCaptchaTestKey,
  render: 'explicit', // or "auto"
  renderQueue: [],
  'jsloaded-callback': e => {
    mtcaptchaConfig._trigger('jsloaded', e);
  },
  'rendered-callback': e => {
    mtcaptchaConfig._trigger('rendered', e);
  },
  'verified-callback': e => {
    mtcaptchaConfig._trigger('verified', e);
  },
  'verifyexpired-callback': e => {
    mtcaptchaConfig._trigger('verifyexpired', e);
  },
  'error-callback': e => {
    mtcaptchaConfig._trigger('error', e);
  }
};
mtcaptchaConfig.__injected = false;
mtcaptchaConfig.__listeners = {};
mtcaptchaConfig.on = (name, cb) => {
  if (!mtcaptchaConfig.__listeners[name]) {
    mtcaptchaConfig.__listeners[name] = [];
  }
  if (!mtcaptchaConfig.__listeners[name].includes(cb)) {
    mtcaptchaConfig.__listeners[name].push(cb);
  }
};
mtcaptchaConfig.off = (name, cb) => {
  if (mtcaptchaConfig.__listeners[name] && mtcaptchaConfig.__listeners[name].includes(cb)) {
    mtcaptchaConfig.__listeners[name].splice(mtcaptchaConfig.__listeners[name].indexOf(cb), 1);
  }
};
mtcaptchaConfig._trigger = (name, e) => {
  if (mtcaptchaConfig.__listeners[name]) {
    mtcaptchaConfig.__listeners[name].forEach(cb => cb(e));
  }
};

const maybeInject = () => {
  if (typeof document !== 'undefined' && mtcaptchaConfig && !mtcaptchaConfig.__injected) {
    const mtService = document.createElement('script');
    mtService.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
    mtService.async = true;
    document.body.appendChild(mtService);

    const mtService2 = document.createElement('script');
    mtService2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
    mtService2.async = true;
    document.body.appendChild(mtService2);
    mtcaptchaConfig.__injected = true;
  }

  if (typeof window !== 'undefined') {
    window.mtcaptchaConfig = mtcaptchaConfig;
    return window.mtcaptchaConfig;
  }

  return mtcaptchaConfig;
};

const getValue = ref => {
  const input = ref.current && ref.current.querySelector('input[type="hidden"]');
  if (!input) {
    return '';
  }
  return input.value;
};

function MTCaptcha(props) {
  const {
    id,
    input: { value, onChange }
  } = props;
  const api = useMemo(() => {
    const mtcaptcha = maybeInject();
    mtcaptchaConfig.renderQueue.push(id);
    return mtcaptcha;
  }, []);
  const ref = useRef();
  useEffect(
    () => {
      const captchaValue = getValue(ref);
      if (!value && captchaValue) {
        (window.mtcaptcha || window.mtcaptcha2).resetUI();
      }
    },
    [value]
  );
  const onVerified = useCallback(() => {
    const v = getValue(ref);
    onChange(v);
  }, []);
  const onVerifyExpired = useCallback(() => {
    onChange('');
  }, []);
  const onError = useCallback(() => {
    const v = getValue(ref);
    onChange(v);
  }, []);
  const onRendered = useCallback(() => {
    onChange('');
  }, []);
  useEffect(
    () => {
      api.on('rendered', onRendered);
      api.on('verified', onVerified);
      api.on('verifyexpired', onVerifyExpired);
      api.on('error', onError);
      return () => {
        api.off('rendered', onRendered);
        api.off('verified', onVerified);
        api.off('verifyexpired', onVerifyExpired);
        api.off('error', onError);
      };
    },
    [api, onVerified, onVerifyExpired, onError, onRendered]
  );
  return (
    <div className={styles.MtCaptcha} ref={ref}>
      <div id={id} />
    </div>
  );
}

MTCaptcha.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }).isRequired
};

MTCaptcha.defaultProps = {
  id: 'captcha'
};

export default MTCaptcha;
