import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TextareaInput from './Textarea';

const Textarea = props => <Field component={TextareaInput} {...props} />;

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string
};

Textarea.defaultProps = {
  id: 'textarea',
  parentId: ''
};

export default Textarea;
