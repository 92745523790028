import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { components } from '@ace/core-utils';

import s from '../Toolbar.module.scss';

const ToolbarItemWrap = ({ children, href, id, label, linkTo, parentId, target }) => {
  const thisId = components.generateId(id, parentId);
  const itemClass = classNames({
    [s.itemWrap]: true,
    [s._onlyLabel]: !children
  });

  return (
    <li className={itemClass} id={thisId}>
      {href ? (
        <a className={s.link} title={label} href={href} target={target}>
          {children}
          <span className={s.label}>{label}</span>
        </a>
      ) : (
        <NavLink activeClassName={s._isActive} className={s.link} exact title={label} to={linkTo}>
          {children}
          <span className={s.label}>{label}</span>
        </NavLink>
      )}
    </li>
  );
};

ToolbarItemWrap.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  parentId: PropTypes.string,
  target: PropTypes.string
};

ToolbarItemWrap.defaultProps = {
  children: null,
  href: undefined,
  id: 'item-wrap',
  linkTo: undefined,
  parentId: '',
  target: undefined
};

export default ToolbarItemWrap;
