import React from 'react';
import PropTypes from 'prop-types';

import { iconTypes } from '@ace/core-visuals';
import { components } from '@ace/core-utils';

import FieldAvatar from '../fields/FieldAvatar';

import s from './UserInfo.module.scss';

const UserInfo = ({ id, name, parentId, subname, userType, view, ...props }) => {
  const thisId = components.generateId(id, parentId);

  const emptyAvatarIcon = userType === 'business' ? iconTypes.menuMerchant : iconTypes.menuConsumer;

  return (
    <div className={s[view]} id={thisId}>
      <div className={s.avatar}>
        <FieldAvatar emptyIcon={emptyAvatarIcon} parentId={thisId} {...props} />
      </div>
      {name && (
        <div className={s.title}>
          <div className={s.name}>{name}</div>
          {subname && <div className={s.subname}>{subname}</div>}
        </div>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  parentId: PropTypes.string,
  subname: PropTypes.string,
  userType: PropTypes.oneOf(['user', 'business']),
  view: PropTypes.oneOf(['column', 'line', 'row'])
};

UserInfo.defaultProps = {
  id: 'user-info',
  name: null,
  parentId: '',
  subname: null,
  userType: 'user',
  view: 'line'
};

export default UserInfo;
