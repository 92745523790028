import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import s from './PageMain.module.scss';

const PageMain = ({ classes, children, id, parentId }) => {
  const thisId = components.generateId(id, parentId);
  const thisClass = classNames(s.this, classes);

  return (
    <main className={thisClass} id={thisId}>
      {children}
    </main>
  );
};

PageMain.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  parentId: PropTypes.string
};

PageMain.defaultProps = {
  id: 'page-main',
  parentId: '',
  classes: []
};

export default PageMain;
