import React from 'react';

const print2digits = number => `0${number}`.substr(-2);
const printTimeDiff = diff => {
  const minutes = Math.floor(diff / 60000);
  const seconds = Math.floor((diff % 60000) / 1000);
  return `${print2digits(minutes)}:${print2digits(seconds)}`;
};
const getTime = (from, to) => {
  const diff = to - from;
  return printTimeDiff(diff > 0 ? diff : 0);
};

const useTimer = expires => {
  const startTime = React.useMemo(() => getTime(Date.now(), expires), [expires]);
  const startDiff = React.useMemo(() => expires - Date.now(), [expires]);
  const [[time, timeDiff], setTime] = React.useState([startTime, startDiff]);
  React.useEffect(
    () => {
      let timer;
      const update = () => {
        const diff = expires - Date.now();
        const t = getTime(Date.now(), expires);
        if (diff > 0) {
          timer = setTimeout(update, 1000);
        }
        setTime([t, diff < 0 ? 0 : diff]);
      };
      timer = setTimeout(update, 1000);
      return () => clearTimeout(timer);
    },
    [expires]
  );
  return [time, timeDiff];
};

export default useTimer;
