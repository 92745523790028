import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '@ace/core-components';

import MenuItem from '../MenuItem';

import s from './Menu.module.scss';

const Menu = ({ children, items, title, ...rest }) => (
  <Block title={title} {...rest}>
    <ul className={s.this}>
      {items && items.map(item => <MenuItem {...item} />)}
      {children}
    </ul>
  </Block>
);

Menu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.element.isRequired
    })
  ),
  title: PropTypes.string
};

Menu.defaultProps = {
  children: null,
  items: null,
  title: null
};

export default Menu;
