import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Route } from 'react-router-dom';
import { Illustration } from '@ace/core-visuals';
import Block from '../../structure/Block';
import Page from '../../structure/Page';
import PageHeader from '../../structure/PageHeader';
import PageMain from '../../structure/PageMain';
import TextBlock from '../../structure/TextBlock';

import i18n from './messages';

const ScreenNotFound = ({ intl: { formatMessage } }) => (
  <Route
    path="*"
    render={() => (
      <Page>
        <PageHeader title={formatMessage(i18n.title)} />
        <PageMain>
          <TextBlock>{formatMessage(i18n.text)}</TextBlock>
          <Block view="image">
            <Illustration title={formatMessage(i18n.illustrationTitle)} type="error" />
          </Block>
        </PageMain>
      </Page>
    )}
  />
);

ScreenNotFound.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ScreenNotFound);
