import mixpanel from 'mixpanel-browser';

const mp = () => {
  let instance;

  const createMixpanelInstance = () => {
    const mixpanelToken = CONFIG.features.mixpanel;
    // init mixpanel only if token exists: MP throws an error if token doesn't exist
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken);
      return mixpanel;
    }

    return null;
  };

  return {
    getMixpanelInstance: () => {
      if (!instance) {
        instance = createMixpanelInstance();
      }
      return instance;
    }
  };
};

export default mp();
