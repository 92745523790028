import React from 'react';

export const menuBTC = (
  <g id="Icons/Menu/Bitcoin" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g
      id="Icons/Currency/Bitcoin/White"
      fill="#FFF"
      fillRule="nonzero"
      transform="translate(10 10)"
    >
      <path
        id="Shape"
        d="M18.238 11.542c-.505-.661-1.268-1.115-2.287-1.362 1.298-.67 1.869-1.807 1.712-3.411-.052-.582-.211-1.086-.477-1.514a3.009 3.009 0 0 0-1.078-1.03 5.897 5.897 0 0 0-1.477-.596 11.53 11.53 0 0 0-1.83-.297V0h-2.013v3.24c-.348 0-.88.008-1.594.026V0H7.181v3.332c-.287.009-.71.013-1.267.013l-2.771-.013V5.5h1.45c.663 0 1.05.3 1.164.9v3.794c.087 0 .157.004.209.013h-.21v5.315c-.069.45-.322.674-.757.674h-1.45l-.406 2.42h2.614c.165 0 .405.002.719.006.313.004.548.007.705.007V22h2.013v-3.332c.366.01.898.014 1.595.014V22H12.8v-3.371a15.473 15.473 0 0 0 1.902-.199 7.67 7.67 0 0 0 1.588-.456 4.276 4.276 0 0 0 1.267-.773c.34-.309.62-.701.843-1.177.222-.476.364-1.027.425-1.653.114-1.225-.083-2.168-.588-2.83zM9.233 5.645a95.51 95.51 0 0 0 1.222-.02c.188-.004.44.007.758.033.318.027.586.062.804.106.218.044.458.117.719.218.261.101.47.225.627.37.157.146.29.33.399.556.109.224.163.482.163.773 0 .247-.04.472-.117.674a1.27 1.27 0 0 1-.353.51 3.577 3.577 0 0 1-.484.357 2.2 2.2 0 0 1-.634.244c-.257.062-.481.11-.673.145-.191.036-.43.06-.718.073a15.12 15.12 0 0 1-1.262.027 49.552 49.552 0 0 0-.45-.007V5.645zm5.496 9.004c-.083.203-.19.379-.32.529-.13.15-.303.282-.516.396a4.43 4.43 0 0 1-.621.278c-.2.07-.443.132-.725.185a9.426 9.426 0 0 1-1.51.165 16.956 16.956 0 0 1-1.32.013 41.683 41.683 0 0 0-.484-.006V11.74c.07 0 .277-.004.62-.013.345-.01.626-.013.844-.013.218 0 .518.013.902.04.383.026.705.065.967.118.261.053.546.134.856.245.31.11.56.244.752.403.191.159.352.361.483.608s.196.529.196.846c0 .247-.041.472-.124.675z"
      />
    </g>
  </g>
);

export const menuCNY = (
  <g id="Icons/Menu/Chinese-Yuan" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g
      id="Icons/Currency/Yuan/White"
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".952"
      transform="translate(11 11)"
    >
      <path
        id="Shape"
        d="M16.122 11.443H11.02V9.711h5.102c.564 0 1.02-.387 1.02-.865s-.456-.866-1.02-.866h-4.414l3.952-6.227c.267-.421.082-.947-.414-1.173-.497-.227-1.115-.07-1.383.352L10 7.02 6.137.932C5.869.51 5.25.353 4.754.58c-.496.227-.682.752-.414 1.173L8.292 7.98H3.878c-.564 0-1.02.388-1.02.866s.456.866 1.02.866H8.98v1.731H3.878c-.564 0-1.02.388-1.02.866s.456.866 1.02.866H8.98v5.483c0 .478.456.866 1.02.866.564 0 1.02-.388 1.02-.866v-5.483h5.102c.564 0 1.02-.388 1.02-.866s-.456-.866-1.02-.866z"
      />
    </g>
  </g>
);

export const menuConsumer = (
  <g
    id="Icons/Toolbar/Profile/Consumer/Placeholder/Selected"
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
  >
    <g id="Group-4" fillRule="nonzero" transform="translate(12 11)">
      <path
        id="Shape"
        fill="#09C1C4"
        d="M18 9.567C18 8.149 16.79 7 15.296 7H2.705C1.21 7 0 8.15 0 9.567v7.367C0 17.523.477 18 1.066 18h15.868c.589 0 1.066-.477 1.066-1.066V9.567z"
      />
      <circle id="Oval" cx="9" cy="5" r="5" fill="#FBD25C" />
    </g>
  </g>
);

export const menuContact = (
  <g id="Icons/Menu/Contact" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Combined-Shape"
      fill="#FFF"
      d="M27.878 26.465l1.538 4.445-5.916-2.272a11.12 11.12 0 0 1-2.5.283c-5.523 0-10-4.012-10-8.96C11 15.01 15.477 11 21 11s10 4.012 10 8.96c0 2.562-1.2 4.872-3.122 6.505z"
    />
  </g>
);

export const menuCross = (
  <g id="Icons/Cross" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Cross" fill="#FFF" transform="translate(11 11)">
      <path
        id="Rectangle"
        d="M10.237-1.313A2.362 2.362 0 0 1 12.6 1.05v18.908a2.362 2.362 0 0 1-4.725 0V1.05a2.362 2.362 0 0 1 2.362-2.362z"
        transform="rotate(45 10.237 10.504)"
      />
      <path
        id="Rectangle"
        d="M10.237-1.313A2.362 2.362 0 0 1 12.6 1.05v18.908a2.362 2.362 0 0 1-4.725 0V1.05a2.362 2.362 0 0 1 2.362-2.362z"
        transform="scale(-1 1) rotate(45 0 -14.212)"
      />
    </g>
  </g>
);

export const menuCurrency = (
  <g id="Icons/Menu/Currency" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <circle id="Oval-7" cx="15" cy="21" r="8" fill="#3DCED0" />
    <circle id="Oval-7" cx="26.848" cy="21" r="8" fill="#EFF1F4" />
    <g
      id="Icons/Currency/Sterling/Black"
      fill="#2B2B2B"
      fillRule="nonzero"
      stroke="#2B2B2B"
      strokeWidth=".5"
      transform="translate(22.85 17)"
    >
      <path
        id="Path"
        d="M4.444.19C3.22.19 2.222.967 2.222 1.922v1.732h-.444c-.246 0-.445.155-.445.346 0 .191.2.346.445.346h.444v2.77h-.444c-.246 0-.445.156-.445.347 0 .191.2.347.445.347h3.555c.246 0 .445-.156.445-.347 0-.191-.2-.346-.445-.346H3.111v-2.77h2.222c.246 0 .445-.156.445-.347 0-.191-.2-.346-.445-.346H3.111V1.922c0-.573.598-1.039 1.333-1.039.736 0 1.334.466 1.334 1.04 0 .19.199.345.444.345.246 0 .445-.155.445-.346C6.667.967 5.67.19 4.444.19z"
      />
    </g>
    <g
      id="Icons/Currency/Dollar/White"
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".5"
      transform="translate(10 16)"
    >
      <path
        id="Shape"
        d="M5.571 4.61V2.045c.665.179 1.143.66 1.143 1.224 0 .24.256.433.572.433.315 0 .571-.194.571-.433 0-1.045-.983-1.92-2.286-2.12V.67c0-.24-.255-.433-.571-.433-.316 0-.571.194-.571.433v.476c-1.303.201-2.286 1.076-2.286 2.121 0 1.046.983 1.92 2.286 2.121v2.567c-.666-.179-1.143-.66-1.143-1.224 0-.24-.256-.433-.572-.433-.315 0-.571.193-.571.433 0 1.045.983 1.92 2.286 2.12v.477c0 .24.255.433.571.433.316 0 .571-.194.571-.433v-.476c1.303-.202 2.286-1.076 2.286-2.121 0-1.046-.983-1.92-2.286-2.121zM3.286 3.269c0-.564.477-1.045 1.143-1.224v2.449c-.666-.18-1.143-.66-1.143-1.225zM5.57 7.956V5.507c.665.179 1.143.66 1.143 1.225 0 .564-.478 1.045-1.143 1.224z"
      />
    </g>
  </g>
);

export const menuFriends = (
  <g id="Icons/Menu/Friends" fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
    <g id="Group-2" transform="translate(8 13)">
      <path
        id="Shape"
        fill="#F4528B"
        d="M14 6.867C14 5.836 13.058 5 11.897 5H2.104C.942 5 0 5.836 0 6.867v5.311c0 .454.368.822.822.822h12.356a.822.822 0 0 0 .822-.822V6.867z"
      />
      <circle id="Oval" cx="7" cy="4" r="4" fill="#C1A77C" />
    </g>
    <g id="Group-2" transform="translate(20 13)">
      <path
        id="Shape"
        fill="#FBD25C"
        d="M14 6.867C14 5.836 13.058 5 11.897 5H2.104C.942 5 0 5.836 0 6.867v5.311c0 .454.368.822.822.822h12.356a.822.822 0 0 0 .822-.822V6.867z"
      />
      <circle id="Oval" cx="7" cy="4" r="4" fill="#D8C180" />
    </g>
    <g id="Group-4" transform="translate(12 12)">
      <path
        id="Shape"
        fill="#09C1C4"
        d="M18 9.567C18 8.149 16.79 7 15.296 7H2.705C1.21 7 0 8.15 0 9.567v7.367C0 17.523.477 18 1.066 18h15.868c.589 0 1.066-.477 1.066-1.066V9.567z"
      />
      <circle id="Oval" cx="9" cy="5" r="5" fill="#FBDE8A" />
    </g>
  </g>
);

export const menuGBP = (
  <g id="Icons/Menu/Pound-Sterling" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g
      id="Icons/Currency/Sterling/White"
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".5"
      transform="translate(12 12)"
    >
      <path
        id="Path"
        d="M10 .429c-2.757 0-5 1.747-5 3.896V8.22H4c-.552 0-1 .349-1 .779 0 .43.448.78 1 .78h1v6.233H4c-.552 0-1 .349-1 .78 0 .43.448.778 1 .778h8c.552 0 1-.348 1-.779 0-.43-.448-.779-1-.779H7V9.779h5c.552 0 1-.349 1-.779 0-.43-.448-.78-1-.78H7V4.326c0-1.29 1.346-2.338 3-2.338s3 1.049 3 2.338c0 .43.448.779 1 .779s1-.349 1-.78C15 2.177 12.757.43 10 .43z"
      />
    </g>
  </g>
);

export const menuHKD = (
  <g id="Icons/Menu/HK-Dollar" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g
      id="Icons/Currency/Dollar/White"
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".952"
      transform="translate(11 11)"
    >
      <path
        id="Shape"
        d="M11.143 9.221V4.088c1.33.357 2.286 1.32 2.286 2.448 0 .479.511.866 1.142.866.632 0 1.143-.387 1.143-.866 0-2.09-1.966-3.84-4.571-4.242v-.952c0-.478-.512-.866-1.143-.866s-1.143.388-1.143.866v.952c-2.605.403-4.571 2.152-4.571 4.242 0 2.091 1.966 3.84 4.571 4.242v5.134c-1.33-.358-2.286-1.32-2.286-2.449 0-.478-.511-.866-1.142-.866-.632 0-1.143.388-1.143.866 0 2.09 1.966 3.84 4.571 4.242v.953c0 .478.512.866 1.143.866s1.143-.388 1.143-.866v-.953c2.605-.402 4.571-2.151 4.571-4.242 0-2.09-1.966-3.84-4.571-4.242zM6.57 6.536c0-1.128.956-2.09 2.286-2.448v4.897c-1.33-.357-2.286-1.32-2.286-2.449zm4.572 9.376v-4.898c1.33.358 2.286 1.32 2.286 2.449 0 1.129-.956 2.091-2.286 2.449z"
      />
    </g>
  </g>
);

export const menuId = (
  <g id="Icons/Menu/ID" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Group" strokeWidth="1.8" transform="translate(12 12)">
      <path
        id="Rectangle"
        stroke="#3DCED0"
        d="M.887.913H.885A.05.05 0 0 0 .89.912zm.02-.006a2.024 2.024 0 0 0-.007.247v5.874c0 .163.002.22.007.247a1.9 1.9 0 0 0 .247.007h5.874c.163 0 .22-.002.247-.007a1.9 1.9 0 0 0 .007-.247V1.154c0-.163-.002-.22-.007-.247A2.024 2.024 0 0 0 7.028.9H1.154a1.9 1.9 0 0 0-.247.007zm-.02 6.362l-.002-.001.005.002zm6.382.026l-.001.002.002-.005zM7.295.913h.002a.068.068 0 0 0-.005-.002z"
      />
      <path
        id="Rectangle"
        stroke="#F4528B"
        d="M10.705.913h-.002a.05.05 0 0 0 .005-.002zm.02-.006a2.024 2.024 0 0 0-.007.247v5.874c0 .163.002.22.007.247a1.9 1.9 0 0 0 .247.007h5.874c.163 0 .22-.002.247-.007a1.9 1.9 0 0 0 .007-.247V1.154c0-.163-.002-.22-.007-.247A2.024 2.024 0 0 0 16.846.9h-5.874c-.163 0-.22.002-.247.007zm.006 6.388l.001.002a.05.05 0 0 0-.003-.005zm-.026-.026l-.002-.001.005.002zm6.382.026v.002c0-.002 0-.003.002-.005zm.026-6.382h.002a.068.068 0 0 0-.005-.002z"
      />
      <path
        id="Rectangle"
        stroke="#FBD25C"
        d="M.887 10.731l-.002.001a.05.05 0 0 0 .005-.003zm.02-.006a2.024 2.024 0 0 0-.007.247v5.874c0 .163.002.22.007.247a1.9 1.9 0 0 0 .247.007h5.874c.163 0 .22-.002.247-.007a1.9 1.9 0 0 0 .007-.247v-5.874c0-.163-.002-.22-.007-.247a2.024 2.024 0 0 0-.247-.007H1.154c-.163 0-.22.002-.247.007zm-.02 6.362H.885c.001 0 .003 0 .005.002zm6.382.026l-.001.002.002-.005z"
      />
      <path
        id="Rectangle"
        fill="#FFF"
        stroke="#FFF"
        d="M10.718 10.718v1.473h1.473v-1.473h-1.473zm0 4.909V17.1h1.473v-1.473h-1.473zm4.909 0V17.1H17.1v-1.473h-1.473zm0-4.909v1.473H17.1v-1.473h-1.473z"
      />
    </g>
  </g>
);

export const menuOfflineCodes = (
  <g id="Icons/Menu/offline/codes">
    <defs>
      <path id="a" d="M0 0h26.014v11.903H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(8 15)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#DBDBDB"
          d="M.35 5.148L5.077.42A1.187 1.187 0 0 1 6.755 2.1l-3.9 3.888 3.9 3.888c.463.46.467 1.208.007 1.671l-.007.007a1.182 1.182 0 0 1-1.671.007l-.007-.007L.35 6.826a1.182 1.182 0 0 1 0-1.678zm9.241 5.034L14.32.728a1.182 1.182 0 1 1 2.103 1.063l-4.727 9.455a1.18 1.18 0 0 1-1.052.65 1.097 1.097 0 0 1-.532-.13 1.182 1.182 0 0 1-.52-1.584zM19.26 2.1A1.187 1.187 0 0 1 20.937.42l4.727 4.728c.463.46.467 1.208.007 1.67l-.007.008-4.727 4.727a1.182 1.182 0 0 1-1.671.007l-.007-.007a1.182 1.182 0 0 1-.007-1.67l.007-.008 3.9-3.888-3.9-3.888z"
          mask="url(#b)"
        />
        <path
          fill="#F8F8F8"
          fillRule="nonzero"
          d="M9.896 10.653a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM11.507 7.482a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM5.015 10.653a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM19.412 10.653a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM19.412 1.241a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM5.172 1.241a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM.53 5.951a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM24.031 5.982a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM14.616 1.241a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM13.116 4.265a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0z"
          mask="url(#b)"
        />
      </g>
    </g>
  </g>
);

export const menuLanguage = (
  <g id="Icons/Language/Menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <defs>
      <circle id="path-1" cx="8" cy="8" r="8" />
      <path id="path-3" d="M11 8h11v8L11 8zm0 0v8H0l11-8zm0 0H0V0l11 8zm0 0V0h11L11 8z" />
      <circle id="path-5" cx="8" cy="8" r="8" />
    </defs>
    <g id="Icons/Language/Menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="English" transform="translate(7 13)">
        <mask id="mask-2" fill="#fff">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="#D8D8D8" fillRule="nonzero" xlinkHref="#path-1" />
        <g id="Flag_of_the_United_Kingdom" mask="url(#mask-2)">
          <g transform="translate(-3)">
            <path id="Shape" fill="#00247D" fillRule="nonzero" d="M0 0v16h22V0z" />
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#FFF"
              strokeWidth="2.667"
              d="M0 0l22 16m0-16L0 16"
            />
            <g id="Clipped">
              <mask id="mask-4" fill="#fff">
                <use xlinkHref="#path-3" />
              </mask>
              <path
                id="Shape"
                fill="#000"
                fillRule="nonzero"
                stroke="#CF142B"
                strokeWidth="1.778"
                d="M0 0l22 16m0-16L0 16"
                mask="url(#mask-4)"
              />
            </g>
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#FFF"
              strokeWidth="4.444"
              d="M11 0v16M0 8h22"
            />
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              stroke="#CF142B"
              strokeWidth="2.667"
              d="M11 0v16M0 8h22"
            />
          </g>
        </g>
      </g>
      <g id="Chinese" transform="translate(19 13)">
        <mask id="mask-6" fill="#fff">
          <use xlinkHref="#path-5" />
        </mask>
        <use id="Mask" fill="#DE2910" fillRule="nonzero" xlinkHref="#path-5" />
        <g id="g14" mask="url(#mask-6)">
          <g id="Group" fill="#FFDE02" fillRule="nonzero" transform="matrix(1 0 0 -1 3 13.5)">
            <g id="g24" transform="translate(0 2.878)">
              <path
                id="path26"
                d="M2.667 5.231l.619-1.746 1.884-.039L3.668 2.33 4.214.56 2.667 1.616 1.119.56l.546 1.77L.163 3.446l1.884.039z"
              />
            </g>
            <g id="g28" transform="translate(5.333 8.386)">
              <path
                id="path30"
                d="M1.333 1.962l.227-.45.506-.072-.366-.351.086-.494-.453.233L.881.595l.086.494-.366.351.506.072z"
              />
            </g>
            <g id="g32" transform="translate(6.667 5.77)">
              <path
                id="path34"
                d="M1.333 1.962l.227-.45.506-.072-.366-.351.086-.494-.453.233L.881.595l.086.494-.366.351.506.072z"
              />
            </g>
            <g id="g36" transform="translate(6.667 3.155)">
              <path
                id="path38"
                d="M1.333 1.962l.227-.45.506-.072-.366-.351.086-.494-.453.233L.881.595l.086.494-.366.351.506.072z"
              />
            </g>
            <g id="g40" transform="translate(5.333 .54)">
              <path
                id="path42"
                d="M1.333 1.962l.227-.451.506-.072-.366-.35.086-.495-.453.234L.881.594l.086.495-.366.35.506.072z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
);

export const menuLogout = (
  <g id="Icons/Menu/Logout" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Group" fill="#FFF" fillRule="nonzero" transform="translate(11 13)">
      <path
        id="Shape"
        d="M16.38.01h-4.202c-.357 0-.647.361-.647.808 0 .446.29.808.647.808h4.201c1.248 0 2.263 1.269 2.263 2.829V6.86c0 1.56-1.015 2.83-2.263 2.83H2.496L4.9 6.686a.956.956 0 0 0 0-1.143c-.252-.316-.662-.316-.914 0L.431 9.988a.956.956 0 0 0 0 1.142l3.555 4.445a.587.587 0 0 0 .457.236.587.587 0 0 0 .457-.236.956.956 0 0 0 0-1.143l-2.5-3.125h13.98c1.96 0 3.555-1.994 3.555-4.445V4.455c0-2.451-1.595-4.446-3.556-4.446z"
      />
    </g>
  </g>
);

export const menuMerchant = (
  <g id="Icons/Menu/Business" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Group-2" fillRule="nonzero" transform="translate(11 13)">
      <g id="Group">
        <path id="Rectangle-path" fill="#A77247" d="M0 3.556h20V16H0z" />
        <g id="Group-3">
          <path
            id="Shape"
            fill="#FFF"
            d="M6 6c-1.105 0-2-.448-2-1V0h4v5c0 .552-.895 1-2 1zm8 0c-1.105 0-2-.448-2-1V0h4v5c0 .552-.895 1-2 1z"
          />
          <path
            id="Shape"
            fill="#09C1C4"
            d="M18 6c-1.105 0-2-.448-2-1V0h4v5c0 .552-.895 1-2 1zM2 6C.895 6 0 5.552 0 5V0h4v5c0 .552-.895 1-2 1zm8 0c-1.105 0-2-.448-2-1V0h4v5c0 .552-.895 1-2 1z"
          />
        </g>
        <path id="Rectangle-path" fill="#B5F1F4" d="M2.5 8h9.167v5.333H2.5z" />
        <path id="Rectangle-path" fill="#1F2D3A" d="M14.167 8h4.167v8h-4.167z" />
      </g>
    </g>
  </g>
);

export const menuPassword = (
  <g id="Icons/Menu/Password" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Group" fill="#FFF" fillRule="nonzero" transform="translate(6 18)">
      <path
        id="Shape"
        d="M2.392.2h1.356l-.194 1.932L5.19 1.056l.656 1.181-1.765.762 1.765.752-.656 1.18-1.636-1.076.194 1.932H2.392l.194-1.932L.95 4.931l-.656-1.18 1.765-.752-1.765-.762.656-1.181 1.636 1.076zm7.902 0h1.356l-.194 1.932 1.636-1.076.656 1.181-1.764.762 1.764.752-.656 1.18-1.636-1.076.194 1.932h-1.356l.194-1.932-1.636 1.076-.656-1.18 1.765-.752-1.765-.762.656-1.181 1.636 1.076zm7.902 0h1.356l-.194 1.932 1.636-1.076.656 1.181-1.764.762 1.764.752-.656 1.18-1.636-1.076.194 1.932h-1.356l.194-1.932-1.636 1.076-.656-1.18 1.765-.752-1.765-.762.656-1.181 1.636 1.076zm7.902 0h1.356l-.194 1.932 1.636-1.076.656 1.181-1.764.762 1.764.752-.656 1.18-1.636-1.076.194 1.932h-1.356l.194-1.932-1.636 1.076L24 3.751l1.765-.752L24 2.237l.656-1.181 1.636 1.076z"
      />
    </g>
  </g>
);

export const menuSecurity = (
  <g id="Icons/Menu/Security" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="Group-3" fillRule="nonzero" transform="translate(12.5 7)">
      <g id="Group" transform="translate(1.5)">
        <g id="Group-4" transform="translate(11 5.5)">
          <path id="Rectangle-path" fill="#969696" d="M.798.955h1.413v.667H.798z" />
          <path id="Rectangle-path" fill="#D4D4D9" d="M.333 1.553h2.344v.65H.333z" />
        </g>
        <path
          id="Shape"
          fill="#D4D4D9"
          d="M13.677 6.531c0-3.584-3.063-6.505-6.83-6.505C3.085.026.018 2.943.018 6.53v5.795h2.344V6.531c0-2.353 2.01-4.272 4.485-4.272 2.471 0 4.486 1.914 4.486 4.272h2.344z"
        />
      </g>
      <g id="Group-2" transform="translate(0 8.667)">
        <g id="Group-11">
          <ellipse id="Oval-14" cx="8.5" cy="8.667" fill="#FBD25C" rx="8.5" ry="8.667" />
          <ellipse id="Oval-14" cx="8.5" cy="8.667" fill="#FBDE8A" rx="5.95" ry="6.067" />
        </g>
        <path
          id="Shape"
          fill="#2C4269"
          d="M10.133 7.654c0-.877-.745-1.587-1.666-1.587-.922 0-1.667.71-1.667 1.587 0 .598.352 1.11.863 1.381v1.859c0 .422.357.766.804.766.442 0 .804-.34.804-.766V9.035c.515-.27.862-.783.862-1.38z"
        />
      </g>
    </g>
  </g>
);

export const menuGoogleAuthenticator = (
  <g
    id="Icons/Companies/Google-Authenticator"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g id="9b025f409c" transform="translate(9.000000, 9.000000)" fillRule="nonzero">
      <path
        d="M23.390625,9.28945312 L12.0914063,9.28945312 L12.0914063,14.0742187 L18.2894531,14.0742187 C17.4210938,16.7144531 14.9320313,18.6234375 12,18.6234375 C8.34375,18.6234375 5.38007813,15.6597656 5.38007813,12.0035156 C5.38007813,8.34726562 8.34375,5.38007812 12,5.38007812 C13.5363281,5.38007812 14.9496094,5.90390625 16.0710938,6.77929688 L19.8257813,3.29179688 C17.7515625,1.42851563 15.009375,0.29296875 12,0.29296875 C5.53476563,0.29296875 0.29296875,5.53476563 0.29296875,12 C0.29296875,18.4652344 5.53476563,23.7070312 12,23.7070312 C18.4652344,23.7070312 23.7070312,18.4652344 23.7070312,12 C23.7070312,11.0648437 23.5980469,10.1578125 23.390625,9.28945312 Z"
        id="Path"
        fill="#DBDBDB"
      />
      <path
        d="M10.9207031,3.084375 C10.9207031,3.6804542 11.4039208,4.16367188 12,4.16367188 C12.5960792,4.16367188 13.0792969,3.6804542 13.0792969,3.084375 C13.0792969,2.69877935 12.8735841,2.34247428 12.5396485,2.14967645 C12.2057128,1.95687862 11.7942872,1.95687862 11.4603515,2.14967645 C11.1264159,2.34247428 10.9207031,2.69877935 10.9207031,3.084375 L10.9207031,3.084375 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M4.64882812,5.66835937 C4.64882812,6.26443858 5.1320458,6.74765625 5.728125,6.74765625 C6.3242042,6.74765625 6.80742187,6.26443858 6.80742187,5.66835937 C6.80742187,5.07228017 6.3242042,4.5890625 5.728125,4.5890625 C5.1320458,4.5890625 4.64882812,5.07228017 4.64882812,5.66835937 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M2.04023438,12 C2.04023436,12.3855956 2.24594718,12.7419007 2.5798828,12.9346985 C2.91381842,13.1274964 3.32524408,13.1274964 3.6591797,12.9346985 C3.99311532,12.7419007 4.19882814,12.3855956 4.19882813,12 C4.19882813,11.4039208 3.71561045,10.9207031 3.11953125,10.9207031 C2.52345205,10.9207031 2.04023438,11.4039208 2.04023438,12 L2.04023438,12 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M4.64882812,18.3140625 C4.64882812,18.9101417 5.1320458,19.3933594 5.728125,19.3933594 C6.3242042,19.3933594 6.80742187,18.9101417 6.80742187,18.3140625 C6.80742187,17.7179833 6.3242042,17.2347656 5.728125,17.2347656 C5.1320458,17.2347656 4.64882812,17.7179833 4.64882812,18.3140625 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M10.9207031,20.9402344 C10.9207031,21.5363136 11.4039208,22.0195313 12,22.0195313 C12.5960792,22.0195313 13.0792969,21.5363136 13.0792969,20.9402344 C13.0792969,20.5546387 12.8735841,20.1983337 12.5396485,20.0055358 C12.2057128,19.812738 11.7942872,19.812738 11.4603515,20.0055358 C11.1264159,20.1983337 10.9207031,20.5546387 10.9207031,20.9402344 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M17.2488281,18.3140625 C17.2488281,18.9101417 17.7320458,19.3933594 18.328125,19.3933594 C18.9242042,19.3933594 19.4074219,18.9101417 19.4074219,18.3140625 C19.4074219,17.7179833 18.9242042,17.2347656 18.328125,17.2347656 C17.7320458,17.2347656 17.2488281,17.7179833 17.2488281,18.3140625 Z"
        id="Path"
        fill="#F8F8F8"
      />
      <path
        d="M19.8996094,12 C19.8996094,12.5960792 20.382827,13.0792969 20.9789063,13.0792969 C21.5749855,13.0792969 22.0582031,12.5960792 22.0582031,12 C22.0582031,11.4039208 21.5749855,10.9207031 20.9789063,10.9207031 C20.382827,10.9207031 19.8996094,11.4039208 19.8996094,12 Z"
        id="Path"
        fill="#F8F8F8"
      />
    </g>
  </g>
);

export const menuTick = (
  <g id="Icons/Tick/Selected" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Fill-3"
      fill="#FFF"
      d="M27.519 13.77l-8.644 8.643-3.394-3.394a2.622 2.622 0 0 0-3.711 0 2.622 2.622 0 0 0 0 3.712l5.25 5.25a2.618 2.618 0 0 0 3.711-.001l10.5-10.499a2.622 2.622 0 0 0 0-3.711 2.622 2.622 0 0 0-3.712 0"
    />
  </g>
);

export const menuTransactions = (
  <g id="Icons/Menu/Transactions" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g id="list" fillRule="nonzero" transform="translate(12 11)">
      <path id="Shape" fill="#EFF1F4" d="M11.737.304H.36v19.324h16.843V5.321z" />
      <path id="Shape" fill="#FFF" d="M11.737 5.321h5.466L11.737.304z" />
      <g id="Group" fill="#57442F" transform="translate(5.684 6.957)">
        <path
          id="Rectangle-path"
          d="M0 .033h6.316v.703H0zm0 2.576h6.316v.702H0zm0 2.608h6.316v.703H0z"
        />
      </g>
    </g>
  </g>
);

export const menuTextMessage = (
  <g
    id="Icons/Menu/TextMessage"
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    // transform="translate(21, 21)"
    fontWeight="500"
  >
    <path d="m0 0h42v42h-42z" />
    <path d="m0 0h42v42h-42z" />
    <circle cx="21" cy="21" fill="#2c4269" r="21" />
    <path
      d="m21 11c5.523 0 10 4.012 10 8.96 0 2.562-1.2 4.872-3.123 6.505l1.54 4.445-5.917-2.272a11.12 11.12 0 0 1 -2.5.283c-5.523 0-10-4.012-10-8.96 0-4.951 4.477-8.961 10-8.961z"
      fill="#fff"
    />
  </g>
);

export const menuUSD = (
  <g id="Icons/Menu/US-Dollar" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g
      id="Icons/Currency/Dollar/White"
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".952"
      transform="translate(11 11)"
    >
      <path
        id="Shape"
        d="M11.143 9.221V4.088c1.33.357 2.286 1.32 2.286 2.448 0 .479.511.866 1.142.866.632 0 1.143-.387 1.143-.866 0-2.09-1.966-3.84-4.571-4.242v-.952c0-.478-.512-.866-1.143-.866s-1.143.388-1.143.866v.952c-2.605.403-4.571 2.152-4.571 4.242 0 2.091 1.966 3.84 4.571 4.242v5.134c-1.33-.358-2.286-1.32-2.286-2.449 0-.478-.511-.866-1.142-.866-.632 0-1.143.388-1.143.866 0 2.09 1.966 3.84 4.571 4.242v.953c0 .478.512.866 1.143.866s1.143-.388 1.143-.866v-.953c2.605-.402 4.571-2.151 4.571-4.242 0-2.09-1.966-3.84-4.571-4.242zM6.57 6.536c0-1.128.956-2.09 2.286-2.448v4.897c-1.33-.357-2.286-1.32-2.286-2.449zm4.572 9.376v-4.898c1.33.358 2.286 1.32 2.286 2.449 0 1.129-.956 2.091-2.286 2.449z"
      />
    </g>
  </g>
);

export const menuOne = (
  <g
    id="Icons/Menu/One"
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    transform="translate(21, 21)"
    fontWeight="500"
  >
    <text
      x="0"
      y="0"
      alignmentBaseline="middle"
      strokeWidth="0"
      textAnchor="middle"
      fill="#fff"
      fontSize="17px"
      fontWeight="bold"
    >
      1
    </text>
  </g>
);

export const menuTwo = (
  <g
    id="Icons/Menu/Two"
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    transform="translate(21, 21)"
    fontWeight="500"
  >
    <text
      x="0"
      y="0"
      alignmentBaseline="middle"
      strokeWidth="0"
      textAnchor="middle"
      fill="#fff"
      fontSize="17px"
      fontWeight="bold"
    >
      2
    </text>
  </g>
);
