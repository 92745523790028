import { errors } from '@ace/core-utils';
import { UI_NOTIFICATION } from '../actions';
import { addItem, removeItem } from './stateOps';

export const getNotifications = state => state.ui.notifications;

const notifications = (state = [], action) => {
  switch (action.type) {
    case UI_NOTIFICATION.REMOVE: {
      return removeItem(state, action.payload);
    }
    default:
  }

  if (!action.meta || !action.meta.notification) {
    // Only create an notification if demanded
    return state;
  }

  const createSuccessNotification = newNotification => ({
    ...newNotification,
    theme: 'success'
  });
  const createErrorNotification = error => {
    if (error._jjHandled) {
      // If we've handled already, don't add an extra notification
      return null;
    }
    // If the network is not available
    if (error.request && !error.response) {
      return {
        code: 'NETWORK_NOT_AVAILABLE',
        theme: 'error'
      };
    }
    // If the server returns an error, we get an error.response
    if (error.request && error.response && error.response.data) {
      // No need to use the message from the BE error, we'll use the code to translate
      const { data } = error.response;
      let { code, values } = data;
      if (errors.hasErrors(data)) {
        code = `${data.errors[0].code || code}`;
        values = `${data.errors[0].values || values}`;
        if (errors.hasNestedErrors(data)) {
          code = `${data.errors[0].code || code}`;
          values = `${data.errors[0].values || values}`;
        }
      }
      return {
        code,
        theme: 'error',
        values
      };
    }
    // If it's FE error
    return {
      code: error.code,
      message: error.message,
      theme: 'error',
      values: error.values
    };
  };

  const newNotification =
    action.meta.notification instanceof Error
      ? createErrorNotification(action.meta.notification)
      : createSuccessNotification(action.meta.notification);

  if (!newNotification) {
    return state;
  }

  return addItem(state, {
    ...newNotification,
    reportingEventId: action.meta.reportingEventId
  });
};

export default notifications;
