import ImageLoader from 'blueimp-load-image';

const validatorImgDimensions = img => {
  const imgConfig = {
    minHeight: 40,
    minWidth: 40,
    maxHeight: 1600,
    maxWidth: 1600
  };

  return new Promise((resolve, reject) => {
    if (!img) {
      return;
    }
    ImageLoader(
      img,
      canvas => {
        if (canvas.width < imgConfig.minWidth || canvas.height < imgConfig.minHeight) {
          const error = new Error('validatorImgDimensionsError');
          error.values = { minWidth: imgConfig.minWidth, minHeight: imgConfig.minHeight };
          reject(error);
        }
        resolve(canvas);
      },
      {
        canvas: true,
        maxHeight: imgConfig.maxHeight,
        maxWidth: imgConfig.maxWidth,
        orientation: true
      }
    );
  });
};

export default validatorImgDimensions;
