import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes, Field, Form, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Page, PageFooter, PageMain, Button, FieldInputs, CustomLink } from '@ace/core-components';
import CustomLinkWithTimer from './CustomLinkWithTimer';
import useTimer from './useTimer';

import Block from '../Block';
import messages from './messages';
import PageHeader from '../PageHeader';
import SecurityMethodsInfoLink from '../SecurityMethodsInfoLink';

import styles from './FillSmsCode.module.scss';
import asyncFormSubmit from '../../utils/asyncFormSubmit';

const gegTitleKey = (mode = 'confirm') => {
  if (mode === 'confirm') {
    return 'titleConfirmMode';
  }
  if (mode === 'confirm-signin-mfa') {
    return 'titleConfirmMode';
  }
  return 'title';
};

function FillSmsCode(props) {
  const {
    intl: { formatMessage },
    form,
    invalid,
    smsCode,
    handleSubmit,
    alternative,
    onAlternative,
    mode,
    submitting,
    phone,
    transaction,
    onSubmit,
    onResendCode
  } = props;

  const _onSubmit = React.useCallback(
    params => asyncFormSubmit(form, onSubmit, params).catch(() => {}),
    [form, onSubmit]
  );

  const onClickResendCode = React.useCallback(
    () => asyncFormSubmit(form, onResendCode).catch(() => {}),
    [form, onResendCode]
  );

  const [timeStr, timeDiff] = useTimer(transaction && transaction.expires);

  const isConfirmMode = mode === 'confirm';
  const isConfirmSigninMfaMode = mode === 'confirm-signin-mfa';
  const pageTitle = formatMessage(messages[gegTitleKey(mode)]);
  const submitLabel =
    isConfirmMode || isConfirmSigninMfaMode
      ? formatMessage(messages.submitButtonConfirmMode)
      : formatMessage(messages.submitButton);

  let intro = null;
  if (isConfirmSigninMfaMode) {
    intro = (
      <>
        <FormattedMessage {...messages.para1} values={{ phoneNumber: phone }} />
        <br />
        <br />
        <FormattedMessage {...messages.para2SignInMfaConfirmMode} />
      </>
    );
  } else {
    intro = <FormattedMessage {...messages.para1} values={{ phoneNumber: phone }} />;
  }

  return (
    <Page>
      <Helmet title={pageTitle} />
      <PageHeader intro={intro} title={pageTitle} />
      <PageMain>
        <div className={styles['confirm-code']}>
          <Form id={form} onSubmit={handleSubmit(_onSubmit)}>
            <Block>
              <FormattedMessage {...messages.confirmLabel}>
                {label => (
                  <Field component={FieldInputs.SmsCode} required label={label} name="smsCode" />
                )}
              </FormattedMessage>
              <div className={styles['code-controls']}>
                <CustomLinkWithTimer
                  disabled={timeDiff > 0 && transaction && transaction.attemptsLeft < 1}
                  href=""
                  onClick={onClickResendCode}
                  showTimer={timeDiff > 0 && transaction && transaction.attemptsLeft < 1}
                  timer={timeStr}
                >
                  {formatMessage(messages.resendCode)}
                </CustomLinkWithTimer>
              </div>
            </Block>
          </Form>
        </div>
      </PageMain>
      <PageFooter>
        {alternative && (
          <Block spacing="sm" align="center">
            <CustomLink onClick={() => onAlternative()} className={styles.smallFont}>
              {alternative === 'backup'
                ? formatMessage(messages.useBackupMethod)
                : formatMessage(messages.usePrimaryMethod)}
            </CustomLink>
          </Block>
        )}
        <Block spacing="sm" align="center">
          <SecurityMethodsInfoLink />
        </Block>

        <Button
          disabled={!(smsCode && smsCode.length >= 6) || invalid}
          submitting={submitting}
          form={form}
          theme="primary"
          title={submitLabel}
          type="submit"
          value={submitLabel}
          view="cta"
        />
      </PageFooter>
    </Page>
  );
}

FillSmsCode.propTypes = {
  ...propTypes,
  alternative: PropTypes.oneOf(['primary', 'backup']),
  intl: intlShape.isRequired,
  mode: PropTypes.oneOf(['validate', 'confirm', 'confirm-signin-mfa']),
  phone: PropTypes.string.isRequired,
  onAlternative: PropTypes.func,
  onResendCode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
FillSmsCode.defaultProps = {
  alternative: null,
  onAlternative: () => {},
  mode: 'confirm'
};

const getFormValue = formValueSelector(CONFIG.formNames.validatemfa);

const FillSmsCodeForm = reduxForm({
  form: CONFIG.formNames.validatemfa
})(FillSmsCode);

const mapStateToProps = state => ({
  smsCode: getFormValue(state, 'smsCode')
});

const FillSmsCodeFormConnected = connect(
  mapStateToProps,
  null
)(FillSmsCodeForm);

export default injectIntl(FillSmsCodeFormConnected);
