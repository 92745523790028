import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import { iconTypes } from '@ace/core-visuals';
import Amount from '../Amount';
import s from './ConversionData.module.scss';

const ConversionData = ({
  amount,
  asideAmount,
  asideCurrency,
  asideLabel,
  currency,
  id,
  label,
  parentId,
  visible
}) => {
  const thisId = components.generateId(id, parentId);

  if (!visible) {
    return null;
  }

  return (
    <div className={s.this} id={thisId}>
      <span className={s.label}>{label}</span>
      <div className={s.content}>
        <div className={s.mainAmount}>
          <Amount
            currency={currency}
            id="main-amount"
            parentId={thisId}
            value={amount}
            view="small"
          />
        </div>
        {(asideAmount || asideAmount === 0) && (
          <div className={s.aside}>
            <div className={s.asideAmount}>
              <Amount
                currency={asideCurrency}
                id="aside-amount"
                parentId={thisId}
                value={asideAmount}
                view="small"
              />
              {asideLabel && <span className={s.asideLabel}>{asideLabel}</span>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ConversionData.propTypes = {
  amount: PropTypes.string.isRequired,
  asideAmount: PropTypes.string,
  asideCurrency: PropTypes.string,
  asideLabel: PropTypes.string,
  currency: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  visible: PropTypes.bool
};

ConversionData.defaultProps = {
  asideAmount: '',
  asideCurrency: iconTypes.mBTC,
  asideLabel: '',
  currency: iconTypes.mBTC,
  id: 'conversion-data',
  parentId: '',
  visible: true
};

export default ConversionData;
