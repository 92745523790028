export const generateId = (origId, parentId) => {
  const id = parentId ? `${parentId}__${origId}` : origId;
  const encodedId = encodeURIComponent(id)
    .split(/%[a-zA-Z0-9]{2}/)
    .join('-')
    .replace('.', '')
    .replace('_-', '_');
  return encodedId;
};

export const getFieldMessage = (message, id, intl, i18n) => {
  const { formatMessage } = intl;
  let messageContent;
  if (message !== undefined) {
    messageContent = message;
  } else if (i18n[id]) {
    messageContent = formatMessage(i18n[id]);
  }
  return messageContent;
};
