export const sortItems = (a, b, value) => {
  const aLower = a.value.toLowerCase();
  const bLower = b.value.toLowerCase();
  const valueLower = value.toLowerCase();
  const queryPosA = aLower.indexOf(valueLower);
  const queryPosB = bLower.indexOf(valueLower);
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB;
  }
  return aLower < bLower ? -1 : 1;
};

export const matchItemToTerm = (item, value) =>
  item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
