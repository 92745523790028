import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import s from './Em.module.scss';

const Em = ({ children, id, parentId, theme }) => {
  const thisId = components.generateId(id, parentId);
  const thisClass = classNames({
    [s.this]: true,
    [s[`_${theme}`]]: theme
  });

  return (
    <strong className={thisClass} id={thisId}>
      {children}
    </strong>
  );
};

Em.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string,
  theme: PropTypes.oneOf(['error', 'success'])
};

Em.defaultProps = {
  id: 'em',
  parentId: '',
  theme: 'success'
};

export default Em;
