import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import { Icon, iconTypeArray } from '@ace/core-visuals';

import FieldAvatar from '../../fields/FieldAvatar';
import ToolbarItemWrap from './ToolbarItemWrap';

import s from '../Toolbar.module.scss';

const ToolbarItemAvatar = ({ avatar, icon, id, parentId, ...props }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <ToolbarItemWrap {...props} id={thisId}>
      <span className={s.avatar}>
        {avatar ? (
          <FieldAvatar avatar={avatar} parentId={thisId} />
        ) : (
          <Icon parentId={thisId} type={icon} />
        )}
      </span>
    </ToolbarItemWrap>
  );
};

ToolbarItemAvatar.propTypes = {
  avatar: PropTypes.string,
  icon: PropTypes.oneOf(iconTypeArray).isRequired,
  id: PropTypes.string,
  parentId: PropTypes.string
};

ToolbarItemAvatar.defaultProps = {
  avatar: '',
  id: 'item-avatar',
  parentId: ''
};

export default ToolbarItemAvatar;
