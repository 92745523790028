import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

import styles from '../Input.module.scss';

const Label = ({ error, hideLabel, htmlFor, id, label, parentId, sublabel }) => {
  const thisId = components.generateId(id, parentId);
  const labelClassName = classNames({
    [styles.labelSr]: hideLabel,
    [styles.label]: !hideLabel,
  });

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={htmlFor} id={thisId} className={labelClassName}>
      {label}
      {error && <span> - {error}</span>}
      {sublabel && <span className={styles.sublabel}>{sublabel}</span>}
    </label>
  );
};

Label.propTypes = {
  error: PropTypes.shape({}),
  hideLabel: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  sublabel: PropTypes.string,
};

Label.defaultProps = {
  error: undefined,
  hideLabel: false,
  id: 'label',
  parentId: '',
  sublabel: undefined,
};

export default Label;
