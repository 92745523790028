import React from 'react';
import PropTypes from 'prop-types';
import { components } from '@ace/core-utils';
import CustomLink from '../CustomLink';
import s from './HelperLink.module.scss';

const HelperLink = ({ id, parentId, ...rest }) => {
  const thisId = components.generateId(id, parentId);
  return (
    <div className={s.this} id={thisId}>
      <CustomLink {...rest} />
    </div>
  );
};

HelperLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  id: PropTypes.string,
  parentId: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};

HelperLink.defaultProps = {
  href: undefined,
  id: 'helper-link',
  parentId: '',
  to: undefined,
  onClick: undefined
};

export default HelperLink;
