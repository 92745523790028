import React from 'react';
import PropTypes from 'prop-types';

import { Button, Popup } from '@ace/core-components';

const ConfirmPopup = ({
  buttonType,
  buttonValue,
  form,
  intro,
  isActive,
  onButtonClick,
  onClose,
  submitting,
  theme,
  title
}) => (
  <Popup intro={intro} isActive={isActive} onClose={onClose} theme={theme} title={title}>
    <Button
      form={form}
      onClick={onButtonClick}
      submitting={submitting}
      theme="primary"
      title={buttonValue}
      type={buttonType}
      value={buttonValue}
      view="cta"
    />
  </Popup>
);

ConfirmPopup.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'submit']),
  buttonValue: PropTypes.string.isRequired,
  form: PropTypes.string,
  intro: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  submitting: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,

  onButtonClick: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

ConfirmPopup.defaultProps = {
  buttonType: 'button',
  form: undefined,
  isActive: false,
  submitting: false,
  theme: undefined,

  onButtonClick: undefined
};

export default ConfirmPopup;
