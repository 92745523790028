import { API_CONSUMER_REGISTER } from '@ace/core-modules/src/user/actions';
import { APP_AUTH_VALIDATE, API_MEDIA_TOKEN } from '../actions';

const initialState = {
  accessToken: '',
  decodedAccessToken: {},
  decodedIdToken: {},
  idToken: '',
  mediaToken: ''
};

// Tokens
export const getSavedTokenExp = state => state.tokens.decodedAccessToken.exp;
export const getAccessToken = state => state.tokens.accessToken;
export const getIdToken = state => state.tokens.idToken;
export const getMediaToken = state => state.tokens.mediaToken;

const tokens = (state = initialState, action) => {
  switch (action.type) {
    case APP_AUTH_VALIDATE.SUCCESS: {
      const { accessToken, decodedAccessToken, decodedIdToken, idToken } = action.payload;
      return {
        ...state,
        accessToken,
        decodedAccessToken,
        decodedIdToken,
        idToken
      };
    }
    case API_CONSUMER_REGISTER.SUCCESS: {
      const { mediaToken } = action.payload;
      return {
        ...state,
        mediaToken
      };
    }
    case API_MEDIA_TOKEN.SUCCESS:
      return {
        ...state,
        mediaToken: action.payload.mediaToken
      };

    default:
      return state;
  }
};

export default tokens;
