import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Scripts, View, CustomSwitch } from '@ace/core-components';
import { apiSignUpNewRequest, passwordResetNewRequest } from './store/actions/user';
import SignUp from './views/SignUp';
import SignUpConfirm from './views/SignUpConfirm';
import SetupMfa from './views/SetupMfa';
import GRCRules from './views/GRCRules';
import Login from './views/Login';
import SignOut from './views/SignOut';
import CheckEmail from './views/CheckEmail';
import PasswordReset from './views/PasswordReset';
import PasswordResetConfirm from './views/PasswordResetConfirm';
import UsernameRecover from './views/UsernameRecover';
import UsernameRecoverSuccess from './views/UsernameRecoverSuccess';
import AccountRecovery from './containers/AccountRecovery';
import UpdateEmailConfirm from './views/UpdateEmailConfirm';
import Elevate from './views/Elevate';
import SwitchLocale from './views/SwitchLocale';
import ErrorPage from './views/Error';
import AppRoutes from './routes';
import AclRoute from './components/AclRoute';
import getParams from './utils/getParams';
import PasswordChange from './views/PasswordChange';
import MaintenanceScreen from './views/Maintenance';
import OfflineCodes from './views/OfflineCodes';
import ExtraSecurity from './views/ExtraSecurity';
import ThemeLoader from './components/ThemeLoader';
import MfaAuthChallenge from './views/MfaAuthChallenge';
import ChangeUsername from './views/ChangeUsername';
import ChangeUsernameSuccess from './views/ChangeUsernameSuccess';
import SecurityMethodsInfo from './views/SecurityMethodsInfo';
import history from './utils/history';

const App = ({ requestApiSignUpNewRequest, requestPasswordResetNewRequest }) => {
  if (CONFIG._maintenanceMode) {
    return (
      <View>
        <Helmet titleTemplate="%s | Global Rewards City" defaultTitle="Global Rewards City" />
        <CustomSwitch>
          <Route exact path="/locale" component={SwitchLocale} />
          <MaintenanceScreen />
        </CustomSwitch>
        <Scripts />
        <ThemeLoader />
      </View>
    );
  }

  return (
    <View>
      <Helmet titleTemplate="%s | Global Rewards City" defaultTitle="Global Rewards City" />
      <CustomSwitch>
        <Route exact path={AppRoutes.signup.index} component={SignUp} />
        <Route exact path={AppRoutes.signup.confirm.index} component={SignUpConfirm} />
        <AclRoute route={{ path: AppRoutes.passphrase.setupMfa }} component={SetupMfa} />
        <Route exact path={AppRoutes.signup.extraSecurity.info} component={SecurityMethodsInfo} />
        <AclRoute
          route={{ path: AppRoutes.signup.extraSecurity.index }}
          component={ExtraSecurity}
        />
        <Route exact path={AppRoutes.grcRules.index} component={GRCRules} />
        <Route exact path={AppRoutes.email.index} component={CheckEmail} />
        <Route exact path={AppRoutes.signin.index} component={Login} />
        <AclRoute
          route={{ path: AppRoutes.signin.backupWarning }}
          component={props => <Login {...props} showBackupMethodPopup />}
        />

        <Route exact path={AppRoutes.signout.index} component={SignOut} />
        <Route
          exact
          path={AppRoutes.signup.success}
          render={props => <CheckEmail type="signup" {...props} />}
        />
        <Route
          exact
          path={AppRoutes.password.change.checkEmail}
          component={() => <CheckEmail title="changePasswordTitle" type="changePassword" />}
        />
        <Route
          exact
          path={AppRoutes.email.change.checkEmail}
          component={() => <CheckEmail type="changeEmail" />}
        />
        <Route
          exact
          path={AppRoutes.signup.link.expired}
          render={props => (
            <CheckEmail
              type="expired"
              title="titleExpired"
              {...props}
              newRequest={requestApiSignUpNewRequest}
            />
          )}
        />
        <Route
          exact
          path={AppRoutes.password.reset.link.expired}
          render={props => (
            <CheckEmail
              type="passwordResetLinkExpired"
              title="titleExpired"
              {...props}
              newRequest={requestPasswordResetNewRequest}
            />
          )}
        />
        <Route
          exact
          path={AppRoutes.passphrase.reset.link.expired}
          render={props => (
            <CheckEmail type="passphraseExpired" title="titleExpired" {...props} icon="fail" />
          )}
        />
        <Route
          exact
          path={AppRoutes.email.update.link.expired}
          render={props => (
            <CheckEmail
              type="emailExpired"
              title="titleExpired"
              {...props}
              newRequest="updateEmailNewRequest"
              icon="fail"
            />
          )}
        />
        <Route exact path={AppRoutes.password.reset.index} component={PasswordReset} />
        <Route exact path={AppRoutes.password.reset.confirm} component={PasswordResetConfirm} />
        <Route path={AppRoutes.email.reset} component={AccountRecovery} />
        <Route exact path={AppRoutes.email.change.confirm} component={UpdateEmailConfirm} />
        <Route exact path={AppRoutes.locale.index} component={SwitchLocale} />
        <Route
          exact
          path={AppRoutes.error.index}
          render={props => <ErrorPage error={getParams().code} {...getParams()} {...props} />}
        />
        <AclRoute
          exact
          route={{ path: AppRoutes.password.change.index }}
          component={PasswordChange}
        />
        <AclRoute route={{ path: AppRoutes.elevate.index }} component={Elevate} />
        <Route path={AppRoutes.mfaAuthChallenge.index} component={MfaAuthChallenge} />
        <AclRoute
          exact
          route={{ path: AppRoutes.passphrase.reset.index }}
          component={SignUpConfirm}
        />
        <Route
          path={AppRoutes.email.change.index}
          render={props => (
            <Redirect
              to={{
                ...props.location,
                pathname: AppRoutes.username.change.index,
              }}
            />
          )}
        />
        <AclRoute exact route={{ path: AppRoutes.offlineCodes.index }} component={OfflineCodes} />
        <AclRoute
          exact
          route={{ path: AppRoutes.offlineCodes.regenerate }}
          render={props => (
            <OfflineCodes
              {...props}
              regenerate
              onSuccess={() =>
                history.push({
                  ...props.location,
                  pathname: props.location.state.redirect,
                })
              }
            />
          )}
        />
        <AclRoute
          exact
          route={{ path: AppRoutes.username.change.index }}
          component={ChangeUsername}
        />
        <Route path={AppRoutes.username.change.success} component={ChangeUsernameSuccess} />
        <Route exact path={AppRoutes.username.recover.index} component={UsernameRecover} />
        <Route exact path={AppRoutes.username.recover.success} component={UsernameRecoverSuccess} />
        <Route render={props => <ErrorPage error="error404" {...props} />} />
      </CustomSwitch>
      <ThemeLoader />
      <Scripts />
    </View>
  );
};

const mapDispatchToProps = {
  requestApiSignUpNewRequest: apiSignUpNewRequest.request,
  requestPasswordResetNewRequest: passwordResetNewRequest.request,
};

// eslint-disable-next-line prettier/prettier
export default withRouter(connect(null, mapDispatchToProps)(App));
