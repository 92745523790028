import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FillOfflineCode from '../FillOfflineCode';
import FillPassphrase from '../FillPassphrase';
import FillSmsCode from '../FillSmsCode';
import FillTotp from '../FillTotp';

import { apiSendSmsCode } from '../../store/actions/user';

const ValidateMfa = memo(
  ({
    alternative,
    authChallenge,
    method,
    mode,
    onAlternative,
    onSubmit,
    phone,
    requestSmsCode,
    ...rest
  }) => {
    const validators = {
      offline_codes: () => (
        <FillOfflineCode
          alternative={alternative}
          onAlternative={onAlternative}
          onSubmit={onSubmit}
          mode={mode}
          {...rest}
        />
      ),
      passphrase: () => (
        <FillPassphrase
          alternative={alternative}
          onAlternative={onAlternative}
          onSubmit={onSubmit}
          mode={mode}
          {...rest}
        />
      ),
      phone: () => (
        <FillSmsCode
          alternative={alternative}
          onAlternative={onAlternative}
          onSubmit={onSubmit}
          phone={phone}
          mode={mode}
          {...rest}
        />
      ),
      totp: () => (
        <FillTotp
          alternative={alternative}
          onAlternative={onAlternative}
          onSubmit={onSubmit}
          mode={mode}
          {...rest}
        />
      ),
    };

    useEffect(() => {
      if (method === 'phone' && phone) {
        requestSmsCode({ params: { authChallenge } });
      }
    }, [method]);

    return validators[method] ? validators[method]() : null;
  },
);

ValidateMfa.defaultProps = {
  alternative: null,
  method: null,
  mode: 'confirm',
  onAlternative: () => {},
};

ValidateMfa.propTypes = {
  alternative: PropTypes.oneOf(['backup', 'primary']),
  method: PropTypes.oneOf(['offline_codes', 'passphrase', 'phone', 'totp']),
  mode: PropTypes.oneOf(['confirm', 'confirm-signin-mfa', 'validate']),
  onAlternative: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  requestSmsCode: apiSendSmsCode.request,
};

export default connect(null, mapDispatchToProps)(ValidateMfa);
