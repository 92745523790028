import React from 'react';
import { reduxForm, propTypes } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import Block from '../../../../components/Block';
import PageHeader from '../../../../components/PageHeader';
import messages from './messages';

const OldEmailPage = ({ handleSubmit, intl: { formatMessage }, pristine, submitting }) => (
  <Page>
    <FormattedMessage {...messages.title}>{title => <Helmet title={title} />}</FormattedMessage>
    <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
    <PageMain>
      <Block>
        <form id={CONFIG.formNames.recover} onSubmit={handleSubmit}>
          <FieldInputs.OldEmail required />
        </form>
      </Block>
    </PageMain>
    <PageFooter>
      <FormattedMessage {...messages.nextButton}>
        {label => (
          <Button
            disabled={pristine}
            form={CONFIG.formNames.recover}
            submitting={submitting}
            theme="primary"
            title={label}
            type="submit"
            value={label}
            view="cta"
          />
        )}
      </FormattedMessage>
    </PageFooter>
  </Page>
);

OldEmailPage.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

const OldEmailPageConnected = reduxForm({
  form: CONFIG.formNames.recover,
  destroyOnUnmount: false
})(OldEmailPage);

export default injectIntl(OldEmailPageConnected);
