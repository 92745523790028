const SettingsRoutes = {
  index: '/settings',
  indexAcl: {
    path: '/settings',
    acl: {
      g: true,
      c: true,
      m: true,
      rm: true
    }
  },
  language: {
    path: '/settings/language',
    acl: {
      g: true,
      c: true,
      m: true,
      rm: true
    }
  },
  currency: {
    path: '/settings/currency',
    acl: {
      g: false,
      c: false,
      m: true
    }
  },
  pinChange: {
    path: '/settings/pin-change',
    acl: {
      g: false,
      c: false,
      m: true
    }
  },
  privacy: '/settings/privacy',
  terms: '/settings/terms'
};

export default SettingsRoutes;
