import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageMain } from '@ace/core-components';

import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import SelectList from './SelectList';
import { switchLocale as switchLocaleAction } from '../../store/actions/locale';
import messages from './messages';
import getParams from '../../utils/getParams';
import history from '../../utils/history';

const SwitchLocale = ({ intl: { formatMessage }, locale, switchLocale }) => (
  <Page>
    <Helmet title={formatMessage(messages.title)} />
    <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
    <PageMain>
      <Block>
        <SelectList
          items={CONFIG.languageOptions.map(({ icon, label, value }) => ({
            active: value === locale,
            icon,
            id: value,
            label,
            value,
            onClick: () => {
              switchLocale(value);
              history.push(getParams().return);
            }
          }))}
        />
      </Block>
    </PageMain>
  </Page>
);

SwitchLocale.propTypes = {
  intl: intlShape.isRequired,
  locale: PropTypes.string.isRequired,
  switchLocale: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  locale: state.locale
});

const mapDispatchToProps = {
  switchLocale: switchLocaleAction.request
};

const SwitchLocaleConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchLocale);

export default injectIntl(SwitchLocaleConnected);
