import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import s from './Carousel.module.scss';

import i18n from './messages';

// TODO: Sort out handling of multiple carousel images.

const Carousel = ({ id, intl: { formatMessage }, images, parentId }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.this} id={thisId}>
      {images.length > 1 && (
        <ul className={s.indicators}>
          {images.map(image => {
            const indicatorClass = classNames({
              [s.indicator]: true,
              [s._active]: image.isActive
            });
            return (
              <li className={indicatorClass} key={`indicator_${image.id}`}>
                <a
                  className={s.indicatorLink}
                  href={`#slide_${image.id}`}
                  title={`${formatMessage(i18n.indicatorTitle)} ${image.title}`}
                >
                  <span className={s.indicatorContent} />
                </a>
              </li>
            );
          })}
        </ul>
      )}
      <ul className={s.slides}>
        {images.map(slide => {
          const slideClass = classNames({
            [s.slide]: true,
            [s._active]: true
          });
          const slideStyle = {
            backgroundImage: `url(${slide.url})`
          };
          return (
            <li
              className={slideClass}
              id={`slide_${slide.id}`}
              key={slide.url}
              style={slideStyle}
              title={slide.title}
            />
          );
        })}
      </ul>
    </div>
  );
};

Carousel.propTypes = {
  id: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  intl: intlShape.isRequired,
  parentId: PropTypes.string
};

Carousel.defaultProps = {
  id: 'carousel',
  parentId: ''
};

export { Carousel as CarouselWithoutIntl };

export default injectIntl(Carousel);
