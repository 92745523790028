const WalletRoutes = {
  business: {
    index: {
      path: '/wallet/business',
      acl: {
        g: false,
        c: false,
        m: true
      }
    },
    insuranceInfo: {
      path: '/wallet/business/insurance-balance-info',
      acl: {
        g: true,
        c: true,
        m: true
      }
    },
    transactions: {
      path: '/wallet/business/transactions',
      acl: {
        g: false,
        c: false,
        m: true
      }
    },
    // earnMore: {
    //   path: '/wallet/business/earn-more',
    //   acl: {
    //     g: false,
    //     c: false,
    //     m: true
    //   }
    // },
    // DepositFlow
    deposit: {
      index: {
        path: '/wallet/business/deposit',
        acl: {
          g: false,
          c: false,
          m: true
        }
      }
    },
    depositcc: {
      index: {
        path: '/wallet/business/deposit-cc',
        acl: {
          g: false,
          c: false,
          m: true
        }
      },
      complete: {
        path: '/wallet/business/deposit-cc/complete',
        acl: {
          g: false,
          c: false,
          m: true
        }
      }
    },
    // DistributeFlow
    distribute: {
      index: {
        path: '/wallet/business/reward',
        acl: {
          g: false,
          c: false,
          m: true
        }
      },
      calculate: {
        path: '/wallet/business/reward/calculate',
        acl: {
          g: false,
          c: false,
          m: true
        }
      },
      complete: {
        path: '/wallet/business/reward/complete',
        acl: {
          g: false,
          c: false,
          m: true
        }
      }
    }
  },
  user: {
    index: {
      path: '/wallet/user',
      acl: {
        g: false,
        c: true,
        m: false
      }
    },
    freeBitcoin: {
      path: '/wallet/user/free-bitcoin',
      acl: {
        g: false,
        c: true,
        m: false
      }
    }
  }
};

export default WalletRoutes;
