import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes } from 'redux-form';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import {
  Button,
  ButtonList,
  CustomLink,
  FieldInputs,
  HelperLink,
  Page,
  PageFooter,
  PageMain,
  Popup
} from '@ace/core-components';

import { signIn, apiSignUpNewRequest, apiSkipMfa } from '../../store/actions/user';
import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import Text from '../../components/Text';
import AppRoutes from '../../routes';
import messages from './messages';
import asyncFormSubmit from '../../utils/asyncFormSubmit';
import { buildUrlWithClientAndRedirect } from '../../utils/url';
import SetupBackupMethodWarningPopup from './SetupBackupMethodWarningPopup';
import { Flag } from '../../flags';

class Login extends React.Component {
  state = {
    showHelpPopup: false,
    showNotConfirmedPopup: false
  };

  toggleHelpPopup = () => this.setState(prevState => ({ showHelpPopup: !prevState.showHelpPopup }));

  toggleNotConfirmedPopup = () =>
    this.setState(prevState => ({ showNotConfirmedPopup: !prevState.showNotConfirmedPopup }));

  onResendConfirm = () => asyncFormSubmit(this.props.form, this.props.requestApiSignUpNewRequest);

  onLogin = () =>
    asyncFormSubmit(this.props.form, this.props.onLogin).catch(e => {
      if (e.code === 'errors.UserNotConfirmedException') {
        this.toggleNotConfirmedPopup();
      }
    });

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      intl: { formatMessage },
      showBackupMethodPopup,
      history,
      requestSkipMfa,
      location
    } = this.props;
    const { showHelpPopup, showNotConfirmedPopup } = this.state;
    return (
      <Page>
        <Helmet title={formatMessage(messages.title)} />
        <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
        <PageMain>
          <Text theme="grey">
            <FormattedMessage
              {...messages.dontHaveAccount}
              values={{
                signupLink: (
                  <CustomLink
                    to={{
                      pathname: AppRoutes.signup.index,
                      search: location.search
                    }}
                  >
                    {formatMessage(messages.signupLink)}
                  </CustomLink>
                )
              }}
            />
          </Text>
          <Block>
            <form id={CONFIG.formNames.login} onSubmit={handleSubmit(this.onLogin)}>
              <Flag
                name="username"
                render={() => <FieldInputs.Login autoComplete="username" required />}
                fallbackRender={() => <FieldInputs.Email autoComplete="username" required />}
              />
              <FieldInputs.EnterPassword autoComplete="current-password" required />
              <HelperLink onClick={this.toggleHelpPopup}>
                <FormattedMessage {...messages.helpLink} />
              </HelperLink>
            </form>
          </Block>
          <Popup
            intro={<FormattedMessage {...messages.notConfirmedPopup} />}
            title="not confirmed"
            onClose={this.toggleNotConfirmedPopup}
            isActive={showNotConfirmedPopup}
          >
            <Button
              submitting={submitting}
              theme="primary"
              title={formatMessage(messages.requestButton)}
              value={formatMessage(messages.requestButton)}
              view="cta"
              onClick={handleSubmit(this.onResendConfirm)}
            />
          </Popup>
          <Popup
            intro={<FormattedHTMLMessage {...messages.helpPopup} />}
            title="help"
            onClose={this.toggleHelpPopup}
            isActive={showHelpPopup}
          >
            <ButtonList
              buttons={[
                {
                  onClick: () =>
                    history.push(buildUrlWithClientAndRedirect(AppRoutes.password.reset.index)),
                  theme: 'primary',
                  title: formatMessage(messages.resetPasswordButton),
                  value: formatMessage(messages.resetPasswordButton),
                  view: 'cta'
                },
                {
                  onClick: () =>
                    history.push(buildUrlWithClientAndRedirect(AppRoutes.username.recover.index)),
                  theme: 'success',
                  title: formatMessage(messages.recoverUsernameButton),
                  value: formatMessage(messages.recoverUsernameButton),
                  view: 'cta'
                }
              ]}
            />
          </Popup>
          {showBackupMethodPopup && (
            <SetupBackupMethodWarningPopup
              isActive
              onClose={requestSkipMfa}
              onButtonClick={() => {
                history.push({ ...location, pathname: AppRoutes.signup.extraSecurity.backup });
              }}
            />
          )}
        </PageMain>
        <PageFooter absolute>
          <FormattedMessage {...messages.signInButton}>
            {label => (
              <Button
                disabled={pristine}
                form={CONFIG.formNames.login}
                submitting={submitting}
                theme="primary"
                title={label}
                type="submit"
                value={label}
                view="cta"
              />
            )}
          </FormattedMessage>
        </PageFooter>
      </Page>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired
};

const LoginForm = reduxForm({
  form: CONFIG.formNames.login
})(Login);

const mapDispatchToProps = {
  onLogin: signIn.request,
  requestApiSignUpNewRequest: apiSignUpNewRequest.request,
  requestSkipMfa: () => apiSkipMfa.request({})
};

// eslint-disable-next-line prettier/prettier
const LoginFormConnected = connect(
  null,
  mapDispatchToProps
)(LoginForm);

export default injectIntl(LoginFormConnected);
