import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes, formValueSelector } from 'redux-form';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import { changeUsername } from '../../store/actions/user';
import Block from '../../components/Block';
import messages from './messages';
import PageHeader from '../../components/PageHeader';
import ConfirmPopup from '../../components/ConfirmPopup';

import asyncFormSubmit from '../../utils/asyncFormSubmit';

function ChangeUsername(props) {
  const {
    handleSubmit,
    onSubmit,
    intl: { formatMessage },
    pristine,
    submitting,
    username,
    form,
  } = props;

  const [showPopup, setPopup] = React.useState(false);
  const onConfirmSubmit = React.useCallback(() => {
    if (!showPopup) {
      setPopup(true);

      return false;
    }

    setPopup(false);

    return asyncFormSubmit(form, onSubmit).catch(() => {});
  }, [showPopup, setPopup, form, onSubmit]);

  return (
    <Page>
      <FormattedMessage {...messages.title}>{title => <Helmet title={title} />}</FormattedMessage>
      <PageHeader title={formatMessage(messages.title)} intro={formatMessage(messages.intro)} />
      <PageMain>
        <Block>
          <form id={form} onSubmit={handleSubmit(onConfirmSubmit)}>
            <FormattedMessage {...messages.username}>
              {label => (
                <FieldInputs.Username
                  required
                  name="username"
                  label={label}
                  extraValidate={[
                    {
                      message: { id: messages.invalidUsernameWith86.id },
                      without: /^\+?86\d+$/,
                    },
                  ]}
                />
              )}
            </FormattedMessage>
          </form>
        </Block>
        <ConfirmPopup
          buttonType="submit"
          buttonValue={formatMessage(messages.confirmButton)}
          form={CONFIG.formNames.changeUsername}
          intro={<FormattedHTMLMessage {...messages.confirmMessage} values={{ username }} />}
          isActive={showPopup}
          onClose={() => setPopup(false)}
          submitting={submitting}
          theme="error"
          title={formatMessage(messages.confirmPopupTitle)}
        />
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.nextButton}>
          {label => (
            <Button
              disabled={pristine}
              form={form}
              submitting={submitting}
              theme="primary"
              title={label}
              type="submit"
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
}

ChangeUsername.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
};

const getFormValue = formValueSelector(CONFIG.formNames.changeUsername);

const UpdateEmailForm = reduxForm({
  form: CONFIG.formNames.changeUsername,
})(ChangeUsername);

const mapStateToProps = state => ({
  username: getFormValue(state, 'username'),
});

const mapDispatchToProps = {
  onSubmit: changeUsername.request,
};

// eslint-disable-next-line prettier/prettier
const UpdateEmailFormConnected = connect(mapStateToProps, mapDispatchToProps)(UpdateEmailForm);

export default injectIntl(UpdateEmailFormConnected);
