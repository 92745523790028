import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Authenticator App.',
  intro: 'Here are some things to know before you generate your authenticator passcode',
  nextButton: 'Next',
  par1: "You'll first need to download the {googleAuthenticator} app.",
  par2: "You'll always need your mobile device to generate your passcode.",
  par3:
    "Make sure your mobile device's time is automatically set. If your device's time is set manually, the new passcodes could be out of sync, resulting in an error.",
  googleAuthenticator: 'Google Authenticator'
});
