import React from 'react';

export const BTC = (
  <g id="Icons/Currency/Bitcoin/Black" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      d="M34.818 22.034c-.964-1.262-2.42-2.128-4.366-2.6 2.478-1.278 3.568-3.449 3.269-6.512-.1-1.11-.404-2.073-.911-2.889a5.744 5.744 0 0 0-2.06-1.968 11.257 11.257 0 0 0-2.819-1.136c-1.014-.261-2.179-.45-3.493-.568V0h-3.842v6.184c-.665 0-1.68.017-3.044.05V0H13.71v6.36a85.31 85.31 0 0 1-2.42.026L6 6.361v4.14h2.77c1.264 0 2.004.571 2.22 1.716v7.244c.166 0 .3.008.4.025h-.4v10.147c-.133.858-.615 1.287-1.447 1.287h-2.77L6 35.54h4.99c.316 0 .774.003 1.373.011a114.8 114.8 0 0 0 1.347.013V42h3.843v-6.36c.698.017 1.713.025 3.044.025V42h3.842v-6.436c1.364-.067 2.574-.193 3.63-.379a14.643 14.643 0 0 0 3.031-.871c.965-.395 1.772-.888 2.42-1.477.65-.588 1.185-1.337 1.61-2.246.424-.909.694-1.96.81-3.156.217-2.338-.157-4.139-1.122-5.4zM17.627 10.777a182.342 182.342 0 0 0 2.333-.038 14.6 14.6 0 0 1 1.447.063c.607.05 1.119.118 1.535.202.415.084.873.223 1.372.416.5.194.898.43 1.197.707.3.278.553.631.761 1.06.208.43.312.922.312 1.477 0 .471-.075.9-.224 1.287-.15.387-.374.711-.674.972a6.87 6.87 0 0 1-.923.682c-.316.193-.72.349-1.21.467-.49.117-.919.21-1.285.277a10.4 10.4 0 0 1-1.372.14c-.549.024-.965.041-1.248.05-.282.008-.67.008-1.16 0a94.597 94.597 0 0 0-.86-.013v-7.749h-.001zm10.492 17.189a3.596 3.596 0 0 1-.611 1.01 3.47 3.47 0 0 1-.986.757 8.453 8.453 0 0 1-1.185.53 9.45 9.45 0 0 1-1.384.353c-.542.101-1.016.173-1.423.215-.408.042-.894.076-1.46.101a32.37 32.37 0 0 1-1.322.038c-.316 0-.716-.005-1.198-.013a75.68 75.68 0 0 0-.923-.012v-8.532c.133 0 .528-.008 1.185-.025a64.363 64.363 0 0 1 1.61-.025c.415 0 .99.025 1.721.075.732.05 1.347.126 1.847.227a12.03 12.03 0 0 1 1.634.467c.59.21 1.069.467 1.435.77.366.303.673.69.923 1.16.25.472.374 1.01.374 1.616 0 .472-.079.9-.237 1.288z"
    />
  </g>
);

export const CNY = (
  <g id="Icons/Currency/Yuan/Black" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth="2"
      d="M33.857 24.03H23.143v-3.636h10.714c1.184 0 2.143-.814 2.143-1.818s-.96-1.818-2.143-1.818h-9.27l8.3-13.078c.56-.884.17-1.986-.871-2.462-1.042-.476-2.342-.146-2.903.739L21 14.74 12.887 1.957c-.562-.885-1.862-1.215-2.903-.74-1.042.477-1.432 1.58-.87 2.463l8.298 13.078h-9.27C6.96 16.758 6 17.572 6 18.576s.96 1.818 2.143 1.818h10.714v3.636H8.143C6.959 24.03 6 24.845 6 25.85s.96 1.818 2.143 1.818h10.714v11.515c0 1.004.96 1.818 2.143 1.818 1.183 0 2.143-.814 2.143-1.818V27.667h10.714c1.184 0 2.143-.814 2.143-1.818 0-1.005-.96-1.819-2.143-1.819z"
    />
  </g>
);

export const GBP = (
  <g
    id="Icons/Currency/Sterling/Black"
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
  >
    <path
      id="Path"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
      d="M23.333 1C16.9 1 11.667 5.078 11.667 10.09v9.092H9.333C8.045 19.182 7 19.996 7 21s1.045 1.818 2.333 1.818h2.334v14.546H9.333c-1.288 0-2.333.814-2.333 1.818S8.045 41 9.333 41H28c1.289 0 2.333-.814 2.333-1.818S29.29 37.364 28 37.364H16.333V22.818H28c1.289 0 2.333-.814 2.333-1.818S29.29 19.182 28 19.182H16.333V10.09c0-3.008 3.14-5.455 7-5.455s7 2.447 7 5.455c0 1.004 1.045 1.818 2.334 1.818 1.288 0 2.333-.814 2.333-1.818C35 5.078 29.766 1 23.333 1z"
    />
  </g>
);

export const HKD = (
  <g id="Icons/Currency/Dollar/Black" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth="2"
      d="M23.4 19.364V8.584c2.793.751 4.8 2.772 4.8 5.142 0 1.005 1.075 1.819 2.4 1.819s2.4-.814 2.4-1.819c0-4.39-4.13-8.063-9.6-8.908v-2C23.4 1.814 22.325 1 21 1s-2.4.814-2.4 1.818v2C13.13 5.663 9 9.336 9 13.727c0 4.39 4.13 8.063 9.6 8.908v10.78c-2.793-.751-4.8-2.772-4.8-5.143 0-1.004-1.075-1.818-2.4-1.818S9 27.268 9 28.272c0 4.39 4.13 8.064 9.6 8.909v2C18.6 40.187 19.675 41 21 41s2.4-.814 2.4-1.818V37.18c5.47-.845 9.6-4.518 9.6-8.909 0-4.39-4.13-8.063-9.6-8.908zm-9.6-5.637c0-2.37 2.007-4.392 4.8-5.142v10.284c-2.793-.751-4.8-2.772-4.8-5.142zm9.6 19.687V23.13c2.793.75 4.8 2.772 4.8 5.142 0 2.37-2.007 4.392-4.8 5.142z"
    />
  </g>
);

export const mBTC = (
  <g
    id="Icons/Currency/mBitcoin/Black"
    fill="currentColor"
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
  >
    <path
      id="m"
      d="M11.987 13.328c.897 0 1.615.307 2.155.92.54.613.81 1.44.81 2.48v5.6h-2.72v-4.763c0-.503-.123-.9-.367-1.19-.245-.29-.576-.436-.994-.436-.479.011-.858.2-1.138.567-.28.367-.42.846-.42 1.437v4.385H6.591v-4.763c0-.503-.123-.9-.367-1.19-.245-.29-.576-.436-.994-.436-.478.011-.86.2-1.146.567-.285.367-.428.846-.428 1.437v4.385H.952v-8.885h2.705v1.462c.286-.515.655-.906 1.108-1.175.454-.268.981-.402 1.582-.402.673 0 1.248.175 1.727.526.48.35.82.837 1.024 1.461.265-.657.645-1.152 1.139-1.486.494-.334 1.077-.501 1.75-.501z"
    />
    <path
      id="Shape"
      fillRule="nonzero"
      d="M40.818 22.034c-.964-1.262-2.42-2.128-4.366-2.6 2.478-1.278 3.568-3.449 3.269-6.512-.1-1.11-.404-2.073-.911-2.889a5.744 5.744 0 0 0-2.06-1.968 11.257 11.257 0 0 0-2.819-1.136c-1.014-.261-2.179-.45-3.493-.568V0h-3.842v6.184c-.665 0-1.68.017-3.044.05V0H19.71v6.36a85.31 85.31 0 0 1-2.42.026L12 6.361v4.14h2.77c1.264 0 2.004.571 2.22 1.716v7.244c.166 0 .3.008.4.025h-.4v10.147c-.133.858-.615 1.287-1.447 1.287h-2.77L12 35.54h4.99c.316 0 .774.003 1.373.011a114.8 114.8 0 0 0 1.347.013V42h3.843v-6.36c.698.017 1.713.025 3.044.025V42h3.842v-6.436c1.364-.067 2.574-.193 3.63-.379a14.643 14.643 0 0 0 3.031-.871c.965-.395 1.772-.888 2.42-1.477.65-.588 1.185-1.337 1.61-2.246.424-.909.694-1.96.81-3.156.217-2.338-.157-4.139-1.122-5.4zM23.627 10.777a182.342 182.342 0 0 0 2.333-.038 14.6 14.6 0 0 1 1.447.063c.607.05 1.119.118 1.535.202.415.084.873.223 1.372.416.5.194.898.43 1.197.707.3.278.553.631.761 1.06.208.43.312.922.312 1.477 0 .471-.075.9-.224 1.287-.15.387-.374.711-.674.972a6.87 6.87 0 0 1-.923.682c-.316.193-.72.349-1.21.467-.49.117-.919.21-1.285.277a10.4 10.4 0 0 1-1.372.14c-.549.024-.965.041-1.248.05-.282.008-.67.008-1.16 0a94.597 94.597 0 0 0-.86-.013v-7.749h-.001zm10.492 17.189a3.596 3.596 0 0 1-.611 1.01 3.47 3.47 0 0 1-.986.757 8.453 8.453 0 0 1-1.185.53 9.45 9.45 0 0 1-1.384.353c-.542.101-1.016.173-1.423.215-.408.042-.894.076-1.46.101a32.37 32.37 0 0 1-1.322.038c-.316 0-.716-.005-1.198-.013a75.68 75.68 0 0 0-.923-.012v-8.532c.133 0 .528-.008 1.185-.025a64.363 64.363 0 0 1 1.61-.025c.415 0 .99.025 1.721.075.732.05 1.347.126 1.847.227a12.03 12.03 0 0 1 1.634.467c.59.21 1.069.467 1.435.77.366.303.673.69.923 1.16.25.472.374 1.01.374 1.616 0 .472-.079.9-.237 1.288z"
    />
  </g>
);

export const USD = (
  <g id="Icons/Currency/Dollar/Black" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      id="Shape"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth="2"
      d="M23.4 19.364V8.584c2.793.751 4.8 2.772 4.8 5.142 0 1.005 1.075 1.819 2.4 1.819s2.4-.814 2.4-1.819c0-4.39-4.13-8.063-9.6-8.908v-2C23.4 1.814 22.325 1 21 1s-2.4.814-2.4 1.818v2C13.13 5.663 9 9.336 9 13.727c0 4.39 4.13 8.063 9.6 8.908v10.78c-2.793-.751-4.8-2.772-4.8-5.143 0-1.004-1.075-1.818-2.4-1.818S9 27.268 9 28.272c0 4.39 4.13 8.064 9.6 8.909v2C18.6 40.187 19.675 41 21 41s2.4-.814 2.4-1.818V37.18c5.47-.845 9.6-4.518 9.6-8.909 0-4.39-4.13-8.063-9.6-8.908zm-9.6-5.637c0-2.37 2.007-4.392 4.8-5.142v10.284c-2.793-.751-4.8-2.772-4.8-5.142zm9.6 19.687V23.13c2.793.75 4.8 2.772 4.8 5.142 0 2.37-2.007 4.392-4.8 5.142z"
    />
  </g>
);
