import React from 'react';
import PropTypes from 'prop-types';

import { components } from '@ace/core-utils';

const FieldFileAtom = ({ id, input, meta, inputRef, parentId, ...rest }) => {
  const thisId = components.generateId(id, parentId);

  return <input type="file" {...input} {...rest} ref={inputRef} value={undefined} id={thisId} />;
};

FieldFileAtom.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  inputRef: PropTypes.func,
  meta: PropTypes.shape({}).isRequired,
  parentId: PropTypes.string
};

FieldFileAtom.defaultProps = {
  id: 'render-field-file',
  inputRef: null,
  parentId: ''
};

export default FieldFileAtom;
