import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Button from '../../Button';
import Page from '../../structure/Page';
import PageHeader from '../../structure/PageHeader';
import PageMain from '../../structure/PageMain';
import TextBlock from '../../structure/TextBlock';
import PageFooter from '../../structure/PageFooter';

import i18n from './messages';

const ScreenComingSoon = ({ intl: { formatMessage } }) => (
  <Page>
    <PageHeader title={formatMessage(i18n.title)} />
    <PageMain>
      <TextBlock>{formatMessage(i18n.text)}</TextBlock>
    </PageMain>
    <PageFooter>
      <Button
        href="mailto:contact@ace.com?subject=Buy Bitcoin"
        target="_blank"
        theme="primary"
        value={formatMessage(i18n.contactUsButtonValue)}
        view="cta"
      />
    </PageFooter>
  </Page>
);

ScreenComingSoon.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ScreenComingSoon);
