import {
  SEND_SMS_CODE,
  RESEND_SMS_CODE,
  VALIDATE_PHONE_NUMBER,
  AUTH_CHALLENGE,
  ELEVATE,
  SETUP_PHONE_NUMBER
} from '../actions/user';

export const getSmsTransaction = state => {
  if (
    state.transactions.phone &&
    state.transactions.phone.expires &&
    state.transactions.phone.expires <= Date.now()
  ) {
    // eslint-disable-next-line no-console
    console.log('Sms transactional code expired. Return null', state.transactions.phone);
    return null;
  }
  return state.transactions.phone;
};

const initialState = {
  phone: null
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_PHONE_NUMBER.SUCCESS:
    case SEND_SMS_CODE.SUCCESS:
    case RESEND_SMS_CODE.SUCCESS: {
      return {
        ...state,
        phone: action.payload
      };
    }
    case AUTH_CHALLENGE.SUCCESS:
    case ELEVATE.SUCCESS:
    case VALIDATE_PHONE_NUMBER.SUCCESS: {
      return { ...state, phone: null };
    }

    default: {
      if (state.phone && state.phone.expires && state.phone.expires <= Date.now()) {
        // eslint-disable-next-line no-console
        console.log('Phone transactional code expired', state.phone);
        return {
          ...state,
          phone: null
        };
      }
      return state;
    }
  }
};

export default transactions;
