import { defineMessages } from 'react-intl';

export default defineMessages({
  alreadyHaveAccount: 'Already have an account? {loginLink}.',
  business: 'Merchant',
  captchaDescription:
    'MTCaptcha is a smart captcha service that helps to determine whether the user is a human or a bot protecting our system from spam and abuse. Enter the letters and numbers you see below. If your entry looks similar to what is shown i,n the picture, it will be recognized as valid, and you will be allowed to proceed.',
  confirmPasswordFieldLabel: 'passwords',
  consentLabel: 'I have read and accept Jemjar’s {privacy} and {terms}.',
  customer: 'Citizen',
  intro:
    'Welcome! To create your Global Rewards City account, please complete all the fields below.',
  introJemjar: 'Welcome to Jemjar! To create your account, please complete all the fields below.',
  invalidUsernameWith86: 'You can not use a Chinese phone number as a username',
  loginLink: 'Log In',
  nextButton: 'Next',
  privacy: 'Privacy policy',
  referrer: 'Referrer',
  referrerContinue: 'Continue',
  referrerHint:
    'Input your introducer’s username or GUID. If you don’t have an introducer, please leave this field empty.',
  referrerNotSelected: 'Referrer not selected!',
  referrerNotSelectedIntro: 'Are you sure that you don’t want to enter your referrer name?',
  roleLabel: 'I am a...*',
  terms: 'Terms and conditions',
  title: 'Sign Up.',
  usernameHint: 'Please keep your username safe to maintain account access.',
});
