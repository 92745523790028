import React from 'react';
import { reduxForm, propTypes } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { parse } from 'query-string';

import { Page, PageFooter, PageMain, Button, FieldInputs } from '@ace/core-components';

import asyncFormSubmit from '../../utils/asyncFormSubmit';
import { forgotPassword } from '../../store/actions/user';
import Block from '../../components/Block';
import PageHeader from '../../components/PageHeader';
import messages from './messages';
import { useFlag } from '../../flags';

function PasswordReset(props) {
  const {
    handleSubmit,
    initialValues,
    intl: { formatMessage },
    pristine,
    submitting,
    onSubmit,
    form,
  } = props;

  const onConfirmSubmit = React.useCallback(
    () => asyncFormSubmit(form, onSubmit).catch(() => {}),
    [],
  );
  const usernameFlag = useFlag('username');

  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
      <PageMain>
        <Block>
          <form id={CONFIG.formNames.reset} onSubmit={handleSubmit(onConfirmSubmit)}>
            {usernameFlag ? (
              <FieldInputs.UsernameOrEmail autoComplete="username" required />
            ) : (
              <FieldInputs.Email autoComplete="username" required />
            )}
          </form>
        </Block>
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.requestButton}>
          {label => (
            <Button
              disabled={pristine && !initialValues.username}
              form={CONFIG.formNames.reset}
              submitting={submitting}
              theme="primary"
              title={label}
              type="submit"
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
}

PasswordReset.propTypes = {
  ...propTypes,
  intl: intlShape.isRequired,
};

const PasswordResetWithIntl = injectIntl(PasswordReset);

const PasswordResetWithIntlForm = reduxForm({
  form: CONFIG.formNames.reset,
})(PasswordResetWithIntl);

const mapDispatchToProps = {
  onSubmit: forgotPassword.request,
};

const mapStateToProps = (_, props) => {
  const location = (props[0] && props[0].location) || props.location;
  const { email, username } = parse(location.search);

  return {
    initialValues: {
      username: email || username,
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const location = (ownProps[0] && ownProps[0].location) || ownProps.location;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ...ownProps[0],
    params: parse(location.search),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PasswordResetWithIntlForm);
