import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import { components } from '@ace/core-utils';

import viewfinder from '../viewfinder.svg';
import s from '../FieldIdNumber.module.scss';

const LoadableReader = Loadable({
  loader: () => import('react-qr-reader' /* webpackChunkName: "jj-qr" */),
  loading: () => null,
  render: (Comp, props) => <Comp.default ref={props.readerRef} {...props} />
});

const idNumberQrReader = ({ id, insufficientBalance, parentId, ...props }) => {
  const thisId = components.generateId(id, parentId);

  return (
    <div className={s.qrReader} id={thisId}>
      <LoadableReader
        className={s.qrReaderContainer}
        delay={250}
        showViewFinder={false}
        style={{}}
        {...props}
      />
      {!insufficientBalance && (
        <div
          className={s.qrReaderViewFinder}
          style={{
            backgroundImage: `url(${viewfinder})`
          }}
        />
      )}
    </div>
  );
};

idNumberQrReader.propTypes = {
  id: PropTypes.string,
  insufficientBalance: PropTypes.bool.isRequired,
  parentId: PropTypes.string
};

idNumberQrReader.defaultProps = {
  id: 'qr-reader',
  parentId: ''
};

export default idNumberQrReader;
