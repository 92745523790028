import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from '@ace/core-utils';

import { allIllustrations, illustrationTypeArray } from './all';

import './illustrations.scss';

const Illustration = ({ id, parentId, title, type }) => {
  if (!type) {
    return null;
  }

  const thisId = components.generateId(id, parentId);
  const illustrationClass = classNames('illustration', `illustration--${type}`);

  return (
    <img
      alt={title}
      className={illustrationClass}
      id={thisId}
      src={allIllustrations[type]}
      title={title}
    />
  );
};

Illustration.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  title: PropTypes.string.isRequired,
  /** Pass the type of the illustration required.
   * Should be used like `type={illustrationTypes.type}`
   * after importing illustrationTypes, to prevent spelling errors etc. */
  type: PropTypes.oneOf(illustrationTypeArray).isRequired
};

Illustration.defaultProps = {
  id: 'illustration',
  parentId: ''
};

export default Illustration;
