import React from 'react';
import { Button, Page, PageFooter, PageMain } from '@ace/core-components';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';

import Text from '../../components/Text';
import ListItemBig from '../../components/ListItemBig';
import PageHeader from '../../components/PageHeader';
import messages from './messages';
import styles from './SecurityMethodsInfo.module.scss';

function SecurityMethodsInfo(props) {
  const {
    intl: { formatMessage },
    onClose = () => props.history.goBack()
  } = props;

  return (
    <Page>
      <Helmet title={formatMessage(messages.title)} />
      <PageHeader intro={formatMessage(messages.intro)} title={formatMessage(messages.title)} />
      <PageMain classes={[styles.this]}>
        <ListItemBig id="part-1" marker={1}>
          <Text theme="grey-no-margin">
            <FormattedHTMLMessage {...messages.par1} />
          </Text>
        </ListItemBig>
        <ListItemBig id="part-2" marker={2}>
          <Text theme="grey-no-margin">
            <FormattedHTMLMessage {...messages.par2} />
          </Text>
        </ListItemBig>
        <ListItemBig id="part-3" marker={3}>
          <Text theme="grey-no-margin">
            <FormattedHTMLMessage {...messages.par3} />
          </Text>
        </ListItemBig>
      </PageMain>
      <PageFooter>
        <FormattedMessage {...messages.closeButton}>
          {label => (
            <Button
              onClick={() => onClose()}
              theme="primary"
              title={label}
              value={label}
              view="cta"
            />
          )}
        </FormattedMessage>
      </PageFooter>
    </Page>
  );
}

SecurityMethodsInfo.defaultProps = {};

SecurityMethodsInfo.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired
};

export default injectIntl(SecurityMethodsInfo);
