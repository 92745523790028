import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import Page from '../../structure/Page';
import PageHeader from '../../structure/PageHeader';
import PageMain from '../../structure/PageMain';
import TextBlock from '../../structure/TextBlock';

import i18n from './messages';

const ScreenLoading = ({ intl: { formatMessage } }) => (
  <Page>
    <PageHeader title={formatMessage(i18n.title)} back={false} />
    <PageMain>
      <TextBlock>{formatMessage(i18n.text)}</TextBlock>
    </PageMain>
  </Page>
);

ScreenLoading.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ScreenLoading);
