import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { signOut } from '../../store/actions/user';
import IconPage from '../../components/IconPage';
import messages from './messages';

class SignOut extends React.Component {
  componentDidMount() {
    this.props.onSignOut();
  }

  render() {
    return <IconPage title={messages.title} intro={messages.intro} icon="logout" />;
  }
}

SignOut.propTypes = {
  onSignOut: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onSignOut: signOut.request
};

const SignOutConnected = connect(
  null,
  mapDispatchToProps
)(SignOut);

export default injectIntl(SignOutConnected);
